import { FETCH_WORK_ORDERS_STOCKER_TABLE,  RECEIVE_WORK_ORDERS_STOCKER_TABLE, FAILED_WORK_ORDERS_STOCKER_TABLE,
            FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM, RECEIVE_WORK_ORDERS_STOCKER_INBOUND_ITEM, FAILED_WORK_ORDERS_STOCKER_INBOUND_ITEM,
            FETCH_WORK_ORDERS_STOCKER_OUTBOUND_ITEM, RECEIVE_WORK_ORDERS_STOCKER_OUTBOUND_ITEM, FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM,
            RECEIVE_WORK_ORDERS_STOCKER_OUTBOUND_ITEM_ONE_ONLY, RECEIVE_WORK_ORDERS_STOCKER_INBOUND_ITEM_ONE_ONLY} from '../../actions/transporter/aTransporter-WoStocker'
            
const defaultWorkOrdersStockerState = {
    workOrdersStocker: [],
    workOrderStockerSelect: [],
    inProgress: false,
    success: true, 
    totalWO: 0, 
}

export function fetchWorkOrdersStocker(state = defaultWorkOrdersStockerState, action){
    switch (action.type) {
        case FETCH_WORK_ORDERS_STOCKER_TABLE:
            return ({workOrdersStocker: [], success: false, inProgress: true, totalWO: 0})
        case RECEIVE_WORK_ORDERS_STOCKER_TABLE:
            const {payload} = action
            let _total = 0
            let selectionResult = []
            let passed = true
            if(!payload)
                passed = false
            if(!Array.isArray(payload) && passed)
                passed = false
            if(payload.length > 0 && passed){
                _total = payload.length
                payload.forEach(r => 
                    selectionResult.push({
                        'label': `${r.outbound_number} to ${r.loc_name}, 
                                 Dept.: ${r.dept_time? r.dept_time : "-"} - Arrival: ${r.arrival_time? r.arrival_time : "-"}`,
                        'number_and_dest':  `${r.outbound_number} to ${r.loc_name}`,
                        'dept_and_arrv': `Departure on ${r.dept_time? r.dept_time : "-"},  Arrival on ${r.arrival_time? r.arrival_time : "-"}`,
                        'arrv': r.arrival_time,
                        'id_outbound_stocker': r.id_outbound_stocker,
                        'value' : r.id
                    })
                )
            }
            return Object.assign({}, state, {workOrdersStocker: action.payload, workOrderStockerSelect: selectionResult,
                                                success: true, inProgress: false, totalWO: _total})
        case FAILED_WORK_ORDERS_STOCKER_TABLE:
            return ({workOrdersStocker: [], success: false, inProgress: false, totalWO: 0})
        default:
            return state
    }
}

function getUniqueDeliveryItemSet(boundsData){
    boundsData.map((data, index) => {
        let filterByItemIdAndQty = boundsData.filter(bou => 
            bou.id === data.id
        )
        if(filterByItemIdAndQty.length > 1)
            boundsData.splice(index, filterByItemIdAndQty.length-1)
        return data
    })
    return boundsData
}

const defaultWoInbound = {
    woInboundData: null,
    woInboundSelect: [],
    success: true,
    inProgress: false,
}

export function woInboundItemDetail(state = defaultWoInbound, action){
    switch (action.type) {
        case FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM:
            return ({woInboundData: [], success: true,woInboundSelect: [], inProgress: true})
        case RECEIVE_WORK_ORDERS_STOCKER_INBOUND_ITEM:
            let inboundItemObj = action.payload
            Object.keys(inboundItemObj).map(key => {
                inboundItemObj[key] = getUniqueDeliveryItemSet(inboundItemObj[key])
                return key
             })
            return Object.assign({}, state, {woInboundData: action.payload, success: true,
                                            woInboundSelect: [], inProgress: false})
        case RECEIVE_WORK_ORDERS_STOCKER_INBOUND_ITEM_ONE_ONLY:
                return Object.assign({}, state, {woInboundData: action.payload, success: true,
                                                woInboundSelect: [], inProgress: false})
        case FAILED_WORK_ORDERS_STOCKER_INBOUND_ITEM:
            return ({woInboundData: [], success: false,woInboundSelect: [], inProgress: false})
        default:
            return state
    }
}

const defaultWoOutbound = {
     woOutboundData: null,
     woOutboundSelect: [],
     success: true,
     inProgress: false,
 }
 
 export function woOtuboundItemDetail(state = defaultWoOutbound, action){
     switch (action.type) {
         case FETCH_WORK_ORDERS_STOCKER_OUTBOUND_ITEM:
             return ({woOutboundData: [], success: true, woOutboundSelect: [], inProgress: true})
         case RECEIVE_WORK_ORDERS_STOCKER_OUTBOUND_ITEM:
            let outboundItemObj = action.payload
            Object.keys(outboundItemObj).map(key => {
                outboundItemObj[key] = getUniqueDeliveryItemSet(outboundItemObj[key])
                return key
             })
             return Object.assign({}, state, {woOutboundData: action.payload, success: true, 
                                                    woOutboundSelect: [], inProgress: false})
        case RECEIVE_WORK_ORDERS_STOCKER_OUTBOUND_ITEM_ONE_ONLY:
            return Object.assign({}, state, {woOutboundData: action.payload, success: true, 
                                                woOutboundSelect: [], inProgress: false})
         case FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM:
             return ({woOutboundData: [], success: false, woOutboundSelect: [], inProgress: false})
         default:
             return state
     }
 }
