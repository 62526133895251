import { FETCH_LL_SENSOR_FABRICATOR_LIST, RECEIVE_LL_SENSOR_FABRICATOR_LIST, FAILED_LL_SENSOR_FABRICATOR_LIST } from '../../actions/landlord/aLandLord-SensorFabricatorList'

const defaultLLSensorFabricatorState = {
    llSensorFabricatorData: [],
    llTotalSensorFabricator: 0,
    llSensorFabricatorSelect: [],
    success: true,
    inProgress: false,
}

export function fetchLLSensorFabricatorList(state = defaultLLSensorFabricatorState, action){
    switch (action.type) {
        case FETCH_LL_SENSOR_FABRICATOR_LIST:
            return ({llSensorFabricatorData: [], success: true, llTotalSensorFabricator: 0, llSensorFabricatorSelect: [], inProgress: true})
        case RECEIVE_LL_SENSOR_FABRICATOR_LIST:
            var _totalSensorFabricator = action.payload.result.length;
            var _selectSensorFabricator = []
            if(action.payload.result !== []) {
                var _locationData = action.payload.result

                _locationData.forEach((p, index) => {
                    var _loc = {
                        'label': _locationData[index].sensor_fabricator_name + ' - ' + _locationData[index].sensor_fabricator_desc,
                        'value': _locationData[index].id
                    }
                    _selectSensorFabricator.push(_loc)
                })
            }
            return Object.assign({}, state, {llSensorFabricatorData: action.payload, success: true, llTotalSensorFabricator: _totalSensorFabricator, llSensorFabricatorSelect: _selectSensorFabricator, inProgress: false})
        case FAILED_LL_SENSOR_FABRICATOR_LIST:
            return ({llSensorFabricatorData: [], success: false, llTotalSensorFabricator: 0, llSensorFabricatorSelect: [], inProgress: false})
        default:
            return state
    }
}