import { FETCH_STCK_DISPOSAL_ITEM_LIST, RECEIVE_STCK_DISPOSAL_ITEM_LIST, FAILED_STCK_DISPOSAL_ITEM_LIST } from '../../actions/stocker/stck.disposalitem.act'

const defaultStckDisposalItemState = {
    stckDisposalItemData: [],
    stckTotalDisposalItem: 0,
    stckDisposaltemSelect: [],
    success: true,
    inProgress: false,
}

export function StckDisposalItem(state = defaultStckDisposalItemState, action){
    switch (action.type) {
        case FETCH_STCK_DISPOSAL_ITEM_LIST:
            return ({stckDisposalItemData: [], success: true, stckTotalDisposalItem: 0, stckDisposaltemSelect: [], inProgress: true})
        case RECEIVE_STCK_DISPOSAL_ITEM_LIST:
            var _totalDisposalItem = action.payload.result.length;
            var _stckDisposaltemSelect = []
            if(action.payload.result !== []) {
                var _dItemData = action.payload.result

                _dItemData.forEach((p, index) => {
                    var _loc = {
                        'label': _dItemData[index].disposal_order_number,
                        'value': _dItemData[index].id,
                        "id": _dItemData[index].id,
                        "id_company": _dItemData[index].id_company,
                        "id_administration_office": _dItemData[index].id_administration_office,
                        "admin_office_name": _dItemData[index].admin_office_name,
                        "admin_office_address": _dItemData[index].admin_office_address,
                        "admin_office_main_phone_number": _dItemData[index].admin_office_main_phone_number,
                        "id_warehouse": _dItemData[index].id_warehouse,
                        "warehouse_name": _dItemData[index].warehouse_name,
                        "warehouse_address": _dItemData[index].warehouse_address,
                        "id_warehouse_area": _dItemData[index].id_warehouse_area,
                        "warehouse_area_name": _dItemData[index].warehouse_area_name,
                        "warehouse_area_size": _dItemData[index].warehouse_area_size,
                        "disposal_order_number": _dItemData[index].disposal_order_number,
                        "disposal_order_issue_date": _dItemData[index].disposal_order_issue_date,
                        "id_disposal_type": _dItemData[index].id_disposal_type,
                        "disposal_method_type": _dItemData[index].disposal_method_type,
                        "expected_item_count": _dItemData[index].expected_item_count,
                        "realization_item_count": _dItemData[index].realization_item_count,
                        "expected_qty": _dItemData[index].expected_qty,
                        "realization_qty": _dItemData[index].realization_qty,
                        "id_unit": _dItemData[index].id_unit,
                        "unit_name": _dItemData[index].unit_name,
                        "expected_disposal_price": _dItemData[index].expected_disposal_price,
                        "realization_disposal_price": _dItemData[index].realization_disposal_price,
                        "id_vendor_disposal": _dItemData[index].id_vendor_disposal,
                        "vendor_disposal_name": _dItemData[index].vendor_disposal_name,
                        "vendor_disposal_address": _dItemData[index].vendor_disposal_address,
                        "vendor_disposal_main_phone_number": _dItemData[index].vendor_disposal_main_phone_number,
                        "expected_total_item_price": _dItemData[index].expected_total_item_price,
                        "realization_total_item_price": _dItemData[index].realization_total_item_price,
                        "expected_total_disposal_amount": _dItemData[index].expected_total_disposal_amount,
                        "realization_total_disposal_amount": _dItemData[index].realization_total_disposal_amount,
                    }
                    _stckDisposaltemSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckDisposalItemData: action.payload, success: true, stckTotalDisposalItem: _totalDisposalItem, stckDisposaltemSelect: _stckDisposaltemSelect, inProgress: false})
        case FAILED_STCK_DISPOSAL_ITEM_LIST:
            return ({stckDisposalItemData: [], success: false, stckTotalDisposalItem: 0, stckDisposaltemSelect: [], inProgress: false})
        default:
            return state
    }
}