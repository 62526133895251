export const FETCH_DEVICE_LIST = 'FETCH_DEVICE_LIST'
export const RECEIVE_DEVICE_LIST = 'RECEIVE_DEVICE_LIST'
export const FAILED_DEVICE_LIST = 'FAILED_DEVICE_LIST'
export const CREATE_DEVICE_TYPE = 'CREATE_DEVICE_TYPE'
export const EDIT_DEVICE_TYPE = 'EDIT_DEVICE_TYPE'
export const DELETE_DEVICE_TYPE = 'DELETE_DEVICE_TYPE'
export const FETCH_DEVICE_INSTALLATION_LIST = 'FETCH_SENSOR_INSTALLATION_LIST'
export const RECEIVE_DEVICE_INSTALLATION_LIST = 'RECEIVE_SENSOR_INSTALLATION_LIST'
export const FAILED_DEVICE_INSTALLATION_LIST = 'FAILED_SENSOR_INSTALLATION_LIST'
export const FETCH_DEVICE_FABRICATOR_LIST = 'FETCH_DEVICE_FABRICATOR_LIST'
export const RECEIVE_DEVICE_FABRICATOR_LIST = 'RECEIVE_DEVICE_FABRICATOR_LIST'
export const FAILED_DEVICE_FABRICATOR_LIST = 'FAILED_DEVICE_FABRICATOR_LIST'
