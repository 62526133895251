import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_MECHANIC_TABLE = 'FETCH_MECHANIC_TABLE'
export const RECEIVE_MECHANIC_TABLE = 'RECEIVE_MECHANIC_TABLE'
export const FAILED_MECHANIC_TABLE = 'FAILED_MECHANIC_TABLE'

export function fetchMechanic(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;


    return (dispatch, getState) => {
        dispatch({type: FETCH_MECHANIC_TABLE});
        axios({
            method: 'post',
            url: API_LINKS.MECHANIC_LIST_URL,
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_MECHANIC_TABLE,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_MECHANIC_TABLE
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_MECHANIC_TABLE
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_MECHANIC_TABLE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_MECHANIC_TABLE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_MECHANIC_TABLE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_MECHANIC_TABLE
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}