import axios from "axios";
import {LANDLORD_SENSOR_REPORT} from '../../config/landlordLinks.jsx'
import {toast} from 'react-toastify'
// import moment from 'moment';

export const FETCH_LANDLORD_SENSOR_REPORT = 'FETCH_LANDLORD_SENSOR_REPORT'
export const RECEIVE_LANDLORD_SENSOR_REPORT = 'RECEIVE_LANDLORD_SENSOR_REPORT'
export const FAILED_LANDLORD_SENSOR_REPORT = 'FAILED_LANDLORD_SENSOR_REPORT'

export function landlord_fetchLandlordSensorReadings(_sensorId, _startDate, _endDate){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    console.log(_startDate, _endDate)

    // var startValue = moment(_startDate).format("MM/DD/YYYY").toString()
    // var endValue = moment(_endDate).format("MM/DD/YYYY").toString()

    var startValue = _startDate
    var endValue = _endDate

    return (dispatch, getState) => {
        dispatch({type: FETCH_LANDLORD_SENSOR_REPORT});
        axios({
            method: 'post',
            url: LANDLORD_SENSOR_REPORT,
            headers: {
                Authorization: auth
            },
            data : {
                id: 'ENV0001',
                start_date: startValue,
                end_date: endValue,
            }
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_LANDLORD_SENSOR_REPORT,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_LANDLORD_SENSOR_REPORT
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_LANDLORD_SENSOR_REPORT
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_LANDLORD_SENSOR_REPORT
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_LANDLORD_SENSOR_REPORT
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_LANDLORD_SENSOR_REPORT
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_LANDLORD_SENSOR_REPORT
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}