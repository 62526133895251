import { FETCH_STCK_BRANDS_LIST, RECEIVE_STCK_BRANDS_LIST, FAILED_STCK_BRANDS_LIST } from '../../actions/stocker/stck.brands.act'

const defaultStckBrandsState = {
    stckBrandsData: [],
    stckTotalBrands: 0,
    stckBrandsSelect: [],
    success: true,
    inProgress: false,
}

export function StckBrands(state = defaultStckBrandsState, action){
    switch (action.type) {
        case FETCH_STCK_BRANDS_LIST:
            return ({stckBrandsData: [], success: true, stckTotalBrands: 0, stckBrandsSelect: [], inProgress: true})
        case RECEIVE_STCK_BRANDS_LIST:
            var _totalUnits = action.payload.result.length;
            var _stckBrandsSelect = []
            if(action.payload.result !== []) {
                var _brandsData = action.payload.result

                _brandsData.forEach((p, index) => {
                    var _loc = {
                        'label': _brandsData[index].brand_name,
                        'value': _brandsData[index].id
                    }
                    _stckBrandsSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckBrandsData: action.payload, success: true, stckTotalBrands: _totalUnits, stckBrandsSelect: _stckBrandsSelect, inProgress: false})
        case FAILED_STCK_BRANDS_LIST:
            return ({stckBrandsData: [], success: false, stckTotalBrands: 0, stckBrandsSelect: [], inProgress: false})
        default:
            return state
    }
}