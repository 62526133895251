import { FETCH_STCK_TRACKER_TYPES_LIST, RECEIVE_STCK_TRACKER_TYPES_LIST, FAILED_STCK_TRACKER_TYPES_LIST } from '../../actions/stocker/stck.trackertypes.act'

const defaultStckTrackerTypesState = {
    stckTrackerTypesData: [],
    stckTotalTrackerTypes: 0,
    stckTrackerTypesSelect: [],
    success: true,
    inProgress: false,
}

export function StckTrackerTypes(state = defaultStckTrackerTypesState, action){
    switch (action.type) {
        case FETCH_STCK_TRACKER_TYPES_LIST:
            return ({stckTrackerTypesData: [], success: true, stckTotalTrackerTypes: 0, stckTrackerTypesSelect: [], inProgress: true})
        case RECEIVE_STCK_TRACKER_TYPES_LIST:
            var _totalTrackerTypes = action.payload.result.length;
            var _stckTrackerTypesSelect = []
            if(action.payload.result !== []) {
                var _trackerTypeData = action.payload.result

                _trackerTypeData.forEach((p, index) => {
                    var _loc = {
                        'label': _trackerTypeData[index].tracker_type + ' - ' + _trackerTypeData[index].tracker_desc,
                        'value': _trackerTypeData[index].id
                    }
                    _stckTrackerTypesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckTrackerTypesData: action.payload, success: true, stckTotalTrackerTypes: _totalTrackerTypes, stckTrackerTypesSelect: _stckTrackerTypesSelect, inProgress: false})
        case FAILED_STCK_TRACKER_TYPES_LIST:
            return ({stckTrackerTypesData: [], success: false, stckTotalTrackerTypes: 0, stckTrackerTypesSelect: [], inProgress: false})
        default:
            return state
    }
}