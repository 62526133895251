import { FETCH_STCK_VENDOR_PIC_LIST, RECEIVE_STCK_VENDOR_PIC_LIST, FAILED_STCK_VENDOR_PIC_LIST } from '../../actions/stocker/stck.vendorpic.act'

const defaultStckVendorPicState = {
    stckVendorPicData: [],
    stckTotalVendorPic: 0,
    stckVendorPicSelect: [],
    success: true,
    inProgress: false,
}

export function StckVendorPic(state = defaultStckVendorPicState, action){
    switch (action.type) {
        case FETCH_STCK_VENDOR_PIC_LIST:
            return ({stckVendorPicData: [], success: true, stckTotalVendorPic: 0, stckVendorPicSelect: [], inProgress: true})
        case RECEIVE_STCK_VENDOR_PIC_LIST:
            var _totalVendorBank = action.payload.result.length;
            var _stckVendorPicSelect = []
            if(action.payload.result !== []) {
                var _vendorPicData = action.payload.result

                _vendorPicData.forEach((p, index) => {
                    var _loc = {
                        'label': _vendorPicData[index].name + ' - ' + _vendorPicData[index].pic_position_name,
                        'value': _vendorPicData[index].id,
                        'id_vendor': _vendorPicData[index].id_vendor,
                        'id_company': _vendorPicData[index].id_company,
                        'id_pic_position': _vendorPicData[index].id_pic_position,
                        'name': _vendorPicData[index].name,
                        'email': _vendorPicData[index].email,
                        'phone_number': _vendorPicData[index].phone_number,
                        'whatsapp_number': _vendorPicData[index].whatsapp_number,
                        'remark': _vendorPicData[index].remark
                    }
                    _stckVendorPicSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckVendorPicData: action.payload, success: true, stckTotalVendorPic: _totalVendorBank, stckVendorPicSelect: _stckVendorPicSelect, inProgress: false})
        case FAILED_STCK_VENDOR_PIC_LIST:
            return ({stckVendorPicData: [], success: false, stckTotalVendorPic: 0, stckVendorPicSelect: [], inProgress: false})
        default:
            return state
    }
}