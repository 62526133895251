import { FETCH_USER_VERIFY, RECEIVE_USER_VERIFY, FAILED_USER_VERIFY}
from '../../actions/aUser-Verify'

const defaultVerifyState = {
    result: [],
    success: false,
    tokenExpired: false
}

export function userVerification(state = defaultVerifyState, action){
    switch (action.type) {
    case FETCH_USER_VERIFY:
        return ({result: [], success: false})
    case RECEIVE_USER_VERIFY:
        return Object.assign({}, state, {result: action.payload, success: true})
    case FAILED_USER_VERIFY:
        return ({result: [], success: false, tokenExpired:true})    
    default:
        return state
    }
}