import { FETCH_STCK_PAYMENT_TERM_LIST, RECEIVE_STCK_PAYMENT_TERM_LIST, FAILED_STCK_PAYMENT_TERM_LIST } from '../../actions/stocker/stck.paymentterm.act'

const defaultStckPaymentTermState = {
    stckPaymentTermData: [],
    stckTotalPaymentTerm: 0,
    stckPaymentTermSelect: [],
    success: true,
    inProgress: false,
}

export function StckPaymentTerm(state = defaultStckPaymentTermState, action){
    switch (action.type) {
        case FETCH_STCK_PAYMENT_TERM_LIST:
            return ({stckPaymentTermData: [], success: true, stckTotalPaymentTerm: 0, stckPaymentTermSelect: [], inProgress: true})
        case RECEIVE_STCK_PAYMENT_TERM_LIST:
            var _totalPaymentTerm = action.payload.result.length;
            var _stckPaymentTermSelect = []
            if(action.payload.result !== []) {
                var _paymentTermData = action.payload.result

                _paymentTermData.forEach((p, index) => {
                    var _loc = {
                        'label': _paymentTermData[index].condition_name + ' (' + _paymentTermData[index].condition_desc + ')',
                        'value': _paymentTermData[index].id
                    }
                    _stckPaymentTermSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckPaymentTermData: action.payload, success: true, stckTotalPaymentTerm: _totalPaymentTerm, stckPaymentTermSelect: _stckPaymentTermSelect, inProgress: false})
        case FAILED_STCK_PAYMENT_TERM_LIST:
            return ({stckPaymentTermData: [], success: false, stckTotalPaymentTerm: 0, stckPaymentTermSelect: [], inProgress: false})
        default:
            return state
    }
}