import { FETCH_STCK_INTERCOMPANY_LIST, RECEIVE_STCK_INTERCOMPANY_LIST, FAILED_STCK_INTERCOMPANY_LIST } from '../../actions/stocker/stck.intercompany.act'

const defaultStckIntercompanyState = {
    stckIntercompanyData: [],
    stckTotalIntercompany: 0,
    inProgress: "loading",
}

export function StckIntercompany(state = defaultStckIntercompanyState, action){
    switch (action.type) {
        case FETCH_STCK_INTERCOMPANY_LIST:
            return ({stckIntercompanyData: [], stckTotalIntercompany: 0, inProgress: "loading"})
        case RECEIVE_STCK_INTERCOMPANY_LIST:
            var _totalIntercompany = action.payload.result.length;
            return Object.assign({}, state, {stckIntercompanyData: action.payload.result[0], stckTotalIntercompany: _totalIntercompany, inProgress: "success"})
        case FAILED_STCK_INTERCOMPANY_LIST:
            return ({stckIntercompanyData: [], stckTotalIntercompany: 0, inProgress: "failed"})
        default:
            return state
    }
}