import { FETCH_SAVED_PLACES_TABLE, RECEIVE_SAVED_PLACES_TABLE, FAILED_SAVED_PLACES_TABLE,
         FETCH_SAVED_PLACES_MESH_LIST, RECEIVE_SAVED_PLACES_MESH_LIST, FAILED_SAVED_PLACES_MESH_LIST,
         FETCH_SAVED_PLACES_NEAR, RECEIVE_SAVED_PLACES_NEAR, FAILED_SAVED_PLACES_NEAR} 
         from '../../actions/transporter/aTransporter-SavedPlacesTable'

const defaultSavedPlacesTableState = {
    savedPlacesTableData: [],
    savedPlaceAllMaps: [],
    savedPlaceApproved: [],
    savedPlaceNotApproved: [],
    savedPlaceDeleted: [],
    selectApprovedPlace: [],
    selectAllPfPlace: [],
    selectorAllPfWithCustGroup: [],
    savedPlaceImage:[],
    totalPlace: 0,
    approvedPlace: 0,
    notApprovedPlace: 0,
    deactivatedPlace: 0,
    success: false,
    savedPlaceWithMainCustomerLabel: [],
}

export function fetchSavedPlacesTable(state = defaultSavedPlacesTableState, action){
    switch (action.type) {
        case FETCH_SAVED_PLACES_TABLE:
            return ({savedPlacesTableData: [], savedPlaceApproved: [], savedPlaceNotApproved: [], savedPlaceDeleted: [], selectAllPfPlace: [], savedPlaceImage: [],
                selectorAllPfWithCustGroup: [], selectApprovedPlace: [], totalPlace: 0, approvedPlace: 0, notApprovedPlace: 0, 
                deactivatedPlace: 0, success: false, inProgress: true,  savedPlaceWithMainCustomerLabel: [],})
        case RECEIVE_SAVED_PLACES_TABLE:
            var listAll = []
            var listApproved = [] //status 1
            var listDeleted = [] //status 2
            var listNotApproved = [] //status 3
            var selectorApp = []
            let selectorAllPf = []
            let _selectorAllPfWithCustGroup = []
            let perCustomerGroup = {}
            if(action.payload.result !== []) {
                var liveMonitoringData = action.payload.result;
                liveMonitoringData.forEach((p, index) => {
                    if(liveMonitoringData[index].lat !== null || liveMonitoringData[index].lng !== null) {

                        if(!perCustomerGroup[liveMonitoringData[index].customer_name])
                            perCustomerGroup[liveMonitoringData[index].customer_name] = []

                        var data = {
                            'id': liveMonitoringData[index].id,
                            'company_name': liveMonitoringData[index].company_name,
                            'name': liveMonitoringData[index].name,
                            'customer_name': liveMonitoringData[index].customer_name,
                            'customer_email': liveMonitoringData[index].customer_email,
                            'customer_handphone': liveMonitoringData[index].customer_handphone,
                            'lat': liveMonitoringData[index].lat,
                            'lng': liveMonitoringData[index].lng,
                            'status': liveMonitoringData[index].status,
                            'public_place': liveMonitoringData[index].public_place,
                            'verify_status': liveMonitoringData[index].verify_status,
                            'public_place_type': liveMonitoringData[index].type_name,
                            'public_place_address': liveMonitoringData[index].address,
                            'email_user_approve':liveMonitoringData[index].email_user_approve,
                            'date_approve':liveMonitoringData[index].date_approve,
                            'reviews': liveMonitoringData[index].reviews,
                            'review_points': liveMonitoringData[index].review_points,
                            'review_url': liveMonitoringData[index].review_url,
                        }

                        var selectorApproved = {
                            'value': liveMonitoringData[index].id,
                            'label': liveMonitoringData[index].name,
                            'lat': liveMonitoringData[index].lat,
                            'lng': liveMonitoringData[index].lng,
                            'customer_name': liveMonitoringData[index].customer_name? liveMonitoringData[index].customer_name : "",
                            'public_place_type': liveMonitoringData[index].type_name,
                            'public_place_address': liveMonitoringData[index].address,
                            'email_user_approve':liveMonitoringData[index].email_user_approve,
                            'date_approve':liveMonitoringData[index].date_approve,
                            'reviews': liveMonitoringData[index].reviews,
                            'review_points': liveMonitoringData[index].review_points,
                            'review_url': liveMonitoringData[index].review_url,
                        }

                        var selectorApprovedgroup = {
                            'value': liveMonitoringData[index].id,
                            'label': "⤷ " + liveMonitoringData[index].name,
                            'lat': liveMonitoringData[index].lat,
                            'lng': liveMonitoringData[index].lng,
                            'customer_name': liveMonitoringData[index].customer_name? liveMonitoringData[index].customer_name : "",
                            'public_place_type': liveMonitoringData[index].type_name,
                            'public_place_address': liveMonitoringData[index].address,
                            'email_user_approve':liveMonitoringData[index].email_user_approve,
                            'date_approve':liveMonitoringData[index].date_approve,
                        }


                        perCustomerGroup[liveMonitoringData[index].customer_name].push(
                            selectorApprovedgroup
                        )

                        if(liveMonitoringData[index].status === 1) {
                            listAll.push(data);
                            listApproved.push(data);
                            selectorApp.push(selectorApproved);
                        } else if(liveMonitoringData[index].status === 3) {
                            listAll.push(data);
                            listDeleted.push(data);
                        } else if(liveMonitoringData[index].status === 2) {
                            listAll.push(data);
                            listNotApproved.push(data);
                        }

                        if(selectorApproved.public_place_type)
                            selectorAllPf.push(selectorApproved)
                    }
                })
            }
            let firstCustomer = action.payload.firstCustomer? action.payload.firstCustomer : null
            let firstCustomerArr = []
            let _savedPlaceWithMainCustomerLabel = []
            let i = -1
            for (const customer in perCustomerGroup) {
                const firstSPObj = perCustomerGroup[customer][0]

                if(customer === firstCustomer){
                    firstCustomerArr.push(
                        {label:customer, value:i,  disabled: true},
                        ...perCustomerGroup[customer]
                    )
                    _savedPlaceWithMainCustomerLabel.push(
                        {label:customer, value:firstSPObj.value,
                        lat:firstSPObj.lat, lng:firstSPObj.lng, 
                        customer_name:firstSPObj.customer_name,}
                    )
                }

                if(customer !== firstCustomer){
                    _selectorAllPfWithCustGroup.push(
                        {label:customer, value:i,  disabled: true},
                        ...perCustomerGroup[customer]
                    )
                    _savedPlaceWithMainCustomerLabel.push(
                        {label:customer, value:firstSPObj.value,
                        lat:firstSPObj.lat, lng:firstSPObj.lng, 
                        customer_name:firstSPObj.customer_name,}
                    )
                }
                i = i - 1
            }
            if(firstCustomerArr.length > 0){
                _selectorAllPfWithCustGroup.unshift(
                    ...firstCustomerArr
                )
            }
            var _totalPlace = listAll.length;
            var _approvedPlace = listApproved.length;
            var _notApprovedPlace = listNotApproved.length;
            var _deactivatedPlace = listDeleted.length;

            return Object.assign({}, state, {savedPlacesTableData: listAll, savedPlaceApproved: listApproved, savedPlaceNotApproved: listNotApproved, savedPlaceDeleted: listDeleted, selectAllPfPlace: selectorAllPf, savedPlaceImage: action.payload.image,
                selectorAllPfWithCustGroup: _selectorAllPfWithCustGroup, totalPlace: _totalPlace, approvedPlace: _approvedPlace, notApprovedPlace: _notApprovedPlace, deactivatedPlace: _deactivatedPlace, 
                selectApprovedPlace: selectorApp, success: true, inProgress: false,  savedPlaceWithMainCustomerLabel: _savedPlaceWithMainCustomerLabel})
        case FAILED_SAVED_PLACES_TABLE:
            return ({savedPlacesTableData: [], savedPlaceApproved: [], savedPlaceNotApproved: [], savedPlaceDeleted: [], selectAllPfPlace: [], savedPlaceImage: [],
                selectorAllPfWithCustGroup: [], selectApprovedPlace: [], totalPlace: 0, approvedPlace: 0, notApprovedPlace: 0, 
                savedPlaceWithMainCustomerLabel: [], deactivatedPlace: 0, success: false, inProgress: false})
        default:
            return state
    }
}


const defaultSavedPlaceMeshState = {
    savedPlaceMeshData: [],
    loading: null,
}

export function fetchSavedPlaceMesh(state = defaultSavedPlaceMeshState, action){
    switch (action.type) {
        case FETCH_SAVED_PLACES_MESH_LIST:
            return ({savedPlaceMeshData: [], totalDriver: 0, loading: "loading"})
        case RECEIVE_SAVED_PLACES_MESH_LIST:
            const {payload} = action
            return Object.assign({}, state, {savedPlaceMeshData: payload, loading: "loaded"})
        case FAILED_SAVED_PLACES_MESH_LIST:
            return ({savedPlaceMeshData: [], totalDriver: 0, loading: "error"})
        default:
            return state
    }
}

const defaultSavedPlaceNearState = {
    savedPlaceNearData: [],
    loading: null,
}

export function fetchSavedPlaceNear(state = defaultSavedPlaceNearState, action){
    switch (action.type) {
        case FETCH_SAVED_PLACES_NEAR:
            return ({savedPlaceNearData: [], totalDriver: 0, loading: "loading"})
        case RECEIVE_SAVED_PLACES_NEAR:
            const {payload} = action
            return Object.assign({}, state, {savedPlaceNearData: payload.result, loading: "loaded"})
        case FAILED_SAVED_PLACES_NEAR:
            return ({savedPlaceNearData: [], totalDriver: 0, loading: "error"})
        default:
            return state
    }
}