import { FETCH_INBOUND_DRIVER_OFFICER_LIST, RECEIVE_INBOUND_DRIVER_OFFICER_LIST, FAILED_INBOUND_DRIVER_OFFICER_LIST } from '../../actions/stocker/stck.inbounddriverofficer.act'

const defaultStckInboundDriverOfficerState = {
    stckInboundDriverOfficerData: [],
    stckTotalInboundDriverOfficer: 0,
    stckInboundDriverOfficerSelect: [],
    success: true,
    inProgress: false,
}

export function StckInboundDriverOfficer(state = defaultStckInboundDriverOfficerState, action){
    switch (action.type) {
        case FETCH_INBOUND_DRIVER_OFFICER_LIST:
            return ({stckInboundDriverOfficerData: [], success: true, stckTotalInboundDriverOfficer: 0, stckInboundDriverOfficerSelect: [], inProgress: true})
        case RECEIVE_INBOUND_DRIVER_OFFICER_LIST:
            var _totalInboundDriverOfficer = action.payload.result.length;
            var _stckInboundDriverOfficerSelect = []
            if(action.payload.result !== []) {
                var _inboundDriverOfficerData = action.payload.result

                _inboundDriverOfficerData.forEach((p, index) => {
                    var _loc = {
                        'label': _inboundDriverOfficerData[index].driver_name,
                        'value': _inboundDriverOfficerData[index].id,
                        "id": _inboundDriverOfficerData[index].id,
                        "id_master_inbound": _inboundDriverOfficerData[index].id_master_inbound,
                        "inbound_number": _inboundDriverOfficerData[index].inbound_number,
                        "document_number": _inboundDriverOfficerData[index].document_number,
                        "id_identity_type": _inboundDriverOfficerData[index].id_identity_type,
                        "identity_type": _inboundDriverOfficerData[index].identity_type,
                        "id_vendor": _inboundDriverOfficerData[index].id_vendor,
                        "vendor_name": _inboundDriverOfficerData[index].vendor_name,
                        "vendor_address": _inboundDriverOfficerData[index].vendor_address,
                        "vendor_main_phone_number": _inboundDriverOfficerData[index].vendor_main_phone_number,
                        "driver_identity_number": _inboundDriverOfficerData[index].driver_identity_number,
                        "driver_name": _inboundDriverOfficerData[index].driver_name,
                        "driver_asst_name": _inboundDriverOfficerData[index].driver_asst_name,
                        "driver_asst_phone": _inboundDriverOfficerData[index].driver_asst_phone,
                        "office_name": _inboundDriverOfficerData[index].office_name,
                        "phone_number": _inboundDriverOfficerData[index].phone_number,
                        "remark": _inboundDriverOfficerData[index].remark,
                    }
                    _stckInboundDriverOfficerSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckInboundDriverOfficerData: action.payload, success: true, stckTotalInboundDriverOfficer: _totalInboundDriverOfficer, stckInboundDriverOfficerSelect: _stckInboundDriverOfficerSelect, inProgress: false})
        case FAILED_INBOUND_DRIVER_OFFICER_LIST:
            return ({stckInboundDriverOfficerData: [], success: false, stckTotalInboundDriverOfficer: 0, stckInboundDriverOfficerSelect: [], inProgress: false})
        default:
            return state
    }
}