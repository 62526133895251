import { FETCH_OUTBOUND_TYPE_LIST, RECEIVE_OUTBOUND_TYPE_LIST, FAILED_OUTBOUND_TYPE_LIST } from '../../actions/stocker/stck.outboundtype.act'

const defaultStckOutboundTypeState = {
    stckOutboundTypeData: [],
    stckTotalOutboundType: 0,
    stckOutboundTypeSelect: [],
    success: true,
    inProgress: false,
}

export function StckOutboundType(state = defaultStckOutboundTypeState, action){
    switch (action.type) {
        case FETCH_OUTBOUND_TYPE_LIST:
            return ({stckOutboundTypeData: [], success: true, stckTotalOutboundType: 0, stckOutboundTypeSelect: [], inProgress: true})
        case RECEIVE_OUTBOUND_TYPE_LIST:
            var _totalOutboundType = action.payload.result.length;
            var _stckOutboundTypeSelect = []
            if(action.payload.result !== []) {
                var _outboundTypeData = action.payload.result

                _outboundTypeData.forEach((p, index) => {
                    var _loc = {
                        'label': _outboundTypeData[index].outbound_type_name,
                        'value': _outboundTypeData[index].id,
                        "id": _outboundTypeData[index].id,
                    }
                    _stckOutboundTypeSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckOutboundTypeData: action.payload, success: true, stckTotalOutboundType: _totalOutboundType, stckOutboundTypeSelect: _stckOutboundTypeSelect, inProgress: false})
        case FAILED_OUTBOUND_TYPE_LIST:
            return ({stckOutboundTypeData: [], success: false, stckTotalOutboundType: 0, stckOutboundTypeSelect: [], inProgress: false})
        default:
            return state
    }
}