//Visual Model
export const FETCH_VISUAL_MODEL_LIST = 'FETCH_VISUAL_MODEL_LIST'
export const RECEIVE_VISUAL_MODEL_LIST = 'RECEIVE_VISUAL_MODEL_LIST'
export const FAILED_VISUAL_MODEL_LIST = 'FAILED_VISUAL_MODEL_LIST'
export const CREATE_VISUAL_MODEL = 'CREATE_VISUAL_MODEL'
export const EDIT_VISUAL_MODEL = 'EDIT_VISUAL_MODEL'
export const DELETE_VISUAL_MODEL = 'DELETE_VISUAL_MODEL'

//Visual Model Device
export const FETCH_VISUAL_MODEL_DEVICE_LIST = 'FETCH_VISUAL_MODEL_DEVICE_LIST'
export const RECEIVE_VISUAL_MODEL_DEVICE_LIST = 'RECEIVE_VISUAL_MODEL_DEVICE_LIST'
export const FAILED_VISUAL_MODEL_DEVICE_LIST = 'FAILED_VISUAL_MODEL_DEVICE_LIST'
export const CREATE_VISUAL_MODEL_DEVICE = 'CREATE_VISUAL_MODEL_DEVICE'
export const EDIT_VISUAL_MODEL_DEVICE = 'EDIT_VISUAL_MODEL_DEVICE'
export const DELETE_VISUAL_MODEL_DEVICE = 'DELETE_VISUAL_MODEL_DEVICE'

//Visual Model Sensor
export const FETCH_VISUAL_MODEL_SENSOR_LIST = 'FETCH_VISUAL_MODEL_SENSOR_LIST'
export const RECEIVE_VISUAL_MODEL_SENSOR_LIST = 'RECEIVE_VISUAL_MODEL_SENSOR_LIST'
export const FAILED_VISUAL_MODEL_SENSOR_LIST = 'FAILED_VISUAL_MODEL_SENSOR_LIST'
export const CREATE_VISUAL_MODEL_SENSOR = 'CREATE_VISUAL_MODEL_SENSOR'
export const EDIT_VISUAL_MODEL_SENSOR = 'EDIT_VISUAL_MODEL_SENSOR'
export const DELETE_VISUAL_MODEL_SENSOR = 'DELETE_VISUAL_MODEL_SENSOR'

//Visual Model Utilities
export const FETCH_VISUAL_MODEL_UTILITIES_LIST = 'FETCH_VISUAL_MODEL_UTILITIES_LIST'
export const RECEIVE_VISUAL_MODEL_UTILITIES_LIST = 'RECEIVE_VISUAL_MODEL_UTILITIES_LIST'
export const FAILED_VISUAL_MODEL_UTILITIES_LIST = 'FAILED_VISUAL_MODEL_UTILITIES_LIST'
export const CREATE_VISUAL_MODEL_UTILITIES = 'CREATE_VISUAL_MODEL_UTILITIES'
export const EDIT_VISUAL_MODEL_UTILITIES = 'EDIT_VISUAL_MODEL_UTILITIES'
export const DELETE_VISUAL_MODEL_UTILITIES = 'DELETE_VISUAL_MODEL_UTILITIES'
