import { FETCH_STCK_WAREHOUSE_GEOFENCES_LIST, RECEIVE_STCK_WAREHOUSE_GEOFENCES_LIST, FAILED_STCK_WAREHOUSE_GEOFENCES_LIST } from '../../actions/stocker/stck.warehousegeofence.act'

const defaultStckWarehouseGeofencesState = {
    stckWarehouseGeofencesData: [],
    stckTotalWarehouseGeofences: 0,
    stckWarehouseGeofencesSelect: [],
    success: true,
    inProgress: false,
}

export function StckWarehouseGeofences(state = defaultStckWarehouseGeofencesState, action){
    switch (action.type) {
        case FETCH_STCK_WAREHOUSE_GEOFENCES_LIST:
            return ({stckWarehouseGeofencesData: [], success: true, stckTotalWarehouseGeofences: 0, stckWarehouseGeofencesSelect: [], inProgress: true})
        case RECEIVE_STCK_WAREHOUSE_GEOFENCES_LIST:
            var _totalWarehouseGeofences = action.payload.result.length;
            var _stckWarehouseGeofencesSelect = []
            if(action.payload.result !== []) {
                var _warehouseGeofenceData = action.payload.result

                _warehouseGeofenceData.forEach((p, index) => {
                    var _loc = {
                        'label': _warehouseGeofenceData[index].point_name + ' - ' + _warehouseGeofenceData[index].point_desc,
                        'value': _warehouseGeofenceData[index].id
                    }
                    _stckWarehouseGeofencesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckWarehouseGeofencesData: action.payload, success: true, stckTotalWarehouseGeofences: _totalWarehouseGeofences, stckWarehouseGeofencesSelect: _stckWarehouseGeofencesSelect, inProgress: false})
        case FAILED_STCK_WAREHOUSE_GEOFENCES_LIST:
            return ({stckWarehouseGeofencesData: [], success: false, stckTotalWarehouseGeofences: 0, stckWarehouseGeofencesSelect: [], inProgress: false})
        default:
            return state
    }
}