import { FETCH_INBOUND_OFFICER_ASST_LIST, RECEIVE_INBOUND_OFFICER_ASST_LIST, FAILED_INBOUND_OFFICER_ASST_LIST } from '../../actions/stocker/stck.inboundofficerassistant.act'

const defaultStckInboundOfficerAsstState = {
    stckInboundOfficerAsstData: [],
    stckTotalInboundOfficerAsst: 0,
    stckInboundOfficerAsstSelect: [],
    success: true,
    inProgress: false,
}

export function StckInboundOfficerAsst(state = defaultStckInboundOfficerAsstState, action){
    switch (action.type) {
        case FETCH_INBOUND_OFFICER_ASST_LIST:
            return ({stckInboundOfficerAsstData: [], success: true, stckTotalInboundOfficerAsst: 0, stckInboundOfficerAsstSelect: [], inProgress: true})
        case RECEIVE_INBOUND_OFFICER_ASST_LIST:
            var _totalInboundOfficerAsst = action.payload.result.length;
            var _stckInboundOfficerAsstSelect = []
            if(action.payload.result !== []) {
                var _inboundOfficerAsstData = action.payload.result

                _inboundOfficerAsstData.forEach((p, index) => {
                    var _loc = {
                        'label': _inboundOfficerAsstData[index].license_number,
                        'value': _inboundOfficerAsstData[index].id,
                        "id": _inboundOfficerAsstData[index].id,
                        "id_master_inbound": _inboundOfficerAsstData[index].id_master_inbound,
                        "inbound_number": _inboundOfficerAsstData[index].inbound_number,
                        "document_number": _inboundOfficerAsstData[index].document_number,
                        "id_inbound_detail": _inboundOfficerAsstData[index].id_inbound_detail,
                        "awb_number": _inboundOfficerAsstData[index].awb_number,
                        "id_identity_type": _inboundOfficerAsstData[index].id_identity_type,
                        "identity_type": _inboundOfficerAsstData[index].identity_type,
                        "identity_number": _inboundOfficerAsstData[index].identity_number,
                        "address": _inboundOfficerAsstData[index].address,
                        "name": _inboundOfficerAsstData[index].name,
                        "id_officer_type": _inboundOfficerAsstData[index].id_officer_type,
                        "officer_type": _inboundOfficerAsstData[index].officer_type,
                        "photo_link": _inboundOfficerAsstData[index].photo_link,
                        "in_time": _inboundOfficerAsstData[index].in_time,
                        "out_time": _inboundOfficerAsstData[index].out_time,
                    }
                    _stckInboundOfficerAsstSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckInboundOfficerAsstData: action.payload, success: true, stckTotalInboundOfficerAsst: _totalInboundOfficerAsst, stckInboundOfficerAsstSelect: _stckInboundOfficerAsstSelect, inProgress: false})
        case FAILED_INBOUND_OFFICER_ASST_LIST:
            return ({stckInboundOfficerAsstData: [], success: false, stckTotalInboundOfficerAsst: 0, stckInboundOfficerAsstSelect: [], inProgress: false})
        default:
            return state
    }
}