import{
    FETCH_FLEET_APPROVED_LIST, RECEIVE_FLEET_APPROVED_LIST, FAILED_FLEET_APPROVED_LIST,
    FETCH_FLEET_LIST_APPROVED_LIST, RECEIVE_FLEET_LIST_APPROVED_LIST, FAILED_FLEET_LIST_APPROVED_LIST
}from '../../actions/transporter/rentee-request-impl.act'

function getSelectFromPayload(payloadData, settings){
    let result = []
    if(!payloadData)
        return result
    if(!Array.isArray(payloadData))
        return result
    let labelField = settings.labelField
    let valueField = settings.valueField
    payloadData.forEach(data => {
        result.push({
            label: data[labelField],
            value: data[valueField]
        })
    })
    return result
}

const defaultRenteeApprovedFleetsState = {
    totalRenteeApprovedFleets:0,
    renteeApprovedFleets: [],
    renteeApprovedSelects: [],
    success: true,  
    inProgress: false
}

export function RenteeApprovedFleets(state = defaultRenteeApprovedFleetsState, action){
    switch (action.type) {
        case FETCH_FLEET_APPROVED_LIST:
            return ({
                // totalRenteeApprovedFleets: [],
                success: true, renteeApprovedSelects: [],
                    inProgress: true, totalRenteeApprovedFleets:0})
        case RECEIVE_FLEET_APPROVED_LIST:
            const {payload} = action
            let _renteeApprovedSelects = getSelectFromPayload(payload, {
                labelField: "reg_number_with_door" , valueField: "id"
            })
            return Object.assign({}, state, {renteeApprovedFleets: payload,
                                                renteeApprovedSelects: _renteeApprovedSelects,
                                                totalRenteeApprovedFleets: payload.length,
                                                success: true, inProgress: false})
        case FAILED_FLEET_APPROVED_LIST:
            return ({
                // totalRenteeApprovedFleets: [],
                success: false, renteeApprovedSelects: [],
                    inProgress: false, totalRenteeApprovedFleets:0,})
        default:
            return state
    }
}

const defaultRenteeListFleetsState = {
    totalRenteeListFleets:0,
    renteeListFleets: [],
    renteeListSelects: [],
    success: true,  
    inProgress: false
}

export function RenteeListFleets(state = defaultRenteeListFleetsState, action){
    switch (action.type) {
        case FETCH_FLEET_LIST_APPROVED_LIST:
            return ({
                // totalRenteeListFleets: [],
                success: true, renteeListSelects: [],
                    inProgress: true, totalRenteeListFleets:0})
        case RECEIVE_FLEET_LIST_APPROVED_LIST:
            const {payload} = action
            let _renteeListSelects = getSelectFromPayload(payload, {
                labelField: "reg_number_with_door" , valueField: "id"
            })
            return Object.assign({}, state, {renteeListFleets: payload,
                                                renteeListSelects: _renteeListSelects,
                                                totalRenteeListFleets: payload.length,
                                                success: true, inProgress: false})
        case FAILED_FLEET_LIST_APPROVED_LIST:
            return ({
                // totalRenteeListFleets: [],
                success: false, renteeListSelects: [],
                    inProgress: false, totalRenteeListFleets:0,})
        default:
            return state
    }
}

const defaultRenteeLiveFleetsState = {
    totalRenteeLiveFleets:0,
    renteeLiveFleets: [],
    renteeLiveSelects: [],
    success: true,  
    inProgress: false
}

export function RenteeLiveFleets(state = defaultRenteeLiveFleetsState, action){
    switch (action.type) {
        case FETCH_FLEET_APPROVED_LIST:
            return ({
                // totalRenteeLiveFleets: [],
                success: true, renteeLiveSelects: [],
                    inProgress: true, totalRenteeLiveFleets:0})
        case RECEIVE_FLEET_APPROVED_LIST:
            const {payload} = action
            let _renteeLiveSelects = getSelectFromPayload(payload, {
                labelField: "reg_number_with_door" , valueField: "id"
            })
            return Object.assign({}, state, {renteeLiveFleets: payload,
                                                renteeLiveSelects: _renteeLiveSelects,
                                                totalRenteeLiveFleets: payload.length,
                                                success: true, inProgress: false})
        case FAILED_FLEET_APPROVED_LIST:
            return ({
                // totalRenteeLiveFleets: [],
                success: false, renteeLiveSelects: [],
                    inProgress: false, totalRenteeLiveFleets:0,})
        default:
            return state
    }
}