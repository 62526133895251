import {
  FETCH_DRIVERS_TABLE,
  RECEIVE_DRIVERS_TABLE,
  FAILED_DRIVERS_TABLE,
  FETCH_DRIVERS_SUMMARY,
  RECEIVE_DRIVERS_SUMMARY,
  FAILED_DRIVERS_SUMMARY,
  FETCH_DRIVER_FLEETS,
  RECEIVE_DRIVER_FLEETS,
  FAILED_DRIVER_FLEETS,
} from "../../actions/transporter/aTransporter-DriversTable";

const defaultDriversTableState = {
  driversTableData: [],
  totalDriver: 0,
  success: false,
};

export function fetchDriversTable(state = defaultDriversTableState, action) {
  switch (action.type) {
    case FETCH_DRIVERS_TABLE:
      return {
        driversTableData: [],
        totalDriver: 0,
        totalWorkOrders: 0,
        verifiedWorkOrders: 0,
        underTimeOrders: 0,
        overTimeOrders: 0,
        normalTimeOrders: 0,
        departureOrders: 0,
        returnHomeOrders: 0,
        noArrivalTimeOrders: 0,
        noCheckoutTimeOrders: 0,
        incompleteOrders: 0,
        success: false,
        inProgress: true,
      };
    case RECEIVE_DRIVERS_TABLE:
      var _totalDriver = action.payload.result.length;
      const data = action.payload.result;

      let totalWorkOrders = 0;
      let verifiedWorkOrders = 0;
      let underTimeOrders = 0;
      let overTimeOrders = 0;
      let normalTimeOrders = 0;
      let departureOrders = 0;
      let returnHomeOrders = 0;
      let noArrivalTimeOrders = 0;
      let noCheckoutTimeOrders = 0;
      let incompleteOrders = 0;

      data.forEach((item) => {
        totalWorkOrders += item.total_work_orders || 0;
        verifiedWorkOrders += item.verified_work_orders || 0;
        underTimeOrders += item.under_time_orders || 0;
        overTimeOrders += item.over_time_orders || 0;
        normalTimeOrders += item.normal_time_orders || 0;
        departureOrders += item.departure_orders || 0;
        returnHomeOrders += item.return_home_orders || 0;
        noArrivalTimeOrders += item.no_arrival_time_orders || 0;
        noCheckoutTimeOrders += item.no_checkout_time_orders || 0;
        incompleteOrders += item.incomplete_orders || 0;
      });

      return Object.assign({}, state, {
        driversTableData: action.payload,
        totalDriver: _totalDriver,
        totalWorkOrders,
        verifiedWorkOrders,
        underTimeOrders,
        overTimeOrders,
        normalTimeOrders,
        departureOrders,
        returnHomeOrders,
        noArrivalTimeOrders,
        noCheckoutTimeOrders,
        incompleteOrders,
        success: true,
        inProgress: false,
      });
    case FAILED_DRIVERS_TABLE:
      return {
        driversTableData: [],
        totalDriver: 0,
        totalWorkOrders: 0,
        verifiedWorkOrders: 0,
        underTimeOrders: 0,
        overTimeOrders: 0,
        normalTimeOrders: 0,
        departureOrders: 0,
        returnHomeOrders: 0,
        noArrivalTimeOrders: 0,
        noCheckoutTimeOrders: 0,
        incompleteOrders: 0,
        success: false,
        inProgress: false,
      };
    default:
      return state;
  }
}

const defaultDriversSummaryState = {
  driversSummaryData: [],
  loading: null,
};

export function fetchDriversSummary(
  state = defaultDriversSummaryState,
  action
) {
  switch (action.type) {
    case FETCH_DRIVERS_SUMMARY:
      return { driversSummaryData: [], totalDriver: 0, loading: "loading" };
    case RECEIVE_DRIVERS_SUMMARY:
      const { payload } = action;
      return Object.assign({}, state, {
        driversSummaryData: payload,
        loading: "loaded",
      });
    case FAILED_DRIVERS_SUMMARY:
      return { driversSummaryData: [], totalDriver: 0, loading: "error" };
    default:
      return state;
  }
}

const defaultDriverFleetsState = {
  driverFleetsData: [],
  loading: null,
};

export function fetchDriverFleets(state = defaultDriverFleetsState, action) {
  switch (action.type) {
    case FETCH_DRIVER_FLEETS:
      return { driverFleetsData: [], loading: "loading" };
    case RECEIVE_DRIVER_FLEETS:
      const { payload } = action;
      return Object.assign({}, state, {
        driverFleetsData: payload.result,
        loading: "loaded",
      });
    case FAILED_DRIVER_FLEETS:
      return { driverFleetsData: [], loading: "error" };
    default:
      return state;
  }
}
