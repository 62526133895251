import { FETCH_WAREHOUSE_TRANSFER_APPROVAL_SETTING_LIST, 
            RECEIVE_WAREHOUSE_TRANSFER_APPROVAL_SETTING_LIST, 
            FAILED_WAREHOUSE_TRANSFER_APPROVAL_SETTING_LIST }
        from '../../actions/stocker/stck.warehousetransferapprovalsetting.act'

const defaultStckWarehouseTransferApprovalSettingState = {
    stckWarehouseTransferApprovalSettingData: [],
    stckTotalWarehouseTransferApprovalSetting: 0,
    stckWarehouseTransferApprovalSettingSelect: [],
    success: true,
    inProgress: false,
}

export function StckWarehouseTransferApprovalSetting(state = defaultStckWarehouseTransferApprovalSettingState, action){
    switch (action.type) {
        case FETCH_WAREHOUSE_TRANSFER_APPROVAL_SETTING_LIST:
            return ({stckWarehouseTransferApprovalSettingData: [], success: true, stckTotalWarehouseTransferApprovalSetting: 0, stckWarehouseTransferApprovalSettingSelect: [], inProgress: true})
        case RECEIVE_WAREHOUSE_TRANSFER_APPROVAL_SETTING_LIST:
            var _totalUnits = action.payload.result.length;
            var _stckWarehouseTransferApprovalSettingSelect = []
            if(action.payload.result !== []) {
                var _repackagingsData = action.payload.result
                _repackagingsData.forEach((p, index) => {
                    var _loc = {
                        'label': _repackagingsData[index].code + " - " + _repackagingsData[index].name ,
                        'value': _repackagingsData[index].id
                    }
                    _stckWarehouseTransferApprovalSettingSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckWarehouseTransferApprovalSettingData: action.payload, success: true, stckTotalWarehouseTransferApprovalSetting: _totalUnits, stckWarehouseTransferApprovalSettingSelect: _stckWarehouseTransferApprovalSettingSelect, inProgress: false})
        case FAILED_WAREHOUSE_TRANSFER_APPROVAL_SETTING_LIST:
            return ({stckWarehouseTransferApprovalSettingData: [], success: false, stckTotalWarehouseTransferApprovalSetting: 0, stckWarehouseTransferApprovalSettingSelect: [], inProgress: false})
        default:
            return state
    }
}