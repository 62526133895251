import { 
    FETCH_PUBLIC_FACILITY_TYPE_LIST,  
    RECEIVE_PUBLIC_FACILITY_TYPE_LIST,
    FAILED_PUBLIC_FACILITY_TYPE_LIST ,
} 
from '../../actions/transporter/publicfacilitytype.act'

const defaultPublicFacilityTypeState = {
    totalPublicFacilityType:0,
    publicFacilityTypeData: [],
    publicFacilityTypeSelect: [],
    success: true,  
    inProgress: false
}

export function publicFacilityType(state = defaultPublicFacilityTypeState, action){
    switch (action.type) {
        case FETCH_PUBLIC_FACILITY_TYPE_LIST:
            return ({publicFacilityTypeData: [], success: true, inProgress: true, 
                                                totalPublicFacilityType:0, publicFacilityTypeSelect: []})
        case RECEIVE_PUBLIC_FACILITY_TYPE_LIST:
            let selectArr = []
            if(action.payload.result !== []) {
                let _publicFacilityTypeData = action.payload.result
                _publicFacilityTypeData.forEach((p) => {
                    selectArr.push({
                        label:p.type_name,
                        value:p.id
                    })
                })
            }
            return Object.assign({}, state, {publicFacilityTypeData: action.payload.result, 
                                             publicFacilityTypeSelect: selectArr,
                                                totalPublicFacilityType: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_PUBLIC_FACILITY_TYPE_LIST:
            return ({publicFacilityTypeData: [], success: false, inProgress: false, 
                                            totalPublicFacilityType:0, publicFacilityTypeSelect: []})
        default:
            return state
    }
}