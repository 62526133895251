import { FETCH_DRIVERS_ASSISTANT_TABLE, RECEIVE_DRIVERS_ASSISTANT_TABLE, FAILED_DRIVERS_ASSISTANT_TABLE } from '../../actions/transporter/aTransporter-DriversAssistantTable'

const defaultDriversAssistantTableState = {
    driversAssistantTableData: [],
    totalDriverAst:0,
    success: false, 
}

export function fetchDriversAssistantTable(state = defaultDriversAssistantTableState, action){
    switch (action.type) {
        case FETCH_DRIVERS_ASSISTANT_TABLE:
            return ({driversAssistantTableData: [], totalDriverAst:0, success: false, inProgress: true})
        case RECEIVE_DRIVERS_ASSISTANT_TABLE:
            var totalDriverAst = action.payload.result.length;
            return Object.assign({}, state, {driversAssistantTableData: action.payload, totalDriverAst: totalDriverAst, success: true, inProgress: false})
        case FAILED_DRIVERS_ASSISTANT_TABLE:
            return ({driversAssistantTableData: [], totalDriverAst:0, success: false, inProgress: false})
        default:
            return state
    }
}