import { FETCH_LL_SENSOR_CLASS_LIST, RECEIVE_LL_SENSOR_CLASS_LIST, FAILED_LL_SENSOR_CLASS_LIST } from '../../actions/landlord/aLandLord-SensorClassList'

const defaultLLSensorClassState = {
    llSensorClassData: [],
    llTotalSensorClass: 0,
    llSensorClassSelect: [],
    success: true,
    inProgress: false,
}

export function fetchLLSensorClassList(state = defaultLLSensorClassState, action){
    switch (action.type) {
        case FETCH_LL_SENSOR_CLASS_LIST:
            return ({llSensorClassData: [], success: true, llTotalSensorClass: 0, llSensorClassSelect: [], inProgress: true})
        case RECEIVE_LL_SENSOR_CLASS_LIST:
            var _totalSensorClass = action.payload.result.length;
            var _selectSensorClass = []
            if(action.payload.result !== []) {
                var _locationData = action.payload.result

                _locationData.forEach((p, index) => {
                    var _loc = {
                        'label': _locationData[index].sensor_class_code + ' - ' + _locationData[index].sensor_class_name,
                        'value': _locationData[index].id
                    }
                    _selectSensorClass.push(_loc)
                })
            }
            return Object.assign({}, state, {llSensorClassData: action.payload, success: true, llTotalSensorClass: _totalSensorClass, llSensorClassSelect: _selectSensorClass, inProgress: false})
        case FAILED_LL_SENSOR_CLASS_LIST:
            return ({llSensorClassData: [], success: false, llTotalSensorClass: 0, llSensorClassSelect: [], inProgress: false})
        default:
            return state
    }
}