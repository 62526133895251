import { FETCH_STCK_ITEM_CATEGORIES_LIST, RECEIVE_STCK_ITEM_CATEGORIES_LIST, FAILED_STCK_ITEM_CATEGORIES_LIST } from '../../actions/stocker/stck.itemcategories.act'

const defaultStckItemCategoriesState = {
    stckItemCategoriesData: [],
    stckTotalItemCategories: 0,
    stckItemCategoriesSelect: [],
    success: true,
    inProgress: false,
}

export function StckItemCategories(state = defaultStckItemCategoriesState, action){
    switch (action.type) {
        case FETCH_STCK_ITEM_CATEGORIES_LIST:
            return ({stckItemCategoriesData: [], success: true, stckTotalItemCategories: 0, stckItemCategoriesSelect: [], inProgress: true})
        case RECEIVE_STCK_ITEM_CATEGORIES_LIST:
            var _totalItemCategories = action.payload.result.length;
            var _stckItemCategoriesSelect = []
            if(action.payload.result !== []) {
                var _itemCategoriesData = action.payload.result

                _itemCategoriesData.forEach((p, index) => {
                    var _loc = {
                        'label': _itemCategoriesData[index].category_name + ' - ' + _itemCategoriesData[index].category_desc,
                        'value': _itemCategoriesData[index].id
                    }
                    _stckItemCategoriesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckItemCategoriesData: action.payload, success: true, stckTotalItemCategories: _totalItemCategories, stckItemCategoriesSelect: _stckItemCategoriesSelect, inProgress: false})
        case FAILED_STCK_ITEM_CATEGORIES_LIST:
            return ({stckItemCategoriesData: [], success: false, stckTotalItemCategories: 0, stckItemCategoriesSelect: [], inProgress: false})
        default:
            return state
    }
}