import {
  FETCH_MASTER_ITEM_LIST,
  RECEIVE_MASTER_ITEM_LIST,
  FAILED_MASTER_ITEM_LIST,
  FETCH_MASTER_ITEM_LIST_INTERCOMPANY,
  RECEIVE_MASTER_ITEM_LIST_INTERCOMPANY,
  FAILED_MASTER_ITEM_LIST_INTERCOMPANY,
  FETCH_MASTER_ITEM_STOCK_COUNT,
  RECEIVE_MASTER_ITEM_STOCK_COUNT,
  FAILED_MASTER_ITEM_STOCK_COUNT,
} from "../../actions/stocker/stck.masteritem.act";

const defaultStckMasterItemState = {
  stckMasterItemData: [],
  stckTotalMasterItem: 0,
  stckMasterItemSelect: [],
  success: true,
  inProgress: false,
};

export function StckMasterItem(state = defaultStckMasterItemState, action) {
  switch (action.type) {
    case FETCH_MASTER_ITEM_LIST:
      return {
        stckMasterItemData: [],
        success: true,
        stckTotalMasterItem: 0,
        stckMasterItemSelect: [],
        inProgress: true,
      };
    case RECEIVE_MASTER_ITEM_LIST:
      var _totalMasterInbound = action.payload.result.length;
      var _stckMasterItemSelect = [];
      if (action.payload.result !== []) {
        var _mItemData = action.payload.result;

        _mItemData.forEach((p, index) => {
          var _loc = {
            label: _mItemData[index].item_name,
            value: _mItemData[index].id,
            id: _mItemData[index].id,
            id_company: _mItemData[index].id_company,
            id_brand: _mItemData[index].id_brand,
            brand_name: _mItemData[index].brand_name,
            id_vendor: _mItemData[index].id_vendor,
            vendor_name: _mItemData[index].vendor_name,
            vendor_address: _mItemData[index].vendor_address,
            vendor_main_phone_number:
              _mItemData[index].vendor_main_phone_number,
            id_item_category: _mItemData[index].id_item_category,
            item_category_name: _mItemData[index].item_category_name,
            id_item_category_quality:
              _mItemData[index].id_item_category_quality,
            item_category_quality_name:
              _mItemData[index].item_category_quality_name,
            item_category_quality_value:
              _mItemData[index].item_category_quality_value,
            id_item_handling_category:
              _mItemData[index].id_item_handling_category,
            item_handling_category_name:
              _mItemData[index].item_handling_category_name,
            item_handling_category_class:
              _mItemData[index].item_handling_category_class,
            id_item_tax_category: _mItemData[index].id_item_tax_category,
            item_tax_category_name: _mItemData[index].item_tax_category_name,
            item_tax_category_value: _mItemData[index].item_tax_category_value,
            sku_code: _mItemData[index].sku_code,
            id_item_depreciation_method:
              _mItemData[index].id_item_depreciation_method,
            item_depreciation_method_name:
              _mItemData[index].item_depreciation_method_name,
            item_depreciation_method_value:
              _mItemData[index].item_depreciation_method_value,
            item_depreciation_method_term:
              _mItemData[index].item_depreciation_method_term,
            barcode: _mItemData[index].barcode,
            item_name: _mItemData[index].item_name,
            sku_item_name: _mItemData[index].sku_item_name,
            remark: _mItemData[index].remark,
            id_unit: _mItemData[index].id_unit,
            unit_name: _mItemData[index].unit_name,
            min_qty_in_stock: _mItemData[index].min_qty_in_stock,
            max_qty_in_stock: _mItemData[index].max_qty_in_stock,
            min_qty_to_order: _mItemData[index].min_qty_to_order,
            item_shelf_lifes: _mItemData[index].item_shelf_lifes,
            each_item_weight: _mItemData[index].each_item_weight,
            item_width: _mItemData[index].item_width,
            item_height: _mItemData[index].item_height,
            item_length: _mItemData[index].item_length,
          };
          _stckMasterItemSelect.push(_loc);
        });
      }
      return Object.assign({}, state, {
        stckMasterItemData: action.payload,
        success: true,
        stckTotalMasterItem: _totalMasterInbound,
        stckMasterItemSelect: _stckMasterItemSelect,
        inProgress: false,
      });
    case FAILED_MASTER_ITEM_LIST:
      return {
        stckMasterItemData: [],
        success: false,
        stckTotalMasterItem: 0,
        stckMasterItemSelect: [],
        inProgress: false,
      };
    default:
      return state;
  }
}

const defaultStckMasterItemIntercompanyState = {
  stckMasterItemIntercompanyData: [],
  stckTotalMasterItemIntercompany: 0,
  stckMasterItemIntercompanySelect: [],
  success: true,
  inProgress: false,
};

export function StckMasterItemIntercompany(
  state = defaultStckMasterItemIntercompanyState,
  action
) {
  switch (action.type) {
    case FETCH_MASTER_ITEM_LIST_INTERCOMPANY:
      return {
        stckMasterItemIntercompanyData: [],
        success: true,
        stckTotalMasterItemIntercompany: 0,
        stckMasterItemIntercompanySelect: [],
        inProgress: true,
      };
    case RECEIVE_MASTER_ITEM_LIST_INTERCOMPANY:
      var _totalMasterInbound = action.payload.result.length;
      var _stckMasterItemIntercompanySelect = [];
      if (action.payload.result !== []) {
        var _mItemData = action.payload.result;

        _mItemData.forEach((p, index) => {
          var _loc = {
            label: _mItemData[index].item_name,
            value: _mItemData[index].id,
            id: _mItemData[index].id,
            id_company: _mItemData[index].id_company,
            id_brand: _mItemData[index].id_brand,
            brand_name: _mItemData[index].brand_name,
            id_vendor: _mItemData[index].id_vendor,
            vendor_name: _mItemData[index].vendor_name,
            vendor_address: _mItemData[index].vendor_address,
            vendor_main_phone_number:
              _mItemData[index].vendor_main_phone_number,
            id_item_category: _mItemData[index].id_item_category,
            item_category_name: _mItemData[index].item_category_name,
            id_item_category_quality:
              _mItemData[index].id_item_category_quality,
            item_category_quality_name:
              _mItemData[index].item_category_quality_name,
            item_category_quality_value:
              _mItemData[index].item_category_quality_value,
            id_item_handling_category:
              _mItemData[index].id_item_handling_category,
            item_handling_category_name:
              _mItemData[index].item_handling_category_name,
            item_handling_category_class:
              _mItemData[index].item_handling_category_class,
            id_item_tax_category: _mItemData[index].id_item_tax_category,
            item_tax_category_name: _mItemData[index].item_tax_category_name,
            item_tax_category_value: _mItemData[index].item_tax_category_value,
            sku_code: _mItemData[index].sku_code,
            id_item_depreciation_method:
              _mItemData[index].id_item_depreciation_method,
            item_depreciation_method_name:
              _mItemData[index].item_depreciation_method_name,
            item_depreciation_method_value:
              _mItemData[index].item_depreciation_method_value,
            item_depreciation_method_term:
              _mItemData[index].item_depreciation_method_term,
            barcode: _mItemData[index].barcode,
            item_name: _mItemData[index].item_name,
            sku_item_name: _mItemData[index].sku_item_name,
            remark: _mItemData[index].remark,
            id_unit: _mItemData[index].id_unit,
            unit_name: _mItemData[index].unit_name,
            min_qty_in_stock: _mItemData[index].min_qty_in_stock,
            max_qty_in_stock: _mItemData[index].max_qty_in_stock,
            min_qty_to_order: _mItemData[index].min_qty_to_order,
            item_shelf_lifes: _mItemData[index].item_shelf_lifes,
            each_item_weight: _mItemData[index].each_item_weight,
            item_width: _mItemData[index].item_width,
            item_height: _mItemData[index].item_height,
            item_length: _mItemData[index].item_length,
            price_min: _mItemData[index].price_min,
            price_max: _mItemData[index].price_max,
          };
          _stckMasterItemIntercompanySelect.push(_loc);
        });
      }
      return Object.assign({}, state, {
        stckMasterItemIntercompanyData: action.payload,
        success: true,
        stckTotalMasterItemIntercompany: _totalMasterInbound,
        stckMasterItemIntercompanySelect: _stckMasterItemIntercompanySelect,
        inProgress: false,
      });
    case FAILED_MASTER_ITEM_LIST_INTERCOMPANY:
      return {
        stckMasterItemIntercompanyData: [],
        success: false,
        stckTotalMasterItemIntercompany: 0,
        stckMasterItemIntercompanySelect: [],
        inProgress: false,
      };
    default:
      return state;
  }
}

const defaultStckMasterItemCountCheckState = {
  stckMasterItemCountCheckData: [],
  success: true,
  inProgress: false,
};

export function StckMasterItemCountCheck(
  state = defaultStckMasterItemCountCheckState,
  action
) {
  switch (action.type) {
    case FETCH_MASTER_ITEM_STOCK_COUNT:
      return {
        stckMasterItemCountCheckData: [],
        success: true,
        inProgress: true,
      };
    case RECEIVE_MASTER_ITEM_STOCK_COUNT:
      return Object.assign({}, state, {
        stckMasterItemCountCheckData: action.payload,
        success: true,
        inProgress: false,
      });
    case FAILED_MASTER_ITEM_STOCK_COUNT:
      return {
        stckMasterItemCountCheckData: [],
        success: false,
        inProgress: false,
      };
    default:
      return state;
  }
}
