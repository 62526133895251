import { FETCH_PO_ITEM_DETAIL_LIST, RECEIVE_PO_ITEM_DETAIL_LIST, RECEIVE_PO_ITEM_DETAIL_COUNTER, FAILED_PO_ITEM_DETAIL_LIST } from '../../actions/stocker/stck.poitemdetail.act'

const defaultStckPoItemDetailState = {
    stckPoItemDetailData: [],
    stckTotalPoItemDetail: 0,
    stckPoItemDetailSelect: [],
    stckPoItemDetailCounterSelect: [],
    stckPoItemDetailIsAllPricePerItemFilled: false,
    success: true,
    inProgress: false,
}

export function StckPoItemDetail(state = defaultStckPoItemDetailState, action){
    switch (action.type) {
        case FETCH_PO_ITEM_DETAIL_LIST:
            return ({stckPoItemDetailData: [], success: true, stckTotalPoItemDetail: 0, stckPoItemDetailCounterSelect: [],
                stckPoItemDetailSelect: [], inProgress: true, stckPoItemDetailIsAllPricePerItemFilled: false})
        case RECEIVE_PO_ITEM_DETAIL_LIST:
            var _totalPoItemDetail = action.payload.result.length;
            var _stckPoItemDetailSelect = []
            let _stckPoItemDetailIsAllPricePerItemFilled = false
            if(action.payload.result !== []) {
                var _poItemDetailData = action.payload.result
                let pricePerItemCounter = 0
                _poItemDetailData.forEach((p, index) => {
                    if(_poItemDetailData[index].price_per_item){
                        pricePerItemCounter += 1
                    }
                    var _loc = {
                        'label': _poItemDetailData[index].po_number,
                        'value': _poItemDetailData[index].id,
                    }
                    _stckPoItemDetailSelect.push(_loc)
                })
                if(pricePerItemCounter === _poItemDetailData.length){
                    _stckPoItemDetailIsAllPricePerItemFilled = true
                }
            }
        return Object.assign({}, state, {stckPoItemDetailData: action.payload, success: true, stckTotalPoItemDetail: _totalPoItemDetail, 
                                            stckPoItemDetailCounterSelect: [], stckPoItemDetailSelect: _stckPoItemDetailSelect, inProgress: false,
                                            stckPoItemDetailIsAllPricePerItemFilled: _stckPoItemDetailIsAllPricePerItemFilled})
        case RECEIVE_PO_ITEM_DETAIL_COUNTER:
            let payload = action.payload
            let resultSelect = []
            let result = []
            if(payload !== []){
                result = decreaseRealizationQty(payload[0], payload[2])
            }
            for(let r of result){
                resultSelect.push({ 'label': r.item_name + " (" + r.expected_qty + ") ", 
                                    'batch_only': r.batch_number,
                                    'item_name':  r.item_name,
                                    'id_master_item': r.id_master_item,
                                    'realization_qty': r.realization_qty,
                                    'expected_qty': r.expected_qty,
                                    'rejected_qty': r.rejected_qty,
                                    'batch_number':  r.batch_number
                                })
            }
        return Object.assign({}, state, {stckPoItemDetailData: action.payload, success: true, stckTotalPoItemDetail: _totalPoItemDetail, 
                                            stckPoItemDetailCounterSelect: resultSelect , stckPoItemDetailSelect: _stckPoItemDetailSelect, inProgress: false})
        case FAILED_PO_ITEM_DETAIL_LIST:
            return ({stckPoItemDetailData: [], success: false, stckTotalPoItemDetail: 0, stckPoItemDetailSelect: [],
                                                                 stckPoItemDetailCounterSelect: [], inProgress: false})
        default:
            return state
    }
}


function decreaseRealizationQty(filteredPoItemDetail, currentPoInbound){
    let result = []
    if(!currentPoInbound)
        return filteredPoItemDetail
    if(filteredPoItemDetail.length === 0)
        return filteredPoItemDetail
    if(currentPoInbound.length === 0)
        return filteredPoItemDetail
    for(let item of filteredPoItemDetail){
        let temp = currentPoInbound.filter(i => i.batch_number === item.batch_number 
                                                && i.id_master_item === item.id_master_item)
        for(let index in temp){
            item["realization_qty"] = temp[index]? Number(item["realization_qty"]) - Number(temp[index]["realization_qty"]) : item["realization_qty"] 
        }
        result.push(item)
    }
    return result
}