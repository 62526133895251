import { FETCH_CARBON_LOGGER_SCOPE1_LIST, RECEIVE_CARBON_LOGGER_SCOPE1_LIST, 
                RECEIVE_CARBON_LOGGER_SCOPE1_ALL_LIST, FAILED_CARBON_LOGGER_SCOPE1_LIST} from '../../actions/carbonx/carbonx.logger-scope1.act'

const defaultStckLoggerScope1State = {
    carbonxLoggerScope1Data: [],
    carbonxCarbonEmissionTotal: 0,
    carbonxTotalLoggerScope1: 0,
    carbonxMaxScope1: 0,
    carbonxMinScope1: 0,
    carbonxAvgScope1: 0,
    carbonxScope1PrimaryDataPercentage: 0,
    carbonxScope1TotalWO: 0,
    success: true,
    inProgress: false,
}

function extractScope1DataOnly(scope1Obj){
    let result = []
    let carbonxScope1PrimaryDataTotal = 0
    let carbonxScopePrimaryData2Total = 0
    scope1Obj.result.data.forEach(scope1 => {
        if(!scope1.data)
            return
        if(!Array.isArray(scope1.data))
            return
        scope1.data.map(data => {
            data.id_fleet = scope1.fleet.id
            result.push(data)
            if(data.notes === 1)
                carbonxScope1PrimaryDataTotal+=1
            if(data.notes3 === 1)
                carbonxScopePrimaryData2Total+=1
            return data;
        })
    })
    return [result, carbonxScope1PrimaryDataTotal, carbonxScopePrimaryData2Total]
}

function countPrimaryDataPercentage(pmt1, pmt2, dl){
    let pmt1Result = (100 * pmt1)/ dl
    let pmt2Rsult = (100 * pmt2)/dl
    let half1pctg = pmt1Result-50 >= 0? pmt1Result-50 : 0
    let half2pctg = pmt2Rsult-50 >= 0? pmt2Rsult-50 : 0
    return half1pctg + half2pctg
}   

export function CarbonXLoggerScope1(state = defaultStckLoggerScope1State, action){
    switch (action.type) {
        case FETCH_CARBON_LOGGER_SCOPE1_LIST:
            return ({carbonxLoggerScope1Data: [], success: true, carbonxTotalLoggerScope1: 0, 
                        carbonxLoggerScope1Select: [], carbonxMaxScope1: 0,  carbonxMinScope1: 0,
                        // carbonxTotalLoggerScope1: 0,
                        inProgress: true})
        case RECEIVE_CARBON_LOGGER_SCOPE1_LIST:
            let payload = action.payload
            let total_carbon = payload.scope1.result.total_carbon
            let dataOnly = extractScope1DataOnly(payload.scope1)
            dataOnly = dataOnly[0].filter(d => {
                return d.id_fleet === payload.id_fleet
            })
            console.log(dataOnly)
            return Object.assign({}, state, {carbonxLoggerScope1Data: dataOnly, success: true,
                carbonxTotalLoggerScope1: dataOnly.length, 
                carbonxCarbonEmissionTotal: (total_carbon? total_carbon : 0).toFixed(2), carbonxMaxScope1: Math.max(...dataOnly.map(o => o.carbon_emission)), 
                carbonxMinScope1: Math.min(...dataOnly.map(o => o.carbon_emission)), 
                carbonxAvgScope1: dataOnly.map(a => a.carbon_emission).reduce((prev, curr) => prev + curr) / dataOnly.length,
                inProgress: false})
    
        case RECEIVE_CARBON_LOGGER_SCOPE1_ALL_LIST:
            let payloadAll = action.payload
            let total_carbon_all = payloadAll.result.total_carbon
            let total_wo_all = payloadAll.result.total_wo
            let dataOnly_all = extractScope1DataOnly(payloadAll)
            return Object.assign({}, state, {carbonxLoggerScope1Data: dataOnly_all[0], success: true,
                                    carbonxTotalLoggerScope1: dataOnly_all[0].length, 
                                    carbonxCarbonEmissionTotal: (total_carbon_all? total_carbon_all : 0).toFixed(2), carbonxMaxScope1: Math.max(...dataOnly_all[0].map(o => o.carbon_emission)), 
                                    carbonxMinScope1: Math.min(...dataOnly_all[0].map(o => o.carbon_emission)), 
                                    carbonxAvgScope1: dataOnly_all[0].map(a => a.carbon_emission).reduce((prev, curr) => prev + curr) / dataOnly_all[0].length,
                                    carbonxScope1PrimaryDataPercentage: countPrimaryDataPercentage(dataOnly_all[1], dataOnly_all[2], dataOnly_all[0].length),
                                    carbonxScope1TotalWO: total_wo_all? total_wo_all : 0,
                                    inProgress: false})
     
        case FAILED_CARBON_LOGGER_SCOPE1_LIST:
            return ({carbonxLoggerScope1Data: [], success: false, carbonxTotalLoggerScope1: 0, 
                            carbonxLoggerScope1Select: [], carbonxMaxScope1: 0,  carbonxMinScope1: 0,
                            // carbonxTotalLoggerScope1: 0,
                             inProgress: false})
        default:
            return state
    }
}