import { FETCH_STCK_ADM_OFFICES_LIST, RECEIVE_STCK_ADM_OFFICES_LIST, FAILED_STCK_ADM_OFFICES_LIST } from '../../actions/stocker/stck.admoffices.act'

const defaultStckUAdmOfficeState = {
    stckAdmOfficesData: [],
    stckTotalAdmOffice: 0,
    stckAdmOfficeSelect: [],
    success: true,
    inProgress: false,
}

export function StckAdmOffices(state = defaultStckUAdmOfficeState, action){
    switch (action.type) {
        case FETCH_STCK_ADM_OFFICES_LIST:
            return ({stckAdmOfficesData: [], success: true, stckTotalAdmOffice: 0, stckAdmOfficeSelect: [], inProgress: true})
        case RECEIVE_STCK_ADM_OFFICES_LIST:
            var _totalAdmOffice = action.payload.result.length;
            var _stckAdmOfficeSelect = []
            if(action.payload.result !== []) {
                var _admOfficeData = action.payload.result

                _admOfficeData.forEach((p, index) => {
                    var _loc = {
                        'label': _admOfficeData[index].office_name + ' - ' + _admOfficeData[index].office_address,
                        'value': _admOfficeData[index].id,
                        'id_company': _admOfficeData[index].id_company,
                        'office_name': _admOfficeData[index].office_name,
                        'office_address': _admOfficeData[index].office_address,
                        'operating_area_name': _admOfficeData[index].operating_area_name,
                        'lat': _admOfficeData[index].lat,
                        'lng': _admOfficeData[index].lng,
                        'kml_link': _admOfficeData[index].kml_link,
                        'id_country': _admOfficeData[index].id_country,
                        'country_name': _admOfficeData[index].country_name,
                        'id_province': _admOfficeData[index].id_province,
                        'province_name': _admOfficeData[index].province_name,
                        'main_phone_number': _admOfficeData[index].main_phone_number,
                        'whatsapp_phone_number': _admOfficeData[index].whatsapp_phone_number,
                        'alt_phone_number': _admOfficeData[index].alt_phone_number,
                        'fax_number': _admOfficeData[index].fax_number
                    }
                    _stckAdmOfficeSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckAdmOfficesData: action.payload, success: true, stckTotalAdmOffice: _totalAdmOffice, stckAdmOfficeSelect: _stckAdmOfficeSelect, inProgress: false})
        case FAILED_STCK_ADM_OFFICES_LIST:
            return ({stckAdmOfficesData: [], success: false, stckTotalAdmOffice: 0, stckAdmOfficeSelect: [], inProgress: false})
        default:
            return state
    }
}