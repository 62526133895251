import { FETCH_OUTBOUND_DETAIL_LIST, RECEIVE_OUTBOUND_DETAIL_LIST, FAILED_OUTBOUND_DETAIL_LIST } from '../../actions/stocker/stck.outbounddetail.act'

const defaultStckOutboundDetailState = {
    stckOutboundDetailData: [],
    stckTotalOutboundDetail: 0,
    stckOutboundDetailSelect: [],
    success: true,
    inProgress: false,
}

export function StckOutboundDetail(state = defaultStckOutboundDetailState, action){
    switch (action.type) {
        case FETCH_OUTBOUND_DETAIL_LIST:
            return ({stckOutboundDetailData: [], success: true, stckTotalOutboundDetail: 0, stckOutboundDetailSelect: [], inProgress: true})
        case RECEIVE_OUTBOUND_DETAIL_LIST:
            var _totalOutboundDetail = action.payload.result.length;
            var _stckOutboundDetailSelect = []
            if(action.payload.result !== []) {
                var _outboundDetailData = action.payload.result

                _outboundDetailData.forEach((p, index) => {
                    var _loc = {
                        'label': _outboundDetailData[index].awb_number,
                        'value': _outboundDetailData[index].id,
                        "id": _outboundDetailData[index].id,
                        "outbound_number": _outboundDetailData[index].outbound_number,
                        "document_number": _outboundDetailData[index].document_number,
                        "id_tracker_type": _outboundDetailData[index].id_tracker_type,
                        "tracker_internal_code": _outboundDetailData[index].tracker_internal_code,
                        "tracker_type": _outboundDetailData[index].tracker_type,
                        "awb_number": _outboundDetailData[index].awb_number,
                        
                    }
                    _stckOutboundDetailSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckOutboundDetailData: action.payload, success: true, stckTotalOutboundDetail: _totalOutboundDetail, stckOutboundDetailSelect: _stckOutboundDetailSelect, inProgress: false})
        case FAILED_OUTBOUND_DETAIL_LIST:
            return ({stckOutboundDetailData: [], success: false, stckTotalOutboundDetail: 0, stckOutboundDetailSelect: [], inProgress: false})
        default:
            return state
    }
}