import { FETCH_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT, RECEIVE_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT, 
    FAILED_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT, FETCH_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE, 
    RECEIVE_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE, FAILED_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE
} from '../../actions/transporter/aTransporter-TriPLBidApprovedPayment'

const defaultTriPLApprovedCheckPaymentState = {
    triPLApprovedCheckPaymentData: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLApprovedCheckPayment(state = defaultTriPLApprovedCheckPaymentState, action){
    switch (action.type) {
        case FETCH_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT:
            return ({triPLApprovedCheckPaymentData: [], success: true, inProgress: true})
        case RECEIVE_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT:
            return Object.assign({}, state, {triPLApprovedCheckPaymentData: action.payload.result, 
                                                 success: true, inProgress: false})
        case FAILED_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT:
            return ({triPLApprovedCheckPaymentData: [], success: false, inProgress: false})
        default:
            return state
    }
}

const defaultTriPLApprovedGenerateInvoiceState = {
    triPLApprovedGenerateInvoiceData: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLApprovedGenerateInvoice(state = defaultTriPLApprovedGenerateInvoiceState, action){
    switch (action.type) {
        case FETCH_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE:
            return ({triPLApprovedGenerateInvoiceData: [], success: true, inProgress: true})
        case RECEIVE_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE:
            return Object.assign({}, state, {triPLApprovedGenerateInvoiceData: action.payload.result, 
                                                 success: true, inProgress: false})
        case FAILED_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE:
            return ({triPLApprovedGenerateInvoiceData: [], success: false, inProgress: false})
        default:
            return state
    }
}