import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
// import { API_LINKS.WORK_ORDER_STOCKER_INBOUND_ITEM, API_LINKS.WORK_ORDER_STOCKER_OUTBOUND_ITEM} from '../../config/stck.links'
import {toast} from 'react-toastify'

export const FETCH_WORK_ORDERS_STOCKER_TABLE = 'FETCH_WORK_ORDERS_STOCKER_TABLE'
export const RECEIVE_WORK_ORDERS_STOCKER_TABLE = 'RECEIVE_WORK_ORDERS_STOCKER_TABLE'
export const FAILED_WORK_ORDERS_STOCKER_TABLE = 'FAILED_WORK_ORDERS_STOCKER_TABLE'

export const FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM = 'FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM'
export const RECEIVE_WORK_ORDERS_STOCKER_INBOUND_ITEM = 'RECEIVE_WORK_ORDERS_STOCKER_INBOUND_ITEM'
export const RECEIVE_WORK_ORDERS_STOCKER_INBOUND_ITEM_ONE_ONLY = 'RECEIVE_WORK_ORDERS_STOCKER_INBOUND_ITEM_ONE_ONLY'
export const FAILED_WORK_ORDERS_STOCKER_INBOUND_ITEM = 'FAILED_WORK_ORDERS_STOCKER_INBOUND_ITEM'

export const FETCH_WORK_ORDERS_STOCKER_OUTBOUND_ITEM = 'FETCH_WORK_ORDERS_STOCKER_OUTBOUND_ITEM'
export const RECEIVE_WORK_ORDERS_STOCKER_OUTBOUND_ITEM = 'RECEIVE_WORK_ORDERS_STOCKER_OUTBOUND_ITEM'
export const RECEIVE_WORK_ORDERS_STOCKER_OUTBOUND_ITEM_ONE_ONLY = 'RECEIVE_WORK_ORDERS_STOCKER_OUTBOUND_ITEM_ONE_ONLY'
export const FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM = 'FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM'

export function fetchWorkOrdersStockerTable(_id){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(auth)

    return (dispatch, getState) => {
        dispatch({type: FETCH_WORK_ORDERS_STOCKER_TABLE});
        axios({
            method: 'post',
            url: API_LINKS.WORK_ORDER_STOCKER,
            headers: {
                Authorization: auth
            },
            data: {
                id_delivery_order: _id,
                // date: 
                // For Parameters
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_WORK_ORDERS_STOCKER_TABLE,
                payload: response.data.result
            })
        })
        .catch(function(error){
            console.log(error)
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_STOCKER_TABLE
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_STOCKER_TABLE
                    })
                    return toast.error("Failed in fetching Work Orders List. Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_STOCKER_TABLE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_WORK_ORDERS_STOCKER_TABLE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_WORK_ORDERS_STOCKER_TABLE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_WORK_ORDERS_STOCKER_TABLE
                })
                return toast.error('Something went wrong... Please try again later...')
            }

        })
    }
}


export function fetchOneWorkOrdersStockerOutboundItemByWoTableByIdWo(id_work_order){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(auth)
    return (dispatch, getState) => {
        dispatch({type: FETCH_WORK_ORDERS_STOCKER_OUTBOUND_ITEM});
        axios({
            method: 'post',
            url: API_LINKS.WORK_ORDER_STOCKER_OUTBOUND_ITEM,
            headers: {
                Authorization: auth
            },
            data: {
                id_work_order
            }
        })   
        .then((response) => {
            dispatch( {
                type: RECEIVE_WORK_ORDERS_STOCKER_OUTBOUND_ITEM_ONE_ONLY,
                payload: response.data.result
            })
        })
    }
}

export function fetchWorkOrdersStockerOutboundItemByWoTable(workOrdersTableData){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(auth)
    return (dispatch, getState) => {
        dispatch({type: FETCH_WORK_ORDERS_STOCKER_OUTBOUND_ITEM});
        let fetchOutbounds= []
        let outItemResult = {}
        workOrdersTableData.forEach(wo => {
            let idStockerOutbound = wo.id_stocker_outbound_saved? wo.id_stocker_outbound_saved : 
            wo.work_order_from? wo.work_order_from.id_outbound_stocker : null
            if(!idStockerOutbound)
                return
            outItemResult[idStockerOutbound] = []
            fetchOutbounds.push(
                axios({
                    method: 'post',
                    url: API_LINKS.WORK_ORDER_STOCKER_OUTBOUND_ITEM,
                    headers: {
                        Authorization: auth
                    },
                    data: {
                        id_work_order: wo.id? wo.id: wo.work_order_from? wo.work_order_from.id : null
                        // date: 
                        // For Parameters
                    }
                })
            )
        })
        Promise.all([...fetchOutbounds])
        .then((responses) => {
            responses.forEach((r)=> {
                let idMasterOutbound = r.data.result[0]? r.data.result[0].id_master_outbound : null
                if(!idMasterOutbound)
                    return
                outItemResult[idMasterOutbound] = [...r.data.result]
            })
            dispatch( {
                type: RECEIVE_WORK_ORDERS_STOCKER_OUTBOUND_ITEM,
                payload: outItemResult
            })
        })
    //     .catch(function(error){
    //         console.log(error)
    //         if (error.response) {
    //             if(error.response.status === 401) {
    //                 dispatch({
    //                     type: FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM
    //                 })
    //                 return toast.error("Invalid username or password! Please try again!");
    //             } else if (error.response.status === 404 || error.response.status === 500) {
    //                 dispatch({
    //                     type: FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM
    //                 })
    //                 return toast.error("Failed in fetching Work Orders List. Server cannot be contacted! Please ask your system administrator!");
    //             } else if (error.response.status === 400) {
    //                 dispatch({
    //                     type: FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM
    //                 })
    //                 return toast.error(error.response.data.message);
    //             } else {
    //                 dispatch({
    //                     type: FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM
    //                 })
    //                 return toast.error('Something went wrong... Please try again later...');
    //             }
    //         } else if (error.request) {
    //             dispatch({
    //                 type: FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM
    //             })
    //             return toast.error('Request error! Please try again later...')
    //         } else {
    //             dispatch({
    //                 type: FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM
    //             })
    //             return toast.error('Something went wrong... Please try again later...')
    //         }

    //     })
    }
}

export function fetchOneWorkOrdersStockerInboundItemByWoTableByIdWo(id_work_order){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(auth)
    return (dispatch, getState) => {
        dispatch({type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM});
        axios({
            method: 'post',
            url: API_LINKS.WORK_ORDER_STOCKER_INBOUND_ITEM,
            headers: {
                Authorization: auth
            },
            data: {
                id_work_order
            }
        })   
        .then((response) => {
            dispatch( {
                type: RECEIVE_WORK_ORDERS_STOCKER_INBOUND_ITEM_ONE_ONLY,
                payload: response.data.result
            })
        })
    }
}

export function fetchWorkOrdersStockerInboundItemByWoTable(workOrdersTableData){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(auth)
    return (dispatch, getState) => {
        dispatch({type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM});
        let fetchInbounds = []
        let inbItemResult = {}
        workOrdersTableData.forEach(wo => {
            let idStockerInbound= wo.id_inbound_stocker? wo.id_inbound_stocker : 
                    wo.work_order_from? wo.work_order_from.id_inbound_stocker : null
            if(!idStockerInbound)
                return
            inbItemResult[idStockerInbound]=[]
            fetchInbounds.push(
                axios({
                    method: 'post',
                    url: API_LINKS.WORK_ORDER_STOCKER_INBOUND_ITEM,
                    headers: {
                        Authorization: auth
                    },
                    data: {
                        id_work_order: wo.id? wo.id: wo.work_order_from? wo.work_order_from.id : null
                        // date: 
                        // For Parameters
                    }
                })
            )
        })
        Promise.all([...fetchInbounds])
        .then((responses) => {
            responses.forEach((r,i)=> {
                let idMasterinbound = r.data.result[0]? r.data.result[0].id_master_inbound : null
                if(!idMasterinbound)
                    return
                inbItemResult[idMasterinbound] = [...r.data.result]
            })
            dispatch( {
                type: RECEIVE_WORK_ORDERS_STOCKER_INBOUND_ITEM,
                payload: inbItemResult
            })
        })
        // .catch(function(error){
        //     console.log(error)
        //     if (error.response) {
        //         if(error.response.status === 401) {
        //             dispatch({
        //                 type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM
        //             })
        //             return toast.error("Invalid username or password! Please try again!");
        //         } else if (error.response.status === 404 || error.response.status === 500) {
        //             dispatch({
        //                 type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM
        //             })
        //             return toast.error("Failed in fetching Work Orders List. Server cannot be contacted! Please ask your system administrator!");
        //         } else if (error.response.status === 400) {
        //             dispatch({
        //                 type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM
        //             })
        //             return toast.error(error.response.data.message);
        //         } else {
        //             dispatch({
        //                 type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM
        //             })
        //             return toast.error('Something went wrong... Please try again later...');
        //         }
        //     } else if (error.request) {
        //         dispatch({
        //             type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM
        //         })
        //         return toast.error('Request error! Please try again later...')
        //     } else {
        //         dispatch({
        //             type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM
        //         })
        //         return toast.error('Something went wrong... Please try again later...')
        //     }

        // })
    }
}


export function fetchWorkOrdersStockerOutboundItemById(_ids){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(auth)
    return (dispatch, getState) => {
        dispatch({type: FETCH_WORK_ORDERS_STOCKER_OUTBOUND_ITEM});
        let fetchOutbounds= []
        let outItemResult = {}
        _ids.forEach(id => {
            let idStockerOutbound = id
            if(!idStockerOutbound)
                return
            outItemResult[idStockerOutbound] = []
            fetchOutbounds.push(
                axios({
                    method: 'post',
                    url: API_LINKS.WORK_ORDER_STOCKER_OUTBOUND_ITEM,
                    headers: {
                        Authorization: auth
                    },
                    data: {
                        id_work_order: idStockerOutbound
                        // date: 
                        // For Parameters
                    }
                })
            )
        })
        Promise.all([...fetchOutbounds])
        .then((responses) => {
            responses.forEach((r)=> {
                let idMasterOutbound = r.data.result[0]? r.data.result[0].id_master_outbound : null
                if(!idMasterOutbound)
                    return
                outItemResult[idMasterOutbound] = [...r.data.result]
            })
            dispatch( {
                type: RECEIVE_WORK_ORDERS_STOCKER_OUTBOUND_ITEM,
                payload: outItemResult
            })
        })
    //     .catch(function(error){
    //         console.log(error)
    //         if (error.response) {
    //             if(error.response.status === 401) {
    //                 dispatch({
    //                     type: FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM
    //                 })
    //                 return toast.error("Invalid username or password! Please try again!");
    //             } else if (error.response.status === 404 || error.response.status === 500) {
    //                 dispatch({
    //                     type: FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM
    //                 })
    //                 return toast.error("Failed in fetching Work Orders List. Server cannot be contacted! Please ask your system administrator!");
    //             } else if (error.response.status === 400) {
    //                 dispatch({
    //                     type: FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM
    //                 })
    //                 return toast.error(error.response.data.message);
    //             } else {
    //                 dispatch({
    //                     type: FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM
    //                 })
    //                 return toast.error('Something went wrong... Please try again later...');
    //             }
    //         } else if (error.request) {
    //             dispatch({
    //                 type: FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM
    //             })
    //             return toast.error('Request error! Please try again later...')
    //         } else {
    //             dispatch({
    //                 type: FAILED_WORK_ORDERS_STOCKER_OUTBOUND_ITEM
    //             })
    //             return toast.error('Something went wrong... Please try again later...')
    //         }

    //     })
    }
}

export function fetchWorkOrdersStockerInboundItemById(_ids){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(auth)
    return (dispatch, getState) => {
        dispatch({type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM});
        let fetchInbounds = []
        let inbItemResult = {}
        _ids.forEach(id => {
            let idStockerInbound= id
            if(!idStockerInbound)
                return
            inbItemResult[idStockerInbound]=[]
            fetchInbounds.push(
                axios({
                    method: 'post',
                    url: API_LINKS.WORK_ORDER_STOCKER_INBOUND_ITEM,
                    headers: {
                        Authorization: auth
                    },
                    data: {
                        id_work_order: id
                        // date: 
                        // For Parameters
                    }
                })
            )
        })
        Promise.all([...fetchInbounds])
        .then((responses) => {
            responses.forEach((r,i)=> {
                let idMasterinbound = r.data.result[0]? r.data.result[0].id_master_inbound : null
                if(!idMasterinbound)
                    return
                inbItemResult[idMasterinbound] = [...r.data.result]
            })
            dispatch( {
                type: RECEIVE_WORK_ORDERS_STOCKER_INBOUND_ITEM,
                payload: inbItemResult
            })
        })
        // .catch(function(error){
        //     console.log(error)
        //     if (error.response) {
        //         if(error.response.status === 401) {
        //             dispatch({
        //                 type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM
        //             })
        //             return toast.error("Invalid username or password! Please try again!");
        //         } else if (error.response.status === 404 || error.response.status === 500) {
        //             dispatch({
        //                 type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM
        //             })
        //             return toast.error("Failed in fetching Work Orders List. Server cannot be contacted! Please ask your system administrator!");
        //         } else if (error.response.status === 400) {
        //             dispatch({
        //                 type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM
        //             })
        //             return toast.error(error.response.data.message);
        //         } else {
        //             dispatch({
        //                 type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM
        //             })
        //             return toast.error('Something went wrong... Please try again later...');
        //         }
        //     } else if (error.request) {
        //         dispatch({
        //             type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM
        //         })
        //         return toast.error('Request error! Please try again later...')
        //     } else {
        //         dispatch({
        //             type: FETCH_WORK_ORDERS_STOCKER_INBOUND_ITEM
        //         })
        //         return toast.error('Something went wrong... Please try again later...')
        //     }

        // })
    }
}

