import axios from "axios";
import {SO_ITEM_DETAIL_LIST_URL, MASTER_OUTBOUND_LIST_URL, 
           MASTER_ITEM_DETAIL_LIST_URL, OUTBOUND_ITEM_DETAIL_LIST_URL} from '../../config/stck.links'
import {toast} from 'react-toastify'

export const FETCH_SO_ITEM_DETAIL_LIST = 'FETCH_SO_ITEM_DETAIL_LIST'
export const RECEIVE_SO_ITEM_DETAIL_LIST = 'RECEIVE_SO_ITEM_DETAIL_LIST'
export const RECEIVE_SO_ITEM_DETAIL_COUNTER = 'RECEIVE_SO_ITEM_DETAIL_COUNTER'
export const FAILED_SO_ITEM_DETAIL_LIST = 'FAILED_SO_ITEM_DETAIL_LIST'

export function fetchSoItemDetail(_id){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_SO_ITEM_DETAIL_LIST});
        axios({
            method: 'post',
            url: SO_ITEM_DETAIL_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_sales_order: _id
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_SO_ITEM_DETAIL_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_SO_ITEM_DETAIL_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_SO_ITEM_DETAIL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_SO_ITEM_DETAIL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_SO_ITEM_DETAIL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_SO_ITEM_DETAIL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_SO_ITEM_DETAIL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}


export function fetchCountingSoItemDetail(_id_so, _id_warehouse, _id_warehouse_area){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return async (dispatch, getState) => {
        dispatch({type: FETCH_SO_ITEM_DETAIL_LIST});
        let request1 = axios({
            method: 'post',
            url: SO_ITEM_DETAIL_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_sales_order: _id_so
            }
        })

        let request2 = axios({
                method: 'post',
                url: MASTER_OUTBOUND_LIST_URL,
                headers: {
                    Authorization: auth
                },
                data:{
                    id_sales_order: _id_so
                }
            })
        let request3 =  axios({
            method: 'post',
            url: MASTER_ITEM_DETAIL_LIST_URL,
            headers: {
                Authorization: auth
            },
            // data:{
            //     id_warehouse: _id_warehouse,
            //     id_warehouse_area: _id_warehouse_area
            // }
        })

        let payloadToSend = []
        await Promise.all(
            [request1, request2, request3]
        ).then(async (values) => {
            let request4Result = []
            payloadToSend = values
            values[0] =  values[0].data.result
            values[1] =  values[1].data.result
            values[2] =  values[2].data.result
            await Promise.all(
                values[1].map(value => {
                    axios({
                        method: 'post',
                        url: OUTBOUND_ITEM_DETAIL_LIST_URL,
                        headers: {
                            Authorization: auth
                        },
                        data:{
                            id_master_outbound: value.id
                        }
                    }).then((response) => {
                        request4Result.push(...response.data.result)
                        payloadToSend[3] = request4Result
                    })
                    return value;
                })
            ).then(() => {
                setTimeout(()=> {
                    dispatch( {
                        type: RECEIVE_SO_ITEM_DETAIL_COUNTER,
                        payload: payloadToSend? payloadToSend : []
                    })
                },1000)
            })
        })
        .catch(function(error){
            console.log(error)
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_SO_ITEM_DETAIL_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_SO_ITEM_DETAIL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_SO_ITEM_DETAIL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_SO_ITEM_DETAIL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_SO_ITEM_DETAIL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_SO_ITEM_DETAIL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}