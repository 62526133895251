/*

    Pembuatan ability, sebagai objek yang menyimpan atribut user level
    beserta batasan aksesnya (disimpan dalam properti action & subject), 
    yang telah didefinisikan pada file permission.js

*/ 


import { Ability, AbilityBuilder } from "@casl/ability"
import {permissionsByLevels, permissionsBySubcriptions} from './permissions.js'
import store from '../redux/store.js';

const ability = new Ability();

const defineRulesFor =  user => {

    const { can, rules } = new AbilityBuilder();

    let getPermission = {}

    if(user.level){
        getPermission = permissionsByLevels
                                .filter(arr => arr.level === user.level)[0]
                                .permissions
        
        getPermission.can.forEach( p => {

            can(p.action, p.subject)
    
        })

    }

    if(user.subs){

        getPermission = permissionsBySubcriptions
                                .filter(arr => arr.subs === user.subs)[0]
                                .permissions
        
        getPermission.can.forEach( p => {

            if(p.class === undefined){

                can(p.action, p.subject)

            } else {

                can(p.action, p.class, p.subject)

            }

            //Lebih elegan namun masih error pada pValues[2]

            // let pValues = Object.values(p)

            // console.log(pValues[2])

            // can(...pValues, pValues[0], pValues[1], pValues[2])
    
        })

    }

    return rules
    
}

//Masih perlu ditelaah untuk metode unsubscribe terbaiknya
store.subscribe(() => {
    
    let auth = store.getState().authStore;

    if(auth.result){

        let authUser = {

            level : String(auth.result.id_roles),

            subs : String(auth.result.subs.plan_name)

        }
        
        ability.update(defineRulesFor(authUser));

    }

});

export default ability;