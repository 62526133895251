import { FETCH_INBOUND_VEHICLE_LIST, RECEIVE_INBOUND_VEHICLE_LIST, FAILED_INBOUND_VEHICLE_LIST } from '../../actions/stocker/stck.inboundvehicle.act'

const defaultStckInboundVehicleState = {
    stckInboundVehicleData: [],
    stckTotalInboundVehicle: 0,
    stckInboundVehicleSelect: [],
    success: true,
    inProgress: false,
}

export function StckInboundVehicle(state = defaultStckInboundVehicleState, action){
    switch (action.type) {
        case FETCH_INBOUND_VEHICLE_LIST:
            return ({stckInboundVehicleData: [], success: true, stckTotalInboundVehicle: 0, stckInboundVehicleSelect: [], inProgress: true})
        case RECEIVE_INBOUND_VEHICLE_LIST:
            var _totalInboundVehicle = action.payload.result.length;
            var _stckInboundVehicleSelect = []
            if(action.payload.result !== []) {
                var _inboundVehicleData = action.payload.result

                _inboundVehicleData.forEach((p, index) => {
                    var _loc = {
                        'label': _inboundVehicleData[index].license_number,
                        'value': _inboundVehicleData[index].id,
                        "id": _inboundVehicleData[index].id,
                        "id_master_inbound": _inboundVehicleData[index].id_master_inbound,
                        "inbound_number": _inboundVehicleData[index].inbound_number,
                        "document_number": _inboundVehicleData[index].document_number,
                        "id_inbound_detail": _inboundVehicleData[index].id_inbound_detail,
                        "awb_number": _inboundVehicleData[index].awb_number,
                        "license_number": _inboundVehicleData[index].license_number,
                        "fleet_brand": _inboundVehicleData[index].fleet_brand,
                        "fleet_type": _inboundVehicleData[index].fleet_type,
                        "fleet_color": _inboundVehicleData[index].fleet_color,
                        "in_time": _inboundVehicleData[index].in_time,
                        "out_time": _inboundVehicleData[index].out_time,
                        "id_security": _inboundVehicleData[index].id_security,
                    }
                    _stckInboundVehicleSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckInboundVehicleData: action.payload, success: true, stckTotalInboundVehicle: _totalInboundVehicle, stckInboundVehicleSelect: _stckInboundVehicleSelect, inProgress: false})
        case FAILED_INBOUND_VEHICLE_LIST:
            return ({stckInboundVehicleData: [], success: false, stckTotalInboundVehicle: 0, stckInboundVehicleSelect: [], inProgress: false})
        default:
            return state
    }
}