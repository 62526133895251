import { FETCH_WORKSHOP_TABLE, RECEIVE_WORKSHOP_TABLE, FAILED_WORKSHOP_TABLE ,
FETCH_BINDED_WORKSHOP_TABLE, RECEIVE_BINDED_WORKSHOP_TABLE, FAILED_BINDED_WORKSHOP_TABLE,
FETCH_WORKSHOP_PIC_POSITION_LIST, RECEIVE_WORKSHOP_PIC_POSITION_LIST, FAILED_WORKSHOP_PIC_POSITION_LIST,
FETCH_WORKSHOP_PAYEMNT_TERM_TYPE_LIST, RECEIVE_WORKSHOP_PAYEMNT_TERM_TYPE_LIST, FAILED_WORKSHOP_PAYEMNT_TERM_TYPE_LIST,
FETCH_WORKSHOP_PIC_LIST, RECEIVE_WORKSHOP_PIC_LIST, FAILED_WORKSHOP_PIC_LIST
} from '../../actions/transporter/workshop.act'

function getSelectFromPayload(payloadData, settings){
    let result = []
    if(!payloadData)
        return result
    if(!Array.isArray(payloadData))
        return result
    let labelField = settings.labelField
    let valueField = settings.valueField
    payloadData.forEach(data => {
        result.push({
            label: data[labelField],
            value: data[valueField]
        })
    })
    return result
}

const defaultBindedWorkshopListState = {
    bindedWorkshopListData: [],
    inProgress: false,
    success: true,
    bindedWorkshopSelectData: [],
    completeWorkshopSelectData: []
}

export function BindedWorkshopList(state = defaultBindedWorkshopListState, action){
    switch (action.type) {
        case FETCH_BINDED_WORKSHOP_TABLE:
            return ({bindedWorkshopListData: [], success: false, inProgress: true, bindedWorkshopSelectData: [], completeWorkshopSelectData: []})
        case RECEIVE_BINDED_WORKSHOP_TABLE:
            var list = []
            if(action.payload !== []) {
                var bindedWorkshopList = action.payload
                bindedWorkshopList.forEach((p, index) => {
                    var workshop = {
                        'label': bindedWorkshopList[index].name,
                        'value': bindedWorkshopList[index].id
                    }
                    list.push(workshop)
                })
            }
            return Object.assign({}, state, {bindedWorkshopListData: action.payload, success: true, inProgress: false, bindedWorkshopSelectData:list})
        case FAILED_BINDED_WORKSHOP_TABLE:
            return ({bindedWorkshopListData: [], success: false, inProgress: false})
        default:
            return state
    }
}

const defaultworkshopListState = {
    workshopListData: [],
    inProgress: false,
    success: true,
    workshopSelectData: [],
    completeworkshopSelectData: []
}

export function WorkshopList(state = defaultworkshopListState, action){
    switch (action.type) {
        case FETCH_WORKSHOP_TABLE:
            return ({workshopListData: [], success: false, inProgress: true, workshopSelectData: [], completeworkshopSelectData: []})
        case RECEIVE_WORKSHOP_TABLE:
            var list = []
            if(action.payload !== []) {
                var workshopList = action.payload.result
                workshopList.forEach((p, index) => {
                    var workshop = {
                        'label': workshopList[index].name,
                        'value': workshopList[index].id
                    }
                    list.push(workshop)
                })
            }
            return Object.assign({}, state, {workshopListData: workshopList, success: true, inProgress: false, workshopSelectData:list})
        case FAILED_WORKSHOP_TABLE:
            return ({workshopListData: [], success: false, inProgress: false})
        default:
            return state
    }
}

const defaultWorkshopPicPositionState = {
    totalPicPositions:0,
    picPositions: [],
    picPositionsSelect: [],
    success: true,  
    inProgress: false
}

export function PicPositions(state = defaultWorkshopPicPositionState, action){
    switch (action.type) {
        case FETCH_WORKSHOP_PIC_POSITION_LIST:
            return ({picPositions: [], success: true, picPositionsSelect: [],
                    inProgress: true, totalPicPositions:0})
        case RECEIVE_WORKSHOP_PIC_POSITION_LIST:
            const {payload} = action
            let picPositionsSelect = getSelectFromPayload(
                payload.result, {labelField:"name", valueField: "id"}
            )
            return Object.assign({}, state, {picPositions: action.payload.result,
                                                picPositionsSelect: picPositionsSelect,
                                                totalPicPositions: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_WORKSHOP_PIC_POSITION_LIST:
            return ({picPositions: [], success: false, picPositionsSelect: [],
                    inProgress: false, totalPicPositions:0,})
        default:
            return state
    }
}

const defaultWorkshopPicState = {
    totalPics:0,
    pic: [],
    picSelect: [],
    success: true,  
    inProgress: false
}

export function Pics(state = defaultWorkshopPicState, action){
    switch (action.type) {
        case FETCH_WORKSHOP_PIC_LIST:
            return ({pic: [], success: true, picSelect: [],
                    inProgress: true, totalPics:0})
        case RECEIVE_WORKSHOP_PIC_LIST:
            const {payload} = action
            let picSelect = getSelectFromPayload(
                payload.result, {labelField:"name", valueField: "id"}
            )
            return Object.assign({}, state, {pic: action.payload.result,
                                                picSelect: picSelect,
                                                totalPics: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_WORKSHOP_PIC_LIST:
            return ({pic: [], success: false, picSelect: [],
                    inProgress: false, totalPics:0,})
        default:
            return state
    }
}

const defaultWorkshopPaymentTermTypeState = {
    totalPaymentTermTypes:0,
    paymentTermType: [],
    success: true,  
    inProgress: false
}

export function PaymentTermTypes(state = defaultWorkshopPaymentTermTypeState, action){
    switch (action.type) {
        case FETCH_WORKSHOP_PAYEMNT_TERM_TYPE_LIST:
            return ({paymentTermType: [], success: true, inProgress: true, totalPaymentTermTypes:0})
        case RECEIVE_WORKSHOP_PAYEMNT_TERM_TYPE_LIST:
            return Object.assign({}, state, {paymentTermType: action.payload.result, 
                                                totalPaymentTermTypes: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_WORKSHOP_PAYEMNT_TERM_TYPE_LIST:
            return ({paymentTermType: [], success: false, inProgress: false, totalPaymentTermTypes:0,})
        default:
            return state
    }
}