import { FETCH_WAREHOUSE_TRANSFER_DETAIL_LIST, RECEIVE_WAREHOUSE_TRANSFER_DETAIL_LIST, FAILED_WAREHOUSE_TRANSFER_DETAIL_LIST } from '../../actions/stocker/stck.warehousetransferdetail.act'

const defaultStckWarehouseTransferDetailState = {
    stckWarehouseTransferDetailData: [],
    stckTotalWarehouseTransferDetail: 0,
    stckWarehouseTransferDetailSelect: [],
    success: true,
    inProgress: false,
}

export function StckWarehouseTransferDetail(state = defaultStckWarehouseTransferDetailState, action){
    switch (action.type) {
        case FETCH_WAREHOUSE_TRANSFER_DETAIL_LIST:
            return ({stckWarehouseTransferDetailData: [], success: true, stckTotalWarehouseTransferDetail: 0, stckWarehouseTransferDetailSelect: [], inProgress: true})
        case RECEIVE_WAREHOUSE_TRANSFER_DETAIL_LIST:
            var _totalUnits = action.payload.result.length;
            var _stckWarehouseTransferDetailSelect = []
            if(action.payload.result !== []) {
                var _repackagingsData = action.payload.result
                _repackagingsData.forEach((p, index) => {
                    var _loc = {
                        'label': _repackagingsData[index].code + " - " + _repackagingsData[index].name ,
                        'value': _repackagingsData[index].id
                    }
                    _stckWarehouseTransferDetailSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckWarehouseTransferDetailData: action.payload, success: true, stckTotalWarehouseTransferDetail: _totalUnits, stckWarehouseTransferDetailSelect: _stckWarehouseTransferDetailSelect, inProgress: false})
        case FAILED_WAREHOUSE_TRANSFER_DETAIL_LIST:
            return ({stckWarehouseTransferDetailData: [], success: false, stckTotalWarehouseTransferDetail: 0, stckWarehouseTransferDetailSelect: [], inProgress: false})
        default:
            return state
    }
}