import { FETCH_CAR_GENERAL_TYPE_TABLE, RECEIVE_CAR_GENERAL_TYPE_TABLE, FAILED_CAR_GENERAL_TYPE_TABLE } from '../../actions/transporter/aTransporter-CarGeneralTypeTable'

const defaultCarGeneralTypeTableState = {
    carGeneralTypeTableData: []    
}

export function fetchCarGeneralTypeTable(state = defaultCarGeneralTypeTableState, action){
    switch (action.type) {
        case FETCH_CAR_GENERAL_TYPE_TABLE:
            return ({carGeneralTypeTableData: [], inProgress: true})
        case RECEIVE_CAR_GENERAL_TYPE_TABLE:
            return Object.assign({}, state, {carGeneralTypeTableData: action.payload, inProgress: false})
        case FAILED_CAR_GENERAL_TYPE_TABLE:
            return ({carGeneralTypeTableData: [], inProgress: false})
        default:
            return state
    }
}