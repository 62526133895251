import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_DRIVERS_TABLE = 'FETCH_DRIVERS_TABLE'
export const RECEIVE_DRIVERS_TABLE = 'RECEIVE_DRIVERS_TABLE'
export const FAILED_DRIVERS_TABLE = 'FAILED_DRIVERS_TABLE'

export const FETCH_DRIVERS_SUMMARY = 'FETCH_DRIVERS_SUMMARY'
export const RECEIVE_DRIVERS_SUMMARY = 'RECEIVE_DRIVERS_SUMMARY'
export const FAILED_DRIVERS_SUMMARY = 'FAILED_DRIVERS_SUMMARY'

export function fetchDriversTable(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_DRIVERS_TABLE});
        axios({
            method: 'post',
            url: API_LINKS.DRIVER_LIST_URL,
            headers: {
                Authorization: auth
            },
            // data: {
            //     dev: 'T02',
            //     // date: 
            //     // For Parameters
            // }
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_DRIVERS_TABLE,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_DRIVERS_TABLE
                    })
                    return toast.error('Unathorized access!');
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_DRIVERS_TABLE
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_DRIVERS_TABLE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_DRIVERS_TABLE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_DRIVERS_TABLE
                })
                return toast.error("Something went wrong!... Please try again later.");
            } else {
                dispatch({
                    type: FAILED_DRIVERS_TABLE
                })
                return toast.error("Something went wrong!... Please try again later.");
            }

        })
    }
}

export function fetchDriversSummary(drivers={}, dateFilter){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    let summariesFetch = []
    return (dispatch, getState) => {
        dispatch({type: FETCH_DRIVERS_SUMMARY});
        for (var key in drivers) {
            if (drivers.hasOwnProperty(key)) {
                summariesFetch.push(
                    axios({
                        method: 'post',
                        url: API_LINKS.DRIVER_SUMMARY_URL,
                        headers: {
                            Authorization: auth
                        },
                        data: {
                            id_driver: key,
                            start_date: dateFilter.start_date,
                            end_date: dateFilter.end_date
                        }
                    })
                )
            }
        }
        Promise.all(summariesFetch)
        .then((responses) => {
            let payload = []
            for(let i=0; i < responses.length; i++){
                let summary = responses[i].data.result[0]
                payload.push([
                    summary.driver_name,
                    summary.wo_amount,
                    summary.do_amount,
                    summary.estimated_do_km,
                    summary.realization_do_km,
                    summary.wo_check_in,
                    summary.wo_with_pod,
                    summary.wo_check_in_time,
                    summary.wo_check_late
                ])
            }
            return payload
        })
        .then((payload) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_DRIVERS_SUMMARY,
                payload
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_DRIVERS_SUMMARY
                    })
                    return toast.error('Unathorized access!');
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_DRIVERS_SUMMARY
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_DRIVERS_SUMMARY
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_DRIVERS_SUMMARY
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_DRIVERS_SUMMARY
                })
                return toast.error("Something went wrong!... Please try again later.");
            } else {
                dispatch({
                    type: FAILED_DRIVERS_SUMMARY
                })
                return toast.error("Something went wrong!... Please try again later.");
            }

        })
    }
}

export const FETCH_DRIVER_FLEETS = 'FETCH_DRIVER_FLEETS'
export const RECEIVE_DRIVER_FLEETS = 'RECEIVE_DRIVER_FLEETS'
export const FAILED_DRIVER_FLEETS = 'FAILED_DRIVER_FLEETS'

export function fetchDriverFleets(id_driver){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_DRIVER_FLEETS});
        axios({
            method: 'post',
            url: API_LINKS.DRIVER_FLEETS_LIST_URL,
            headers: {
                Authorization: auth
            },
            data: {
                id_driver
            }
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_DRIVER_FLEETS,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_DRIVER_FLEETS
                    })
                    return toast.error('Unathorized access!');
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_DRIVER_FLEETS
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_DRIVER_FLEETS
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_DRIVER_FLEETS
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_DRIVER_FLEETS
                })
                return toast.error("Something went wrong!... Please try again later.");
            } else {
                dispatch({
                    type: FAILED_DRIVER_FLEETS
                })
                return toast.error("Something went wrong!... Please try again later.");
            }

        })
    }
}