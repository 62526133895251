import { FETCH_WORK_ORDERS_REVIEW_TABLE, RECEIVE_WORK_ORDERS_REVIEW_TABLE, FAILED_WORK_ORDERS_REVIEW_TABLE } from '../../actions/transporter/aTransporter-WOReview'

const defaultWorkOrdersReviewState = {
    workOrdersReviewData: [],
    inProgress: false,
    success: true, 
    totalWO: 0, 
}

export function fetchWorkOrdersReview(state = defaultWorkOrdersReviewState, action){
    switch (action.type) {
        case FETCH_WORK_ORDERS_REVIEW_TABLE:
            return ({workOrdersReviewData: [], success: false, inProgress: true, totalWO: 0})
        case RECEIVE_WORK_ORDERS_REVIEW_TABLE:
            var _total = action.payload.result.length
            return Object.assign({}, state, {workOrdersReviewData: action.payload, success: true, inProgress: false, totalWO: _total})
        case FAILED_WORK_ORDERS_REVIEW_TABLE:
            return ({workOrdersReviewData: [], success: false, inProgress: false, totalWO: 0})
        default:
            return state
    }
}