import {FETCH_DIVISIONS_LIST, RECEIVE_DIVISIONS_LIST, FAILED_DIVISIONS_LIST,
} from '../../actions/transporter/divisions.act'
        
function getSelectFromPayload(payloadData, settings){
    let result = []
    if(!payloadData)
        return result
    if(!Array.isArray(payloadData))
        return result
    let labelField = settings.labelField
    let valueField = settings.valueField
    payloadData.forEach(data => {
        result.push({
            label: data[labelField],
            value: data[valueField]
        })
    })
    return result
}


const defaultDivisionsState = {
    totalDivisions:0,
    divisions: [],
    divisionsSelect: [],
    success: true,  
    inProgress: false
}

export function Divisions(state = defaultDivisionsState, action){
    switch (action.type) {
        case FETCH_DIVISIONS_LIST:
            return ({divisions: [], success: true, divisionsSelect: [],
                    inProgress: true, totalDivisions:0})
        case RECEIVE_DIVISIONS_LIST:
            const {payload} = action
            let divisionsSelect = getSelectFromPayload(
                payload.result, {labelField:"name", valueField: "id"}
            )
            return Object.assign({}, state, {divisions: action.payload.result,
                                                divisionsSelect: divisionsSelect,
                                                totalDivisions: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_DIVISIONS_LIST:
            return ({divisions: [], success: false, divisionsSelect: [],
                    inProgress: false, totalDivisions:0,})
        default:
            return state
    }
}