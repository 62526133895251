import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_SM_APPROVAL_TYPE_TABLE = 'FETCH_SM_APPROVAL_TYPE_TABLE'
export const RECEIVE_SM_APPROVAL_TYPE_TABLE = 'RECEIVE_SM_APPROVAL_TYPE_TABLE'
export const FAILED_SM_APPROVAL_TYPE_TABLE = 'FAILED_SM_APPROVAL_TYPE_TABLE'

export const FETCH_SM_APPROVAL_LIST = 'FETCH_SM_APPROVAL_LIST'
export const RECEIVE_SM_APPROVAL_LIST = 'RECEIVE_SM_APPROVAL_LIST'
export const FAILED_SM_APPROVAL_LIST = 'FAILED_SM_APPROVAL_LIST'

export const FETCH_SM_APPROVAL_TYPE_SETTING_LIST = 'FETCH_SM_APPROVAL_TYPE_SETTING_LIST'
export const RECEIVE_SM_APPROVAL_TYPE_SETTING_LIST = 'RECEIVE_SM_APPROVAL_TYPE_SETTING_LIST'
export const FAILED_SM_APPROVAL_TYPE_SETTING_LIST = 'FAILED_SM_APPROVAL_TYPE_SETTING_LIST'

export function fetchSmApprovalType(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;


    return (dispatch, getState) => {
        dispatch({type: FETCH_SM_APPROVAL_TYPE_TABLE});
        axios({
            method: 'post',
            url: API_LINKS.SM_APPROVAL_TYPE_LIST_URL,
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_SM_APPROVAL_TYPE_TABLE,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_SM_APPROVAL_TYPE_TABLE
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_SM_APPROVAL_TYPE_TABLE
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_SM_APPROVAL_TYPE_TABLE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_SM_APPROVAL_TYPE_TABLE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_SM_APPROVAL_TYPE_TABLE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_SM_APPROVAL_TYPE_TABLE
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchSmApproval(id_sm){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_SM_APPROVAL_LIST});
        axios({
            method: 'post',
            url: API_LINKS.SCHEDULED_MAINTENANCE_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id: id_sm
            }
        })
        .then((response) => {
            if(response.data.statusCode === 200){
                let responseDataResult = response.data.result
                let id_scheduled_maintenance_approval_type = responseDataResult? responseDataResult[0].id_scheduled_maintenance_approval_type : null
                let fetchCheckSheetApprovalList = axios({
                    method: 'post',
                    url: API_LINKS.SM_APPROVAL_LIST_URL,
                    headers: {
                        Authorization: auth
                    },
                    data:{
                        id_scheduled_maintenance: id_sm
                    }
                })
                let fetchCheckSheetApprovalSettingList = axios({
                    method: 'post',
                    url: API_LINKS.SM_APPROVAL_SETTING_LIST_URL,
                    headers: {
                        Authorization: auth
                    },
                    data:{
                        id_scheduled_maintenance_approval_type: id_scheduled_maintenance_approval_type
                    }
                })
                Promise.all([fetchCheckSheetApprovalList, fetchCheckSheetApprovalSettingList])
                .then((responses) => {
                    let payload = {}
                    if(!responses)
                        return payload
                    if(!responses.length > 0)
                        return payload
                    payload["users_approval_list"] = responses[0].data
                    payload["users_approval_setting"] = responses[1].data
                    return payload
                })
                .then((payload) => {
                    dispatch( {
                        type: RECEIVE_SM_APPROVAL_LIST,
                        payload: payload
                    })
                })
            }
        })
        // .catch(function(error){
        //     if (error.response) {
        //         if(error.response.status === 401) {
        //             dispatch({
        //                 type: FAILED_SM_APPROVAL_LIST
        //             })
        //             return toast.error("Invalid credentials! Please try again!");
        //         } else if (error.response.status === 404 || error.response.status === 500) {
        //             dispatch({
        //                 type: FAILED_SM_APPROVAL_LIST
        //             })
        //             return toast.error("Server cannot be contacted! Please ask your system administrator!");
        //         } else if (error.response.status === 400) {
        //             dispatch({
        //                 type: FAILED_SM_APPROVAL_LIST
        //             })
        //             return toast.error(error.response.data.message);
        //         } else {
        //             dispatch({
        //                 type: FAILED_SM_APPROVAL_LIST
        //             })
        //             return toast.error('Something went wrong... Please try again later...');
        //         }
        //     } else if (error.request) {
        //         dispatch({
        //             type: FAILED_SM_APPROVAL_LIST
        //         })
        //         return toast.error('Request error! Please try again later...')
        //     } else {
        //         dispatch({
        //             type: FAILED_SM_APPROVAL_LIST
        //         })
        //         return toast.error('Something went wrong... Please try again later...')
        //     }
        // })
    }
}

export function fetchApprovalTypeSetting(id_scheduled_maintenance_approval_type){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_SM_APPROVAL_TYPE_SETTING_LIST});
        axios({
            method: 'post',
            url: API_LINKS.SM_APPROVAL_SETTING_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_scheduled_maintenance_approval_type: id_scheduled_maintenance_approval_type
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_SM_APPROVAL_TYPE_SETTING_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_SM_APPROVAL_TYPE_SETTING_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_SM_APPROVAL_TYPE_SETTING_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_SM_APPROVAL_TYPE_SETTING_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_SM_APPROVAL_TYPE_SETTING_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_SM_APPROVAL_TYPE_SETTING_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_SM_APPROVAL_TYPE_SETTING_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}