import { FETCH_DELIVERY_STATUS_LIST, RECEIVE_DELIVERY_STATUS_LIST, FAILED_DELIVERY_STATUS_LIST } from '../../actions/transporter/aTransporter-DeliveryStatusList'

const defaultDeliveryStatusListState = {
    deliveryStatusListData: [],
    deliveryStatusApproveOnlyData: [],
    success: false,
}

export function fetchDeliveryStatusList(state = defaultDeliveryStatusListState, action){
    switch (action.type) {
        case FETCH_DELIVERY_STATUS_LIST:
            return ({deliveryStatusListData: [], success: true, inProgress: true, deliveryStatusApproveOnlyData: []})
        case RECEIVE_DELIVERY_STATUS_LIST:
            var list = []
            if(action.payload.result !== []) {
                var deliveryStatusList = action.payload.result
                deliveryStatusList.forEach((p, index) => {
                    if(deliveryStatusList[index].value === 1) {
                        var dStat = {
                            'label': deliveryStatusList[index].label,
                            'value': deliveryStatusList[index].value
                        }
                        list.push(dStat)
                    }
                })
            }
            return Object.assign({}, state, {deliveryStatusListData: action.payload, success: true, inProgress: false, deliveryStatusApproveOnlyData: list})
        case FAILED_DELIVERY_STATUS_LIST:
            return ({deliveryStatusListData: [], success: false, inProgress: false, deliveryStatusApproveOnlyData: []})
        default:
            return state
    }
}