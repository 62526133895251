import { FETCH_LL_RESOURCE_USAGE_TYPE_LIST, RECEIVE_LL_RESOURCE_USAGE_TYPE_LIST, FAILED_LL_RESOURCE_USAGE_TYPE_LIST } from '../../actions/landlord/aLandLord-ResourceUsageTypeList'

const defaultLLResourceUsageTypeState = {
    llResourceUsageTypeData: [],
    llTotalResourceUsageType: 0,
    llResourceUsageTypeSelect: [],
    success: true,
    inProgress: false,
}

export function fetchLLResourceUsageTypeList(state = defaultLLResourceUsageTypeState, action){
    switch (action.type) {
        case FETCH_LL_RESOURCE_USAGE_TYPE_LIST:
            return ({llResourceUsageTypeData: [], success: true, llTotalResourceUsageType: 0, llResourceUsageTypeSelect: [], inProgress: true})
        case RECEIVE_LL_RESOURCE_USAGE_TYPE_LIST:
            var _totalResourceUsageType = action.payload.result.length;
            var _selectResourceUsageType = []
            if(action.payload.result !== []) {
                var _resourceUsageTypeData = action.payload.result

                _resourceUsageTypeData.forEach((p, index) => {
                    var _resUsageType = {
                        'label': _resourceUsageTypeData[index].resource_usage_type_name + ' - ' + _resourceUsageTypeData[index].resource_usage_type_desc,
                        'value': _resourceUsageTypeData[index].id
                    }
                    _selectResourceUsageType.push(_resUsageType)
                })
            }
            return Object.assign({}, state, {llResourceUsageTypeData: action.payload, success: true, llTotalResourceUsageType: _totalResourceUsageType, llResourceUsageTypeSelect: _selectResourceUsageType, inProgress: false})
        case FAILED_LL_RESOURCE_USAGE_TYPE_LIST:
            return ({llResourceUsageTypeData: [], success: false, llTotalResourceUsageType: 0, llResourceUsageTypeSelect: [], inProgress: false})
        default:
            return state
    }
}