import { FETCH_PACKAGE_MATERIAL_COMPOSITION_LIST, 
         RECEIVE_PACKAGE_MATERIAL_COMPOSITION_LIST, 
         FAILED_PACKAGE_MATERIAL_COMPOSITION_LIST } from '../../actions/stocker/stck.packagematerialcomposition.act'

const defaultPackageMaterialCompositionState = {
    stckPackageMaterialCompositionData: [],
    stckTotalPackageMaterialComposition: 0,
    stckPackageMaterialCompositionSelect: [],
    success: true,
    inProgress: false,
}

export function StckPackageMaterialComposition(state = defaultPackageMaterialCompositionState, action){
    switch (action.type) {
        case FETCH_PACKAGE_MATERIAL_COMPOSITION_LIST:
            return ({stckPackageMaterialCompositionData: [], success: true, stckTotalPackageMaterialComposition: 0, stckPackageMaterialCompositionSelect: [], inProgress: true})
        case RECEIVE_PACKAGE_MATERIAL_COMPOSITION_LIST:
            var _totalUnits = action.payload.result.length;
            var _stckPackageMaterialCompositionSelect = []
            if(action.payload.result !== []) {
                var _packageMaterialCompositionData = action.payload.result
                _packageMaterialCompositionData.forEach((p, index) => {
                    var _loc = {
                        'label': _packageMaterialCompositionData[index].name,
                        'value': _packageMaterialCompositionData[index].id
                    }
                    _stckPackageMaterialCompositionSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckPackageMaterialCompositionData: action.payload, success: true, stckTotalPackageMaterialComposition: _totalUnits, stckPackageMaterialCompositionSelect: _stckPackageMaterialCompositionSelect, inProgress: false})
        case FAILED_PACKAGE_MATERIAL_COMPOSITION_LIST:
            return ({stckPackageMaterialCompositionData: [], success: false, stckTotalPackageMaterialComposition: 0, stckPackageMaterialCompositionSelect: [], inProgress: false})
        default:
            return state
    }
}