import { FETCH_MASTER_ITEM_DEFECT_LIST, RECEIVE_MASTER_ITEM_DEFECT_LIST, FAILED_MASTER_ITEM_DEFECT_LIST } from '../../actions/stocker/stck.defectitem.act'

const defaultStckMasterDefectItemState = {
    stckMasterDefectItemData: [],
    stckTotalMasterDefectItem: 0,
    stckMasterDefectItemSelect: [],
    success: true,
    inProgress: false,
}

export function StckMasterDefectItem(state = defaultStckMasterDefectItemState, action){
    switch (action.type) {
        case FETCH_MASTER_ITEM_DEFECT_LIST:
            return ({stckMasterDefectItemData: [], success: true, stckTotalMasterDefectItem: 0, stckMasterDefectItemSelect: [], inProgress: true})
        case RECEIVE_MASTER_ITEM_DEFECT_LIST:
            var _totalMasterDefectItem = action.payload.result.length;
            var _stckMasterDefectItemSelect = []
            if(action.payload.result !== []) {
                var _mItemDefectData = action.payload.result

                _mItemDefectData.forEach((p, index) => {
                    var _loc = {
                        'label': _mItemDefectData[index].item_name,
                        'value': _mItemDefectData[index].id,
                        "id": _mItemDefectData[index].id,
                        "id_administration_office": _mItemDefectData[index].id_administration_office,
                        "admin_office_name": _mItemDefectData[index].admin_office_name,
                        "admin_office_address": _mItemDefectData[index].admin_office_address,
                        "admin_office_main_phone_number": _mItemDefectData[index].admin_office_main_phone_number,
                        "id_return_order": _mItemDefectData[index].id_return_order,
                        "return_issue_date": _mItemDefectData[index].return_issue_date,
                        "return_order_number": _mItemDefectData[index].return_order_number,
                        "id_disposal_order": _mItemDefectData[index].id_disposal_order,
                        "disposal_order_number": _mItemDefectData[index].disposal_order_number,
                        "disposal_order_issue_date": _mItemDefectData[index].disposal_order_issue_date,
                        "id_master_item": _mItemDefectData[index].id_master_item,
                        "item_name": _mItemDefectData[index].item_name,
                        "sku_item_name": _mItemDefectData[index].sku_item_name,
                        "id_master_item_detail": _mItemDefectData[index].id_master_item_detail,
                        "item_detail_qty": _mItemDefectData[index].item_detail_qty,
                        "id_master_inbound": _mItemDefectData[index].id_master_inbound,
                        "inbound_number": _mItemDefectData[index].inbound_number,
                        "document_number": _mItemDefectData[index].document_number,
                        "id_origin_warehouse": _mItemDefectData[index].id_origin_warehouse,
                        "origin_warehouse_name": _mItemDefectData[index].origin_warehouse_name,
                        "origin_warehouse_address": _mItemDefectData[index].origin_warehouse_address,
                        "id_origin_warehouse_area": _mItemDefectData[index].id_origin_warehouse_area,
                        "origin_warehouse_area_name": _mItemDefectData[index].origin_warehouse_area_name,
                        "origin_warehouse_area_size": _mItemDefectData[index].origin_warehouse_area_size,
                        "id_destination_warehouse": _mItemDefectData[index].id_destination_warehouse,
                        "id_destination_warehouse_area": _mItemDefectData[index].id_destination_warehouse_area,
                        "qty": _mItemDefectData[index].qty,
                        "remark": _mItemDefectData[index].remark,
                        "id_unit": _mItemDefectData[index].id_unit,
                        "unit_name": _mItemDefectData[index].unit_name,
                    }
                    _stckMasterDefectItemSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckMasterDefectItemData: action.payload, success: true, stckTotalMasterDefectItem: _totalMasterDefectItem, stckMasterDefectItemSelect: _stckMasterDefectItemSelect, inProgress: false})
        case FAILED_MASTER_ITEM_DEFECT_LIST:
            return ({stckMasterDefectItemData: [], success: false, stckTotalMasterDefectItem: 0, stckMasterDefectItemSelect: [], inProgress: false})
        default:
            return state
    }
}