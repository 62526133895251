import { FETCH_TRIPL_APPROVED_REQUEST_LIST, RECEIVE_TRIPL_APPROVED_REQUEST_LIST, FAILED_TRIPL_APPROVED_REQUEST_LIST } from '../../actions/transporter/aTransporter-TriPLApprovedRequestList'

const nullIdGroupAsAutomaWide = function(triPlRequest){
    triPlRequest.map(request =>{
        if(request.id_group === null)
            request.group_name = "Automa Wide"
        return request
    })
    return triPlRequest
}


const defaultTriPLApprovedRequestListState = {
    triPLApprovedRequestListData: [],
    selectApprovedList: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLApprovedRequestList(state = defaultTriPLApprovedRequestListState, action){
    switch (action.type) {
        case FETCH_TRIPL_APPROVED_REQUEST_LIST:
            return ({triPLApprovedRequestListData: [], success: true, selectApprovedList: [], inProgress: true})
        case RECEIVE_TRIPL_APPROVED_REQUEST_LIST:
            var selectList = []
            if(action.payload.result.length !== 0) {
                var _approvedData = action.payload.result
                _approvedData.forEach((p, index) => {
                    let requestor_company
                    if(_approvedData[index].id_company_take_non_com === null){
                        requestor_company = _approvedData[index].company_name
                    }
                    if(_approvedData[index].id_company_take_non_com){
                        requestor_company = _approvedData[index].requster_non_com_company_name
                    }
                    var _data = {
                        'label': _approvedData[index].wo_number + ' - ' + requestor_company,
                        'value': _approvedData[index].id
                    }
                    selectList.push(_data)
                })
            }
            return Object.assign({}, state, {triPLApprovedRequestListData: nullIdGroupAsAutomaWide(action.payload.result), 
                                            selectApprovedList: selectList, success: true, inProgress: false})
        case FAILED_TRIPL_APPROVED_REQUEST_LIST:
            return ({triPLApprovedRequestListData: [], success: false, selectApprovedList: [], inProgress: false})
        default:
            return state
    }
}