import { FETCH_STCK_COUNTRIES_LIST, RECEIVE_STCK_COUNTRIES_LIST, FAILED_STCK_COUNTRIES_LIST } from '../../actions/stocker/stck.countries.act'

const defaultStckCountriesState = {
    stckCountriesData: [],
    stckTotalCountries: 0,
    stckCountriesSelect: [],
    success: true,
    inProgress: false,
}

export function StckCountries(state = defaultStckCountriesState, action){
    switch (action.type) {
        case FETCH_STCK_COUNTRIES_LIST:
            return ({stckCountriesData: [], success: true, stckTotalCountries: 0, stckCountriesSelect: [], inProgress: true})
        case RECEIVE_STCK_COUNTRIES_LIST:
            var _totalCountries = action.payload.result.length;
            var _stckCountriesSelect = []
            if(action.payload.result !== []) {
                var _countriesData = action.payload.result

                _countriesData.forEach((p, index) => {
                    if(index === 0)
                        return
                    var _loc = {
                        'label': _countriesData[index].name,
                        'value': _countriesData[index].id
                    }
                    _stckCountriesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckCountriesData: action.payload, success: true, stckTotalCountries: _totalCountries, stckCountriesSelect: _stckCountriesSelect, inProgress: false})
        case FAILED_STCK_COUNTRIES_LIST:
            return ({stckCountriesData: [], success: false, stckTotalCountries: 0, stckCountriesSelect: [], inProgress: false})
        default:
            return state
    }
}