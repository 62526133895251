export const SET_INTERCOMPANY_ORDER_CART = "SET_INTERCOMPANY_ORDER_CART";
export const SET_INTERCOMPANY_ORDER_SELECTED_ITEM =
  "SET_INTERCOMPANY_ORDER_SELECTED_ITEM";
export const SET_INTERCOMPANY_ORDER_SHIPMENT =
  "SET_INTERCOMPANY_ORDER_SHIPMENT";
export const GET_INTERCOMPANY_ORDER_CART_LOADING =
  "GET_INTERCOMPANY_ORDER_CART_LOADING";
export const GET_INTERCOMPANY_ORDER_CART_SUCCESS =
  "GET_INTERCOMPANY_ORDER_CART_SUCCESS";
export const GET_INTERCOMPANY_ORDER_CART_ERROR =
  "GET_INTERCOMPANY_ORDER_CART_ERROR";
export const SET_INTERCOMPANY_ORDER_SEARCH_KEYWORD =
  "SET_INTERCOMPANY_ORDER_SEARCH_KEYWORD";
