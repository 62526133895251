import {FETCH_LIVE_MONITORING_GEOCODING_LIST, RECEIVE_LIVE_MONITORING_GEOCODING_LIST, 
        FAILED_LIVE_MONITORING_GEOCODING_LIST,CLEAR_LIVE_MONITORING_GEOCODING_LIST,
    FETCH_RENTEE_LIVE_MONITORING_GEOCODING_LIST, RECEIVE_RENTEE_LIVE_MONITORING_GEOCODING_LIST, 
    FAILED_RENTEE_LIVE_MONITORING_GEOCODING_LIST, CLEAR_RENTEE_LIVE_MONITORING_GEOCODING_LIST
} from '../../actions/transporter/aTransporter-Geocoding'

const defaultLiveMonitoringAddressState = {
    liveMonitoringAddress: [],
    indexReferenceById: {},
    success: true,  
    inProgress: false
}

export function LiveMonitoringAddress(state = defaultLiveMonitoringAddressState, action){
    let newLiveMonitoringAddress = [...state.liveMonitoringAddress]
    let _indexReferenceById = {...state.indexReferenceById}
    let id = null
    let getIndex = null
    switch (action.type) {
        case FETCH_LIVE_MONITORING_GEOCODING_LIST:
            id = action.payload.id
            getIndex = _indexReferenceById[id]
            if(getIndex !== undefined){
                newLiveMonitoringAddress[getIndex].isLoading = true
            }
            if(getIndex === undefined){
                action.payload.isLoading = true
                newLiveMonitoringAddress.push(action.payload)
                let currentLength = newLiveMonitoringAddress.length
                _indexReferenceById[action.payload.id] = currentLength-1
            }
            return ({liveMonitoringAddress: newLiveMonitoringAddress, indexReferenceById:_indexReferenceById, 
                    success: true, inProgress: true,})
        case RECEIVE_LIVE_MONITORING_GEOCODING_LIST:
            id = action.payload.givenData.id
            getIndex = _indexReferenceById[id]
            if(getIndex !== undefined){
                newLiveMonitoringAddress[getIndex].address = action.payload.result
                newLiveMonitoringAddress[getIndex].isLoading = false
            }
            return Object.assign({}, state, {liveMonitoringAddress: newLiveMonitoringAddress,
                                                indexReferenceById:{...state.indexReferenceById},
                                                success: true, inProgress: false})
        case FAILED_LIVE_MONITORING_GEOCODING_LIST:
            id = action.payload.givenData.id
            getIndex = _indexReferenceById[id]
            if(getIndex !== undefined){
                newLiveMonitoringAddress[getIndex].isLoading = false
            }
            return ({liveMonitoringAddress: newLiveMonitoringAddress, indexReferenceById:{...state.indexReferenceById}, success: false, inProgress: false})
        case CLEAR_LIVE_MONITORING_GEOCODING_LIST:
            return ({liveMonitoringAddress: [], indexReferenceById:{}, success: true, inProgress: false})
        default:
            return state
    }
}

const defaultRenteeLiveMonitoringAddressState = {
    liveMonitoringAddress: [],
    indexReferenceById: {},
    success: true,  
    inProgress: false
}

export function RenteeLiveMonitoringAddress(state = defaultRenteeLiveMonitoringAddressState, action){
    let newLiveMonitoringAddress = [...state.liveMonitoringAddress]
    let _indexReferenceById = {...state.indexReferenceById}
    let id = null
    let getIndex = null
    switch (action.type) {
        case FETCH_RENTEE_LIVE_MONITORING_GEOCODING_LIST:
            id = action.payload.id
            getIndex = _indexReferenceById[id]
            if(getIndex !== undefined){
                newLiveMonitoringAddress[getIndex].isLoading = true
            }
            if(getIndex === undefined){
                action.payload.isLoading = true
                newLiveMonitoringAddress.push(action.payload)
                let currentLength = newLiveMonitoringAddress.length
                _indexReferenceById[action.payload.id] = currentLength-1
            }
            return ({liveMonitoringAddress: newLiveMonitoringAddress, indexReferenceById:_indexReferenceById, 
                    success: true, inProgress: true,})
        case RECEIVE_RENTEE_LIVE_MONITORING_GEOCODING_LIST:
            id = action.payload.givenData.id
            getIndex = _indexReferenceById[id]
            if(getIndex !== undefined){
                newLiveMonitoringAddress[getIndex].address = action.payload.result
                newLiveMonitoringAddress[getIndex].isLoading = false
            }
            return Object.assign({}, state, {liveMonitoringAddress: newLiveMonitoringAddress,
                                                indexReferenceById:{...state.indexReferenceById},
                                                success: true, inProgress: false})
        case FAILED_RENTEE_LIVE_MONITORING_GEOCODING_LIST:
            id = action.payload.givenData.id
            getIndex = _indexReferenceById[id]
            if(getIndex !== undefined){
                newLiveMonitoringAddress[getIndex].isLoading = false
            }
            return ({liveMonitoringAddress: newLiveMonitoringAddress, indexReferenceById:{...state.indexReferenceById}, success: false, inProgress: false})
        case CLEAR_RENTEE_LIVE_MONITORING_GEOCODING_LIST:
            return ({liveMonitoringAddress: [], indexReferenceById:{}, success: true, inProgress: false}) 
        default:
            return state
    }
}