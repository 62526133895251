import { FETCH_LL_UTILITY_LIST, RECEIVE_LL_UTILITY_LIST, FAILED_LL_UTILITY_LIST } from '../../actions/landlord/aLandLord-UtilityList'

const defaultLLUtilityState = {
    llUtilityData: [],
    llTotalUtility: 0,
    llUtilitySelect: [],
    success: true,
    inProgress: false,
}

export function fetchLLUtilityList(state = defaultLLUtilityState, action){
    switch (action.type) {
        case FETCH_LL_UTILITY_LIST:
            return ({llUtilityData: [], success: true, llTotalUtility: 0, llUtilitySelect: [], inProgress: true})
        case RECEIVE_LL_UTILITY_LIST:
            var _totalUtility = action.payload.result.length;
            var _selectUtility = []
            if(action.payload.result !== []) {
                var _utilityData = action.payload.result

                _utilityData.forEach((p, index) => {
                    var _util = {
                        'label': _utilityData[index].utility_name + ' - ' + _utilityData[index].desc,
                        'value': _utilityData[index].id
                    }
                    _selectUtility.push(_util)
                })
            }
            return Object.assign({}, state, {llUtilityData: action.payload, success: true, llTotalUtility: _totalUtility, llUtilitySelect: _selectUtility, inProgress: false})
        case FAILED_LL_UTILITY_LIST:
            return ({llUtilityData: [], success: false, llTotalUtility: 0, llUtilitySelect: [], inProgress: false})
        default:
            return state
    }
}