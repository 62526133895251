import { FETCH_STCK_ITEM_DEPRECIATON_METHODS_LIST, RECEIVE_STCK_ITEM_DEPRECIATON_METHODS_LIST, FAILED_STCK_ITEM_DEPRECIATON_METHODS_LIST } from '../../actions/stocker/stck.itemdepreciationmethods.act'

const defaultStckItemDepreciationMethodsState = {
    stckItemDepreciationMethodsData: [],
    stckTotalItemDepreciationMethods: 0,
    stckItemDepreciationMethodsSelect: [],
    success: true,
    inProgress: false,
}

export function StckItemDepreciationMethods(state = defaultStckItemDepreciationMethodsState, action){
    switch (action.type) {
        case FETCH_STCK_ITEM_DEPRECIATON_METHODS_LIST:
            return ({stckItemDepreciationMethodsData: [], success: true, stckTotalItemDepreciationMethods: 0, stckItemDepreciationMethodsSelect: [], inProgress: true})
        case RECEIVE_STCK_ITEM_DEPRECIATON_METHODS_LIST:
            var _totalItemDepreciationMethods = action.payload.result.length;
            var _stckItemDepreciationMethodsSelect = []
            if(action.payload.result !== []) {
                var _itemDepreciationMethodsData = action.payload.result

                _itemDepreciationMethodsData.forEach((p, index) => {
                    var _loc = {
                        'label': _itemDepreciationMethodsData[index].depreciation_method_name + ' - (' + _itemDepreciationMethodsData[index].depreciation_method_name + '%)',
                        'value': _itemDepreciationMethodsData[index].id
                    }
                    _stckItemDepreciationMethodsSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckItemDepreciationMethodsData: action.payload, success: true, stckTotalItemDepreciationMethods: _totalItemDepreciationMethods, stckItemDepreciationMethodsSelect: _stckItemDepreciationMethodsSelect, inProgress: false})
        case FAILED_STCK_ITEM_DEPRECIATON_METHODS_LIST:
            return ({stckItemDepreciationMethodsData: [], success: false, stckTotalItemDepreciationMethods: 0, stckItemDepreciationMethodsSelect: [], inProgress: false})
        default:
            return state
    }
}