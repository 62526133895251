import { FETCH_SERVICE_PLAN_LIST, RECEIVE_SERVICE_PLAN_LIST, FAILED_SERVICE_PLAN_LIST } from '../../actions/subs.actions'

const defaultServicePlanListState = {
    servicePlanData: [],
    success: true,
    inProgress: false,
}

export function servicePlan(state = defaultServicePlanListState, action){
    switch (action.type) {
        case FETCH_SERVICE_PLAN_LIST:
            return ({ servicePlanData: [], success: false,  inProgress: true })
        case RECEIVE_SERVICE_PLAN_LIST:
            return Object.assign({}, state, {servicePlanData: action.payload.result, inProgress: false, success: true })
        case FAILED_SERVICE_PLAN_LIST:
            return ({ servicePlanData: [], success: false,  inProgress: false })
        default:
            return state
    }
}