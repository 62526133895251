import { FETCH_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST, RECEIVE_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST, 
            FAILED_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST, FECTH_OUTBOUND_ITEM_LIST,
            RECEIVE_OUTBOUND_ITEM_LIST, FAILED_OUTBOUND_ITEM_LIST} from '../../actions/carbonx/carbonx.logger-scope3downstream.act'

const defaultStckLoggerScope3DownstreamState = {
    carbonxLoggerScope3DownstreamData: [],
    carbonxCarbonEmissionTotal: 0,
    carbonxTotalLoggerScope3Downstream: 0,
    success: true,
    inProgress: false,
}

export function CarbonXLoggerScope3Downstream(state = defaultStckLoggerScope3DownstreamState, action){
    switch (action.type) {
        case FETCH_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST:
            return ({carbonxLoggerScope3DownstreamData: [], success: true, carbonxTotalLoggerScope3Downstream: 0, carbonxLoggerScope3DownstreamSelect: [], inProgress: true})
        case RECEIVE_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST:
            let payload = action.payload
            let scope3Result = []
            for(let data of payload){
                if(scope3Result.some(obj => obj.batch === data.batch_number))
                    continue
                scope3Result.push({
                    date:data.inbound_date,
                    batch: data.batch_number,
                    carbon_emission: 0
                })
            }
            return Object.assign({}, state, {carbonxLoggerScope3DownstreamData: scope3Result, success: true,
                                                carbonxTotalLoggerScope3Downstream: scope3Result.length, 
                                                carbonxCarbonEmissionTotal: (0).toFixed(2), inProgress: false})
        case FAILED_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST:
            return ({carbonxLoggerScope3DownstreamData: [], success: false, carbonxTotalLoggerScope3Downstream: 0, carbonxLoggerScope3DownstreamSelect: [], inProgress: false})
        default:
            return state
    }
}

const defaultOutboundItem = {
    outboundItemData: [],
    outboundItemTotal: 0,
    success: true,
    inProgress: false,
}

export function OutboundItemsCarb(state = defaultOutboundItem, action){
    switch (action.type) {
        case FECTH_OUTBOUND_ITEM_LIST:
            return ({outboundItemData: [], outboundItemTotal: 0, success: true, inProgress: true})
        case RECEIVE_OUTBOUND_ITEM_LIST:
            let payload = action.payload
            return Object.assign({}, state, {outboundItemData: payload, success: true,
                                                outboundItemTotal: payload.length, inProgress: false})
        case FAILED_OUTBOUND_ITEM_LIST:
            return ({outboundItemData: [], success: false, outboundItemTotal: 0, inProgress: false})
        default:
            return state
    }
}