import {
  WALLET_ERROR_GET_SALDO,
  WALLET_OPEN_PAYMENT_MODAL,
  WALLET_PENDING_GET_SALDO,
  WALLET_SUCCESS_GET_SALDO,
} from "../../../redux/types/wallet/tWallet-Saldo";

const defaultSaldoState = {
  currentSaldo: 0,
  isPending: false,
  isError: null,
  lastUpdate: "",
  isModalPaymentOpen: false,
  xenditUrl: null,
  paymentTotalAmount: null,
  companyDestinationId: null,
  invoiceId: null,
  typeTransaction: null,
  additionalFunction: () => null,
};

const walletSaldo = (state = defaultSaldoState, action) => {
  switch (action.type) {
    case WALLET_PENDING_GET_SALDO:
      return {
        ...state,
        currentSaldo: 0,
        isPending: true,
        isError: null,
        lastUpdate: action.payload,
      };
    case WALLET_SUCCESS_GET_SALDO:
      return {
        ...state,
        currentSaldo: action.payload.current_saldo,
        isPending: false,
        isError: null,
      };
    case WALLET_ERROR_GET_SALDO:
      return {
        ...state,
        currentSaldo: 0,
        isPending: false,
        isError: action.payload,
      };
    case WALLET_OPEN_PAYMENT_MODAL:
      return {
        ...state,
        isModalPaymentOpen: action.payload.openModal,
        xenditUrl: action.payload.xenditUrl,
        paymentTotalAmount: action.payload.paymentTotalAmount,
        companyDestinationId: action.payload.companyDestinationId,
        invoiceId: action.payload.invoiceId,
        typeTransaction: action.payload.typeTransaction,
        agreementText: action.payload.agreementText,
        additionalFunction: action.payload.additionalFunction,
      };
    default:
      return { ...state };
  }
};

export default walletSaldo;
