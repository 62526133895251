import { FETCH_TRIPL_GROUP_LIST, RECEIVE_TRIPL_GROUP_LIST, FAILED_TRIPL_GROUP_LIST,
            FETCH_TRIPL_GROUP_ASSIGN_CREATE_FIND, RECEIVE_TRIPL_GROUP_ASSIGN_CREATE_FIND, FAILED_TRIPL_GROUP_ASSIGN_CREATE_FIND,
            FETCH_TRIPL_GROUP_ASSIGN_FIND, RECEIVE_TRIPL_GROUP_ASSIGN_FIND, FAILED_TRIPL_GROUP_ASSIGN_FIND,
            FETCH_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION, RECEIVE_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION, FAILED_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION,
            FETCH_TRIPL_GROUP_FLEET_CHECK, RECEIVE_TRIPL_GROUP_FLEET_CHECK, FAILED_TRIPL_GROUP_FLEET_CHECK,
            FETCH_TRIPL_EST_COST, CLEAR_TRIPL_EST_COST, RECEIVE_TRIPL_EST_COST, FAILED_TRIPL_EST_COST,
            FETCH_TRIPL_MEMBER_VIEW, RECEIVE_TRIPL_MEMBER_VIEW, FAILED_TRIPL_MEMBER_VIEW
        } from '../../actions/transporter/aTransporter-TriPLGrouping'
import moment from 'moment';

//Helpers
const approvedInvitationToSelectObj = function approvedInvitationToSelectObj(approvedInvitations){
    let result = [{label: "Automa Wide", value: null}]
    if(!approvedInvitations){
        return result
    }
    approvedInvitations.map(inv => {
        result.push({
            label: inv.group_name,
            idCompany: inv.id_company,
            value : inv.id_group,
            truck_availability: inv.truck_availability
        })
        return inv
    })
    return result
}

const approvedInvitationsMemberSelectForSO = (approvedInvitations) => {
    const {company} = JSON.parse(window.atob(localStorage.Automalt));
    const result = []
    approvedInvitations.filter(a => a.group_name === "SYSTEM-GOODS-TRADE-INTERCOMPANY-GROUP")
    .forEach(a => {
        if(a.name_company_creator !== company){
            result.push({
                label:a.name_company_creator,
                value:a.id_company_creator 
            })
        }
    })
    return result
}

const approvedInvitationsMemberSelectForPO = (approvedInvitations) => {
    const {company} = JSON.parse(window.atob(localStorage.Automalt));
    const result = []
    approvedInvitations.filter(a => a.group_name === "SYSTEM-GOODS-TRADE-INTERCOMPANY-GROUP")
    .forEach(a => {
        if(a.name_company !== company){
            result.push({
                label:a.name_company,
                value:a.id_company 
            })
        }
    })
    return result
}


const isApprovedInvitationStartDateReach = function isApprovedInvitationStartDateReach(startDate){
    let todaysDate = moment(new Date(), "DD-MM-YYYY HH")
    let allowedDate = moment(new Date(startDate), "DD-MM-YYYY").subtract(1, "days")
    if(todaysDate.isAfter(allowedDate, "day")){
        return true
    }
    return false
}

const isApprovedInvitationValid = function isApprovedInvitationValid(endDate){
    let todaysDate = moment(new Date(), "DD-MM-YYYY")
    let expireDate = moment(new Date(endDate), "DD-MM-YYYY").endOf('day')
    if(todaysDate.isAfter(expireDate)){
        return false
    }
    return true
}

const isNowOnApprovedInvitationEndDateNextDay = function isNowOnApprovedInvitationEndDateNextDay(endDate){
    let todaysDate = moment(new Date(), "DD-MM-YYYY")
    let expireDate = moment(new Date(endDate), "DD-MM-YYYY").add(1, "days").startOf("day")
    if(todaysDate.isSame(expireDate, "day")){
        return true
    }
    return false
}

const unconfirmInvitationToNotification = function unconfirmInvitationToNotification(unconfirmedInvitations){
    let result = []
    unconfirmedInvitations.map(inv => {
        result.push({
            invitationFrom: inv.name_company_creator,
            text :`You have unconfirmed invitation to be part of ${inv.group_name}`
        })
        return inv
    })
    return result
}

const approveReachDateToNotification = function approveReachDateToNotification(approvedInvitations){
    let result = []
    approvedInvitations.map(inv => {
        result.push({
            groupName: inv.group_name,
            text : 'Your participant in this 3PL group has ended'
        })
        return inv
    })
    return result
}

const defaultTriPLGroupingListState = {
    triPLGroupingListData: [],
    totalGroups:0,
    success: true,  
    inProgress: false
}

export function fetchTriPLGroupingList(state = defaultTriPLGroupingListState, action){
    switch (action.type) {
        case FETCH_TRIPL_GROUP_LIST:
            return ({triPLGroupingListData: [], totalGroups: 0, success: true, inProgress: true})
        case RECEIVE_TRIPL_GROUP_LIST:
            let _totalGroup = action.payload.result.length
            return Object.assign({}, state, {triPLGroupingListData: action.payload.result, 
                                                        totalGroups: _totalGroup,
                                                        success: true, inProgress: false})
        case FAILED_TRIPL_GROUP_LIST:
            return ({triPLGroupingListData: [], totalGroups: 0, success: false, inProgress: false})
        default:
            return state
    }
}

const defaultTriPLGroupingAssignCreateFindState = {
    triPLGroupingAssignCreateFindData: [],
    groupingIntercompanyMemberSelect: [],
    totalMember:0,
    totalMemberApproved:0,
    totalMemberDeclined:0,
    success: true,  
    inProgress: false,
    status:"loading"
}

export function fetchTriPLGroupingAssignCreateFind(state = defaultTriPLGroupingAssignCreateFindState, action){
    switch (action.type) {
        case FETCH_TRIPL_GROUP_ASSIGN_CREATE_FIND:
            return ({triPLGroupingAssignCreateFindData: [], totalMember: 0, totalMemberApproved:0, status: "loading",
                        groupingIntercompanyMemberSelect: [], totalMemberDeclined:0, success: true, inProgress: true})
        case RECEIVE_TRIPL_GROUP_ASSIGN_CREATE_FIND:
            const APPROVE_STATUS_CODE = 1
            const DECLINE_STATUS_CODE = 2
            let _filteredByApprovedInvitation = [...action.payload.result].filter(r => r.approval_status === APPROVE_STATUS_CODE &&
                                                        isApprovedInvitationStartDateReach(r.start_date) && 
                                                        isApprovedInvitationValid(r.end_date))
            let _filteredResultByGroupId = action.payload.result.filter(r => r.id_group === Number(action.id_group))
            let _filteredResultByApprovedStatus = _filteredResultByGroupId.filter(r => r.approval_status === APPROVE_STATUS_CODE)
            let _filteredResultByDeclinedStatus = _filteredResultByGroupId.filter(r => r.approval_status === DECLINE_STATUS_CODE)
            let _totalMember = _filteredResultByGroupId.length
            let _totalMemberApproved = _filteredResultByApprovedStatus.length
            let _totalMemberDeclined = _filteredResultByDeclinedStatus.length
            let _groupingIntercompanyMemberSelect = approvedInvitationsMemberSelectForPO(_filteredByApprovedInvitation)
            return Object.assign({}, state, {triPLGroupingAssignCreateFindData: _filteredResultByGroupId, 
                                                        groupingIntercompanyMemberSelect: _groupingIntercompanyMemberSelect,
                                                        totalMember: _totalMember,
                                                        totalMemberApproved: _totalMemberApproved,
                                                        totalMemberDeclined: _totalMemberDeclined,
                                                        status: "success",
                                                        success: true, inProgress: false})
        case FAILED_TRIPL_GROUP_ASSIGN_CREATE_FIND:
            return ({triPLGroupingAssignCreateFindData: [], totalMember: 0, totalMemberApproved:0, status: "failed",
                        groupingIntercompanyMemberSelect: [], totalMemberDeclined:0, success: false, inProgress: false})
        default:
            return state
    }
}

const defaultTriPLGroupingAssigneFindState = {
    triPLGroupingAssignFindData: [],
    triPLGroupingApprovedSelect: [],
    groupingIntercompanyMemberSelect: [],
    totalApproved:0,
    totalDeclined:0,
    totalInvitation:0,
    totalUnConfirmInvitations: 0,
    // triPLGroupingAssignFindData: [],
    success: true,  
    inProgress: false,
    status:"loading"    
}

export function fetchTriPLGroupingAssigneFind(state = defaultTriPLGroupingAssigneFindState, action){
    switch (action.type) {
        case FETCH_TRIPL_GROUP_ASSIGN_FIND:
            return ({triPLGroupingAssignFindData: [], triPLGroupingApprovedSelect: [], totalApproved:0, status:"loading",
                        groupingIntercompanyMemberSelect: [], totalDeclined:0, totalInvitation: 0, success: true, inProgress: true})
        case RECEIVE_TRIPL_GROUP_ASSIGN_FIND:
            const APPROVE_STATUS_CODE = 1
            const DECLINE_STATUS_CODE = 2
            const NOT_CONFIRMED_STATUS_CODE = 3
            let _totalInvitation = action.payload.result.length
            let _filteredByUnconfirmedInvitation = action.payload.result.filter(r => r.approval_status === NOT_CONFIRMED_STATUS_CODE)
            let _totalUnConfirmInvitations = _filteredByUnconfirmedInvitation.length
            let _filteredByApprovedInvitation = action.payload.result.filter(r => r.approval_status === APPROVE_STATUS_CODE &&
                                                                                    isApprovedInvitationStartDateReach(r.start_date) && 
                                                                                    isApprovedInvitationValid(r.end_date))
            let _triPLGroupingApprovedSelect = approvedInvitationToSelectObj(_filteredByApprovedInvitation)
            let _groupingIntercompanyMemberSelect = approvedInvitationsMemberSelectForSO(_filteredByApprovedInvitation)
            let _filteredResultByApprovedStatus = action.payload.result.filter(r => r.approval_status === APPROVE_STATUS_CODE)
            let _filteredResultByDeclinedStatus = action.payload.result.filter(r => r.approval_status === DECLINE_STATUS_CODE)
            let _totalApproved = _filteredResultByApprovedStatus.length
            let _totalDeclined= _filteredResultByDeclinedStatus.length

            return Object.assign({}, state, {triPLGroupingAssignFindData: action.payload.result, 
                                             triPLGroupingApprovedSelect: _triPLGroupingApprovedSelect, 
                                                        totalInvitation: _totalInvitation,
                                                        groupingIntercompanyMemberSelect: _groupingIntercompanyMemberSelect,
                                                        totalUnConfirmInvitations: _totalUnConfirmInvitations,
                                                        totalApproved : _totalApproved,
                                                        totalDeclined : _totalDeclined,
                                                        status:"success",
                                                        success: true, inProgress: false})
        case FAILED_TRIPL_GROUP_ASSIGN_FIND:
            return ({triPLGroupingAssignFindData: [], triPLGroupingApprovedSelect: [], totalApproved:0, status:"failed",
                        groupingIntercompanyMemberSelect: [], totalDeclined:0, totalInvitation: 0, success: false, inProgress: false})
        default:
            return state
    }
}

const defaultTriPLGroupingAssigneFindNotificationState = {
    unconfirmInvitationNotif: [],
    approvedReachEndDateNotif : [],
    totalUnConfirmInvitations: 0,
    totalApprovedReachEndDateNotif : 0,
    success: true,  
    inProgress: false
}

export function fetchTriPLGroupingAssigneFindNotification(state = defaultTriPLGroupingAssigneFindNotificationState, action){
    switch (action.type) {
        case FETCH_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION:
            return ({unconfirmInvitationNotif: [], totalUnConfirmInvitations: 0, totalApprovedReachEndDateNotif : 0,
                     success: true, inProgress: true})
        case RECEIVE_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION:
            const APPROVE_STATUS_CODE = 1
            const NOT_CONFIRMED_STATUS_CODE = 3 
            let _filteredByUnconfirmedInvitation = action.payload.result.filter(r => r.approval_status === NOT_CONFIRMED_STATUS_CODE &&
                                                                                                    isApprovedInvitationValid(r.end_date ))
            let _filteredByJoinedGroupReachEndDate = action.payload.result.filter(r => r.approval_status === APPROVE_STATUS_CODE &&
                                                                                        isNowOnApprovedInvitationEndDateNextDay(r.end_date ))
            let _totalUnConfirmInvitations = _filteredByUnconfirmedInvitation.length
            let _totalApprovedReachEndDateNotif = _filteredByJoinedGroupReachEndDate.length
            let _unconfirmInvitationNotif = unconfirmInvitationToNotification(_filteredByUnconfirmedInvitation)
            let _approvedReachEndDateNotif = approveReachDateToNotification(_filteredByJoinedGroupReachEndDate)
            return Object.assign({}, state, {unconfirmInvitationNotif: _unconfirmInvitationNotif,
                                                approvedReachEndDateNotif : _approvedReachEndDateNotif,
                                                totalUnConfirmInvitations: _totalUnConfirmInvitations,
                                                totalApprovedReachEndDateNotif : _totalApprovedReachEndDateNotif,
                                                        success: true, inProgress: false})
        case FAILED_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION:
            return ({unconfirmInvitationNotif: [], totalUnConfirmInvitations: 0, totalApprovedReachEndDateNotif : 0,
                    success: false, inProgress: false})
        default:
            return state
    }
}


const defaultTriPLGroupFleetCheck = {
    triPLGroupFleetCheck: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLGroupFleetCheck(state = defaultTriPLGroupFleetCheck, action){
    switch (action.type) {
        case FETCH_TRIPL_GROUP_FLEET_CHECK:
            return ({triPLGroupFleetCheck: [], success: true, inProgress: true})
        case RECEIVE_TRIPL_GROUP_FLEET_CHECK:
            return Object.assign({}, state, {triPLGroupFleetCheck: action.payload.result, 
                                                 success: true, inProgress: false})
        case FAILED_TRIPL_GROUP_FLEET_CHECK:
            return ({triPLGroupFleetCheck: [], success: false, inProgress: false})
        default:
            return state
    }
}

const defaultTriPLEstCost = {
    triPLEstCost: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLEstCost(state = defaultTriPLEstCost, action){
    switch (action.type) {
        case FETCH_TRIPL_EST_COST:
            return ({triPLEstCost: [], success: true, inProgress: true})
        case RECEIVE_TRIPL_EST_COST:
            return Object.assign({}, state, {triPLEstCost: action.payload.result, 
                                                 success: true, inProgress: false})
        case CLEAR_TRIPL_EST_COST:
            return Object.assign({}, state, {triPLEstCost: [], 
                                success: true, inProgress: false})
        case FAILED_TRIPL_EST_COST:
            return ({triPLEstCost: [], success: false, inProgress: false})
        default:
            return state
    }
}

const defaultTriPLMemberView = {
    triPLMemberView: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLMemberView(state = defaultTriPLMemberView, action){
    switch (action.type) {
        case FETCH_TRIPL_MEMBER_VIEW:
            return ({triPLMemberView: [], success: true, inProgress: true})
        case RECEIVE_TRIPL_MEMBER_VIEW:
            return Object.assign({}, state, {triPLMemberView: action.payload.result, 
                                                 success: true, inProgress: false})
        case FAILED_TRIPL_MEMBER_VIEW:
            return ({triPLMemberView: [], success: false, inProgress: false})
        default:
            return state
    }
}
