import { FETCH_CAR_FUEL_TABLE, RECEIVE_CAR_FUEL_TABLE, FAILED_CAR_FUEL_TABLE } from '../../actions/transporter/aTransporter-CarFuelTypeTable'

const defaultCarFuelTableState = {
    carFuelTableData: [],
    fuelTypeSelect: [],
    inProgress: false,
    success: true  
}

export function fetchCarFuelTable(state = defaultCarFuelTableState, action){
    switch (action.type) {
        case FETCH_CAR_FUEL_TABLE:
            return ({carFuelTableData: [], fuelTypeSelect: [], success:true, inProgress: true})
        case RECEIVE_CAR_FUEL_TABLE:
            var list = []
            if(action.payload.result !== []) {
                var carFuelData = action.payload.result

                carFuelData.forEach((p, index) => {
                    var carFuel = {
                        'label': carFuelData[index].name,
                        'value': carFuelData[index].id
                    }
                    list.push(carFuel)
                })
            }
            return Object.assign({}, state, {carFuelTableData: action.payload, fuelTypeSelect: list, success:true,  inProgress: false})
        case FAILED_CAR_FUEL_TABLE:
            return ({carFuelTableData: [], fuelTypeSelect: [], success:false,  inProgress: false})
        default:
            return state
    }
}