import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_COVID_TEST_LIST = 'FETCH_COVID_TEST_LIST'
export const RECEIVE_COVID_TEST_LIST = 'RECEIVE_COVID_TEST_LIST'
export const FAILED_COVID_TEST_LIST = 'FAILED_COVID_TEST_LIST'

export function fetchCovidTest(_id, _isDriver){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    var _link = _isDriver ? API_LINKS.DRIVER_COVID_TEST_LIST_URL : API_LINKS.DRIVER_ASST_COVID_TEST_LIST_URL
    var _driver = {
        id_driver: _id
    }

    var _driverAst = {
        id_driver_asst: _id
    }

    var _data = _isDriver ? _driver : _driverAst

    return (dispatch, getState) => {
        dispatch({type: FETCH_COVID_TEST_LIST});
        axios({
            method: 'post',
            url: _link,
            headers: {
                Authorization: auth
            },
            data: _data
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_COVID_TEST_LIST,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_COVID_TEST_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_COVID_TEST_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_COVID_TEST_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_COVID_TEST_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_COVID_TEST_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_COVID_TEST_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}