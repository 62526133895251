import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_WORK_ORDERS_REVIEW_TABLE = 'FETCH_WORK_ORDERS_REVIEW_TABLE'
export const RECEIVE_WORK_ORDERS_REVIEW_TABLE = 'RECEIVE_WORK_ORDERS_REVIEW_TABLE'
export const FAILED_WORK_ORDERS_REVIEW_TABLE = 'FAILED_WORK_ORDERS_REVIEW_TABLE'

function isPODVerifReview(id){
    if(id === "6" || id === "7" || id === "8")
        return true
    return false
}

export function fetchWorkOrdersReview(_id, _months){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_WORK_ORDERS_REVIEW_TABLE});
        axios({
            method: 'post',
            url: API_LINKS.WORK_ORDER_REVIEW_URL,
            headers: {
                Authorization: auth
            },
            data: {
                status_group: _id,
                month_before: _months
                // date: 
                // For Parameters
            }
        })
        .then(async (response) => {
            if(!isPODVerifReview(_id))
                dispatch( {
                    type: RECEIVE_WORK_ORDERS_REVIEW_TABLE,
                    payload: response.data.result
                })
            if(isPODVerifReview(_id)){
                const {result} = response.data.result || null
                if(!Array.isArray(result))
                    throw new Error("")
                let responseWOPODVerif = await axios({
                    method: 'post',
                    url: API_LINKS.WORK_ORDER_POD_VERIF_LOG,
                    headers: {
                        Authorization: auth
                    },
                })
                let responseResult = responseWOPODVerif.data.result.result
                if(Array.isArray(responseResult)){
                    for(let data of result){
                        data.pod_verif_log = responseResult.filter(r => 
                                r.id_work_order === data.id
                        )
                    }
                }  
                dispatch( {
                    type: RECEIVE_WORK_ORDERS_REVIEW_TABLE,
                    payload: response.data.result
                })   
            }
        })
        .catch(function(error){
            console.log(error)
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_REVIEW_TABLE
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_REVIEW_TABLE
                    })
                    return toast.error("Failed in fetching Work Orders List. Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_REVIEW_TABLE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_WORK_ORDERS_REVIEW_TABLE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_WORK_ORDERS_REVIEW_TABLE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_WORK_ORDERS_REVIEW_TABLE
                })
                return toast.error('Something went wrong... Please try again later...')
            }

        })
    }
}