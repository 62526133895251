import { FETCH_WAREHOUSE_TRANSFER_APPROVAL_LIST, RECEIVE_WAREHOUSE_TRANSFER_APPROVAL_LIST, FAILED_WAREHOUSE_TRANSFER_APPROVAL_LIST,
    FETCH_WAREHOUSE_TRANSFER_APPROVAL_CHECK, RECEIVE_WAREHOUSE_TRANSFER_APPROVAL_CHECK, FAILED_WAREHOUSE_TRANSFER_APPROVAL_CHECK } from '../../actions/stocker/stck.warehousetransferapproval.act'

const defaultStckWarehouseTransferApprovalState = {
    stckWarehouseTransferApprovalData: [],
    stckTotalWarehouseTransferApproval: 0,
    stckWarehouseTransferApprovalSelect: [],
    success: true,
    inProgress: false,
}

export function StckWarehouseTransferApproval(state = defaultStckWarehouseTransferApprovalState, action){
    switch (action.type) {
        case FETCH_WAREHOUSE_TRANSFER_APPROVAL_LIST:
            return ({stckWarehouseTransferApprovalData: [], success: true, stckTotalWarehouseTransferApproval: 0, stckWarehouseTransferApprovalSelect: [], inProgress: true})
        case RECEIVE_WAREHOUSE_TRANSFER_APPROVAL_LIST:
            var _totalUnits = action.payload.result.length;
            var _stckWarehouseTransferApprovalSelect = []
            if(action.payload.result !== []) {
                var _repackagingsData = action.payload.result
                _repackagingsData.forEach((p, index) => {
                    var _loc = {
                        'label': _repackagingsData[index].code + " - " + _repackagingsData[index].name ,
                        'value': _repackagingsData[index].id
                    }
                    _stckWarehouseTransferApprovalSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckWarehouseTransferApprovalData: action.payload, success: true, stckTotalWarehouseTransferApproval: _totalUnits, stckWarehouseTransferApprovalSelect: _stckWarehouseTransferApprovalSelect, inProgress: false})
        case FAILED_WAREHOUSE_TRANSFER_APPROVAL_LIST:
            return ({stckWarehouseTransferApprovalData: [], success: false, stckTotalWarehouseTransferApproval: 0, stckWarehouseTransferApprovalSelect: [], inProgress: false})
        default:
            return state
    }
}

const defaultStckWarehouseTransferApprovalCheckState = {
    stckWarehouseTransferApprovalCheckData: [],
    success: true,
    inProgress: false,
}

export function StckWarehouseTransferApprovalCheck(state = defaultStckWarehouseTransferApprovalCheckState, action){
    switch (action.type) {
        case FETCH_WAREHOUSE_TRANSFER_APPROVAL_CHECK:
            return ({stckWarehouseTransferApprovalCheckData: [], success: true, stckWarehouseTransferApprovalCheckSelect: [], inProgress: true})
        case RECEIVE_WAREHOUSE_TRANSFER_APPROVAL_CHECK:
            return Object.assign({}, state, {stckWarehouseTransferApprovalCheckData: action.payload, success: true, inProgress: false})
        case FAILED_WAREHOUSE_TRANSFER_APPROVAL_CHECK:
            return ({stckWarehouseTransferApprovalCheckData: [], success: false, stckWarehouseTransferApprovalCheckSelect: [], inProgress: false})
        default:
            return state
    }
}