import { FETCH_PTH_BANKS_LIST, RECEIVE_PTH_BANKS_LIST, FAILED_PTH_BANKS_LIST } from '../../actions/pointhub/pth.banks.act'

const defaultPthBankState = {
    pthBanksData: [],
    pthTotalBanks: 0,
    pthBankSelect: [],
    success: true,
    inProgress: false,
}

export function PthBanks(state = defaultPthBankState, action){
    switch (action.type) {
        case FETCH_PTH_BANKS_LIST:
            return ({pthBanksData: [], success: true, pthTotalBanks: 0, pthBankSelect: [], inProgress: true})
        case RECEIVE_PTH_BANKS_LIST:
            var _totalBanks = action.payload.result.length;
            var _pthBankSelect = []
            if(action.payload.result !== []) {
                var _pthBankData = action.payload.result

                _pthBankData.forEach((p, index) => {
                    var _loc = {
                        'label': _pthBankData[index].bank_name,
                        'value': _pthBankData[index].id
                    }
                    _pthBankSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {pthBanksData: action.payload, success: true, pthTotalBanks: _totalBanks, pthBankSelect: _pthBankSelect, inProgress: false})
        case FAILED_PTH_BANKS_LIST:
            return ({pthBanksData: [], success: false, pthTotalBanks: 0, pthBankSelect: [], inProgress: false})
        default:
            return state
    }
}