import {FETCH_PREVENTIVE_MAINTENANCE_LIST, RECEIVE_PREVENTIVE_MAINTENANCE_LIST, FAILED_PREVENTIVE_MAINTENANCE_LIST,
    FETCH_PREVENTIVE_MAINTENANCE_TASK_LIST, RECEIVE_PREVENTIVE_MAINTENANCE_TASK_LIST, FAILED_PREVENTIVE_MAINTENANCE_TASK_LIST,
    FETCH_FLEET_MAINTENANCE_REMINDER, RECEIVE_FLEET_MAINTENANCE_REMINDER, FAILED_FLEET_MAINTENANCE_REMINDER
} from '../../actions/transporter/preventive-maintenance.act'

function getSelectFromPayload(payloadData, settings, decorator=null){
    let result = []
    if(!payloadData)
        return result
    if(!Array.isArray(payloadData))
        return result
    let labelField = settings.labelField
    let valueField = settings.valueField
    payloadData.forEach(data => {
        result.push({
            label: data[labelField],
            value: data[valueField]
        })
    })
    if(decorator)
        return decorator(payloadData, result)
    return result
}

function filterByDistanceOnlyDecorator(...args){
    let [payloadData, result] = args
    result.map((r, index) => {
        if(payloadData[index].reference_form === "km"){
            r.label = r.label + " - every " + payloadData[index].every +"km"
            return r
        }
        r.value = null
        return r
    })
    return result.filter(r => r.value)
}

const defaultPreventiveMaintenanceState = {
    totalPreventiveMaintenances:0,
    preventiveMaintenances: [],
    preventiveMaintenancesSelect: [],
    preventiveMaintenancesSelectByDistanceOnly: [],
    success: true,  
    inProgress: false
}

export function PreventiveMaintenance(state = defaultPreventiveMaintenanceState, action){
    switch (action.type) {
        case FETCH_PREVENTIVE_MAINTENANCE_LIST:
            return ({preventiveMaintenances: [], success: true, preventiveMaintenancesSelect: [],
                     preventiveMaintenancesSelectByDistanceOnly: [], inProgress: true, totalPreventiveMaintenances:0})
        case RECEIVE_PREVENTIVE_MAINTENANCE_LIST:
            const {payload} = action
            let preventiveMaintenancesSelect = getSelectFromPayload(
                payload.preventiveMaintenances.result, {labelField:"code", valueField: "id"}
            )
            let preventiveMaintenancesSelectByDistanceOnly = getSelectFromPayload(
                payload.preventiveMaintenances.result, {labelField:"code", valueField: "id"}, filterByDistanceOnlyDecorator
            )
            payload.preventiveMaintenances.result.map(pm => {
                let filterByIdPM = payload.preventiveFleet.result.filter(pf => 
                    pf.id_preventive_maintenance_setting === pm.id
                )
                pm.id_setting_fleet=null
                pm.id_fleet=null
                pm.fleet_reg=null
                if(filterByIdPM.length>0){
                    pm.id_setting_fleet=filterByIdPM[0].id
                    pm.id_fleet = filterByIdPM[0].id_fleet
                    pm.fleet_reg = filterByIdPM[0].fleet_reg_number
                }
                return pm

            })
            return Object.assign({}, state, {preventiveMaintenances: payload.preventiveMaintenances.result,
                                                preventiveMaintenancesSelect: preventiveMaintenancesSelect,
                                                preventiveMaintenancesSelectByDistanceOnly: preventiveMaintenancesSelectByDistanceOnly,
                                                totalPreventiveMaintenances: payload.preventiveMaintenances.result.length,
                                                success: true, inProgress: false})
        case FAILED_PREVENTIVE_MAINTENANCE_LIST:
            return ({preventiveMaintenances: [], success: false, preventiveMaintenancesSelect: [],
                    preventiveMaintenancesSelectByDistanceOnly: [], inProgress: false, totalPreventiveMaintenances:0,})
        default:
            return state
    }
}

const defaultPreventiveMaintenanceTaskState = {
    totalPreventiveMaintenanceTask:0,
    preventiveMaintenanceTask: [],
    preventiveMaintenanceTaskSelect: [],
    success: true,  
    inProgress: false
}

export function PreventiveMaintenanceTask(state = defaultPreventiveMaintenanceTaskState, action){
    switch (action.type) {
        case FETCH_PREVENTIVE_MAINTENANCE_TASK_LIST:
            return ({preventiveMaintenanceTask: [], success: true, preventiveMaintenanceTaskSelect: [],
                    inProgress: true, totalPreventiveMaintenanceTask:0})
        case RECEIVE_PREVENTIVE_MAINTENANCE_TASK_LIST:
            const {payload} = action
            let preventiveMaintenanceTaskSelect = getSelectFromPayload(
                payload.pmTask.result, {labelField:"name", valueField: "id"}
            )
            payload.pmTask.result.map(pm => {
                let filterByIdPM = payload.pmTaskSubCat.result.filter(pf => 
                    pf.id_preventive_maintenance_task === pm.id
                )
                pm.id_sub_cat=null
                pm.id_sub_category=null
                pm.subC=null
                pm.catg=null
                if(filterByIdPM.length>0){
                    pm.id_sub_cat=filterByIdPM[0].id
                    pm.id_sub_category = filterByIdPM[0].id_sub_category
                    pm.subC = filterByIdPM[0].categorical_sub_maintenance_name
                    pm.catg = filterByIdPM[0].categorical_maintenance_name
                }

                return pm

            })
            return Object.assign({}, state, {preventiveMaintenanceTask: payload.pmTask.result,
                                                preventiveMaintenanceTaskSelect: preventiveMaintenanceTaskSelect,
                                                totalPreventiveMaintenanceTask: payload.pmTask.result.length,
                                                success: true, inProgress: false})
        case FAILED_PREVENTIVE_MAINTENANCE_TASK_LIST:
            return ({preventiveMaintenanceTask: [], success: false, preventiveMaintenanceTaskSelect: [],
                    inProgress: false, totalPreventiveMaintenanceTask:0,})
        default:
            return state
    }
}

const defaultfleetMaintenanceReminderState = {
    fleetsMaintenanceReminder: [],
    loading: "loading",
}

export function FleetMaintenanceReminderDashboardList(state = defaultfleetMaintenanceReminderState, action){
    switch (action.type) {
        case FETCH_FLEET_MAINTENANCE_REMINDER:
            return ({fleetsMaintenanceReminder: [], loading: "loading"})
        case RECEIVE_FLEET_MAINTENANCE_REMINDER:
            return Object.assign({}, state, {fleetsMaintenanceReminder: action.payload, loading: "loaded"})
        case FAILED_FLEET_MAINTENANCE_REMINDER:
            return ({fleetsMaintenanceReminder: [], loading: "failed"})
        default:
            return state
    }
}