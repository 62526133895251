import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_TRIPL_GROUP_LIST = 'FETCH_TRIPL_GROUP_LIST'
export const RECEIVE_TRIPL_GROUP_LIST = 'RECEIVE_TRIPL_GROUP_LIST'
export const FAILED_TRIPL_GROUP_LIST = 'FAILED_TRIPL_GROUP_LIST'

export function fetchTriPLGroupList(groupType){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_TRIPL_GROUP_LIST});
        axios({
            method: 'post',
            url: API_LINKS.TRIPL_GROUP_LIST_URL,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            const TRIPL_GROUP_TYPE = 1
            if(!groupType)
                response.data.result = response.data.result.filter(r => 
                    r.group_type === TRIPL_GROUP_TYPE ||
                    r.group_type === null
                )
            else
                response.data.result = response.data.result.filter(r => 
                    r.group_type === groupType
                )
            dispatch( {
                type: RECEIVE_TRIPL_GROUP_LIST,
                payload: response.data,
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_TRIPL_GROUP_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_TRIPL_GROUP_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export const FETCH_TRIPL_GROUP_ASSIGN_CREATE_FIND = 'FETCH_TRIPL_GROUP_ASSIGN_CREATE_FIND'
export const RECEIVE_TRIPL_GROUP_ASSIGN_CREATE_FIND = 'RECEIVE_TRIPL_GROUP_ASSIGN_CREATE_FIND'
export const FAILED_TRIPL_GROUP_ASSIGN_CREATE_FIND= 'FAILED_TRIPL_GROUP_ASSIGN_CREATE_FIND'

export function fetchTriPLGroupCreateFind(id_group){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_TRIPL_GROUP_ASSIGN_CREATE_FIND});
        axios({
            method: 'post',
            url: API_LINKS.TRIPL_GROUP_ASSIGN_CREATE_FIND_URL,
            headers: {
                Authorization: auth
            },
            // data: {
            //     id: id_group
            // }
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_TRIPL_GROUP_ASSIGN_CREATE_FIND,
                payload: response.data,
                id_group : id_group
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_ASSIGN_CREATE_FIND
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_ASSIGN_CREATE_FIND
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_ASSIGN_CREATE_FIND
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_ASSIGN_CREATE_FIND
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_TRIPL_GROUP_ASSIGN_CREATE_FIND
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_TRIPL_GROUP_ASSIGN_CREATE_FIND
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export const FETCH_TRIPL_GROUP_ASSIGN_FIND = 'FETCH_TRIPL_GROUP_ASSIGN_FIND'
export const RECEIVE_TRIPL_GROUP_ASSIGN_FIND = 'RECEIVE_TRIPL_GROUP_ASSIGN_FIND'
export const FAILED_TRIPL_GROUP_ASSIGN_FIND= 'FAILED_TRIPL_GROUP_ASSIGN_FIND'

export function fetchTriPLGroupAssignFind(groupType){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_TRIPL_GROUP_ASSIGN_FIND});
        axios({
            method: 'post',
            url: API_LINKS.TRIPL_GROUP_ASSIGN_FIND_URL,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            const TRIPL_GROUP_TYPE = 1
            if(!groupType)
                response.data.result = response.data.result.filter(r => 
                    r.group_type === TRIPL_GROUP_TYPE ||
                    r.group_type === null
                )
            else
                response.data.result = response.data.result.filter(r => 
                    r.group_type === groupType
                )
            dispatch( {
                type: RECEIVE_TRIPL_GROUP_ASSIGN_FIND,
                payload: response.data,
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_ASSIGN_FIND
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_ASSIGN_FIND
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_ASSIGN_FIND
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_ASSIGN_FIND
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_TRIPL_GROUP_ASSIGN_FIND
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_TRIPL_GROUP_ASSIGN_FIND
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export const FETCH_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION = 'FETCH_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION'
export const RECEIVE_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION = 'RECEIVE_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION'
export const FAILED_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION= 'FAILED_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION'

export function fetchTriPLGroupAssignFindNotification(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION});
        axios({
            method: 'post',
            url: API_LINKS.TRIPL_GROUP_ASSIGN_FIND_URL,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION,
                payload: response.data,
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_TRIPL_GROUP_ASSIGN_FIND_NOTIFICATION
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export const FETCH_TRIPL_GROUP_FLEET_CHECK = 'FETCH_TRIPL_GROUP_FLEET_CHECK'
export const RECEIVE_TRIPL_GROUP_FLEET_CHECK = 'RECEIVE_TRIPL_GROUP_FLEET_CHECK'
export const FAILED_TRIPL_GROUP_FLEET_CHECK= 'FAILED_TRIPL_GROUP_FLEET_CHECK'

export function fetchTriPLGroupFleetCheck(_idGroup, _date){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_TRIPL_GROUP_FLEET_CHECK});
        axios({
            method: 'post',
            url: API_LINKS.TRIPL_GROUP_FLEET_CHECK_URL,
            headers: {
                Authorization: auth,
            },
            data:{
                id: _idGroup,
                date: _date
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_TRIPL_GROUP_FLEET_CHECK,
                payload: response.data,
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_FLEET_CHECK
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_FLEET_CHECK
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_FLEET_CHECK
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_TRIPL_GROUP_FLEET_CHECK
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_TRIPL_GROUP_FLEET_CHECK
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_TRIPL_GROUP_FLEET_CHECK
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export const FETCH_TRIPL_EST_COST = 'FETCH_TRIPL_EST_COST'
export const CLEAR_TRIPL_EST_COST = 'CLEAR_TRIPL_EST_COST'
export const RECEIVE_TRIPL_EST_COST = 'RECEIVE_TRIPL_EST_COST'
export const FAILED_TRIPL_EST_COST= 'FAILED_TRIPL_EST_COST'

export function fetchTriPLEstCost(_data){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return async (dispatch, getState) => {
        dispatch({type: FETCH_TRIPL_EST_COST});
        axios({
            method: 'post',
            url: API_LINKS.TRIPL_CHECK_EST_COST_URL,
            headers: {
                Authorization: auth,
            },
            data:_data
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_TRIPL_EST_COST,
                payload: response.data,
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_TRIPL_EST_COST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_TRIPL_EST_COST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_TRIPL_EST_COST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_TRIPL_EST_COST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_TRIPL_EST_COST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_TRIPL_EST_COST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function clearTriPLEstCost(_data){
    return async (dispatch, getState) => {
        dispatch({type: CLEAR_TRIPL_EST_COST});
    }
}

export const FETCH_TRIPL_MEMBER_VIEW = 'FETCH_TRIPL_MEMBER_VIEW'
export const RECEIVE_TRIPL_MEMBER_VIEW = 'RECEIVE_TRIPL_MEMBER_VIEW'
export const FAILED_TRIPL_MEMBER_VIEW= 'FAILED_TRIPL_MEMBER_VIEW'

export function fetchTriPLMemberView(_id_group){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return async (dispatch, getState) => {
        dispatch({type: FETCH_TRIPL_MEMBER_VIEW});
        axios({
            method: 'post',
            url: API_LINKS.TRIPL_GROUP_MEMBER_VIEW_URL,
            headers: {
                Authorization: auth,
            },
            data:{
                id_group : _id_group
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_TRIPL_MEMBER_VIEW,
                payload: response.data,
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_TRIPL_MEMBER_VIEW
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_TRIPL_MEMBER_VIEW
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_TRIPL_MEMBER_VIEW
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_TRIPL_MEMBER_VIEW
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_TRIPL_MEMBER_VIEW
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_TRIPL_MEMBER_VIEW
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}
