import { FETCH_COMPANY_LIST, RECEIVE_COMPANY_LIST, FAILED_COMPANY_LIST,
            FETCH_COMPANY_LIST_ALL, RECEIVE_COMPANY_LIST_ALL, FAILED_COMPANY_LIST_ALL }
from '../../actions/aUser-CompanyList'

const defaultCompanyState = {
    companyListData: [],
    success: false,
    companyKML: '',
    lat: null,
    lng: null,
    link_logo: null
}

export function fetchCompanyList(state = defaultCompanyState, action){
    switch (action.type) {
        case FETCH_COMPANY_LIST:
            return ({companyListData: [], companyKML: '', success: false, link_logo: null, inProgress: true, lat: null, lng: null})
        case RECEIVE_COMPANY_LIST:
            return Object.assign({}, state, {companyListData: action.payload, companyKML: action.payload.result[0].kml_link, link_logo: action.payload.result[0].link_logo, inProgress: false, success: true, lat: action.payload.result[0].lat, lng:  action.payload.result[0].lon})
        case FAILED_COMPANY_LIST:
            return ({companyListData: [], companyKML: '', success: false, link_logo: null, inProgress: false, lat: null, lng: null})
        default:
            return state
    }
}

const defaultCompanyAllState = {
    companyListData: [],
    companyListSelect: [],
    companyListByType: [],
    success: false,
    inProgress: true
}

export function fetchCompanyAllList(state = defaultCompanyAllState, action){
    switch (action.type) {
        case FETCH_COMPANY_LIST_ALL:
            return ({companyListData: [], companyListSelect: [], companyListByType: [],
                success: false, inProgress: true,  lng: null})
        case RECEIVE_COMPANY_LIST_ALL:
            let _companyListSelect = companyListAllCreateSelect(action.payload.result)
            let _companyListByType = companySelectByType(action.payload.result, action.specificCompanyType)
            return Object.assign({}, state, {companyListData: action.payload, 
                                                companyListSelect:_companyListSelect, 
                                                companyListByType: _companyListByType,
                                                inProgress: false, success: true
                                })
        case FAILED_COMPANY_LIST_ALL:
            return ({companyListData: [], companyListSelect: [], companyListByType: [],
                success: false, inProgress: false,  lng: null})
        default:
            return state
    }
}

const companyListAllCreateSelect = function companyListAllCreateSelect(payloadResult){
    let result = []
    payloadResult.map(company => {
        result.push({
            'label' : company.company,
            'value' : company.id,
            'logo' : company.link_logo
        })
        return company
    })
    return result
}

function companySelectByType(payloadResult, type){
    if(!type)
        return []
    let result = []
    payloadResult
            .filter(p => p.id_type === type)
            .map(company => {
                result.push({
                    'label' : company.company,
                    'value' : company.id,
                    'logo' : company.link_logo,
                    data:company
            })
            return company
    })
    return result
}