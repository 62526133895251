import { FETCH_TRIPL_LIVE_MONITORING_LIST,  
        RECEIVE_TRIPL_LIVE_MONITORING_LIST,
        FAILED_TRIPL_LIVE_MONITORING_LIST } 
        from '../../actions/transporter/aTransporter-TriPLLiveMonitoring'


const defaultTriPLWLiveMonitoringState = {
    triPLWLiveMonitoringData: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLWLiveMonitoring(state = defaultTriPLWLiveMonitoringState, action){
    switch (action.type) {
        case FETCH_TRIPL_LIVE_MONITORING_LIST:
            return ({triPLWLiveMonitoringData: [], success: true, inProgress: true})
        case RECEIVE_TRIPL_LIVE_MONITORING_LIST:
            return Object.assign({}, state, {triPLWLiveMonitoringData: action.payload.result,
                                                success: true, inProgress: false})
        case FAILED_TRIPL_LIVE_MONITORING_LIST:
            return ({triPLWLiveMonitoringData: [], success: false, inProgress: false})
        default:
            return state
    }
}