/*

    Pembuatan context sebagai layanan yang akan membantu mendistribusikan
    kemampuan objek ability ke komponen-komponen lain.

*/

import { createContext } from 'react';
import { createContextualCan } from '@casl/react';

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);