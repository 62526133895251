import { FETCH_CAR_DETAILS_TABLE, RECEIVE_CAR_DETAILS_TABLE, FAILED_CAR_DETAILS_TABLE } from '../../actions/transporter/aTransporter-CarDetailsTable.jsx'

const defaultCarDetailsTableState = {
     carDetailsTableData: []    
}

export function fetchCarDetailsTable(state = defaultCarDetailsTableState, action){
    switch (action.type) {
        case FETCH_CAR_DETAILS_TABLE:
            return ({carDetailsTableData: [], inProgress: true})
        case RECEIVE_CAR_DETAILS_TABLE:
            return Object.assign({}, state, {carDetailsTableData: action.payload, inProgress: false})
        case FAILED_CAR_DETAILS_TABLE:
            return ({carDetailsTableData: [], inProgress: false})
        default:
            return state
    }
}