import { FETCH_LIVE_MONITORING_FOR_EST_COST,  
    RECEIVE_LIVE_MONITORING_FOR_EST_COST,
    FAILED_LIVE_MONITORING_FOR_EST_COST } 
    from '../../actions/transporter/aTransporter-LiveMonitoringLite'


const defaultLiveMonitoringListState = {
    liveMonitoringData: [],
    success: true,  
    inProgress: false
}

export function fetchLiveMonitoringFleetForEstCost(state = defaultLiveMonitoringListState, action){
switch (action.type) {
        case FETCH_LIVE_MONITORING_FOR_EST_COST:
            return ({liveMonitoringData: [], success: true, inProgress: true})
        case RECEIVE_LIVE_MONITORING_FOR_EST_COST:
            let result = action.payload.result? action.payload.result[0] : []
            return Object.assign({}, state, {liveMonitoringData: result, 
                                                success: true, inProgress: false})
        case FAILED_LIVE_MONITORING_FOR_EST_COST:
            return ({liveMonitoringData: [], success: false, inProgress: false})
        default:
            return state
    }
}