import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_PACKAGE_MAINTENANCE_LIST = 'FETCH_PACKAGE_MAINTENANCE_LIST'
export const RECEIVE_PACKAGE_MAINTENANCE_LIST = 'RECEIVE_PACKAGE_MAINTENANCE_LIST'
export const FAILED_PACKAGE_MAINTENANCE_LIST = 'FAILED_PACKAGE_MAINTENANCE_LIST'

export const FETCH_PACKAGE_MAINTENANCE_TASK_LIST = 'FETCH_PACKAGE_MAINTENANCE_TASK_LIST'
export const RECEIVE_PACKAGE_MAINTENANCE_TASK_LIST = 'RECEIVE_PACKAGE_MAINTENANCE_TASK_LIST'
export const FAILED_PACKAGE_MAINTENANCE_TASK_LIST = 'FAILED_PACKAGE_MAINTENANCE_TASK_LIST'

export function fetchPackageMaintenance(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_PACKAGE_MAINTENANCE_LIST});
        axios({
            method: 'post',
            url: API_LINKS.PACKAGE_MAINTENANCE_LIST,
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_PACKAGE_MAINTENANCE_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_PACKAGE_MAINTENANCE_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_PACKAGE_MAINTENANCE_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_PACKAGE_MAINTENANCE_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_PACKAGE_MAINTENANCE_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_PACKAGE_MAINTENANCE_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_PACKAGE_MAINTENANCE_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchPackageMaintenanceTask(id_package_maintenance){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_PACKAGE_MAINTENANCE_TASK_LIST});
        axios({
            method: 'post',
            url: API_LINKS.PACKAGE_MAINTENANCE_TASK_LIST,
            headers: {
                Authorization: auth,
            },
            data:{
                id_package_maintenance: id_package_maintenance,
                isWithDetail:"true"
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_PACKAGE_MAINTENANCE_TASK_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_PACKAGE_MAINTENANCE_TASK_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_PACKAGE_MAINTENANCE_TASK_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_PACKAGE_MAINTENANCE_TASK_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_PACKAGE_MAINTENANCE_TASK_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_PACKAGE_MAINTENANCE_TASK_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_PACKAGE_MAINTENANCE_TASK_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}