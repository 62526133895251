import { FETCH_CAR_BRANDS_TABLE, RECEIVE_CAR_BRANDS_TABLE, FAILED_CAR_BRANDS_TABLE } from '../../actions/transporter/aTransporter-CarBrandsTable'

const defaultCarBrandsTableState = {
    carBrandsTableData: []    
}

export function fetchCarBrandsTable(state = defaultCarBrandsTableState, action){
    switch (action.type) {
        case FETCH_CAR_BRANDS_TABLE:
            return ({carBrandsTableData: [], inProgress: true})
        case RECEIVE_CAR_BRANDS_TABLE:
            return Object.assign({}, state, {carBrandsTableData: action.payload, inProgress: false})
        case FAILED_CAR_BRANDS_TABLE:
            return ({carBrandsTableData: [], inProgress: false})
        default:
            return state
    }
}