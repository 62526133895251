import { FETCH_DISPOSAL_TYPE_LIST, RECEIVE_DISPOSAL_TYPE_LIST, FAILED_DISPOSAL_TYPE_LIST } from '../../actions/stocker/stck.disposaltype.act'

const defaultStckDisposalTypeState = {
    stckDisposalTypeData: [],
    stckTotalDisposalType: 0,
    stckDisposalTypeSelect: [],
    success: true,
    inProgress: false,
}

export function StckDisposalType(state = defaultStckDisposalTypeState, action){
    switch (action.type) {
        case FETCH_DISPOSAL_TYPE_LIST:
            return ({stckDisposalTypeData: [], success: true, stckTotalDisposalType: 0, stckDisposalTypeSelect: [], inProgress: true})
        case RECEIVE_DISPOSAL_TYPE_LIST:
            var _totalDisposalType = action.payload.result.length;
            var _stckDisposalTypeSelect = []
            if(action.payload.result !== []) {
                var _stckDisposalType = action.payload.result

                _stckDisposalType.forEach((p, index) => {
                    var _loc = {
                        'label': _stckDisposalType[index].disposal_method_type,
                        'value': _stckDisposalType[index].id
                    }
                    _stckDisposalTypeSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckDisposalTypeData: action.payload, success: true, stckTotalDisposalType: _totalDisposalType, stckDisposalTypeSelect: _stckDisposalTypeSelect, inProgress: false})
        case FAILED_DISPOSAL_TYPE_LIST:
            return ({stckDisposalTypeData: [], success: false, stckTotalDisposalType: 0, stckDisposalTypeSelect: [], inProgress: false})
        default:
            return state
    }
}