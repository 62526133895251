import axios from "axios";
import {LIVE_MONITORING_SHARE_TOKEN} from '../../config/links'
import {toast} from 'react-toastify'

export const FETCH_LIVE_MONITORING_SHARE = 'FETCH_LIVE_MONITORING_SHARE'
export const RECEIVE_LIVE_MONITORING_SHARE = 'RECEIVE_LIVE_MONITORING_SHARE'
export const FAILED_LIVE_MONITORING_SHARE = 'FAILED_LIVE_MONITORING_SHARE'

export function fetchLiveMonitoringShare(_id_fleet, _duration){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_LIVE_MONITORING_SHARE});
        axios({
            method: 'post',
            url: LIVE_MONITORING_SHARE_TOKEN,
            headers: {
                Authorization: auth
            },
            data:{
                id_fleet: _id_fleet,
                duration: _duration
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_LIVE_MONITORING_SHARE,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_LIVE_MONITORING_SHARE
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_LIVE_MONITORING_SHARE
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_LIVE_MONITORING_SHARE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_LIVE_MONITORING_SHARE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_LIVE_MONITORING_SHARE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_LIVE_MONITORING_SHARE
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}