import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import * as serviceWorker from './serviceWorker';
// import ability from './casl/Ability';

// window.ability = ability

// Sentry.init({
//   dsn: "https://beebbf32b6b04fe1ad32b8d741f39f16@o449392.ingest.sentry.io/5432219",
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
