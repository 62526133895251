import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_DELETE_TRANSPORTER_ITEM = 'FETCH_DELETE_TRANSPORTER_ITEM'
export const RECEIVE_DELETE_TRANSPORTER_ITEM = 'RECEIVE_DELETE_TRANSPORTER_ITEM'
export const FAILED_DELETE_TRANSPORTER_ITEM = 'FAILED_DELETE_TRANSPORTER_ITEM'

var toastId

const notify = () => toastId = toast('Delete item in progress...', { autoClose: false });

export function fetchDeleteItem(_type, _id){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    notify();

    return (dispatch, getState) => {
        dispatch({type: FETCH_DELETE_TRANSPORTER_ITEM});
        axios({
            method: 'post',
            url: API_LINKS.TRANSPORTER_DELETE_URL,
            headers: {
                Authorization: auth
            },
            data: {
                item_name: _type,
                id: _id
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_DELETE_TRANSPORTER_ITEM,
                payload: response.data
            })
            toast.update(toastId, {
                render: "Deleted successfully....",
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
              });
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_DELETE_TRANSPORTER_ITEM
                    })
                    // return toast.error(error.response.data.message);
                    return toast.update(toastId, {
                        render: "error.response.data.message",
                        type: toast.TYPE.ERROR,
                        autoClose: 5000
                      });
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_DELETE_TRANSPORTER_ITEM
                    })
                    // return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    return toast.update(toastId, {
                        render: "Server cannot be contacted! Please ask your system administrator!",
                        type: toast.TYPE.ERROR,
                        autoClose: 5000
                      });
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_DELETE_TRANSPORTER_ITEM
                    })
                    // return toast.error(error.response.data.message);
                    return toast.update(toastId, {
                        render: error.response.data.message,
                        type: toast.TYPE.ERROR,
                        autoClose: 5000
                      })
                } else {
                    dispatch({
                        type: FAILED_DELETE_TRANSPORTER_ITEM
                    })
                    // return toast.error('Something went wrong... Please try again later...');
                    return toast.update(toastId, {
                        render: 'Something went wrong... Please try again later...',
                        type: toast.TYPE.ERROR,
                        autoClose: 5000
                      })
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_DELETE_TRANSPORTER_ITEM
                })
                return toast.update(toastId, {
                    render: 'Something went wrong... Please try again later...',
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                  })
            } else {
                dispatch({
                    type: FAILED_DELETE_TRANSPORTER_ITEM
                })
                return toast.update(toastId, {
                    render: 'Something went wrong... Please try again later...',
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                  })
            }
        })
    }
}