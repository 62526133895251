import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_SCHEDULED_MAINTENANCE_INVOICE_TABLE = 'FETCH_SCHEDULED_MAINTENANCE_INVOICE_TABLE'
export const RECEIVE_SCHEDULED_MAINTENANCE_INVOICE_TABLE = 'RECEIVE_SCHEDULED_MAINTENANCE_INVOICE_TABLE'
export const FAILED_SCHEDULED_MAINTENANCE_INVOICE_TABLE = 'FAILED_SCHEDULED_MAINTENANCE_INVOICE_TABLE'

export function fetchScheduledMaintenanceInvoice(id_sm){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;


    return (dispatch, getState) => {
        dispatch({type: FETCH_SCHEDULED_MAINTENANCE_INVOICE_TABLE});
        let fetchSmById = axios({
            method: 'post',
            url: API_LINKS.SCHEDULED_MAINTENANCE_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id:id_sm
            }
        })

        let fetchSmTask = axios({
            method: 'post',
            url: API_LINKS.SM_MECHANIC_TASK_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_scheduled_maintenance:id_sm
            }
        })

        let payload = {
            "scheduled_maintenance": [],
            "scheduled_maintenance_task": [],
            "scheduled_maintenance_task_detail": []
        }

        let counter = 0
        Promise.all([fetchSmById, fetchSmTask])
            .then(responses => {
            for(let response of responses){
                counter = counter + 1
                let responseResult = response.data.result
                if(!responseResult)
                    continue
                if(!responseResult.length > 0)
                    continue
                if(counter === 1){
                    payload["scheduled_maintenance"] = responseResult
                    continue
                }
                if(counter === 2){
                    payload["scheduled_maintenance_task"] = responseResult
                    continue
                }
            }
        }) 
        .then(() => {
            let fetchTaskDetail = []
            payload["scheduled_maintenance_task"].forEach(task => 
                fetchTaskDetail.push(
                    axios({
                        method: 'post',
                        url: API_LINKS.SCHEDULED_MAINTENANCE_TASK_DETAIL_LIST_URL,
                        headers: {
                            Authorization: auth
                        },
                        data:{
                            id_scheduled_maintenance_mechanic_task:task.id
                        }
                    })
                )
            )
            Promise.all([...fetchTaskDetail])
                .then(responses => {
                for(let response of responses){
                    let responseResult = response.data.result
                    if(!responseResult)
                        continue
                    if(!responseResult.length > 0)
                        continue
                    payload["scheduled_maintenance_task_detail"].push(
                        [...responseResult.filter(result => 
                                result.approval === 1
                        )]
                    )
                }
            }).then(()=> {
                dispatch( {
                    type: RECEIVE_SCHEDULED_MAINTENANCE_INVOICE_TABLE,
                    payload: payload
                })
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_SCHEDULED_MAINTENANCE_INVOICE_TABLE
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_SCHEDULED_MAINTENANCE_INVOICE_TABLE
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_SCHEDULED_MAINTENANCE_INVOICE_TABLE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_SCHEDULED_MAINTENANCE_INVOICE_TABLE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_SCHEDULED_MAINTENANCE_INVOICE_TABLE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_SCHEDULED_MAINTENANCE_INVOICE_TABLE
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}