import { FETCH_MASTER_ITEM_DETAIL_LIST, RECEIVE_MASTER_ITEM_DETAIL_LIST, FAILED_MASTER_ITEM_DETAIL_LIST } from '../../actions/stocker/stck.masteritemdetail.act'

const defaultStckMasterItemDetailState = {
    stckMasterItemDetailData: [],
    stckTotalMasterItemDetail: 0,
    stckMasterItemDetailSelect: [],
    success: true,
    inProgress: false,
}

export function StckMasterItemDetail(state = defaultStckMasterItemDetailState, action){
    switch (action.type) {
        case FETCH_MASTER_ITEM_DETAIL_LIST:
            return ({stckMasterItemDetailData: [], success: true, stckTotalMasterItemDetail: 0, stckMasterItemDetailSelect: [], inProgress: true})
        case RECEIVE_MASTER_ITEM_DETAIL_LIST:
            var _totalMasterItemDetail = action.payload.result.length;
            var _stckMasterItemDetailSelect = []
            if(action.payload.result !== []) {
                var _mItemDetailData = action.payload.result

                _mItemDetailData.forEach((p, index) => {
                    if(p.qty <= 0)
                        return
                    var _loc = {
                        'label': _mItemDetailData[index].batch_number +" - "+_mItemDetailData[index].warehouse_name + " > "+_mItemDetailData[index].warehouse_area_name + " (" +_mItemDetailData[index].qty + ") ",
                        'batch_only': _mItemDetailData[index].batch_number ,
                        'value': _mItemDetailData[index].id,
                        "id": _mItemDetailData[index].id,
                        "id_company": _mItemDetailData[index].id_company,
                        "id_master_item": _mItemDetailData[index].id_master_item,
                        "item_name": _mItemDetailData[index].item_name,
                        "sku_item_name": _mItemDetailData[index].sku_item_name,
                        "id_master_inbound": _mItemDetailData[index].id_master_inbound,
                        "inbound_number": _mItemDetailData[index].inbound_number,
                        "document_number": _mItemDetailData[index].document_number,
                        "id_administration_office": _mItemDetailData[index].id_administration_office,
                        "office_name": _mItemDetailData[index].office_name,
                        "office_address": _mItemDetailData[index].office_address,
                        "office_main_phone_number": _mItemDetailData[index].office_main_phone_number,
                        "id_warehouse": _mItemDetailData[index].id_warehouse,
                        "warehouse_name": _mItemDetailData[index].warehouse_name,
                        "warehouse_address": _mItemDetailData[index].warehouse_address,
                        "id_warehouse_area": _mItemDetailData[index].id_warehouse_area,
                        "warehouse_area_name": _mItemDetailData[index].warehouse_area_name,
                        "warehouse_area_size": _mItemDetailData[index].warehouse_area_size,
                        "qty": _mItemDetailData[index].qty,
                        "id_unit": _mItemDetailData[index].id_unit,
                        "unit_name": _mItemDetailData[index].unit_name,
                        "batch_number": _mItemDetailData[index].batch_number,
                        "production_date": _mItemDetailData[index].production_date,
                        "expiry_date": _mItemDetailData[index].expiry_date,
                        "first_in_date": _mItemDetailData[index].first_in_date,
                        "last_stock_date": _mItemDetailData[index].last_stock_date,
                        "cogs_price": _mItemDetailData[index].cogs_price,
                        "tco_cost": _mItemDetailData[index].tco_cost,
                    }
                    _stckMasterItemDetailSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckMasterItemDetailData: action.payload, success: true, stckTotalMasterItemDetail: _totalMasterItemDetail, stckMasterItemDetailSelect: _stckMasterItemDetailSelect, inProgress: false})
        case FAILED_MASTER_ITEM_DETAIL_LIST:
            return ({stckMasterItemDetailData: [], success: false, stckTotalMasterItemDetail: 0, stckMasterItemDetailSelect: [], inProgress: false})
        default:
            return state
    }
}