import axios from "axios";
import { MAIN_CUSTOMER_LIST_URL } from '../../config/links.jsx'
import {PTH_CUSTOMER_LIST_URL, PTH_CUSTOMER_ADD_URL, 
            PIC_POSITIONS_LIST_URL, PTH_CUSTOMER_PIC_ADD_URL} from '../../config/pth.links'
import {toast} from 'react-toastify'

export const FETCH_PTH_CUSTOMER_LIST = 'FETCH_PTH_CUSTOMER_LIST'
export const RECEIVE_PTH_CUSTOMER_LIST = 'RECEIVE_PTH_CUSTOMER_LIST'
export const FAILED_PTH_CUSTOMER_LIST = 'FAILED_PTH_CUSTOMER_LIST'

export function fetchPthCustomerList(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_PTH_CUSTOMER_LIST});
        axios({
            method: 'post',
            url: PTH_CUSTOMER_LIST_URL,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_PTH_CUSTOMER_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_PTH_CUSTOMER_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_PTH_CUSTOMER_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_PTH_CUSTOMER_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_PTH_CUSTOMER_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_PTH_CUSTOMER_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_PTH_CUSTOMER_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchPthCustomerByNameList(_name){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_PTH_CUSTOMER_LIST});
        axios({
            method: 'post',
            url: PTH_CUSTOMER_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                name:_name
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_PTH_CUSTOMER_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_PTH_CUSTOMER_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_PTH_CUSTOMER_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_PTH_CUSTOMER_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_PTH_CUSTOMER_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_PTH_CUSTOMER_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_PTH_CUSTOMER_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}


export const FETCH_PTH_CUSTOMER_SYNC_LIST = 'FETCH_PTH_CUSTOMER_SYNC_LIST'
export const RECEIVE_PTH_CUSTOMER_SYNC_LIST = 'RECEIVE_PTH_CUSTOMER_SYNC_LIST'
export const FAILED_PTH_CUSTOMER_SYNC_LIST = 'FAILED_PTH_CUSTOMER_SYNC_LIST'

export function syncPthCustomerListWMainCustomer(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_PTH_CUSTOMER_SYNC_LIST});
        const fetchPthCustomerData =    
                    axios({
                        method: 'post',
                        url: PTH_CUSTOMER_LIST_URL,
                        headers: {
                            Authorization: auth
                        },
                    })
        const fetchMainCustomerData = 
                    axios({
                        method: 'post',
                        url: MAIN_CUSTOMER_LIST_URL,
                        headers: {
                            Authorization: auth
                        },
                    })
        const fetchPicPostion = 
                    axios({
                        method: 'post',
                        url: PIC_POSITIONS_LIST_URL,
                        headers: {
                            Authorization: auth
                        },
                    })
        Promise.all([fetchPthCustomerData, fetchMainCustomerData, fetchPicPostion])
        .then(responses => {
            if(responses[2].data.result.length === 0){
                toast.error("No Pic Position Found!")
                return null
            }
            let [result1, result2]  = [responses[0].data.result, responses[1].data.result]
            let difference = result2.filter(x => !result1.some(y => y.name === x.name));
            return [difference, responses[2].data.result[0].id]
        })
        .then((results) => {
            if(!results){
                return false
            }
            if(results[0].length === 0){
                toast.info("No new data to Sync!")
                return false
            }
            let result = results[0]
            let picId = results[1]
            if(result.length === 0)
                return
            for(let r of result){
                let customerData = {
                    customer_company_name: r.name? r.name : "",
                    name: r.name? r.name : "",
                    address: r.main_address? r.main_address : "",
                    id_country: 0,
                    id_province: 8,
                    lat: r.lat? r.lat : 0,
                    lng: r.lng? r.lng : 0,
                    main_phone_number: r.phone? r.phone : "0",
                    main_whatsapp_number: r.handphone? r.handphone : "0"
                }
                axios({
                    method: 'post',
                    url: PTH_CUSTOMER_ADD_URL,
                    headers: {
                        Authorization: auth,
                    },
                    data : customerData
                }).then((response) => {
                    if(response.data.statusCode === 201){
                        let customerId = response.data.result.id
                        let customerPicData = {
                            id_customer : customerId,
                            id_pic_position : picId,
                            name : r.name? r.name: "",
                            email : r.email? r.email: "",
                            main_phone_number : r.handphone? r.handphone : "0",
                            whatsapp_number : r.handphone? r.handphone : "0"
                        }
                        axios({
                            method: 'post',
                            url: PTH_CUSTOMER_PIC_ADD_URL,
                            headers: {
                                Authorization: auth,
                            },
                            data : customerPicData
                        })
                    }
                })
            }
            return true
        })
        .then(isSync => {
            if(!isSync){
                dispatch( {
                    type: FAILED_PTH_CUSTOMER_SYNC_LIST,
                })
            }

            if(isSync){
                dispatch( {
                    type: RECEIVE_PTH_CUSTOMER_SYNC_LIST,
                })
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_PTH_CUSTOMER_SYNC_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_PTH_CUSTOMER_SYNC_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_PTH_CUSTOMER_SYNC_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_PTH_CUSTOMER_SYNC_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_PTH_CUSTOMER_SYNC_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_PTH_CUSTOMER_SYNC_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}