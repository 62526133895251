import {FETCH_ITEMS_ITEM_LIST, RECEIVE_ITEMS_ITEM_LIST, 
            FAILED_ITEMS_ITEM_LIST} from '../../actions/carbonx/carbonx.invoice.act'

const defaultCarbonInvoice = {
    batchItems: null,
}

export function carbonInvoice(state = defaultCarbonInvoice, action){
    switch (action.type) {
        case FETCH_ITEMS_ITEM_LIST:
            return ({batchItems: []})
        case RECEIVE_ITEMS_ITEM_LIST:
            let payload = action.payload
            return Object.assign({}, state, {batchItems: payload})
        case FAILED_ITEMS_ITEM_LIST:
            return ({batchItems: []})
        default:
            return state
    }
}