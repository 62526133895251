import { FETCH_STCK_SO_APPROVAL_LIST, RECEIVE_STCK_SO_APPROVAL_LIST, FAILED_STCK_SO_APPROVAL_LIST,
            FETCH_STCK_SO_APPROVAL_CHECK, RECEIVE_STCK_SO_APPROVAL_CHECK, FAILED_STCK_SO_APPROVAL_CHECK } from '../../actions/stocker/stck.soapproval.act'

const defaultStckSoApprovalState = {
    stckSoApprovalData: [],
    stckTotalSoApproval: 0,
    stckSoApprovalSelect: [],
    success: true,
    inProgress: false,
}

export function StckSoApproval(state = defaultStckSoApprovalState, action){
    switch (action.type) {
        case FETCH_STCK_SO_APPROVAL_LIST:
            return ({stckSoApprovalData: [], success: true, stckTotalSoApproval: 0, stckSoApprovalSelect: [], inProgress: true})
        case RECEIVE_STCK_SO_APPROVAL_LIST:
            var _totalSoApproval = action.payload.result.length;
            var _stckSoApprovalSelect = []
            // if(action.payload.result !== []) {
            //     var _poApprovalData = action.payload.result

            //     _poApprovalData.forEach((p, index) => {
            //         var _loc = {
            //             'label': _poApprovalData[index].po_type_name + ' (' + _poApprovalData[index].po_type_desc + ')',
            //             'value': _poApprovalData[index].id,"id": 1,
            //             "id_company": 1,
            //             "id_purchase_order": 1,
            //             "po_number": "SO0001",
            //             "po_creation_date": "2021-10-15 21:00:00",
            //             "po_due_date": "2021-12-15 21:00:00",
            //             "id_po_rule": 1,
            //             "limit_po_value": 10,
            //             "step_count": 10,
            //             "id_user_approval": 122,
            //             "id_division": 1,
            //             "division_name": "",
            //             "remark": ""
            //         }
            //         _stckSoApprovalSelect.push(_loc)
            //     })
            // }
            return Object.assign({}, state, {stckSoApprovalData: action.payload, success: true, stckTotalSoApproval: _totalSoApproval, stckSoApprovalSelect: _stckSoApprovalSelect, inProgress: false})
        case FAILED_STCK_SO_APPROVAL_LIST:
            return ({stckSoApprovalData: [], success: false, stckTotalSoApproval: 0, stckSoApprovalSelect: [], inProgress: false})
        default:
            return state
    }
}

const defaultStckSoApprovalCheckState = {
    stckSoApprovalCheckData: [],
    success: true,
    inProgress: false,
}

export function StckSoApprovalCheck(state = defaultStckSoApprovalCheckState, action){
    switch (action.type) {
        case FETCH_STCK_SO_APPROVAL_CHECK:
            return ({stckSoApprovalCheckData: [], success: true, inProgress: true})
        case RECEIVE_STCK_SO_APPROVAL_CHECK:
            return Object.assign({}, state, {stckSoApprovalCheckData: action.payload, 
                                                        success: true, inProgress: false})
        case FAILED_STCK_SO_APPROVAL_CHECK:
            return ({stckSoApprovalCheckData: [], success: false, inProgress: false})
        default:
            return state
    }
}