import axios from "axios";
import * as API_LINKS from "../../config/links.jsx";
import { toast } from "react-toastify";

export const FETCH_DELIVERY_ORDERS_TABLE = "FETCH_DELIVERY_ORDERS_TABLE";
export const RECEIVE_DELIVERY_ORDERS_TABLE = "RECEIVE_DELIVERY_ORDERS_TABLE";
export const FAILED_DELIVERY_ORDERS_TABLE = "FAILED_DELIVERY_ORDERS_TABLE";

export function fetchDeliveryOrdersTable(_day, _by_assign_date, _flag) {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;
  // console.log(auth)

  return (dispatch, getState) => {
    dispatch({ type: FETCH_DELIVERY_ORDERS_TABLE });
    let checkSheetDoData = {};
    axios({
      method: "post",
      url: API_LINKS.CHECK_SHEET_DO_LIST_URL,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        let result = response.data.result;
        for (let i = 0; i < result.length; i++) {
          checkSheetDoData[result[i].id_do] = result[i];
        }
        axios({
          method: "post",
          url: API_LINKS.DELIVERY_ORDERS_LIST_URL,
          headers: {
            Authorization: auth,
          },
          data: {
            id_delivery_order: "1",
            day: _day,
            by_assigned_date:
              _by_assign_date !== "0" ? _by_assign_date : undefined,
            flag: _flag,
            // date:
            // For Parameters
          },
        }).then((response) => {
          response.data.result.map((r) => {
            r.lock_audit = r.lock_audit ? r.lock_audit : 0;
            if (checkSheetDoData[r.id]) {
              r.check_sheet_approval = checkSheetDoData[r.id].is_approve;
              r.check_sheet_filled = true;
            }
            return r;
          });
          dispatch({
            type: RECEIVE_DELIVERY_ORDERS_TABLE,
            payload: response.data,
          });
          // console.log(response.data)
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_DELIVERY_ORDERS_TABLE,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_DELIVERY_ORDERS_TABLE,
            });
            return toast.error(
              "Failed in fetching Delivery Orders List. Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_DELIVERY_ORDERS_TABLE,
            });
            return toast.error(error.response.data.message);
          } else {
            dispatch({
              type: FAILED_DELIVERY_ORDERS_TABLE,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_DELIVERY_ORDERS_TABLE,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          dispatch({
            type: FAILED_DELIVERY_ORDERS_TABLE,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}
