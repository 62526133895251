import { FETCH_UNPLANNED_STOP_LOCK, RECEIVE_UNPLANNED_STOP_LOCK, FAILED_UNPLANNED_STOP_LOCK} 
from '../../actions/transporter/unplannedStop.act'
    
const defaultUnplannedStopState = {
    totalUnplannedStops:0,
    unplannedStop: [],
    anotherUnplannedStop: [],
    status:"loading"
}

export function UnplannedStop(state = defaultUnplannedStopState, action){
    switch (action.type) {
        case FETCH_UNPLANNED_STOP_LOCK:
            return ({unplannedStop: [], anotherUnplannedStop: [], status: "loading", totalUnplannedStops:0})
        case RECEIVE_UNPLANNED_STOP_LOCK:
            return Object.assign({}, state, {unplannedStop: action.payload.result, 
                                                anotherUnplannedStop: JSON.parse(JSON.stringify(action.payload.result)),
                                                totalUnplannedStops: action.payload.result.length,
                                                status: "success"})
        case FAILED_UNPLANNED_STOP_LOCK:
            return ({unplannedStop: [], anotherUnplannedStop:[], status: "success", totalUnplannedStops:0,})
        default:
            return state
    }
}