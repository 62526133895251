import { FETCH_LL_SENSOR_INSTALLATION_LIST, RECEIVE_LL_SENSOR_INSTALLATION_LIST, FAILED_LL_SENSOR_INSTALLATION_LIST } from '../../actions/landlord/aLandLord-SensorInstallationList'

const defaultLLSensorInstallationState = {
    llSensorInstallationData: [],
    llTotalSensorInstallation: 0,
    isSensorInstalled: false,
    success: true,
    inProgress: false,
}

export function fetchLLSensorInstallationList(state = defaultLLSensorInstallationState, action){
    switch (action.type) {
        case FETCH_LL_SENSOR_INSTALLATION_LIST:
            return ({llSensorInstallationData: [], success: true, llTotalSensor: 0, isSensorInstalled: true, inProgress: true})
        case RECEIVE_LL_SENSOR_INSTALLATION_LIST:
            var _totalSensorInstallation = action.payload.result.length;
            var _isInstalled = action.payload.result.filter( x => 
                (
                    x.installation_date != null &&
                    x.removal_date == null
                )
            );
            // console.log(_isInstalled)
            var _result = _isInstalled.length
            // if(_isInstalled == []) {
            //     _result = false
            // } else {
            //     _result = true
            // }
            // console.log(_isInstalled)
            return Object.assign({}, state, {llSensorInstallationData: action.payload, success: true, llTotalSensorInstallation: _totalSensorInstallation, isSensorInstalled: _result, inProgress: false})
        case FAILED_LL_SENSOR_INSTALLATION_LIST:
            return ({llSensorInstallationData: [], success: false, llTotalSensor: 0, isSensorInstalled: false, inProgress: false})
        default:
            return state
    }
}