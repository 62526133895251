import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'
import moment from "moment";

export const FETCH_FLEET_APPROVED_LIST = 'FETCH_FLEET_APPROVED_LIST'
export const RECEIVE_FLEET_APPROVED_LIST = 'RECEIVE_FLEET_APPROVED_LIST'
export const FAILED_FLEET_APPROVED_LIST = 'FAILED_FLEET_APPROVED_LIST'

export const FETCH_FLEET_LIST_APPROVED_LIST = 'FETCH_FLEET_LIST_APPROVED_LIST'
export const RECEIVE_FLEET_LIST_APPROVED_LIST = 'RECEIVE_FLEET_LIST_APPROVED_LIST'
export const FAILED_FLEET_LIST_APPROVED_LIST = 'FAILED_FLEET_LIST_APPROVED_LIST'

export const FETCH_RENTEE_FLEET_LIVE_LIST = 'FETCH_RENTEE_FLEET_LIVE_LIST'
export const RECEIVE_RENTEE_FLEET_LIVE_LIST = 'RECEIVE_RENTEE_FLEET_LIVE_LIST'
export const FAILED_RENTEE_FLEET_LIVE_LIST = 'FAILED_RENTEE_FLEET_LIVE_LIST'


export function fetchRenteeFleetApproved(id_request){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_FLEET_APPROVED_LIST});
        axios({
            method: 'post',
            url: API_LINKS.RENTEE_REQUEST_FLEET_OWNER_LIST,
            headers: {
                Authorization: auth
            },
            data:{
                id_request: id_request
            }
        })
        .then(payload => {
            dispatch( {
                type: RECEIVE_FLEET_APPROVED_LIST,
                payload: payload
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_FLEET_APPROVED_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_FLEET_APPROVED_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_FLEET_APPROVED_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_FLEET_APPROVED_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_FLEET_APPROVED_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_FLEET_APPROVED_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchRenteeFleetListApproved(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_FLEET_LIST_APPROVED_LIST});
        let requestContract = {}
        axios({
            method: 'post',
            url: API_LINKS.RENTEE_REQUEST_LIST,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            response.data.result.forEach(r => {
                r.start_date = moment(r.start_date).format("YYYY-MM-DD")
                r.end_date = moment(r.end_date).format("YYYY-MM-DD")
                requestContract[r.id] = r.id_contract
            })
            response.data.result = response.data.result.filter(r => 
                r.approve_by_fleet_owner !== null 
                && r.approve_by_rente !== null
                && (moment() >= moment(r.start_date) && moment() <= moment(r.end_date))
            )
            let fetchFleetByAllRequest = []
            for(let data of response.data.result){
                fetchFleetByAllRequest.push(
                    axios({
                        method: 'post',
                        url: API_LINKS.RENTEE_REQUEST_FLEET_OWNER_LIST,
                        headers: {
                            Authorization: auth
                        },
                        data:{
                            id_request: data.id
                        }
                    })
                )
            }
            Promise.all([...fetchFleetByAllRequest])
            .then(responses => {
                let payload = []
                for(let response of responses){
                    let responseResult = response.data.result
                    if(!responseResult)
                        continue
                    if(!responseResult.length > 0)
                        continue
                    responseResult.forEach(r => {
                        r.id_contract = requestContract[r.id_request]
                    })
                    payload = [...payload, ...responseResult]
                }
                return payload.filter(p => p.id_status === 1 || p.id_status === 3)
            })
            .then(payload => {
                dispatch( {
                    type: RECEIVE_FLEET_LIST_APPROVED_LIST,
                    payload: payload
                })
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_FLEET_LIST_APPROVED_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_FLEET_LIST_APPROVED_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_FLEET_LIST_APPROVED_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_FLEET_LIST_APPROVED_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_FLEET_LIST_APPROVED_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_FLEET_LIST_APPROVED_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchRenteeFleetLive(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_FLEET_APPROVED_LIST});
        axios({
            method: 'post',
            url: API_LINKS.RENTEE_REQUEST_LIST,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            console.log(response)
            response.data.result.forEach(r => {
                r.start_date = moment(r.start_date).format("YYYY-MM-DD")
                r.end_date = moment(r.end_date).format("YYYY-MM-DD")
            })
            response.data.result = response.data.result.filter(r => 
                r.approve_by_fleet_owner !== null 
                && r.approve_by_rente !== null
                && (moment() >= moment(r.start_date) && moment() <= moment(r.end_date))
            )
            let fetchFleetByAllRequest = []
            for(let data of response.data.result){
                fetchFleetByAllRequest.push(
                    axios({
                        method: 'post',
                        url: API_LINKS.RENTEE_FLEET_LIVE_MONITORING,
                        headers: {
                            Authorization: auth
                        },
                        data:{
                            id_contract: data.id_contract
                        }
                    })
                )
            }
            Promise.all([...fetchFleetByAllRequest])
            .then(responses => {
                let payload = []
                for(let response of responses){
                    let responseResult = response.data.result
                    if(!responseResult)
                        continue
                    if(!responseResult.length > 0)
                        continue
                    payload = [...payload, ...responseResult]
                }
                return payload
            })
            .then(payload => {
                dispatch( {
                    type: RECEIVE_FLEET_APPROVED_LIST,
                    payload: payload
                })
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_FLEET_APPROVED_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_FLEET_APPROVED_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_FLEET_APPROVED_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_FLEET_APPROVED_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_FLEET_APPROVED_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_FLEET_APPROVED_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}