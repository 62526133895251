import { FETCH_TRIPL_WORK_ORDER_LIST, RECEIVE_TRIPL_WORK_ORDER_LIST, FAILED_TRIPL_WORK_ORDER_LIST } from '../../actions/transporter/aTransporter-TriPLWOList'

const nullIdGroupAsAutomaWide = function(triPlRequest){
    triPlRequest.map(request =>{
        if(request.id_group === null)
            request.group_name = "Automa Wide"
        return request
    })
    return triPlRequest
}


const defaultTriPLWorkOrderListState = {
    triPLWorkOrderListData: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLWorkOrderList(state = defaultTriPLWorkOrderListState, action){
    switch (action.type) {
        case FETCH_TRIPL_WORK_ORDER_LIST:
            return ({triPLWorkOrderListData: [], success: true, inProgress: true})
        case RECEIVE_TRIPL_WORK_ORDER_LIST:
            return Object.assign({}, state, {triPLWorkOrderListData: nullIdGroupAsAutomaWide(action.payload.result),
                                                 success: true, inProgress: false})
        case FAILED_TRIPL_WORK_ORDER_LIST:
            return ({triPLWorkOrderListData: [], success: false, inProgress: false})
        default:
            return state
    }
}