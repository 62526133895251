import { MEASURE_UNIT_LIST, RECEIVE_MEASURE_UNIT_LIST, FAILED_MEASURE_UNIT_LIST } from '../../actions/stocker/stck.measureunit.act'

const defaultStckMeasureUnitState = {
    stckMeasureUnitData: [],
    stckTotalMeasureUnit: 0,
    stckMeasureUnitSelect: [],
    success: true,
    inProgress: false,
}

export function StckMeasureUnit(state = defaultStckMeasureUnitState, action){
    switch (action.type) {
        case MEASURE_UNIT_LIST:
            return ({stckMeasureUnitData: [], success: true, stckTotalMeasureUnit: 0, stckMeasureUnitSelect: [], inProgress: true})
        case RECEIVE_MEASURE_UNIT_LIST:
            var _totalUnits = action.payload.result.length;
            var _stckMeasureUnitSelect = []
            if(action.payload.result !== []) {
                var _measureunitData = action.payload.result
                _measureunitData.forEach((p, index) => {
                    var _loc = {
                        'label': _measureunitData[index].name + " (" + _measureunitData[index].symbol + ")",
                        'value': _measureunitData[index].id
                    }
                    _stckMeasureUnitSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckMeasureUnitData: action.payload, success: true, stckTotalMeasureUnit: _totalUnits, stckMeasureUnitSelect: _stckMeasureUnitSelect, inProgress: false})
        case FAILED_MEASURE_UNIT_LIST:
            return ({stckMeasureUnitData: [], success: false, stckTotalMeasureUnit: 0, stckMeasureUnitSelect: [], inProgress: false})
        default:
            return state
    }
}