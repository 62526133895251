import axios from "axios";
import { SERVICE_PLAN_LIST_URL } from '../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_SERVICE_PLAN_LIST = 'FETCH_SERVICE_PLAN_LIST'
export const RECEIVE_SERVICE_PLAN_LIST = 'RECEIVE_SERVICE_PLAN_LIST'
export const FAILED_SERVICE_PLAN_LIST = 'FAILED_SERVICE_PLAN_LIST'

export function fetchServicePlanList(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_SERVICE_PLAN_LIST});
        axios({
            method: 'post',
            url: SERVICE_PLAN_LIST_URL,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_SERVICE_PLAN_LIST,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_SERVICE_PLAN_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_SERVICE_PLAN_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_SERVICE_PLAN_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_SERVICE_PLAN_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_SERVICE_PLAN_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_SERVICE_PLAN_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}