import { FETCH_SM_MECHANIC_TASK_TABLE, RECEIVE_SM_MECHANIC_TASK_TABLE, FAILED_SM_MECHANIC_TASK_TABLE,
    FETCH_SCHEDULED_MAINTENANCE_TASK_DETAIL_TABLE, RECEIVE_SCHEDULED_MAINTENANCE_TASK_DETAIL_TABLE, FAILED_SCHEDULED_MAINTENANCE_TASK_DETAIL_TABLE,
    FETCH_SCHEDULED_MAINTENANCE_TASK_PROOF_TABLE, RECEIVE_SCHEDULED_MAINTENANCE_TASK_PROOF_TABLE, FAILED_SCHEDULED_MAINTENANCE_TASK_PROOF_TABLE} from '../../actions/transporter/smmechanictask.act'

const defaultSmMechanicTaskListState = {
    smMechanicTaskListData: [],
    smMechanicTaskListTotal:0,
    smMechanicTaskListTotalCost: 0,
    smMechanicTaskListTotalDuration: 0,
    smMechanicTaskApprovalTotal: 0,
    inProgress: false,
    success: true
}

export function SMMechanicTaskList(state = defaultSmMechanicTaskListState, action){
    switch (action.type) {
        case FETCH_SM_MECHANIC_TASK_TABLE:
            return ({smMechanicTaskListData: [], smMechanicTaskListTotal:0,
                smMechanicTaskListTotalCost: 0, smMechanicTaskListTotalDuration: 0,
                smMechanicTaskApprovalTotal: 0,
                success: false, inProgress: true})
        case RECEIVE_SM_MECHANIC_TASK_TABLE:
            let totalCost = 0
            let totalDuration = 0
            let _smMechanicTaskApprovalTotal = 0
            for(let smtd of action.payload.result){
                totalCost = totalCost + Number(smtd.total_cost)
                totalDuration = totalDuration + Number(smtd.total_duration)
                if(smtd.approval === 1)
                    _smMechanicTaskApprovalTotal = _smMechanicTaskApprovalTotal + 1
            }
            return Object.assign({}, state, {smMechanicTaskListData: action.payload.result, 
                smMechanicTaskListTotalCost: totalCost, smMechanicTaskListTotalDuration: totalDuration,
                smMechanicTaskApprovalTotal: _smMechanicTaskApprovalTotal,
                smMechanicTaskListTotal: action.payload.result.length, success: true, inProgress: false})
        case FAILED_SM_MECHANIC_TASK_TABLE:
            return ({smMechanicTaskListData: [], smMechanicTaskListTotal:0,
                smMechanicTaskListTotalCost: 0, smMechanicTaskListTotalDuration: 0,
                smMechanicTaskApprovalTotal: 0,
                success: false, inProgress: false})
        default:
            return state
    }
}

const defaultScheduledMaintenanceTaskDetailistState = {
    scheduledMaintenanceTaskDetailListData: [],
    scheduledMaintenanceTaskDetailTotalCost: 0,
    scheduledMaintenanceTaskDetailTotalDuration: 0,
    scheduledMaintenanceTaskDetailTotal: 0,
    inProgress: false,
    success: true
}

export function ScheduledMaintenanceTaskDetailist(state = defaultScheduledMaintenanceTaskDetailistState, action){
    switch (action.type) {
        case FETCH_SCHEDULED_MAINTENANCE_TASK_DETAIL_TABLE:
            return ({scheduledMaintenanceTaskDetailListData: [],scheduledMaintenanceTaskDetailTotal:0,
                scheduledMaintenanceTaskDetailTotalDuration: 0,
                scheduledMaintenanceTaskDetailTotalCost:0, success: false, inProgress: true})
        case RECEIVE_SCHEDULED_MAINTENANCE_TASK_DETAIL_TABLE:
            // console.log("===========")
            // console.log(action.payload.result
            //     .reduce((a,b) => {
            //         return a.cost + b.cost
            //     }))
            let totalCost = 0
            let totalDuration = 0
            for(let smtd of action.payload.result){
                if(smtd.approval === 1){
                    totalCost = totalCost + Number(smtd.cost)
                    totalDuration = totalDuration + Number(smtd.duration)
                }
            }
            return Object.assign({}, state, {scheduledMaintenanceTaskDetailListData: action.payload.result,
                scheduledMaintenanceTaskDetailTotal: action.payload.result.length,
                scheduledMaintenanceTaskDetailTotalDuration: totalDuration,
                scheduledMaintenanceTaskDetailTotalCost: totalCost,
                success: true, inProgress: false})
        case FAILED_SCHEDULED_MAINTENANCE_TASK_DETAIL_TABLE:
            return ({scheduledMaintenanceTaskDetailListData: [],scheduledMaintenanceTaskDetailTotal:0,
                scheduledMaintenanceTaskDetailTotalDuration: 0,
                scheduledMaintenanceTaskDetailTotalCost:0, success: false, inProgress: false})
        default:
            return state
    }
}


const defaultScheduledMaintenanceTaskProofistState = {
    scheduledMaintenanceTaskProofListData: [],
    scheduledMaintenanceTaskProofTotal: 0,
    inProgress: false,
    success: true
}

export function ScheduledMaintenanceTaskProofist(state = defaultScheduledMaintenanceTaskProofistState, action){
    switch (action.type) {
        case FETCH_SCHEDULED_MAINTENANCE_TASK_PROOF_TABLE:
            return ({scheduledMaintenanceTaskProofListData: [],scheduledMaintenanceTaskProofTotal:0,
                 success: false, inProgress: true})
        case RECEIVE_SCHEDULED_MAINTENANCE_TASK_PROOF_TABLE:
            // var _total = action.payload.result.length
            return Object.assign({}, state, {scheduledMaintenanceTaskProofListData: action.payload.result,
                scheduledMaintenanceTaskProofTotal: action.payload.result.length,
                success: true, inProgress: false})
        case FAILED_SCHEDULED_MAINTENANCE_TASK_PROOF_TABLE:
            return ({scheduledMaintenanceTaskProofListData: [],scheduledMaintenanceTaskProofTotal:0,
                 success: false, inProgress: false})
        default:
            return state
    }
}