import { FETCH_PTH_CUSTOMER_BANK_ACC_LIST, RECEIVE_PTH_CUSTOMER_BANK_ACC_LIST, FAILED_PTH_CUSTOMER_BANK_ACC_LIST } from '../../actions/pointhub/pth.customerbankacc.act'

const defaultPthCustomerBankAccState = {
    pthCustomerBankAccData: [],
    pthTotalCustomerBankAcc: 0,
    pthCustomerBankAccSelect: [],
    success: true,
    inProgress: false,
}

export function PthCustomerBankAcc(state = defaultPthCustomerBankAccState, action){
    switch (action.type) {
        case FETCH_PTH_CUSTOMER_BANK_ACC_LIST:
            return ({pthCustomerBankAccData: [], success: true, pthTotalCustomerBankAcc: 0, pthCustomerBankAccSelect: [], inProgress: true})
        case RECEIVE_PTH_CUSTOMER_BANK_ACC_LIST:
            var _totalBankAcc = action.payload.result.length;
            var _pthCustomerBankAccSelect = []
            if(action.payload.result !== []) {
                var _pthCustBankAccData = action.payload.result

                _pthCustBankAccData.forEach((p, index) => {
                    var _loc = {
                        'label': _pthCustBankAccData[index].account_number,
                        'value': _pthCustBankAccData[index].id
                    }
                    _pthCustomerBankAccSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {pthCustomerBankAccData: action.payload, success: true, pthTotalCustomerBankAcc: _totalBankAcc, pthCustomerBankAccSelect: _pthCustomerBankAccSelect, inProgress: false})
        case FAILED_PTH_CUSTOMER_BANK_ACC_LIST:
            return ({pthCustomerBankAccData: [], success: false, pthTotalCustomerBankAcc: 0, pthCustomerBankAccSelect: [], inProgress: false})
        default:
            return state
    }
}