import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_CAR_DETAILS_TABLE = 'FETCH_CAR_DETAILS_TABLE'
export const RECEIVE_CAR_DETAILS_TABLE = 'RECEIVE_CAR_DETAILS_TABLE'
export const FAILED_CAR_DETAILS_TABLE = 'FAILED_CAR_DETAILS_TABLE'

export function fetchCarDetailsTable(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_CAR_DETAILS_TABLE});
        axios({
            method: 'post',
            url: API_LINKS.CAR_DETAILS_TABLE_URL,
            headers: {
                Authorization: auth
            },
            data: {
                dev: 'T02',
                // date: 
                // For Parameters
            }
        })
        
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_CAR_DETAILS_TABLE,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if(error.response.status === 401) {
                dispatch({
                    type: FAILED_CAR_DETAILS_TABLE
                })
                return toast.error("Unathorized access!");
            } else if (error.response.status === 404 || error.response.status === 500) {
                dispatch({
                    type: FAILED_CAR_DETAILS_TABLE
                })
                return toast.error("Server cannot be contacted! Please ask your system administrator!");
            }

        })
    }
}