import { FETCH_WORKSHOP_OH_TABLE, RECEIVE_WORKSHOP_OH_TABLE, FAILED_WORKSHOP_OH_TABLE } from '../../actions/transporter/workshopOperationalHour.act'

const defaultWorkshopOHListState = {
    workshopOperationalHourListData: [],
    workshopOperationalHourTotal: 0,
    inProgress: false,
    success: true
}

export function WorkshopOperationalHourList(state = defaultWorkshopOHListState, action){
    switch (action.type) {
        case FETCH_WORKSHOP_OH_TABLE:
            return ({workshopOperationalHourListData: [],  workshopOperationalHourTotal: 0, success: false, inProgress: true})
        case RECEIVE_WORKSHOP_OH_TABLE:
            var _total = action.payload.result.length
            return Object.assign({}, state, {workshopOperationalHourListData: action.payload.result, 
                workshopOperationalHourTotal: _total,
                success: true, inProgress: false})
        case FAILED_WORKSHOP_OH_TABLE:
            return ({workshopOperationalHourListData: [], workshopOperationalHourTotal: 0, success: false, inProgress: false})
        default:
            return state
    }
}