import { 
    FETCH_LIVE_MONITORING_SHARE,
    RECEIVE_LIVE_MONITORING_SHARE,
    FAILED_LIVE_MONITORING_SHARE
} 
from '../../actions/transporter/aTransporter-LiveMonitoringShare'

const defaultLiveMonitoringShareState = {
    tokenResult: null,
}

export function LiveMonitoringShareToken(state = defaultLiveMonitoringShareState, action){
    switch (action.type) {
        case FETCH_LIVE_MONITORING_SHARE:
            return ({tokenResult: null})
        case RECEIVE_LIVE_MONITORING_SHARE:
        return Object.assign({}, state, {tokenResult: action.payload.result})
        case FAILED_LIVE_MONITORING_SHARE:
            return ({tokenResult: null})
        default:
            return state
    }
}