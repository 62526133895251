import { FETCH_MASTER_WAREHOUSE_TRANSFER_LIST, RECEIVE_MASTER_WAREHOUSE_TRANSFER_LIST, FAILED_MASTER_WAREHOUSE_TRANSFER_LIST } from '../../actions/stocker/stck.masterwarehousetransfer.act'

const defaultStckMasterWarehouseTransferState = {
    stckMasterWarehouseTransferData: [],
    stckTotalMasterWarehouseTransfer: 0,
    stckMasterWarehouseTransferSelect: [],
    success: true,
    inProgress: false,
}

export function StckMasterWarehouseTransfer(state = defaultStckMasterWarehouseTransferState, action){
    switch (action.type) {
        case FETCH_MASTER_WAREHOUSE_TRANSFER_LIST:
            return ({stckMasterWarehouseTransferData: [], success: true, stckTotalMasterWarehouseTransfer: 0, stckMasterWarehouseTransferSelect: [], inProgress: true})
        case RECEIVE_MASTER_WAREHOUSE_TRANSFER_LIST:
            var _totalUnits = action.payload.result.length;
            var _stckMasterWarehouseTransferSelect = []
            if(action.payload.result !== []) {
                var _repackagingsData = action.payload.result
                _repackagingsData.forEach((p, index) => {
                    var _loc = {
                        'label': _repackagingsData[index].code,
                        'value': _repackagingsData[index].id
                    }
                    _stckMasterWarehouseTransferSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckMasterWarehouseTransferData: action.payload, success: true, stckTotalMasterWarehouseTransfer: _totalUnits, stckMasterWarehouseTransferSelect: _stckMasterWarehouseTransferSelect, inProgress: false})
        case FAILED_MASTER_WAREHOUSE_TRANSFER_LIST:
            return ({stckMasterWarehouseTransferData: [], success: false, stckTotalMasterWarehouseTransfer: 0, stckMasterWarehouseTransferSelect: [], inProgress: false})
        default:
            return state
    }
}