import { FETCH_STCK_WAREHOUSE_RULES_LIST, RECEIVE_STCK_WAREHOUSE_RULES_LIST, FAILED_STCK_WAREHOUSE_RULES_LIST } from '../../actions/stocker/stck.warehouserules.act'

const defaultStckWarehouseRulesState = {
    stckWarehouseRulesData: [],
    stckTotalWarehouseRules: 0,
    stckWarehouseRulesSelect: [],
    success: true,
    inProgress: false,
}

export function StckWarehouseRules(state = defaultStckWarehouseRulesState, action){
    switch (action.type) {
        case FETCH_STCK_WAREHOUSE_RULES_LIST:
            return ({stckWarehouseRulesData: [], success: true, stckTotalWarehouseRules: 0, stckWarehouseRulesSelect: [], inProgress: true})
        case RECEIVE_STCK_WAREHOUSE_RULES_LIST:
            var _totalWarehouse = action.payload.result.length;
            var _stckWarehouseRulesSelect = []
            if(action.payload.result !== []) {
                var _warehouseRulesData = action.payload.result

                _warehouseRulesData.forEach((p, index) => {
                    var _loc = {
                        'label': _warehouseRulesData[index].rules_name + ' - ' + _warehouseRulesData[index].rules_desc,
                        'value': _warehouseRulesData[index].id,
                        "id_warehouse": _warehouseRulesData[index].id,
                        "warehouse_name": _warehouseRulesData[index].warehouse_name,
                        "warehouse_address": _warehouseRulesData[index].warehouse_address
                    }
                    _stckWarehouseRulesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckWarehouseRulesData: action.payload, success: true, stckTotalWarehouseRules: _totalWarehouse, stckWarehouseRulesSelect: _stckWarehouseRulesSelect, inProgress: false})
        case FAILED_STCK_WAREHOUSE_RULES_LIST:
            return ({stckWarehouseRulesData: [], success: false, stckTotalWarehouseRules: 0, stckWarehouseRulesSelect: [], inProgress: false})
        default:
            return state
    }
}