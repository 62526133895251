import { 
    FETCH_MAINTENANCE_CATEGORIES_TABLE,  
    RECEIVE_MAINTENANCE_CATEGORIES_TABLE,
    FAILED_MAINTENANCE_CATEGORIES_TABLE ,

    FETCH_MAINTENANCE_SUB_CATEGORY_TABLE,
    RECEIVE_MAINTENANCE_SUB_CATEGORY_TABLE,
    FAILED_MAINTENANCE_SUB_CATEGORY_TABLE
} 
from '../../actions/transporter/maintenance-categories-subcategories.act'

function getSelectFromPayload(payloadData, settings){
    let result = []
    if(!payloadData)
        return result
    if(!Array.isArray(payloadData))
        return result
    let labelField = settings.labelField
    let valueField = settings.valueField
    payloadData.forEach(data => {
        result.push({
            label: data[labelField],
            value: data[valueField]
        })
    })
    return result
}

const defaultCategoriesState = {
    totalCategories:0,
    categories: [],
    categoriesSelect: [],
    success: true,  
    inProgress: false
}

export function MaintenanceCategories(state = defaultCategoriesState, action){
    switch (action.type) {
        case FETCH_MAINTENANCE_CATEGORIES_TABLE:
            return ({categories: [], categoriesSelect: [], success: true, 
                    inProgress: true, totalCategories:0})
        case RECEIVE_MAINTENANCE_CATEGORIES_TABLE:
            const {payload} = action
            let categoriesSelect = getSelectFromPayload(
                payload.result, {labelField:"name", valueField: "id"}
            )
            return Object.assign({}, state, {categories: action.payload.result, 
                                                categoriesSelect: categoriesSelect,
                                                totalCategories: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_MAINTENANCE_CATEGORIES_TABLE:
            return ({categories: [], categoriesSelect: [],success: false, 
                    inProgress: false, totalCategories:0,})
        default:
            return state
    }
}

const defaultSubCategoriesState = {
    totalSubCategories:0,
    subCategories: [],
    subCategoriesSelect: [],
    success: true,  
    inProgress: false
}

export function MaintenanceSubCategories(state = defaultSubCategoriesState, action){
    switch (action.type) {
        case FETCH_MAINTENANCE_SUB_CATEGORY_TABLE:
            return ({subCategories: [], subCategoriesSelect: [], success: true, 
                    inProgress: true, totalSubCategories:0})
        case RECEIVE_MAINTENANCE_SUB_CATEGORY_TABLE:
            const {payload} = action
            let subCategoriesSelect = getSelectFromPayload(
                payload.result, {labelField:"name", valueField: "id"}
            )
            return Object.assign({}, state, {subCategories: action.payload.result, 
                                                subCategoriesSelect: subCategoriesSelect,
                                                totalSubCategories: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_MAINTENANCE_SUB_CATEGORY_TABLE:
            return ({subCategories: [], subCategoriesSelect: [], success: false, 
                    inProgress: false, totalSubCategories:0,})
        default:
            return state
    }
}