import { FETCH_DELIVERY_ORDERS_TABLE, RECEIVE_DELIVERY_ORDERS_TABLE, FAILED_DELIVERY_ORDERS_TABLE } from '../../actions/transporter/aTransporter-DeliveryOrdersTable'
import moment from 'moment';

const defaultDeliveryOrdersTableState = {
    deliveryOrdersTableData: [],
    success: false,
    totalDOToday: 0,
}

export function fetchDeliveryOrdersTable(state = defaultDeliveryOrdersTableState, action){
    switch (action.type) {
        case FETCH_DELIVERY_ORDERS_TABLE:
            return ({deliveryOrdersTableData: [], success: false, totalDOToday: 0, inProgress: true})
        case RECEIVE_DELIVERY_ORDERS_TABLE:
            var today = moment(new Date(), "YYYY-MM-DD");
            var doToday = action.payload.result.filter(function (a) {
                // eslint-disable-next-line
                return (moment(a.assigned_do_date, "YYYY-MM-DD") ==  today)
              });
            //   console.log(doToday)
            var _totalDOToday = doToday.length;
            return Object.assign({}, state, {deliveryOrdersTableData: action.payload, totalDOToday: _totalDOToday, success: true, inProgress: false})
        case FAILED_DELIVERY_ORDERS_TABLE:
            return ({deliveryOrdersTableData: [], success: false, totalDOToday: 0, inProgress: false})
        default:
            return state
    }
}