import { FETCH_STCK_WAREHOUSE_LIST, RECEIVE_STCK_WAREHOUSE_LIST, FAILED_STCK_WAREHOUSE_LIST } from '../../actions/stocker/stck.warehouse.act'

const defaultStckWarehouseState = {
    stckWarehouseData: [],
    stckTotalWarehouse: 0,
    stckWarehouseSelect: [],
    success: true,
    inProgress: false,
}

export function StckWarehouse(state = defaultStckWarehouseState, action){
    switch (action.type) {
        case FETCH_STCK_WAREHOUSE_LIST:
            return ({stckWarehouseData: [], success: true, stckTotalWarehouse: 0, stckWarehouseSelect: [], inProgress: true})
        case RECEIVE_STCK_WAREHOUSE_LIST:
            var _totalWarehouse = action.payload.result.length;
            var _stckWarehouseSelect = []
            if(action.payload.result !== []) {
                var _warehouseData = action.payload.result

                _warehouseData.forEach((p, index) => {
                    var _loc = {
                        'label': _warehouseData[index].warehouse_name,
                        'value': _warehouseData[index].id,
                        "address": _warehouseData[index].address,
                        "is_intercompany": _warehouseData[index].is_intercompany,
                        "id_administration_office": _warehouseData[index].id_administration_office,
                        "administration_office_name": _warehouseData[index].administration_office_name,
                        "administration_office_address": _warehouseData[index].administration_office_address,
                        "id_operating_area": _warehouseData[index].id_operating_area,
                        "operating_area_name": _warehouseData[index].operating_area_name,
                        "id_province": _warehouseData[index].id_province,
                        "province_name": _warehouseData[index].province_name,
                        "id_country": _warehouseData[index].id_country,
                        "country_name": _warehouseData[index].country_name,
                        "lat": _warehouseData[index].lat,
                        "lng": _warehouseData[index].lng,
                        "area_size": _warehouseData[index].area_size,
                        "height": _warehouseData[index].height,
                        "full_capacity_cubic": _warehouseData[index].full_capacity_cubic
                    }
                    _stckWarehouseSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckWarehouseData: action.payload, success: true, stckTotalWarehouse: _totalWarehouse, stckWarehouseSelect: _stckWarehouseSelect, inProgress: false})
        case FAILED_STCK_WAREHOUSE_LIST:
            return ({stckWarehouseData: [], success: false, stckTotalWarehouse: 0, stckWarehouseSelect: [], inProgress: false})
        default:
            return state
    }
}
