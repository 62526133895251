import { FETCH_FUEL_REPORT, RECEIVE_FUEL_REPORT, FAILED_FUEL_REPORT, RESET_FUEL_REPORT } from '../../actions/transporter/aTransporter-FuelReport.jsx'
import moment from 'moment';

const defaultFuelReportState = {
    fuelReportTableData: [],
    fuelReportMaps: [],
    inProgress: false,
    success: true
}

export function fetchFuelReport(state = defaultFuelReportState, action){
    switch (action.type) {
        case FETCH_FUEL_REPORT:
            return ({fuelReportTableData: [], fuelReportMaps: [], inProgress: true, success: true})
        case RECEIVE_FUEL_REPORT:
            var list = []
            if(action.payload.result !== []) {
                var fuelData = action.payload.result

                fuelData.forEach((p, index) => {
                    if(fuelData[index].lat !== null || fuelData[index].lng !== null) {
                        var data = {
                            'id': fuelData[index].id,
                            // 'imei': fuelData[index].imei,
                            'lat': fuelData[index].lat,
                            'lng': fuelData[index].lon,
                            'level_agg': fuelData[index].level_agg,
                            'bench': fuelData[index].bench,
                            // 'spd': fuelData[index].spd,
                            // 'ign': fuelData[index].ign,
                            // 'travel': fuelData[index].travel,
                            'theft_alert': fuelData[index].theft_alert,
                            'fill_alert': fuelData[index].fill_alert,
                            'time': moment(fuelData[index].time).format("DD-MM-YYYY HH:mm:ss")
                        }
                        list.push(data)
                    }
                })
            }
            return Object.assign({}, state, {fuelReportTableData: action.payload, fuelReportMaps: list, inProgress: false, success: true})
        case FAILED_FUEL_REPORT:
            return ({fuelReportTableData: [], fuelReportMaps: [], inProgress: false, success: false})
        case RESET_FUEL_REPORT:
            return ({fuelReportTableData: [], fuelReportMaps: [], inProgress: false, success: true})
        default:
            return state
    }
}