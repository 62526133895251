import { FETCH_PACKAGE_MATERIAL_TYPE_LIST, RECEIVE_PACKAGE_MATERIAL_TYPE_LIST, FAILED_PACKAGE_MATERIAL_TYPE_LIST } from '../../actions/stocker/stck.packagematerial.act'

const defaultPackageMaterialTypeState = {
    stckPackageMaterialTypeData: [],
    stckTotalPackageMaterialType: 0,
    stckPackageMaterialTypeSelect: [],
    success: true,
    inProgress: false,
}

export function StckPackageMaterialType(state = defaultPackageMaterialTypeState, action){
    switch (action.type) {
        case FETCH_PACKAGE_MATERIAL_TYPE_LIST:
            return ({stckPackageMaterialTypeData: [], success: true, stckTotalPackageMaterialType: 0, stckPackageMaterialTypeSelect: [], inProgress: true})
        case RECEIVE_PACKAGE_MATERIAL_TYPE_LIST:
            var _totalUnits = action.payload.result.length;
            var _stckPackageMaterialTypeSelect = []
            if(action.payload.result !== []) {
                var _PackageMaterialTypeData = action.payload.result
                _PackageMaterialTypeData.forEach((p, index) => {
                    var _loc = {
                        'label': _PackageMaterialTypeData[index].name,
                        'value': _PackageMaterialTypeData[index].id
                    }
                    _stckPackageMaterialTypeSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckPackageMaterialTypeData: action.payload, success: true, stckTotalPackageMaterialType: _totalUnits, stckPackageMaterialTypeSelect: _stckPackageMaterialTypeSelect, inProgress: false})
        case FAILED_PACKAGE_MATERIAL_TYPE_LIST:
            return ({stckPackageMaterialTypeData: [], success: false, stckTotalPackageMaterialType: 0, stckPackageMaterialTypeSelect: [], inProgress: false})
        default:
            return state
    }
}