import {FETCH_CHECK_SHEET_LIST, RECEIVE_CHECK_SHEET_LIST, FAILED_CHECK_SHEET_LIST,
    FETCH_CHECK_SHEET_DETAIL_LIST, RECEIVE_CHECK_SHEET_DETAIL_LIST, FAILED_CHECK_SHEET_DETAIL_LIST,
    FETCH_CHECK_SHEET_APPROVAL_TYPE_LIST, RECEIVE_CHECK_SHEET_APPROVAL_TYPE_LIST, FAILED_CHECK_SHEET_APPROVAL_TYPE_LIST,
    FETCH_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST, RECEIVE_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST, FAILED_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST,
    FETCH_CHECK_SHEET_BINDING_DO_SETTING_LIST, RECEIVE_CHECK_SHEET_BINDING_DO_SETTING_LIST, FAILED_CHECK_SHEET_BINDING_DO_SETTING_LIST,
    FETCH_CHECK_SHEET_DO_DETAIL_LIST, RECEIVE_CHECK_SHEET_DO_DETAIL_LIST, FAILED_CHECK_SHEET_DO_DETAIL_LIST,
    FETCH_CHECK_SHEET_DO_APPROVAL_LIST, RECEIVE_CHECK_SHEET_DO_APPROVAL_LIST, FAILED_CHECK_SHEET_DO_APPROVAL_LIST
} from '../../actions/transporter/check-sheet.act'
        
function getSelectFromPayload(payloadData, settings){
    let result = []
    if(!payloadData)
        return result
    if(!Array.isArray(payloadData))
        return result
    let labelField = settings.labelField
    let valueField = settings.valueField
    payloadData.forEach(data => {
        result.push({
            label: data[labelField],
            value: data[valueField]
        })
    })
    return result
}


const defaultCheckSheetState = {
    totalCheckSheets:0,
    checkSheets: [],
    checkSheetsSelect: [],
    success: true,  
    inProgress: false
}

export function CheckSheets(state = defaultCheckSheetState, action){
    switch (action.type) {
        case FETCH_CHECK_SHEET_LIST:
            return ({checkSheets: [], success: true, checkSheetsSelect: [],
                    inProgress: true, totalCheckSheets:0})
        case RECEIVE_CHECK_SHEET_LIST:
            const {payload} = action
            let checkSheetsSelect = getSelectFromPayload(
                payload.result, {labelField:"check_sheet_name", valueField: "id"}
            )
            return Object.assign({}, state, {checkSheets: action.payload.result,
                                                checkSheetsSelect: checkSheetsSelect,
                                                totalCheckSheets: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_CHECK_SHEET_LIST:
            return ({checkSheets: [], success: false, checkSheetsSelect: [],
                    inProgress: false, totalCheckSheets:0,})
        default:
            return state
    }
}

const defaultCheckSheetDetailState = {
    totalCheckSheetDetails:0,
    checkSheetsDetail: [],
    checkSheetsDetailSelect: [],
    success: true,  
    inProgress: false
}

export function CheckSheetsDetail(state = defaultCheckSheetDetailState, action){
    switch (action.type) {
        case FETCH_CHECK_SHEET_DETAIL_LIST:
            return ({checkSheetsDetail: [], success: true, checkSheetsDetailSelect: [],
                    inProgress: true, totalCheckSheetDetails:0})
        case RECEIVE_CHECK_SHEET_DETAIL_LIST:
            const {payload} = action
            let checkSheetsDetailSelect = getSelectFromPayload(
                payload.result, {labelField:"activity_name", valueField: "id"}
            )
            return Object.assign({}, state, {checkSheetsDetail: action.payload.result,
                                                checkSheetsDetailSelect: checkSheetsDetailSelect,
                                                totalCheckSheetDetails: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_CHECK_SHEET_DETAIL_LIST:
            return ({checkSheetsDetail: [], success: false, checkSheetsDetailSelect: [],
                    inProgress: false, totalCheckSheetDetails:0,})
        default:
            return state
    }
}

const defaultCheckSheetApprovalTypeState = {
    totalCheckSheetApprovalTypes:0,
    checkSheetsApprovalType: [],
    checkSheetsApprovalTypeSelect: [],
    success: true,  
    inProgress: false
}

export function CheckSheetApprovalType(state = defaultCheckSheetApprovalTypeState, action){
    switch (action.type) {
        case FETCH_CHECK_SHEET_APPROVAL_TYPE_LIST:
            return ({checkSheetsApprovalType: [], success: true, checkSheetsApprovalTypeSelect: [],
                    inProgress: true, totalCheckSheetApprovalTypes:0})
        case RECEIVE_CHECK_SHEET_APPROVAL_TYPE_LIST:
            const {payload} = action
            let checkSheetsApprovalTypeSelect = getSelectFromPayload(
                payload.result, {labelField:"name", valueField: "id"}
            )
            return Object.assign({}, state, {checkSheetsApprovalType: action.payload.result,
                                                checkSheetsApprovalTypeSelect: checkSheetsApprovalTypeSelect,
                                                totalCheckSheetApprovalTypes: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_CHECK_SHEET_APPROVAL_TYPE_LIST:
            return ({checkSheetsApprovalType: [], success: false, checkSheetsApprovalTypeSelect: [],
                    inProgress: false, totalCheckSheetApprovalTypes:0,})
        default:
            return state
    }
}

const defaultCheckSheetApprovalSettingState = {
    totalCheckSheetApprovalSettings:0,
    checkSheetsApprovalSetting: [],
    success: true,  
    inProgress: false
}

export function CheckSheetApprovalSetting(state = defaultCheckSheetApprovalSettingState, action){
    switch (action.type) {
        case FETCH_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST:
            return ({checkSheetsApprovalSetting: [], success: true, 
                    inProgress: true, totalCheckSheetApprovalSettings:0})
        case RECEIVE_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST:
            const {payload} = action
            return Object.assign({}, state, {checkSheetsApprovalSetting: payload.result,
                                                totalCheckSheetApprovalSettings: payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST:
            return ({checkSheetsApprovalSetting: [], success: false, 
                    inProgress: false, totalCheckSheetApprovalSettings:0,})
        default:
            return state
    }
}

const defaultCheckSheetBindingDoState = {
    totalCheckSheetBindingDos:0,
    checkSheetsBindingDo: [],
    success: true,  
    inProgress: false
}

export function CheckSheetBindingDo(state = defaultCheckSheetBindingDoState, action){
    switch (action.type) {
        case FETCH_CHECK_SHEET_BINDING_DO_SETTING_LIST:
            return ({checkSheetsBindingDo: [], success: true, 
                    inProgress: true, totalCheckSheetBindingDos:0})
        case RECEIVE_CHECK_SHEET_BINDING_DO_SETTING_LIST:
            const {payload} = action
            return Object.assign({}, state, {checkSheetsBindingDo: payload.result,
                                                totalCheckSheetBindingDos: payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_CHECK_SHEET_BINDING_DO_SETTING_LIST:
            return ({checkSheetsBindingDo: [], success: false, 
                    inProgress: false, totalCheckSheetBindingDos:0,})
        default:
            return state
    }
}

const defaultCheckSheetDoDetailState = {
    totalCheckSheetDoDetails:0,
    totalCheckSheetDoDetailsChecked:0,
    totalCheckSheetDoDetailsReported:0,
    checkSheetDoDetail: [],
    success: true,  
    inProgress: false
}

export function CheckSheetDoDetail(state = defaultCheckSheetDoDetailState, action){
    switch (action.type) {
        case FETCH_CHECK_SHEET_DO_DETAIL_LIST:
            return ({checkSheetDoDetail: [], success: true, 
                    totalCheckSheetDoDetailsChecked: 0, totalCheckSheetDoDetailsReported: 0,
                    inProgress: true, totalCheckSheetDoDetails:0})
        case RECEIVE_CHECK_SHEET_DO_DETAIL_LIST:
            const {payload} = action
            let checkSheetDoTotal = 0
            let checkedDetail = 0
            let reportedDetail = 0
            if(payload.result){
                checkSheetDoTotal= payload.result.length
                checkedDetail = payload.result.filter(r => 
                    r.checked === 1
                ).length
                reportedDetail = payload.result.filter(r => 
                    r.checked === 0 || r.checked === null
                ).length
            }
            return Object.assign({}, state, {checkSheetDoDetail: payload.result,
                                                totalCheckSheetDoDetailsChecked: checkedDetail,
                                                totalCheckSheetDoDetailsReported: reportedDetail,
                                                totalCheckSheetDoDetails: checkSheetDoTotal,
                                                success: true, inProgress: false})
        case FAILED_CHECK_SHEET_DO_DETAIL_LIST:
            return ({checkSheetDoDetail: [], success: false, 
                    totalCheckSheetDoDetailsChecked: 0, totalCheckSheetDoDetailsReported: 0,
                    inProgress: false, totalCheckSheetDoDetails:0,})
        default:
            return state
    }
}

function approvalCheck(userApprovalList, userApprovalSetting){
    if(!userApprovalList || !userApprovalSetting)
        return false
    if(userApprovalList.length === 0 || userApprovalSetting.length === 0)
        return false
    if(userApprovalList.length !== userApprovalSetting.length)
        return false
    // let approvalList = userApprovalList.map(d => d.id_user) 
    // let approvalSettings = userApprovalSetting.map(d => d.id_user) 
    // const intersection = approvalSettings.filter(element => approvalList.includes(element));
    // console.log(intersection)
    // if(intersection)
    //     return true
    return true
}

const defaultCheckSheetApprovalsState = {
    totalCheckSheetApprovals:0,
    checkSheetsApprovals: [],
    isApprovalComplete: false,
    success: true,  
    inProgress: false
}

export function CheckSheetApprovals(state = defaultCheckSheetApprovalsState, action){
    switch (action.type) {
        case FETCH_CHECK_SHEET_DO_APPROVAL_LIST:
            return ({checkSheetsApprovals: [], success: true, 
                    isApprovalComplete: false,
                    inProgress: true, totalCheckSheetApprovals:0})
        case RECEIVE_CHECK_SHEET_DO_APPROVAL_LIST:
            const {payload} = action
            let userApprovalList = payload["users_approval_list"].result
            let userApprovalSetting = payload["users_approval_setting"].result
            let isApprovalComplete = approvalCheck(userApprovalList, userApprovalSetting)
            return Object.assign({}, state, {checkSheetsApprovals: userApprovalList,
                                                isApprovalComplete: isApprovalComplete,
                                                totalCheckSheetApprovals: userApprovalList.length,
                                                success: true, inProgress: false})
        case FAILED_CHECK_SHEET_DO_APPROVAL_LIST:
            return ({checkSheetsApprovals: [], success: false, 
                    isApprovalComplete: false,
                    inProgress: false, totalCheckSheetApprovals:0,})
        default:
            return state
    }
}