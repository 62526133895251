const permissionsByLevels = [

    {

        level : '1',

        permissions : {

            can : [

                //Main Customer Datatable
                { action : 'view', subject : 'register_new_customer_button@main_customer_datatable'},
                { action : 'view', subject : 'customer_detail_button_group@main_customer_datatable'},
                { action : 'view', subject : 'import_csv_button@main_customer_datatable'},

                //Delivery Order
                { action : 'view', subject : 'add_delivery_order_button@delivery_orders'},

                //Driver Datatable
                { action : 'view', subject : 'edit_button@driver_datatable'},

                //Fleet Maintenance Datatable
                { action : 'view', subject : 'actual_maintenance_button@fleet_maintenance_datatable'},
                { action : 'view', subject : 'register_fleet_maintenance_button@fleet_maintenance_datatable'},

                //Fleets
                { action : 'view', subject : 'add_fleet_button@fleets'},

                //Fleets Datatable
                { action : 'view', subject : 'edit_button@fleets_datatable'},
                { action : 'view', subject : 'bind_obd_button@fleets_datatable'},

                //WO Review Datatable
                { action : 'view', subject : 'security_approval_button@wo_review_datatable'},

                //Saved Place
                { action : 'view', subject : 'add_button@saved_places'},
                { action : 'view', subject : 'view_only@saved_places'},
                { action : 'view', subject : 'import_csv_button@saved_places_datatable'},

                //Security
                { action : 'view', subject : 'add_button@security'},

                //Work Order New Datatable
                { action : 'view', subject : 'refresh_data_or_add_work_order_button@work_order_new_datatable' },
                { action : 'view', subject : 'delete_button@work_order_new_datatable' },

                //Work Orders Datatable
                { action : 'view', subject : 'view_detail_button@work_order_datatable' },
                { action : 'view', subject : 'edit_button@work_order_datatable' },

                //TriPL Bidding Datatable
                {action : 'view', subject : 'bid_button@tripl_bidding_datatable'},

                //TriPL Request Datatable
                {action : 'view', subject : 'add_work_order_req_button@tripl_request_datatable'},

                //TriPL Bid Datatable
                {action : 'view', subject : 'approve_and_decline_button@tripl_wo_incoming_bid_datatable'},

                //Delivery Orders Datatable
                {action : 'view', subject : 'action_table_content_1@delivery_orders_datatable'},

                //Work Orders New
                {action : 'view', subject : 'work_orders_new_settings@work_orders_new'},
                { action : 'view', subject : 'printwo@work_order_datatable'},

                //Work Order Widget
                {action : 'view', subject : 'security_approval_button@work_order_widget'},
                {action : 'view', subject : 'edit_delete_wo_list@work_order_widget'},

                //Fuel Management
                {action : 'view', subject : 'add_fuel_fill_button@fuel_management_datatable'},

                //CovidTest and Vaccine
                {action : 'view', subject : 'add_covid@covid_related'},

                //Scheduled Maintenance
                {action : 'view', subject : 'update_schedule_maintenance@schedule_maintenance'},
                {action : 'view', subject : 'bind_sm_mechanic@schedule_maintenance'},
                {action : 'view', subject : 'sm_mechanic_task@schedule_maintenance'},

                {action : 'view', subject : 'update_sm_mechanic_task@schedule_maintenance'},
                {action : 'view', subject : 'delete_sm_mechanic_task@schedule_maintenance'},

                {action : 'view', subject : 'workshop_approval@schedule_maintenance'},
                {action : 'view', subject : 'client_approval@schedule_maintenance'},

                //WO Image Compare
                {action : 'view', subject : 'admin@wo_image_compare'},

                //Stocker Opname
                {action : 'view', subject : 'stocker_create_opname_task@index'},

            ]

        },

    },

    {

        level : '2',

        permissions : {

            can : [

                //Fleet Maintenance Datatable
                { action : 'view', subject : 'actual_maintenance_button@fleet_maintenance_datatable'},
                { action : 'view', subject : 'register_fleet_maintenance_button@fleet_maintenance_datatable'},

                //Security Datatable
                { action : 'view', subject : 'security_approval_button@security_datatable'},

                //Saved Place
                { action : 'view', subject : 'approval_or_view@saved_places'},

                //Work Order New Datatable
                { action : 'view', subject : 'refresh_data_or_add_work_order_button@work_order_new_datatable' },

                //Work Orders Datatable
                { action : 'view', subject : 'view_detail_button@work_order_datatable' },
                { action : 'view', subject : 'printwo@work_order_datatable'},

                //Delivery Orders Datatable
                {action : 'view', subject : 'action_table_content_2@delivery_orders_datatable'},

                //Fuel Management Datatable
                {action : 'view', subject : 'fuel_management_approval_button@fuel_management_datatable'},
                {action : 'view', subject : 'add_fuel_fill_button@fuel_management_datatable'},

                //Scheduled Maintenance
                {action : 'view', subject : 'update_schedule_maintenance@schedule_maintenance'},
                {action : 'view', subject : 'delete_schedule_maintenance@schedule_maintenance'},
                {action : 'view', subject : 'bind_sm_mechanic@schedule_maintenance'},
                {action : 'view', subject : 'sm_mechanic_task@schedule_maintenance'},

                {action : 'view', subject : 'update_sm_mechanic_task@schedule_maintenance'},
                {action : 'view', subject : 'delete_sm_mechanic_task@schedule_maintenance'},

                {action : 'view', subject : 'workshop_approval@schedule_maintenance'},
                {action : 'view', subject : 'client_approval@schedule_maintenance'},

                {action : 'view', subject : 'approve_sm_mechanic_task_detail@schedule_maintenance'},
                {action : 'view', subject : 'delete_sm_mechanic_task_detail@schedule_maintenance'},

                //Check Sheet Detail
                {action : 'view', subject : 'approval_button@check_sheet_detail'},

                //Stocker Opname
                {action : 'view', subject : 'stocker_create_opname_task@index'},
            ]

        },

    },

    {

        level : '3',

        permissions : {

            can : [

                //Work Orders Datatable
                { action : 'view', subject : 'view_detail_button@work_order_datatable' },
                { action : 'view', subject : 'security_approval_button@work_order_datatable' },

                //Saved Place
                { action : 'view', subject : 'view_only@saved_places'},

                //WO Review Datatable
                { action : 'view', subject : 'security_approval_button@wo_review_datatable'},

                //Delivery Orders Datatable
                {action : 'view', subject : 'action_table_content_3@delivery_orders_datatable'},

                //Work Order Widget
                {action : 'view', subject : 'security_approval_button@work_order_widget'},

                //Work Order Image Upload
                {action : 'view', subject : 'pod_upload@pod_button'},
                {action : 'view', subject : 'edit_pod_upload@pod_button'},

                //Check Sheet Detail
                {action : 'view', subject : 'approval_button@check_sheet_detail'},

            ]

        },

    },

    {

        level : '4',

        permissions : {

            can : [
                
                //Main Customer Datatable
                { action : 'view', subject : 'register_new_customer_button@main_customer_datatable'},
                { action : 'view', subject : 'customer_detail_button_group@main_customer_datatable'},
                { action : 'view', subject : 'delete_customer_button@main_customer_datatable'},
                { action : 'view', subject : 'import_csv_button@main_customer_datatable'},

                //Setting Menu
                { action : 'view', subject : 'transporter_reminder_and_company_component@settings'},

                //Delivery Order
                { action : 'view', subject : 'add_delivery_order_button@delivery_orders'},
            
                //Driver Assistant Datatable
                { action : 'view', subject : 'edit_and_delete_button@drivers_assistant_datatable'},

                //Driver Datatable
                { action : 'view', subject : 'edit_button@driver_datatable'},
                { action : 'view', subject : 'delete_button@driver_datatable'},

                //Fleet Maintenance Datatable
                { action : 'view', subject : 'actual_maintenance_button@fleet_maintenance_datatable'},
                { action : 'view', subject : 'register_fleet_maintenance_button@fleet_maintenance_datatable'},
                { action : 'view', subject : 'edit_fleet_maintenance_button@fleet_maintenance_datatable'},
                { action : 'view', subject : 'delete_fleet_maintenance_button@fleet_maintenance_datatable'},

                //Fleets Datatable
                { action : 'view', subject : 'add_fleet_button@fleets'},
                { action : 'view', subject : 'edit_button@fleets_datatable'},
                { action : 'view', subject : 'bind_obd_button@fleets_datatable'},
                { action : 'view', subject : 'delete_button@fleets_datatable'},
                { action : 'view', subject : 'unbind_obd_button@fleets_datatable'},

                //Saved Place
                { action : 'view', subject : 'add_button@saved_places'},
                { action : 'view', subject : 'approval_or_view_or_edit_or_delete@saved_places_datatable'},
                { action : 'view', subject : 'import_csv_button@saved_places_datatable'},
                { action : 'view', subject : 'export_csv_button@saved_places_datatable'},

                //Security Datatable
                { action : 'view', subject : 'security_delete_button@security_datatable'},
                
                //Security
                { action : 'view', subject : 'add_button@security'},

                //WO Review Datatable
                { action : 'view', subject : 'security_approval_button@wo_review_datatable'},
                { action : 'reset', subject : 'security_reset_approval_button@wo_review_datatable'},

                //WO Review Datatable
                { action : 'view', subject : 'security_approval_button@wo_review_datatable'},
                
                //Work Order New Datatable
                { action : 'view', subject : 'refresh_data_or_add_work_order_button@work_order_new_datatable' },
                { action : 'view', subject : 'delete_button@work_order_new_datatable' },

                //Work Orders Datatable
                { action : 'view', subject : 'view_detail_button@work_order_datatable' },
                { action : 'view', subject : 'security_approval_button@work_order_datatable' },
                { action : 'view', subject : 'edit_button@work_order_datatable'},
                { action : 'view', subject : 'delete_button@work_order_datatable'},
                { action : 'view', subject : 'printwo@work_order_datatable'},

                //TriPL Bidding Datatable
                {action : 'view', subject : 'bid_button@tripl_bidding_datatable'},

                //TriPL Request Datatable
                {action : 'view', subject : 'add_work_order_req_button@tripl_request_datatable'},

                //TriPL Bid Datatable
                {action : 'view', subject : 'approve_and_decline_button@tripl_wo_incoming_bid_datatable'},

                //Delivery Orders Datatable
                {action : 'view', subject : 'action_table_content_4@delivery_orders_datatable'},

                //Work Orders New
                {action : 'view', subject : 'work_orders_new_settings@work_orders_new'},

                //Work Order Widget
                {action : 'view', subject : 'security_approval_button@work_order_widget'},
                {action : 'view', subject : 'edit_delete_wo_list@work_order_widget'},
                { action : 'reset', subject : 'security_reset_approval_button@work_order_widget' },

                //Management Datatable
                {action : 'view', subject : 'lock_unclock_and_reset_password_button@management_datatable'},
                
                //Management
                {action : 'view', subject : 'management_add_user@management'},
                {action : 'view', subject : 'card@management'},

                //Fuel Management Datatable
                {action : 'view', subject : 'fuel_management_approval_button@fuel_management_datatable'},
                {action : 'view', subject : 'add_fuel_fill_button@fuel_management_datatable'},

                //CovidTest and Vaccine
                {action : 'view', subject : 'add_covid@covid_related'},

                //3PL Grouping
                {action : 'view', subject : 'delete_group@tripl_group'},

                //Work Order Image Upload
                {action : 'view', subject : 'pod_upload@pod_button'},
                {action : 'view', subject : 'pod_delete@pod_button'},
                {action : 'view', subject : 'edit_pod_upload@pod_button'},

                //Driver App User
                {action : 'view', subject : 'add_driver_app_user@driver_app_user'},
                {action : 'view', subject : 'manage_driver@driver_app_user'},
                {action : 'view', subject : 'edit_driver_app_user@driver_app_user'},
                {action : 'view', subject : 'delete_driver_app_user@driver_app_user'},

                //Mechanics
                {action : 'view', subject : 'view_mechanic_certification@mechanic'},
                {action : 'view', subject : 'view_mechanic_specialization@mechanic'},
                {action : 'view', subject : 'view_mechanic_operational_hour@mechanic'},

                //Workshop
                {action : 'view', subject : 'update_workshop@workshop'},
                {action : 'view', subject : 'delete_workshop@workshop'},
                {action : 'view', subject : 'view_workshop_operational_hour@workshop'},

                //Scheduled Maintenance
                {action : 'view', subject : 'update_schedule_maintenance@schedule_maintenance'},
                {action : 'view', subject : 'delete_schedule_maintenance@schedule_maintenance'},
                {action : 'view', subject : 'bind_sm_mechanic@schedule_maintenance'},
                {action : 'view', subject : 'sm_mechanic_task@schedule_maintenance'},

                {action : 'view', subject : 'update_sm_mechanic_task@schedule_maintenance'},
                {action : 'view', subject : 'delete_sm_mechanic_task@schedule_maintenance'},

                {action : 'view', subject : 'workshop_approval@schedule_maintenance'},
                {action : 'view', subject : 'client_approval@schedule_maintenance'},
                
                {action : 'view', subject : 'approve_sm_mechanic_task_detail@schedule_maintenance'},
                {action : 'view', subject : 'delete_sm_mechanic_task_detail@schedule_maintenance'},

                //Check Sheet Detail
                {action : 'view', subject : 'approval_button@check_sheet_detail'},

                //WO Image Compare
                {action : 'view', subject : 'super_admin@wo_image_compare'},

                //====================Stocker===============================
                {action : 'view', subject : 'adjt_button@master_item_adjt'},

                //Stocker Opname
                {action : 'view', subject : 'stocker_create_opname_task@index'},

            ]

        },

    },

    {

        level : '5',

        permissions : {

            can : [

                //Main Customer Datatable
                { action : 'view', subject : 'register_new_customer_button@main_customer_datatable'},
                { action : 'view', subject : 'customer_detail_button_group@main_customer_datatable'},
                { action : 'view', subject : 'delete_customer_button@main_customer_datatable'},
                { action : 'view', subject : 'company_field@main_customer_datatable'},
                { action : 'view', subject : 'import_csv_button@main_customer_datatable'},

                //Driver Assistant
                { action : 'view', subject : 'delete button'},
                
                //Driver Assistant Datatable
                { action : 'view', subject : 'edit_button@driver_datatable'},
                { action : 'view', subject : 'edit_and_delete_button@drivers_assistant_datatable'},
                { action : 'view', subject : 'company_field@drivers_assistant_datatable'},


                //Driver Datatable
                { action : 'view', subject : 'company_field@drivers_datatable'},

                //Fleet Maintenance Datatable
                { action : 'view', subject : 'actual_maintenance_button@fleet_maintenance_datatable'},
                { action : 'view', subject : 'register_fleet_maintenance_button@fleet_maintenance_datatable'},
                { action : 'view', subject : 'company_field@fleet_maintenance_datatable'},
                
                //Fleets Datatable
                { action : 'view', subject : 'edit_button@fleets_datatable'},
                { action : 'view', subject : 'bind_obd_button@fleets_datatable'},
                { action : 'view', subject : 'delete_button@fleets_datatable'},
                { action : 'view', subject : 'unbind_obd_button@fleets_datatable'},
                { action : 'view', subject : 'company_field@fleets_datatable'},

                //Saved Places
                { action : 'view', subject : 'add_button@saved_places'},
                { action : 'view', subject : 'import_csv_button@saved_places_datatable'},
                { action : 'view', subject : 'export_csv_button@saved_places_datatable'},

                //Saved Places Datatable
                { action : 'view', subject : 'approval_or_view_or_edit_or_delete@saved_places_datatable'},
                { action : 'view', subject : 'company_field@saved_places_datatable'},

                //Security Datatable
                { action : 'view', subject : 'security_delete_button@security_datatable'},

                //Live Monitoring Datatable
                { action : 'view', subject : 'company_field@live_monitoring_datatable'},

                //WO Review Datatable
                { action : 'view', subject : 'company_field@wo_review_datatable'},

                //Security Datatable
                { action : 'view', subject : 'company_field@security_datatable'},

                //WO Review Datatable
                { action : 'view', subject : 'security_approval_button@wo_review_datatable'},

                //Work Order New Datatable
                { action : 'view', subject : 'company_field@work_order_new_datatable'},

                //Work Orders Datatable
                { action : 'view', subject : 'view_detail_button@work_order_datatable' },
                { action : 'view', subject : 'edit_button@work_order_datatable' },
                { action : 'view', subject : 'delete_button@work_order_datatable' },
                { action : 'view', subject : 'company_field@work_order_datatable'},

                //TriPL Request Datatable
                {action : 'view', subject : 'company_field@tripl_request_datatable'},

                //Delivery Orders Datatable
                {action : 'view', subject : 'company_field@delivery_orders_datatable'},
                {action : 'view', subject : 'action_table_content_5@delivery_orders_datatable'},

                //Work Orders New
                {action : 'view', subject : 'work_orders_new_settings@work_orders_new'},

                //Work Order Widget
                {action : 'view', subject : 'security_approval_button@work_order_widget'},
                {action : 'view', subject : 'edit_delete_wo_list@work_order_widget'},
                { action : 'reset', subject : 'security_reset_approval_button@work_order_widget' },

                //Fuel Management
                {action : 'view', subject : 'add_fuel_fill_button@fuel_management_datatable'},

                //CovidTest and Vaccine
                {action : 'view', subject : 'add_covid@covid_related'},
            ]

        },

    },

    {

        level : '6',

        permissions : {

            can : [
                //Scheduled Maintenance
                {action : 'view', subject : 'update_schedule_maintenance@schedule_maintenance'},
                {action : 'view', subject : 'sm_mechanic_task@schedule_maintenance'},
            ]

        },
    },

    {

        level : '7',

        permissions : {

            can : [
                //Scheduled Maintenance
                {action : 'view', subject : 'update_schedule_maintenance@schedule_maintenance'},
                {action : 'view', subject : 'delete_schedule_maintenance@schedule_maintenance'},
                {action : 'view', subject : 'bind_sm_mechanic@schedule_maintenance'},
                {action : 'view', subject : 'sm_mechanic_task@schedule_maintenance'},

                {action : 'view', subject : 'update_sm_mechanic_task@schedule_maintenance'},

                {action : 'view', subject : 'workshop_approval@schedule_maintenance'},

                {action : 'view', subject : 'approve_sm_mechanic_task_detail@schedule_maintenance'},
                {action : 'view', subject : 'delete_sm_mechanic_task_detail@schedule_maintenance'},
            ]

        },

    },
    {

        level : '8',

        permissions : {

            can : [
     
            ]

        },

    }
    

]

const permissionsBySubcriptions = [

    {

        subs : 'FREE',

        permissions : {

            can : [

                //Total Driver
                { action : 'create', subject: 'max_10_free@total_drivers_subs'},

                //Total Fleet
                { action : 'create', subject: 'max_5_free@total_fleets_subs'},

                //Total customer
                { action : 'create', subject: 'max_200_free@total_customers_subs'},

                //User Amount
                { action : 'create', subject: 'max_1_free@total_users_subs'},

                //User Level
                { action : 'view', subject : 'max_1_free@management_add_user_subs'},

                //Fleet History
                { action : 'search', subject: 'max_5_days@fleet_history_subs'},

                //Advanced Schedule
                { action : 'create', subject: 'max_5_days@fleet_maintenance_subs'},

                //Utility Report
                { action : 'create', subject: 'max_5_days@send_report_subs'},

            ]

        },

    },

    {

        subs : 'BASIC',

        permissions : {

            can : [

                //Total Driver
                { action : 'create', subject: 'max_10_basic@total_drivers_subs'},

                //Total Fleet
                { action : 'create', subject: 'max_5_basic@total_fleets_subs'},

                //Total customer
                { action : 'create', subject: 'max_200_basic@total_customers_subs'},

                //User Amount
                { action : 'create', subject: 'max_2_basic@total_users_subs'},

                //DDOR
                { action : 'view', subject : 'daily_report_switch@settings_subs'},

                //Disconected Report   
                { action : 'view', subject : 'disconnected_report_switch@settings_subs'},

                //Approval
                { action : 'view', subject : 'approval_button@saved_place_subs'},
                { action : 'view', subject : 'approval_button@fuel_management_subs'},

                //User Level
                { action : 'view', subject : 'max_1_basic@management_add_user_subs'},

                //Fleet History
                { action : 'search', subject: 'max_14_days@fleet_history_subs'},

                //Advanced Schedule
                { action : 'create', subject: 'max_30_days@fleet_maintenance_subs'},

                //Utility Report
                { action : 'create', subject: 'max_14_days@send_report_subs'},

                //CovidTest and Vaccine
                {action : 'view', subject : 'add_covid@covid_related_subs'},

            ]

        },

    },

    {

        subs : 'MEDIUM',

        permissions : {

            can : [

                //Total Driver
                { action : 'create', subject: 'max_20_medium@total_drivers_subs'},

                //Total Fleet
                { action : 'create', subject: 'max_10_medium@total_fleets_subs'},

                //Total customer
                { action : 'create', subject: 'max_500_medium@total_customers_subs'},

                //Security Datatable
                { action : 'view', subject : 'security_approval_button@security_datatable_subs'},

                //Work Orders Datatable
                { action : 'view', subject : 'security_approval_button@work_order_datatable_subs' },

                //Work Order Widget
                { action : 'view', subject : 'security_approval_button@work_order_widget_subs' },
                { action : 'reset', subject : 'security_reset_approval_button@work_order_widget_subs' },

                //Do Datatable
                { action : 'view', subject : 'security_approval_button@delivery_orders_subs' },

                //User Amount
                { action : 'create', subject: 'max_5_medium@total_users_subs'},

                //DDOR
                { action : 'view', subject : 'daily_report_switch@settings_subs'},

                //Disconected Report   
                { action : 'view', subject : 'disconnected_report_switch@settings_subs'},

                //Manager Approval
                { action : 'view', subject : 'approval_button@saved_place_subs'},
                { action : 'view', subject : 'approval_button@fuel_management_subs'},

                //User Level
                { action : 'view', subject : 'max_3_medium@management_add_user_subs'},

                //Fleet History
                { action : 'search', subject: 'max_30_days@fleet_history_subs'},

                //Advanced Schedule
                { action : 'create', subject: 'max_60_days@fleet_maintenance_subs'},

                //Shared Track
                { action : 'view', subject: 'share_wo_button@audit_widget_subs'},

                //Utility Report
                { action : 'create', subject: 'max_30_days@send_report_subs'},

                //CovidTest and Vaccine
                {action : 'view', subject : 'add_covid@covid_related_subs'},

            ]

        },

    },

    {

        subs : 'ADVANCED',

        permissions : {

            can : [

                //Total Driver
                { action : 'create', subject: 'max_advanced@total_drivers_subs'},

                //Total Fleet
                { action : 'create', subject: 'max_advanced@total_fleets_subs'},

                //Total customer
                { action : 'create', subject: 'max_advanced@total_customers_subs'},

                //Security Datatable
                { action : 'view', subject : 'security_approval_button@security_datatable_subs'},

                //Work Orders Datatable
                { action : 'view', subject : 'security_approval_button@work_order_datatable_subs' },

                //Work Order Widget
                { action : 'view', subject : 'security_approval_button@work_order_widget_subs' },
                { action : 'reset', subject : 'security_reset_approval_button@work_order_widget_subs' },

                //Do Datatable
                { action : 'view', subject : 'security_approval_button@delivery_orders_subs' },

                //User Amount
                { action : 'create', subject: 'max_10_advanced@total_users_subs'},

                //DDOR
                { action : 'view', subject : 'daily_report_switch@settings_subs'},

                //Disconected Report   
                { action : 'view', subject : 'disconnected_report_switch@settings_subs'},

                //Manager Approval
                { action : 'view', subject : 'approval_button@saved_place_subs'},
                { action : 'view', subject : 'approval_button@fuel_management_subs'},

                //User Level
                { action : 'view', subject : 'max_4_advanced@management_add_user_subs'},

                //Fleet History
                { action : 'search', subject: 'max_60_days@fleet_history_subs'},

                //Advanced Schedule
                { action : 'create', subject: 'max_90_days@fleet_maintenance_subs'},

                //Shared Track
                { action : 'view', subject: 'share_wo_button@audit_widget_subs'},

                //Utility Report
                { action : 'create', subject: 'max_60_days@send_report_subs'},

                //CovidTest and Vaccine
                {action : 'view', subject : 'add_covid@covid_related_subs'},

                //Main Customer
                { action : 'view', subject : 'import_csv_button@main_customer_datatable_subs'},

                //Saved Places
                { action : 'view', subject : 'import_csv_button@saved_places_datatable_subs'},

            ]

        },

    },

    {

        subs : 'STAND ALONE',

        permissions : {

            can : [

                    //Fleet History
                    { action : 'search', subject: 'max_365_days@fleet_history_subs'},

                    //Utility Report
                    { action : 'create', subject: 'max_365_days@send_report_subs'},

            ]

        },

    },


]

export {

    permissionsByLevels,
    permissionsBySubcriptions

}