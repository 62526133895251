import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import rootReducer from "./reducers";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { activeFeature } from "../constants/constants";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authStore"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function logger({ getState }) {
  return (next) => (action) => {
    if (activeFeature.REDUX_LOGGER) {
      console.log('will dispatch', action)
    }

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);

    if (activeFeature.REDUX_LOGGER) {
      console.log('state after dispatch', getState())
    }

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
}

const middleware = composeWithDevTools(
  applyMiddleware(promise(), thunk, logger)
);

// export default () => {
//     let store = createStore(persistedReducer, middleware)
//     let persistor = persistStore(store)
//     return { store, persistor }
//   }

export default createStore(persistedReducer, middleware);
