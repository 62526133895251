import { FETCH_MAIN_CUSTOMER_LIST, RECEIVE_MAIN_CUSTOMER_LIST, FAILED_MAIN_CUSTOMER_LIST } from '../../actions/transporter/aTransporter-MainCustomerList'

const defaultMainCustomerState = {
    mainCustomerData: [],
    totalMainCustomer: 0,
    mainCustomerSelect: [],
    success: true,
    inProgress: false,
}

export function fetchMainCustomerList(state = defaultMainCustomerState, action){
    switch (action.type) {
        case FETCH_MAIN_CUSTOMER_LIST:
            return ({mainCustomerData: [], success: true, totalMainCustomer: 0, mainCustomerSelect: [], inProgress: true})
        case RECEIVE_MAIN_CUSTOMER_LIST:
            var _totalSensorClass = action.payload.result.length;
            var _selectMainCustomer = []
            if(action.payload.result !== []) {
                var _customerData = action.payload.result
                _customerData.forEach((p, index) => {
                    var _loc = {
                        'label': _customerData[index].name + ' - ',
                        'value': _customerData[index].id,
                        'address': _customerData[index].main_address,
                        'handphone': _customerData[index].handphone
                    }
                    _selectMainCustomer.push(_loc)
                })
            }
            return Object.assign({}, state, {mainCustomerData: action.payload, success: true, totalMainCustomer: _totalSensorClass, mainCustomerSelect: _selectMainCustomer, inProgress: false})
        case FAILED_MAIN_CUSTOMER_LIST:
            return ({mainCustomerData: [], success: false, totalMainCustomer: 0, mainCustomerSelect: [], inProgress: false})
        default:
            return state
    }
}