import { FETCH_DELIVERY_CHECK_IN_LOG, RECEIVE_DELIVERY_CHECK_IN_LOG, FAILED_DELIVERY_CHECK_IN_LOG } from '../../actions/transporter/aTransporter-DeliveryCheckInLog'

const defaultDeliveryCheckInLogState = {
    deliveryCheckInLogData: []    
}

export function fetchDeliveryCheckInLog(state = defaultDeliveryCheckInLogState, action){
    switch (action.type) {
        case FETCH_DELIVERY_CHECK_IN_LOG:
            return ({deliveryCheckInLogData: [], inProgress: true})
        case RECEIVE_DELIVERY_CHECK_IN_LOG:
            return Object.assign({}, state, {deliveryCheckInLogData: action.payload, inProgress: false})
        case FAILED_DELIVERY_CHECK_IN_LOG:
            return ({deliveryCheckInLogData: [], inProgress: false})
        default:
            return state
    }
}