import { FETCH_STCK_OFFICER_TYPES_LIST, RECEIVE_STCK_OFFICER_TYPES_LIST, FAILED_STCK_OFFICER_TYPES_LIST } from '../../actions/stocker/stck.officertype.act'

const defaultStckOfficerTypesState = {
    stckOfficerTypesData: [],
    stckTotalOfficerTypes: 0,
    stckOfficerTypesSelect: [],
    success: true,
    inProgress: false,
}

export function StckOfficerTypes(state = defaultStckOfficerTypesState, action){
    switch (action.type) {
        case FETCH_STCK_OFFICER_TYPES_LIST:
            return ({stckOfficerTypesData: [], success: true, stckTotalOfficerTypes: 0, stckOfficerTypesSelect: [], inProgress: true})
        case RECEIVE_STCK_OFFICER_TYPES_LIST:
            var _totalOfficerTypes = action.payload.result.length;
            var _stckOfficerTypesSelect = []
            if(action.payload.result !== []) {
                var _officerTypeData = action.payload.result

                _officerTypeData.forEach((p, index) => {
                    var _loc = {
                        'label': _officerTypeData[index].officer_type + ' - ' + _officerTypeData[index].officer_desc,
                        'value': _officerTypeData[index].id
                    }
                    _stckOfficerTypesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckOfficerTypesData: action.payload, success: true, stckTotalOfficerTypes: _totalOfficerTypes, stckOfficerTypesSelect: _stckOfficerTypesSelect, inProgress: false})
        case FAILED_STCK_OFFICER_TYPES_LIST:
            return ({stckOfficerTypesData: [], success: false, stckTotalOfficerTypes: 0, stckOfficerTypesSelect: [], inProgress: false})
        default:
            return state
    }
}