import { FETCH_SM_APPROVAL_TYPE_TABLE, RECEIVE_SM_APPROVAL_TYPE_TABLE, FAILED_SM_APPROVAL_TYPE_TABLE,
    FETCH_SM_APPROVAL_LIST, RECEIVE_SM_APPROVAL_LIST, FAILED_SM_APPROVAL_LIST,
    FETCH_SM_APPROVAL_TYPE_SETTING_LIST, RECEIVE_SM_APPROVAL_TYPE_SETTING_LIST, FAILED_SM_APPROVAL_TYPE_SETTING_LIST
} from '../../actions/transporter/smapprovaltype.act'

const defaultSmApprovalTypeListState = {
    smApprovalTypeTotalData: 0,
    smApprovalTypeListData: [],
    inProgress: false,
    success: true,
    smApprovalTypeSelectData: [],
    completeSmApprovalTypeSelectData: []
}

export function SmApprovalTypeList(state = defaultSmApprovalTypeListState, action){
    switch (action.type) {
        case FETCH_SM_APPROVAL_TYPE_TABLE:
            return ({smApprovalTypeListData: [], smApprovalTypeTotalData: 0, success: false, inProgress: true, smApprovalTypeSelectData: [], completeSmApprovalTypeSelectData: []})
        case RECEIVE_SM_APPROVAL_TYPE_TABLE:
            // var _total = action.payload.result.length;
            var list = []
            if(action.payload.result.length !== 0) {
                var smApprovalTypeList = action.payload.result
                smApprovalTypeList.forEach((p, index) => {
                    var smApprovalType = {
                        'label': smApprovalTypeList[index].name,
                        'value': smApprovalTypeList[index].id
                    }
                    list.push(smApprovalType)
                })
            }
            return Object.assign({}, state, {smApprovalTypeListData: action.payload.result, smApprovalTypeTotalData: action.payload.result.length, success: true, inProgress: false, smApprovalTypeSelectData:list})
        case FAILED_SM_APPROVAL_TYPE_TABLE:
            return ({smApprovalTypeListData: [], smApprovalTypeTotalData: 0, success: false, inProgress: false})
        default:
            return state
    }
}

const defaultSmApprovalSettingState = {
    totalSmApprovalSettings:0,
    smsApprovalSetting: [],
    success: true,  
    inProgress: false
}

export function SmApprovalSetting(state = defaultSmApprovalSettingState, action){
    switch (action.type) {
        case FETCH_SM_APPROVAL_TYPE_SETTING_LIST:
            return ({smsApprovalSetting: [], success: true, 
                    inProgress: true, totalSmApprovalSettings:0})
        case RECEIVE_SM_APPROVAL_TYPE_SETTING_LIST:
            const {payload} = action
            return Object.assign({}, state, {smsApprovalSetting: payload.result,
                                                totalSmApprovalSettings: payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_SM_APPROVAL_TYPE_SETTING_LIST:
            return ({smsApprovalSetting: [], success: false, 
                    inProgress: false, totalSmApprovalSettings:0,})
        default:
            return state
    }
}

function approvalCheck(userApprovalList, userApprovalSetting){
    if(!userApprovalList || !userApprovalSetting)
        return false
    if(userApprovalList.length === 0 || userApprovalSetting.length === 0)
        return false
    if(userApprovalList.length !== userApprovalSetting.length)
        return false
    // let approvalList = userApprovalList.map(d => d.id_user) 
    // let approvalSettings = userApprovalSetting.map(d => d.id_user) 
    // const intersection = approvalSettings.filter(element => approvalList.includes(element));
    // console.log(intersection)
    // if(intersection)
    //     return true
    return true
}

const defaultSmApprovalsState = {
    totalSmApprovals:0,
    smsApprovals: [],
    isApprovalComplete: false,
    success: true,  
    inProgress: false
}

export function SmApprovals(state = defaultSmApprovalsState, action){
    switch (action.type) {
        case FETCH_SM_APPROVAL_LIST:
            return ({smsApprovals: [], success: true, 
                    isApprovalComplete: false,
                    inProgress: true, totalSmApprovals:0})
        case RECEIVE_SM_APPROVAL_LIST:
            const {payload} = action
            let userApprovalList = payload["users_approval_list"].result
            let userApprovalSetting = payload["users_approval_setting"].result
            let isApprovalComplete = approvalCheck(userApprovalList, userApprovalSetting)
            return Object.assign({}, state, {smsApprovals: userApprovalList,
                                                isApprovalComplete: isApprovalComplete,
                                                totalSmApprovals: userApprovalList.length,
                                                success: true, inProgress: false})
        case FAILED_SM_APPROVAL_LIST:
            return ({smsApprovals: [], success: false, 
                    isApprovalComplete: false,
                    inProgress: false, totalSmApprovals:0,})
        default:
            return state
    }
}