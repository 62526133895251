import { FETCH_REPACKAGING_LIST, RECEIVE_REPACKAGING_LIST, FAILED_REPACKAGING_LIST } from '../../actions/stocker/stck.repackaging.act'

const defaultStckRepackagingState = {
    stckRepackagingData: [],
    stckTotalRepackaging: 0,
    stckRepackagingSelect: [],
    success: true,
    inProgress: false,
}

export function StckRepackaging(state = defaultStckRepackagingState, action){
    switch (action.type) {
        case FETCH_REPACKAGING_LIST:
            return ({stckRepackagingData: [], success: true, stckTotalRepackaging: 0, stckRepackagingSelect: [], inProgress: true})
        case RECEIVE_REPACKAGING_LIST:
            var _totalUnits = action.payload.result.length;
            var _stckRepackagingSelect = []
            if(action.payload.result !== []) {
                var _repackagingsData = action.payload.result
                _repackagingsData.forEach((p, index) => {
                    var _loc = {
                        'label': _repackagingsData[index].code + " - " + _repackagingsData[index].name ,
                        'value': _repackagingsData[index].id
                    }
                    _stckRepackagingSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckRepackagingData: action.payload, success: true, stckTotalRepackaging: _totalUnits, stckRepackagingSelect: _stckRepackagingSelect, inProgress: false})
        case FAILED_REPACKAGING_LIST:
            return ({stckRepackagingData: [], success: false, stckTotalRepackaging: 0, stckRepackagingSelect: [], inProgress: false})
        default:
            return state
    }
}