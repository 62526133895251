import { FETCH_MECHANIC_OPERATIONALHOUR_TABLE, RECEIVE_MECHANIC_OPERATIONALHOUR_TABLE, FAILED_MECHANIC_OPERATIONALHOUR_TABLE } from '../../actions/transporter/mechanicOperationalHour.act'

const defaultMechanicOperationalHourListState = {
    mechanicsOperationalHourListData: [],
    inProgress: false,
    success: true
}

export function MechanicOperationalHourList(state = defaultMechanicOperationalHourListState, action){
    switch (action.type) {
        case FETCH_MECHANIC_OPERATIONALHOUR_TABLE:
            return ({mechanicsOperationalHourListData: [], success: false, inProgress: true})
        case RECEIVE_MECHANIC_OPERATIONALHOUR_TABLE:
            // var _total = action.payload.result.length
            return Object.assign({}, state, {mechanicsOperationalHourListData: action.payload.result, success: true, inProgress: false})
        case FAILED_MECHANIC_OPERATIONALHOUR_TABLE:
            return ({mechanicsOperationalHourListData: [], success: false, inProgress: false})
        default:
            return state
    }
}