import { FETCH_STCK_PO_RULE_LIST, RECEIVE_STCK_PO_RULE_LIST, FAILED_STCK_PO_RULE_LIST } from '../../actions/stocker/stck.porule.act'

const defaultStckPoRuleState = {
    stckPoRuleData: [],
    stckTotalPoRule: 0,
    stckPoRuleSelect: [],
    success: true,
    inProgress: false,
}

export function StckPoRule(state = defaultStckPoRuleState, action){
    switch (action.type) {
        case FETCH_STCK_PO_RULE_LIST:
            return ({stckPoRuleData: [], success: true, stckTotalPoRule: 0, stckPoRuleSelect: [], inProgress: true})
        case RECEIVE_STCK_PO_RULE_LIST:
            var _totalPoRule = action.payload.result.length;
            var _stckPoRuleSelect = []
            if(action.payload.result !== []) {
                var _poRuleData = action.payload.result

                _poRuleData.forEach((p, index) => {
                    var _loc = {
                        'label': `Limit ${_poRuleData[index].limit_po_value} - Step ${_poRuleData[index].step_count}`,
                        'value': _poRuleData[index].id
                    }
                    _stckPoRuleSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckPoRuleData: action.payload, success: true, stckTotalPoRule: _totalPoRule, stckPoRuleSelect: _stckPoRuleSelect, inProgress: false})
        case FAILED_STCK_PO_RULE_LIST:
            return ({stckPoRuleData: [], success: false, stckTotalPoRule: 0, stckPoRuleSelect: [], inProgress: false})
        default:
            return state
    }
}