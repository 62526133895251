import axios from "axios";
import {INBOUND_ITEM_DETAIL_LIST_URL} from '../../config/stck.links'
import {toast} from 'react-toastify'

export const FETCH_INBOUND_ITEM_DETAIL_LIST = 'FETCH_INBOUND_ITEM_DETAIL_LIST'
export const RECEIVE_INBOUND_ITEM_DETAIL_LIST = 'RECEIVE_INBOUND_ITEM_DETAIL_LIST'
export const FAILED_INBOUND_ITEM_DETAIL_LIST = 'FAILED_INBOUND_ITEM_DETAIL_LIST'

export function fetchInboundItemDetail(_id){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_INBOUND_ITEM_DETAIL_LIST});
        axios({
            method: 'post',
            url: INBOUND_ITEM_DETAIL_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_master_inbound: _id
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_INBOUND_ITEM_DETAIL_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_INBOUND_ITEM_DETAIL_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_INBOUND_ITEM_DETAIL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_INBOUND_ITEM_DETAIL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_INBOUND_ITEM_DETAIL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_INBOUND_ITEM_DETAIL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_INBOUND_ITEM_DETAIL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}


export function fetchInboundItemDetailBasedOnMasterItem(_id, _idInbound){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_INBOUND_ITEM_DETAIL_LIST});
        axios({
            method: 'post',
            url: INBOUND_ITEM_DETAIL_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_master_item: _id,
                id_master_inbound: _idInbound
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_INBOUND_ITEM_DETAIL_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_INBOUND_ITEM_DETAIL_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_INBOUND_ITEM_DETAIL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_INBOUND_ITEM_DETAIL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_INBOUND_ITEM_DETAIL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_INBOUND_ITEM_DETAIL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_INBOUND_ITEM_DETAIL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchInboundItemDetailByArrMasterItemBatch(batchItems){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_INBOUND_ITEM_DETAIL_LIST});
        let arrOutboundFetch = []
        if(!batchItems)
            return []
        if(!batchItems.length > 0)
            return []
        batchItems.forEach(a => 
            arrOutboundFetch.push(
            axios({
                method: 'post',
                url: INBOUND_ITEM_DETAIL_LIST_URL,
                headers: {
                    Authorization: auth
                },
                data:{
                    id_master_item: a.id_master_item,
                    batch_number: a.batch_number
                }
             })
            )
        )
        Promise.all(arrOutboundFetch)
        .then(responses => {
            let payload = []
            let responseResult = responses
            if(!responseResult)
                return payload
            if(!responseResult.length > 0)
                return payload
            let result = []
            responseResult.forEach((r,i) => {
                if(!r.data.result[0])
                    return
                result.push(...r.data.result)
            })
            payload = [...payload, ...result]
            return payload
        })
        .then((_payload) => {
            dispatch( {
                type: RECEIVE_INBOUND_ITEM_DETAIL_LIST,
                payload: {result:_payload}
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_INBOUND_ITEM_DETAIL_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_INBOUND_ITEM_DETAIL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_INBOUND_ITEM_DETAIL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_INBOUND_ITEM_DETAIL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_INBOUND_ITEM_DETAIL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_INBOUND_ITEM_DETAIL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}