import { FETCH_PACKAGE_TYPE_LIST, RECEIVE_PACKAGE_TYPE_LIST, FAILED_PACKAGE_TYPE_LIST } from '../../actions/stocker/stck.pacakgetype.act'

const defaultStckPackageTypesState = {
    stckPackageTypesData: [],
    stckTotalPackageTypes: 0,
    stckPackageTypesSelect: [],
    success: true,
    inProgress: false,
}

export function StckPackageType(state = defaultStckPackageTypesState, action){
    switch (action.type) {
        case FETCH_PACKAGE_TYPE_LIST:
            return ({stckPackageTypesData: [], success: true, stckTotalPackageTypes: 0, stckPackageTypesSelect: [], inProgress: true})
        case RECEIVE_PACKAGE_TYPE_LIST:
            var _totalUnits = action.payload.result.length;
            var _stckPackageTypesSelect = []
            if(action.payload.result !== []) {
                var _packageTypeData = action.payload.result
                _packageTypeData.forEach((p, index) => {
                    var _loc = {
                        'label': _packageTypeData[index].name,
                        'value': _packageTypeData[index].id
                    }
                    _stckPackageTypesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckPackageTypesData: action.payload, success: true, stckTotalPackageTypes: _totalUnits, stckPackageTypesSelect: _stckPackageTypesSelect, inProgress: false})
        case FAILED_PACKAGE_TYPE_LIST:
            return ({stckPackageTypesData: [], success: false, stckTotalPackageTypes: 0, stckPackageTypesSelect: [], inProgress: false})
        default:
            return state
    }
}