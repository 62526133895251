import { FETCH_PTH_PIC_POSITIONS_LIST, RECEIVE_PTH_PIC_POSITIONS_LIST, FAILED_PTH_PIC_POSITIONS_LIST } from '../../actions/pointhub/pth.picpositions.act'

const defaultPthPicPositionsState = {
    pthPicPositionsData: [],
    pthTotalPicPositions: 0,
    pthPicPositionSelect: [],
    success: true,
    inProgress: false,
}

export function PthPicPositions(state = defaultPthPicPositionsState, action){
    switch (action.type) {
        case FETCH_PTH_PIC_POSITIONS_LIST:
            return ({pthPicPositionsData: [], success: true, pthTotalPicPositions: 0, pthPicPositionSelect: [], inProgress: true})
        case RECEIVE_PTH_PIC_POSITIONS_LIST:
            var _totalPicPositions = action.payload.result.length;
            var _pthPicPositionSelect = []
            if(action.payload.result !== []) {
                var _pthPicPositionsData = action.payload.result

                _pthPicPositionsData.forEach((p, index) => {
                    var _loc = {
                        'label': _pthPicPositionsData[index].position_name,
                        'value': _pthPicPositionsData[index].id
                    }
                    _pthPicPositionSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {pthPicPositionsData: action.payload, success: true, pthTotalPicPositions: _totalPicPositions, pthPicPositionSelect: _pthPicPositionSelect, inProgress: false})
        case FAILED_PTH_PIC_POSITIONS_LIST:
            return ({pthPicPositionsData: [], success: false, pthTotalPicPositions: 0, pthPicPositionSelect: [], inProgress: false})
        default:
            return state
    }
}