import { FETCH_DELETE_DRIVERAPPUSER_ITEM, RECEIVE_DELETE_DRIVERAPPUSER_ITEM, FAILED_DELETE_DRIVERAPPUSER_ITEM } from '../../actions/transporter/driverappuser-delete.act'

const defaultDeleteItemState = {
    deleteItemData: []    
}

export function fetchDeleteDriverAppUser(state = defaultDeleteItemState, action){
    switch (action.type) {
        case FETCH_DELETE_DRIVERAPPUSER_ITEM:
            return ({deleteItemData: [], inProgress: true})
        case RECEIVE_DELETE_DRIVERAPPUSER_ITEM:
            return Object.assign({}, state, {deleteItemData: action.payload, inProgress: false})
        case FAILED_DELETE_DRIVERAPPUSER_ITEM:
            return ({deleteItemData: [], inProgress: false})
        default:
            return state
    }
}