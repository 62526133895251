import axios from "axios";
import {PO_ITEM_DETAIL_LIST_URL, MASTER_INBOUND_LIST_URL,
            INBOUND_ITEM_DETAIL_LIST_URL} from '../../config/stck.links'
import {toast} from 'react-toastify'

export const FETCH_PO_ITEM_DETAIL_LIST = 'FETCH_PO_ITEM_DETAIL_LIST'
export const RECEIVE_PO_ITEM_DETAIL_LIST = 'RECEIVE_PO_ITEM_DETAIL_LIST'
export const RECEIVE_PO_ITEM_DETAIL_COUNTER = 'RECEIVE_PO_ITEM_DETAIL_COUNTER'
export const FAILED_PO_ITEM_DETAIL_LIST = 'FAILED_PO_ITEM_DETAIL_LIST'

export function fetchPoItemDetail(_id){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_PO_ITEM_DETAIL_LIST});
        axios({
            method: 'post',
            url: PO_ITEM_DETAIL_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_purchase_order: _id
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_PO_ITEM_DETAIL_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_PO_ITEM_DETAIL_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_PO_ITEM_DETAIL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_PO_ITEM_DETAIL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_PO_ITEM_DETAIL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_PO_ITEM_DETAIL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_PO_ITEM_DETAIL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchMultiPoItemDetail(pos=[]){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_PO_ITEM_DETAIL_LIST});
        const multiFetchPoItemDetail = []
        pos.forEach(po => {
            multiFetchPoItemDetail.push(axios({
                method: 'post',
                url: PO_ITEM_DETAIL_LIST_URL,
                headers: {
                    Authorization: auth
                },
                data:{
                    id_purchase_order: po.id
                }
            }))
        })
        Promise.all(multiFetchPoItemDetail)
        .then((responses) => {
            const r = {result:[]}
            for (let response of responses) {
                let responseResult = response.data.result;
                if (!responseResult) continue;
                if (!responseResult.length > 0) continue;
                r.result.push(...responseResult)
            }
            return r
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_PO_ITEM_DETAIL_LIST,
                payload: response
            })
        })
        // .catch(function(error){
        //     if (error.response) {
        //         if(error.response.status === 401) {
        //             dispatch({
        //                 type: FAILED_PO_ITEM_DETAIL_LIST
        //             })
        //             return toast.error("Invalid username or password! Please try again!");
        //         } else if (error.response.status === 404 || error.response.status === 500) {
        //             dispatch({
        //                 type: FAILED_PO_ITEM_DETAIL_LIST
        //             })
        //             return toast.error("Server cannot be contacted! Please ask your system administrator!");
        //         } else if (error.response.status === 400) {
        //             dispatch({
        //                 type: FAILED_PO_ITEM_DETAIL_LIST
        //             })
        //             return toast.error(error.response.data.message);
        //         } else {
        //             dispatch({
        //                 type: FAILED_PO_ITEM_DETAIL_LIST
        //             })
        //             return toast.error('Something went wrong... Please try again later...');
        //         }
        //     } else if (error.request) {
        //         dispatch({
        //             type: FAILED_PO_ITEM_DETAIL_LIST
        //         })
        //         return toast.error('Request error! Please try again later...')
        //     } else {
        //         dispatch({
        //             type: FAILED_PO_ITEM_DETAIL_LIST
        //         })
        //         return toast.error('Something went wrong... Please try again later...')
        //     }
        // })
    }
}

export function fetchCountingPoItemDetail(_id_po){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return async (dispatch, getState) => {
        dispatch({type: FETCH_PO_ITEM_DETAIL_LIST});
        let request1 = axios({
            method: 'post',
            url: PO_ITEM_DETAIL_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_purchase_order: _id_po
            }
        })
        let request2 = axios({
                method: 'post',
                url: MASTER_INBOUND_LIST_URL,
                headers: {
                    Authorization: auth
                },
                data:{
                    id_purchase_order: _id_po
                }
        })
        let payloadToSend = []
        await Promise.all(
            [request1, request2]
        ).then(async (values) => {
            let request3Result = []
            payloadToSend = values
            values[0] =  values[0].data.result
            values[1] =  values[1].data.result
            await Promise.all(
                values[1].map(value => {
                    axios({
                        method: 'post',
                        url: INBOUND_ITEM_DETAIL_LIST_URL,
                        headers: {
                            Authorization: auth
                        },
                        data:{
                            id_master_inbound: value.id
                        }
                    }).then((response) => {
                        request3Result.push(...response.data.result)
                        payloadToSend[2] = request3Result
                    })
                    return value;
                })
            ).then(() => {
                setTimeout(()=> {
                    dispatch( {
                        type: RECEIVE_PO_ITEM_DETAIL_COUNTER,
                        payload: payloadToSend? payloadToSend : []
                    })
                },1000)
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_PO_ITEM_DETAIL_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_PO_ITEM_DETAIL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_PO_ITEM_DETAIL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_PO_ITEM_DETAIL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_PO_ITEM_DETAIL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_PO_ITEM_DETAIL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

