import { FETCH_LL_USAGE_SCHEDULE_LIST, RECEIVE_LL_USAGE_SCHEDULE_LIST, FAILED_LL_USAGE_SCHEDULE_LIST } from '../../actions/landlord/aLandLord-UsageScheduleList'

const defaultLLUsageScheduleState = {
    llUsageScheduleData: [],
    llTotalUsageSchedule: 0,
    llUsageScheduleSelect: [],
    success: true,
    inProgress: false,
}

export function fetchLLUsageScheduleList(state = defaultLLUsageScheduleState, action){
    switch (action.type) {
        case FETCH_LL_USAGE_SCHEDULE_LIST:
            return ({llUsageScheduleData: [], success: true, llTotalUsageSchedule: 0, llUsageScheduleSelect: [], inProgress: true})
        case RECEIVE_LL_USAGE_SCHEDULE_LIST:
            var _totalUsageSchedule = action.payload.result.length;
            var _selectUsageSchedule = []
            if(action.payload.result !== []) {
                var _usageScheduleData = action.payload.result

                _usageScheduleData.forEach((p, index) => {
                    var _usageSched = {
                        'label': _usageScheduleData[index].usage_sched_name + ' - ' + _usageScheduleData[index].usage_sched_desc,
                        'value': _usageScheduleData[index].id
                    }
                    _selectUsageSchedule.push(_usageSched)
                })
            }
            return Object.assign({}, state, {llUsageScheduleData: action.payload, success: true, llTotalUsageSchedule: _totalUsageSchedule, llUsageScheduleSelect: _selectUsageSchedule, inProgress: false})
        case FAILED_LL_USAGE_SCHEDULE_LIST:
            return ({llUsageScheduleData: [], success: false, llTotalUsageSchedule: 0, llUsageScheduleSelect: [], inProgress: false})
        default:
            return state
    }
}