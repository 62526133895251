import axios from "axios";
import {
  PURCHASE_ORDER_LIST_URL,
  INTERCOMPANY_LIST_URL,
} from "../../config/stck.links";
import { toast } from "react-toastify";

export const FETCH_STCK_PURCHASE_ORDER_LIST = "FETCH_STCK_PURCHASE_ORDER_LIST";
export const RECEIVE_STCK_PURCHASE_ORDER_LIST =
  "RECEIVE_STCK_PURCHASE_ORDER_LIST";
export const FAILED_STCK_PURCHASE_ORDER_LIST =
  "FAILED_STCK_PURCHASE_ORDER_LIST";

export function fetchStckPurchaseOrder(
  _idAdmOffice,
  _idVendor,
  _idPoType,
  _idDivisionReq,
  _idPaymentTermCondition
) {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;

  return async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_STCK_PURCHASE_ORDER_LIST });
      const responsePO = await axios({
        method: "post",
        url: PURCHASE_ORDER_LIST_URL,
        headers: {
          Authorization: auth,
        },
        data: {
          id_administratioon_office: _idAdmOffice,
          id_vendor: _idVendor,
          id_po_type: _idPoType,
          id_division_requestor: _idDivisionReq,
          id_payment_term_condition: _idPaymentTermCondition,
        },
      });
      const intercompanyList = await axios({
        method: "post",
        url: INTERCOMPANY_LIST_URL,
        headers: {
          Authorization: auth,
        },
      });
      let newData = [];
      for (let key in responsePO.data.result) {
        // let intercompanyData =null;
        let r = {...responsePO.data.result[key]};
        r.po_number = r.human_po_number ? r.human_po_number : r.po_number;
        if (!r.is_intercompany) r.is_intercompany = 0;
        if (r.is_intercompany === 1) {
          r.is_destination = 0;
          const filteredIntercompany = intercompanyList.data.result.filter(
            (i) => i.id_purchase_order === r.id
          );
          if (filteredIntercompany.length > 0) {
            r.id_company_destination_name =
              filteredIntercompany[0].id_company_destination_name;
            r.area_name = filteredIntercompany[0].area_name;
            r.warehouse_name = filteredIntercompany[0].warehouse_name;
            r.vendor_name = filteredIntercompany[0].id_company_destination_name;
            r.intercompany_id = filteredIntercompany[0].id;
            r.inter_so_number = filteredIntercompany[0].so_number;
            r.is_destination =
              filteredIntercompany[0].creator_company ===
              filteredIntercompany[0].id_company_destination
                ? 0
                : 1;
          }
          if (filteredIntercompany.length === 0) {
            r.is_destination = 1;
            const response = await axios({
              method: "post",
              url: INTERCOMPANY_LIST_URL,
              headers: {
                Authorization: auth,
              },
              data: {
                id_purchase_order: r.id,
                is_destination: 1,
              },
            });
            // if(r.id===2264){
            //   console.log(response)
            // }
            const [responseResult] = response.data.result;
            if(responseResult){
              r.id_company_receiver = responseResult.id_company_destination;
              r.id_company_sender = responseResult.id_company_origin;
              r.area_name = responseResult.area_name;
              r.warehouse_name = responseResult.warehouse_name;
              r.vendor_name = responseResult.id_company_origin_name;
              r.intercompany_id = responseResult.id;
              r.approval_status = responseResult.approval_status;
              r.inter_so_number = responseResult.so_number;
              r.is_destination =
                responseResult.creator_company ===
                responseResult.id_company_destination
                  ? 0
                  : 1;
            }
            // intercompanyData = {...fillDestData};
          }
        }
        newData = [...newData, {...r, }]
      }

      const newResult = { ...responsePO.data, result: [...newData]};

      // console.log("====",newData.filter((item)=>item.human_po_number==="PO/250324/837ECB4489-D89D"));

      dispatch({
        type: RECEIVE_STCK_PURCHASE_ORDER_LIST,
        payload: newResult,
      });
    } catch (error) {
      // console.log(error)
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({
            type: FAILED_STCK_PURCHASE_ORDER_LIST,
          });
          return toast.error("Invalid username or password! Please try again!");
        } else if (
          error.response.status === 404 ||
          error.response.status === 500
        ) {
          dispatch({
            type: FAILED_STCK_PURCHASE_ORDER_LIST,
          });
          return toast.error(
            "Server cannot be contacted! Please ask your system administrator!"
          );
        } else if (error.response.status === 400) {
          dispatch({
            type: FAILED_STCK_PURCHASE_ORDER_LIST,
          });
          return toast.error(error.response.data.message);
        } else {
          dispatch({
            type: FAILED_STCK_PURCHASE_ORDER_LIST,
          });
          return toast.error(
            "Something went wrong... Please try again later...2"
          );
        }
      } else if (error.request) {
        dispatch({
          type: FAILED_STCK_PURCHASE_ORDER_LIST,
        });
        return toast.error("Request error! Please try again later...");
      } else {
        dispatch({
          type: FAILED_STCK_PURCHASE_ORDER_LIST,
        });
        return toast.error("Something went wrong... Please try again later...1");
      }
    }
  };
}
