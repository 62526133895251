import { FETCH_STCK_WAREHOUSE_OPERATIONS_RULES_LIST, RECEIVE_STCK_WAREHOUSE_OPERATIONS_RULES_LIST, FAILED_STCK_WAREHOUSE_OPERATIONS_RULES_LIST } from '../../actions/stocker/stck.warehouseoperationrules.act'

const defaultStckWarehouseOperationRulesState = {
    stckWarehouseOperationRulesData: [],
    stckTotalWarehouseOperationRules: 0,
    stckWarehouseOperationRulesSelect: [],
    success: true,
    inProgress: false,
}

export function StckWarehouseOperationRules(state = defaultStckWarehouseOperationRulesState, action){
    switch (action.type) {
        case FETCH_STCK_WAREHOUSE_OPERATIONS_RULES_LIST:
            return ({stckWarehouseOperationRulesData: [], success: true, stckTotalWarehouseOperationRules: 0, stckWarehouseOperationRulesSelect: [], inProgress: true})
        case RECEIVE_STCK_WAREHOUSE_OPERATIONS_RULES_LIST:
            var _totalWarehouseOperation = action.payload.result.length;
            var _stckWarehouseOperationRulesSelect = []
            if(action.payload.result !== []) {
                var _warehouseOperationRulesData = action.payload.result

                _warehouseOperationRulesData.forEach((p, index) => {
                    var _loc = {
                        'label': _warehouseOperationRulesData[index].day + ' - (' + _warehouseOperationRulesData[index].open_time + ' - ' + _warehouseOperationRulesData[index].close_time + ')',
                        'value': _warehouseOperationRulesData[index].id
                    }
                    _stckWarehouseOperationRulesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckWarehouseOperationRulesData: action.payload, success: true, stckTotalWarehouseOperationRules: _totalWarehouseOperation, stckWarehouseOperationRulesSelect: _stckWarehouseOperationRulesSelect, inProgress: false})
        case FAILED_STCK_WAREHOUSE_OPERATIONS_RULES_LIST:
            return ({stckWarehouseOperationRulesData: [], success: false, stckTotalWarehouseOperationRules: 0, stckWarehouseOperationRulesSelect: [], inProgress: false})
        default:
            return state
    }
}