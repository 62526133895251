export const FETCH_WORK_ORDER_ADDER_MULTI = 'FETCH_WORK_ORDER_ADDER_MULTI'
export const RECEIVE_WORK_ORDER_ADDER_MULTI = 'RECEIVE_WORK_ORDER_ADDER_MULTI'
export const FAILED_WORK_ORDER_ADDER_MULTI = 'FAILED_WORK_ORDER_ADDER_MULTI'
export const RESET_WORK_ORDER_ADDER_MULTI = 'RESET_WORK_ORDER_ADDER_MULTI'

export function fetchWorkOrderAdderMulti(_data){
    return (dispatch, getState) => {
        dispatch({type: FETCH_WORK_ORDER_ADDER_MULTI});
        dispatch( {
            type: RECEIVE_WORK_ORDER_ADDER_MULTI,
            payload: _data
        })
    }
}

export function resetWorkOrderAdderMulti() {
    return (dispatch, getState) => {
        dispatch( {
            type: RESET_WORK_ORDER_ADDER_MULTI,
            payload: []
        })
    }
}