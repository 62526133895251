import { FETCH_LL_SENSOR_TYPE_LIST, RECEIVE_LL_SENSOR_TYPE_LIST, FAILED_LL_SENSOR_TYPE_LIST } from '../../actions/landlord/aLandLord-SensorTypeList'

const defaultLLSensorTypeState = {
    llSensorTypeData: [],
    llTotalSensorType: 0,
    llSensorTypeSelect: [],
    success: true,
    inProgress: false,
}

export function fetchLLSensorTypeList(state = defaultLLSensorTypeState, action){
    switch (action.type) {
        case FETCH_LL_SENSOR_TYPE_LIST:
            return ({llSensorTypeData: [], success: true, llTotalSensorType: 0, llSensorTypeSelect: [], inProgress: true})
        case RECEIVE_LL_SENSOR_TYPE_LIST:
            var _totalSensorType = action.payload.result.length;
            var _selectSensorType = []
            if(action.payload.result !== []) {
                var _locationData = action.payload.result

                _locationData.forEach((p, index) => {
                    var _loc = {
                        'label': _locationData[index].sensor_type_name + ' - ' + _locationData[index].sensor_type_desc,
                        'value': _locationData[index].id
                    }
                    _selectSensorType.push(_loc)
                })
            }
            return Object.assign({}, state, {llSensorTypeData: action.payload, success: true, llTotalSensorType: _totalSensorType, llSensorTypeSelect: _selectSensorType, inProgress: false})
        case FAILED_LL_SENSOR_TYPE_LIST:
            return ({llSensorTypeData: [], success: false, llTotalSensorType: 0, llSensorTypeSelect: [], inProgress: false})
        default:
            return state
    }
}