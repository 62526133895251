import { FETCH_FLEET_LIST_TABLE, RECEIVE_FLEET_LIST_TABLE, FAILED_FLEET_LIST_TABLE,
        FETCH_FLEET_QR, RECEIVE_FLEET_QR, FAILED_FLEET_QR,
        FETCH_FLEET_MAINTENANCE_BIND, RECEIVE_FLEET_MAINTENANCE_BIND, FAILED_FLEET_MAINTENANCE_BIND
        } from '../../actions/transporter/aTransporter-FleetListTable'

const defaultFleetListTableState = {
    fleetListTableData: []  ,
    totalFleet: 0,
    totalAssignedFleet: 0,
    totalUnsignedFleet: 0,
    success: false,
    fleetSelectData: [],
    completeFleetSelectData: []
}

export function fetchFleetListTable(state = defaultFleetListTableState, action){
    switch (action.type) {
        case FETCH_FLEET_LIST_TABLE:
            return ({fleetListTableData: [], success: false, 
                    totalFleet: 0, totalAssignedFleet: 0, totalUnsignedFleet: 0,
                    completeFleetSelectData: [], inProgress: true})
        case RECEIVE_FLEET_LIST_TABLE:
            var totalFleet = action.payload.result.length;
            let totalAssignedFleet = [...action.payload.result].filter(r => r.obd_code !== null).length
            let totalUnsignedFleet = totalFleet - totalAssignedFleet
            var list = []
            var listCompleteFleet = []
            if(Array.isArray(action.payload.result) && action.payload.result.length !== 0) {
                var fleetList = action.payload.result
                fleetList.forEach((p, index) => {
                    var fleet = {
                        'label': fleetList[index].reg_number,
                        'value': fleetList[index].id
                    }

                    // Untuk fleet komplit dengan tipe mobil
                    var completeFleet = {
                        'label': fleetList[index].reg_number + '(' + fleetList[index].car_brands + ' ' + fleetList[index].car_type + ')',
                        'value': fleetList[index].id,
                        'odometer': fleetList[index].odometer,
                        'reg_number': fleetList[index].reg_number,
                    }

                    list.push(fleet)
                    listCompleteFleet.push(completeFleet)
                })
            }
            return Object.assign({}, state, {fleetListTableData: action.payload,
                                            totalFleet, totalAssignedFleet, totalUnsignedFleet,
                                            fleetSelectData: list, completeFleetSelectData: listCompleteFleet, 
                                            success: true, inProgress: false})
        case FAILED_FLEET_LIST_TABLE:
            return ({fleetListTableData: [], success: false, 
                    totalFleet: 0, totalAssignedFleet: 0, totalUnsignedFleet: 0,
                    fleetSelectData: [], completeFleetSelectData: [], inProgress: false})
        default:
            return state
    }
}

const defaultfleetQrState = {
    fleetsQr: [],
    inProgress: false,
    success: true,
    completeworkshopSelectData: []
}

export function FleetQrList(state = defaultfleetQrState, action){
    switch (action.type) {
        case FETCH_FLEET_QR:
            return ({fleetsQr: [], success: false, inProgress: true,})
        case RECEIVE_FLEET_QR:
            var _result = action.payload
            return Object.assign({}, state, {fleetsQr: _result, success: true, inProgress: false})
        case FAILED_FLEET_QR:
            return ({fleetsQr: [], success: false, inProgress: false})
        default:
            return state
    }
}

const defaultfleetMaintenanceBindingState = {
    fleetsMaintenanceBinding: [],
    inProgress: false,
    success: true,
    completeworkshopSelectData: []
}

export function FleetMaintenanceBindingList(state = defaultfleetMaintenanceBindingState, action){
    switch (action.type) {
        case FETCH_FLEET_MAINTENANCE_BIND:
            return ({fleetsMaintenanceBinding: [], success: false, inProgress: true,})
        case RECEIVE_FLEET_MAINTENANCE_BIND:
            var _result = action.payload
            return Object.assign({}, state, {fleetsMaintenanceBinding: _result, success: true, inProgress: false})
        case FAILED_FLEET_MAINTENANCE_BIND:
            return ({fleetsMaintenanceBinding: [], success: false, inProgress: false})
        default:
            return state
    }
}
