import { FETCH_MASTER_INBOUND_LIST, RECEIVE_MASTER_INBOUND_LIST, FAILED_MASTER_INBOUND_LIST } from '../../actions/stocker/stck.masterinbound.act'

const defaultStckMasterInboundState = {
    stckMasterInboundData: [],
    stckTotalMasterInbound: 0,
    stckMasterInboundSelect: [],
    stckMasterInboundSelectWCustomerforTriPL: [],
    success: true,
    inProgress: false,
    assignedPoIds: {}
}

export function StckMasterInbound(state = defaultStckMasterInboundState, action){
    switch (action.type) {
        case FETCH_MASTER_INBOUND_LIST:
            return ({stckMasterInboundData: [], success: true, stckTotalMasterInbound: 0, assignedPoIds: {},
                         stckMasterInboundSelectWCustomerforTriPL: [], stckMasterInboundSelect: [], inProgress: true})
        case RECEIVE_MASTER_INBOUND_LIST:
            var _totalMasterInbound = action.payload.result.length;
            var _stckMasterInboundSelect = []
            var _stckMasterInboundSelectDesc = []
            var _assignedPoIds = {}
            if(action.payload.result !== []) {
                var _mInboundData = action.payload.result

                _mInboundData.forEach((p, index) => {
                    var _loc = {
                        'label': _mInboundData[index].inbound_number,
                        'value': _mInboundData[index].id,
                        "id": _mInboundData[index].id,
                        "id_company": _mInboundData[index].id_company,
                        "id_purchase_order": _mInboundData[index].id_purchase_order,
                        "po_number": _mInboundData[index].po_number,
                        "po_creation_date": _mInboundData[index].po_creation_date,
                        "po_due_date": _mInboundData[index].po_due_date,
                        "inbound_number": _mInboundData[index].inbound_number,
                        "document_number": _mInboundData[index].document_number,
                        "id_adm_office": _mInboundData[index].id_adm_office,
                        "adm_office_name": _mInboundData[index].adm_office_name,
                        "adm_office_address": _mInboundData[index].adm_office_address,
                        "adm_office_main_phone_number": _mInboundData[index].adm_office_main_phone_number,
                        "id_warehouse": _mInboundData[index].id_warehouse,
                        "warehouse_name": _mInboundData[index].warehouse_name,
                        "warehouse_address": _mInboundData[index].warehouse_address,
                        "id_warehouse_area": _mInboundData[index].id_warehouse_area,
                        "warehouse_area_name": _mInboundData[index].warehouse_area_name,
                        "warehouse_area_size": _mInboundData[index].warehouse_area_size,
                        "id_vendor": _mInboundData[index].id_vendor,
                        "vendor_name": _mInboundData[index].vendor_name,
                        "vendor_address": _mInboundData[index].vendor_address,
                        "vendor_main_phone_number": _mInboundData[index].vendor_main_phone_number,
                        "remark": _mInboundData[index].remark,
                        "expected_in_time": _mInboundData[index].expected_in_time,
                        "expected_out_time": _mInboundData[index].expected_out_time,
                        "in_time": _mInboundData[index].in_time,
                        "out_time": _mInboundData[index].out_time,
                    }
                    var _loc2 = {
                        'label': _mInboundData[index].inbound_number + ' - ' + _mInboundData[index].po_number
                                                 + ' - ' + _mInboundData[index].warehouse_name + ' , ' + _mInboundData[index].warehouse_address,
                        'value': _mInboundData[index].id,
                        "id": _mInboundData[index].id,
                        "id_company": _mInboundData[index].id_company,
                        "id_purchase_order": _mInboundData[index].id_purchase_order,
                        "po_number": _mInboundData[index].po_number,
                        "po_creation_date": _mInboundData[index].po_creation_date,
                        "po_due_date": _mInboundData[index].po_due_date,
                        "inbound_number": _mInboundData[index].inbound_number,
                        "document_number": _mInboundData[index].document_number,
                        "id_adm_office": _mInboundData[index].id_adm_office,
                        "adm_office_name": _mInboundData[index].adm_office_name,
                        "adm_office_address": _mInboundData[index].adm_office_address,
                        "adm_office_main_phone_number": _mInboundData[index].adm_office_main_phone_number,
                        "id_warehouse": _mInboundData[index].id_warehouse,
                        "warehouse_name": _mInboundData[index].warehouse_name,
                        "warehouse_address": _mInboundData[index].warehouse_address,
                        "id_warehouse_area": _mInboundData[index].id_warehouse_area,
                        "warehouse_area_name": _mInboundData[index].warehouse_area_name,
                        "warehouse_area_size": _mInboundData[index].warehouse_area_size,
                        "id_vendor": _mInboundData[index].id_vendor,
                        "vendor_name": _mInboundData[index].vendor_name,
                        "vendor_address": _mInboundData[index].vendor_address,
                        "vendor_main_phone_number": _mInboundData[index].vendor_main_phone_number,
                        "remark": _mInboundData[index].remark,
                        "expected_in_time": _mInboundData[index].expected_in_time,
                        "expected_out_time": _mInboundData[index].expected_out_time,
                        "in_time": _mInboundData[index].in_time,
                        "out_time": _mInboundData[index].out_time,
                    }
                    _stckMasterInboundSelect.push(_loc)
                    _stckMasterInboundSelectDesc.push(_loc2)
                    _assignedPoIds[_mInboundData[index].id_purchase_order] = _mInboundData[index].po_number

                })
            }
            return Object.assign({}, state, {stckMasterInboundData: action.payload, success: true, stckTotalMasterInbound: _totalMasterInbound, stckMasterInboundSelect: _stckMasterInboundSelect, 
                stckMasterInboundSelectWCustomerforTriPL: _stckMasterInboundSelectDesc.reverse(), inProgress: false, assignedPoIds: _assignedPoIds})
        case FAILED_MASTER_INBOUND_LIST:
            return ({stckMasterInboundData: [], success: false, stckTotalMasterInbound: 0, assignedPoIds: {},
                stckMasterInboundSelectWCustomerforTriPL: [], stckMasterInboundSelect: [], inProgress: false})
        default:
            return state
    }
}