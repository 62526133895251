import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_WORKSHOP_TABLE = 'FETCH_WORKSHOP_TABLE'
export const RECEIVE_WORKSHOP_TABLE = 'RECEIVE_WORKSHOP_TABLE'
export const FAILED_WORKSHOP_TABLE = 'FAILED_WORKSHOP_TABLE'

export const FETCH_BINDED_WORKSHOP_TABLE = 'FETCH_BINDED_WORKSHOP_TABLE'
export const RECEIVE_BINDED_WORKSHOP_TABLE = 'RECEIVE_BINDED_WORKSHOP_TABLE'
export const FAILED_BINDED_WORKSHOP_TABLE = 'FAILED_BINDED_WORKSHOP_TABLE'

export const FETCH_WORKSHOP_PIC_POSITION_LIST = 'FETCH_WORKSHOP_PIC_POSITION_LIST'
export const RECEIVE_WORKSHOP_PIC_POSITION_LIST = 'RECEIVE_WORKSHOP_PIC_POSITION_LIST'
export const FAILED_WORKSHOP_PIC_POSITION_LIST = 'FAILED_WORKSHOP_PIC_POSITION_LIST'

export const FETCH_WORKSHOP_PAYEMNT_TERM_TYPE_LIST = 'FETCH_WORKSHOP_PAYEMNT_TERM_TYPE_LIST'
export const RECEIVE_WORKSHOP_PAYEMNT_TERM_TYPE_LIST = 'RECEIVE_WORKSHOP_PAYEMNT_TERM_TYPE_LIST'
export const FAILED_WORKSHOP_PAYEMNT_TERM_TYPE_LIST = 'FAILED_WORKSHOP_PAYEMNT_TERM_TYPE_LIST'

export const FETCH_WORKSHOP_PIC_LIST = 'FETCH_WORKSHOP_PIC_LIST'
export const RECEIVE_WORKSHOP_PIC_LIST = 'RECEIVE_WORKSHOP_PIC_LIST'
export const FAILED_WORKSHOP_PIC_LIST = 'FAILED_WORKSHOP_PIC_LIST'

export function fetchWorkshopAll(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;


    return (dispatch, getState) => {
        dispatch({type: FETCH_WORKSHOP_TABLE});
        axios({
            method: 'post',
            url: API_LINKS.WORKSHOP_LIST_URL,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_WORKSHOP_TABLE,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_WORKSHOP_TABLE
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_WORKSHOP_TABLE
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_WORKSHOP_TABLE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_WORKSHOP_TABLE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_WORKSHOP_TABLE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_WORKSHOP_TABLE
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchBindedWorkshop(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_BINDED_WORKSHOP_TABLE});
        axios({
            method: 'post',
            url: API_LINKS.TRIPL_GROUP_LIST_URL,
            headers: {
                Authorization: auth
            },
        })
        .then((responseComp) => {
            if(responseComp.status === 200){
                axios({
                    method: 'post',
                    url: API_LINKS.TRIPL_GROUP_ASSIGN_CREATE_FIND_URL,
                    headers: {
                        Authorization: auth
                    },
                })
                .then((responseMember) => {
                    if(responseMember.status === 200){
                        axios({
                            method: 'post',
                            url: API_LINKS.WORKSHOP_LIST_URL,
                            headers: {
                                Authorization: auth
                            }
                        })
                        .then((response) => {
                            // console.log(response.data)
                            let getSystemGroup = responseComp.data.result.filter(rg => 
                                rg.group_name === "SYSTEM-WORKSHOP-GROUP"
                            )
                            if(getSystemGroup.length > 0){
                                let arrOfResponse = response.data.result
                                let filterMemberByApproval = responseMember.data.result.filter(fff => 
                                    fff.approval_status === 1 && fff.id_group === getSystemGroup[0].id
                                )
                                let payloadResult = []
                                filterMemberByApproval.map(aRR => {
                                    if(arrOfResponse.some((fm) =>
                                        fm.id_company === aRR.id_company
                                    )){
                                        payloadResult.push(arrOfResponse.filter((fm) =>
                                            fm.id_company === aRR.id_company
                                        )[0])
                                    }
                                    return aRR
                            })
                                dispatch( {
                                    type: RECEIVE_BINDED_WORKSHOP_TABLE,
                                    payload: payloadResult
                                })
                            }else{
                                dispatch( {
                                    type: RECEIVE_BINDED_WORKSHOP_TABLE,
                                    payload: []
                                })
                            }
                            // console.log(response.data)
                        })
                    }
                })
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_BINDED_WORKSHOP_TABLE
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_BINDED_WORKSHOP_TABLE
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_BINDED_WORKSHOP_TABLE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_BINDED_WORKSHOP_TABLE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_BINDED_WORKSHOP_TABLE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_BINDED_WORKSHOP_TABLE
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchWorkshopForSetting(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_WORKSHOP_TABLE});
        axios({
            method: 'post',
            url: API_LINKS.WORKSHOP_LIST_URL,
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            response.data.result = response.data.result.filter(r =>
                r.id_company === loginCreds.id_company
            )
            dispatch( {
                type: RECEIVE_WORKSHOP_TABLE,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_WORKSHOP_TABLE
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_WORKSHOP_TABLE
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_WORKSHOP_TABLE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_WORKSHOP_TABLE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_WORKSHOP_TABLE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_WORKSHOP_TABLE
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchWorkshopPicPosition(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_WORKSHOP_PIC_POSITION_LIST});
        axios({
            method: 'post',
            url: API_LINKS.WORKSHOP_PIC_POS_LIST_URL,
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_WORKSHOP_PIC_POSITION_LIST,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_WORKSHOP_PIC_POSITION_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_WORKSHOP_PIC_POSITION_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_WORKSHOP_PIC_POSITION_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_WORKSHOP_PIC_POSITION_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_WORKSHOP_PIC_POSITION_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_WORKSHOP_PIC_POSITION_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchWorkshopPic(id_workshop){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_WORKSHOP_PIC_LIST});
        axios({
            method: 'post',
            url: API_LINKS.WORKSHOP_PIC_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_workshop: id_workshop
            }
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_WORKSHOP_PIC_LIST,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_WORKSHOP_PIC_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_WORKSHOP_PIC_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_WORKSHOP_PIC_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_WORKSHOP_PIC_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_WORKSHOP_PIC_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_WORKSHOP_PIC_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchWorkshopPaymentTermType(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_WORKSHOP_PAYEMNT_TERM_TYPE_LIST});
        axios({
            method: 'post',
            url: API_LINKS.WORKSHOP_PAYMENT_TERM_TYPE_LIST_URL,
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_WORKSHOP_PAYEMNT_TERM_TYPE_LIST,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_WORKSHOP_PAYEMNT_TERM_TYPE_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_WORKSHOP_PAYEMNT_TERM_TYPE_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_WORKSHOP_PAYEMNT_TERM_TYPE_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_WORKSHOP_PAYEMNT_TERM_TYPE_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_WORKSHOP_PAYEMNT_TERM_TYPE_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_WORKSHOP_PAYEMNT_TERM_TYPE_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

