import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'
import moment from 'moment';

export const FETCH_FUEL_REPORT = 'FETCH_FUEL_REPORT'
export const RECEIVE_FUEL_REPORT = 'RECEIVE_FUEL_REPORT'
export const FAILED_FUEL_REPORT = 'FAILED_FUEL_REPORT'
export const RESET_FUEL_REPORT = 'RESET_FUEL_REPORT'

export function fetchFuelReport(_id, _startDate, _endDate, _history){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(_startDate, _endDate)
    var _link = API_LINKS.FLEET_FUEL_REPORT_URL

    var startValue = moment(_startDate).format("MM-DD-YYYY HH:mm:ss").toString()
    var endValue = moment(_endDate).format("MM-DD-YYYY HH:mm:ss").toString()

    return (dispatch, getState) => {
        dispatch({type: FETCH_FUEL_REPORT});
        axios({
            method: 'post',
            url: _link,
            headers: {
                Authorization: auth
            },
            data : {
                id: _id,
                start_date: startValue,
                end_date: endValue,
            }
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_FUEL_REPORT,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_FUEL_REPORT
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_FUEL_REPORT
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_FUEL_REPORT
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_FUEL_REPORT
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_FUEL_REPORT
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_FUEL_REPORT
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function resetFuelReport() {
    return (dispatch, getState) => {
        dispatch({
            type: RESET_FUEL_REPORT
        })
    }
}