import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'
import moment from 'moment';

export const FETCH_FLEET_REPORT = 'FETCH_FLEET_REPORT'
export const RECEIVE_FLEET_REPORT = 'RECEIVE_FLEET_REPORT'
export const FAILED_FLEET_REPORT = 'FAILED_FLEET_REPORT'
export const CLEAR_FLEET_REPORT = 'CLEAR_FLEET_REPORT'

export function fetchFleetReport(_id, _obd_code, _startDate, _endDate, _history, _advanceOptions){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(_startDate, _endDate)
    var _link = _history ? API_LINKS.OBD_HISTORY_LIST_CHECK_URL : API_LINKS.FLEET_REPORT_URL

    var startValue = moment(_startDate).format("MM-DD-YYYY HH:mm:ss").toString()
    var endValue = moment(_endDate).format("MM-DD-YYYY HH:mm:ss").toString()

    let dataRequest =  {
        id_history: _id,
        start_date: startValue,
        end_date: endValue,
    }
    if(_advanceOptions){
        delete  dataRequest.id_history
        dataRequest["id"] = _id
        dataRequest[Object.keys(_advanceOptions)[0]] = Object.values(_advanceOptions)[0]
        dataRequest[Object.keys(_advanceOptions)[1]] = Object.values(_advanceOptions)[1]
    }
    if(_advanceOptions.with_address){
        dataRequest["id_delivery_order"] = _obd_code
    }
    return async (dispatch, getState) => {
        dispatch({type: FETCH_FLEET_REPORT});
        axios({
            method: 'post',
            url: _link,
            headers: {
                Authorization: auth
            },
            data : dataRequest
        })
        .then(async (response) => {
            let newTrip = []
            let stopOnly = []
            let trip_numbers_obj = {}
            let trip = response.data.trip
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            if(dataRequest.stop_filter === 1){
                for (let i = 0; i < trip.length; i++) {
                    if(trip[i].duration <= 0)
                        continue
                    // if(_advanceOptions.with_address && trip[i].intented_stop === 1){
                    //         continue
                    // }
                   let tempObj = {
                        id: i+1,
                        _id: _obd_code,
                        trip_number: zeroPad(trip[i].trip_number, 3),
                        lat: trip[i].average_stop_lat,
                        lng: trip[i].average_stop_lon,
                        start_date: trip[i].start_date,
                        saved_place_near: trip[i].saved_place_near,
                        public_place_near:trip[i].public_place_near,
                        end_date:trip[i].end_date,
                        stop_duration: Number((trip[i].duration/60).toFixed(2)),
                        address: null,
                        intented_stop: trip[i].intented_stop,
                   }
                    stopOnly.push(tempObj)
                }
            }
            let tripCounter = 1
            if(dataRequest.trip_filter === 1){
                for (let i = 0; i < trip.length; i++) {
                    if(trip[i].data.length === 0)
                        continue
                    let trip_number = zeroPad(tripCounter, 3)
                    trip_numbers_obj[trip_number] = trip_number
                    let tripColor = "#" + Math.floor(Math.random()*16777215).toString(16);
                    for (let j = 0; j < trip[i].data.length; j++) {
                        trip[i].data[j].key = moment().unix()
                        trip[i].data[j].trip_number = trip_number
                        trip[i].data[j].lng = trip[i].data[j].lon
                        trip[i].data[j].color = tripColor
                        trip[i].data[j].id = `id_${i}${j}`
                        delete trip[i].data[j].lon
                        newTrip.push(trip[i].data[j])
                    }
                    tripCounter += 1
                }
            }
            response.data.result.map((r,index) => r.id = index+1)
            response.data.stop_only = stopOnly
            response.data.trip = newTrip
            response.data.trip_numbers = trip_numbers_obj
            dispatch( {
                type: RECEIVE_FLEET_REPORT,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_FLEET_REPORT
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_FLEET_REPORT
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_FLEET_REPORT
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_FLEET_REPORT
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_FLEET_REPORT
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_FLEET_REPORT
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function clearFleetReportState(){
    return (dispatch, getState) => {
        dispatch({type: CLEAR_FLEET_REPORT});
    }
}

export const PROCESS_TRIP_MODE = 'PROCESS_TRIP_MODE'
export const RECEIVE_TRIP_MODE_PROCESS = 'RECEIVE_TRIP_MODE_PROCESS'
export const FAILED_PROCESS_TRIP_MODE= 'FAILED_PROCESS_TRIP_MODE'
export const CLEAR_FLEET_REPORT_TRIP_MODE = 'CLEAR_FLEET_REPORT_TRIP_MODE'

export function clearfleetReportTripMode(){
    return (dispatch, getState) => {
        dispatch({type: CLEAR_FLEET_REPORT_TRIP_MODE});
    }
}

export function changefleetReportTripMode(data, mode, modeThreeOption){
    return (dispatch, getState) => {
        const zeroPad = (num, places) => String(num).padStart(places, '0')
        let tripNumbersByEngine = {}
        let tripNumbersByTime = {}
        new Promise((resolve, reject) => {
            dispatch({type: PROCESS_TRIP_MODE});
            let tripNumber = 1
            tripNumbersByEngine[zeroPad(tripNumber,3)] = zeroPad(tripNumber,3)
            tripNumbersByTime[zeroPad(tripNumber,3)] = zeroPad(tripNumber,3)
            let tripColor = "#" + Math.floor(Math.random()*16777215).toString(16);
            if(mode === 2){
                for(let i=0; i<data.length; i++){
                    if(i > 0){
                        if((data[i].wrn === 0) && (data[i-1].wrn === 1)){
                            tripNumber +=1
                            tripNumbersByEngine[zeroPad(tripNumber,3)] = zeroPad(tripNumber,3)
                            tripColor = "#" + Math.floor(Math.random()*16777215).toString(16);
                        }
                    }
                    data[i].trip_number = zeroPad(tripNumber,3)
                    data[i].color =  tripColor
                }
            }
            if(mode === 3){
                for(let i=0; i<data.length; i++){
                    console.log(data[i].time)
                    console.log(modeThreeOption.startDate)
                    const miliDiff = Math.abs(new Date(data[i].time) - new Date(modeThreeOption.startDate))
                    const minute = Math.floor((miliDiff/1000)/60);
                    console.log(minute)
                    if(minute > (modeThreeOption.inputMinute * tripNumber)){
                        tripNumber +=1
                        tripNumbersByTime[zeroPad(tripNumber,3)] = zeroPad(tripNumber,3)
                        tripColor = "#" + Math.floor(Math.random()*16777215).toString(16);
                    }
                    data[i].trip_number = zeroPad(tripNumber,3)
                    data[i].color =  tripColor
                }
            }
            resolve(data)
        })
        .then(data => {
            dispatch( {
                type: RECEIVE_TRIP_MODE_PROCESS,
                mode, tripNumbersByEngine, tripNumbersByTime,
                payload: data
            })
        })
        .catch(function(error){
            dispatch({
                type: FAILED_PROCESS_TRIP_MODE
            })
            return toast.error(error)
        })
    }
}