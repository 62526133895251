import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_CHECK_SHEET_LIST = 'FETCH_CHECK_SHEET_LIST'
export const RECEIVE_CHECK_SHEET_LIST = 'RECEIVE_CHECK_SHEET_LIST'
export const FAILED_CHECK_SHEET_LIST = 'FAILED_CHECK_SHEET_LIST'

export const FETCH_CHECK_SHEET_DETAIL_LIST = 'FETCH_CHECK_SHEET_DETAIL_LIST'
export const RECEIVE_CHECK_SHEET_DETAIL_LIST = 'RECEIVE_CHECK_SHEET_DETAIL_LIST'
export const FAILED_CHECK_SHEET_DETAIL_LIST = 'FAILED_CHECK_SHEET_DETAIL_LIST'

export const FETCH_CHECK_SHEET_APPROVAL_TYPE_LIST = 'FETCH_CHECK_SHEET_APPROVAL_TYPE_LIST'
export const RECEIVE_CHECK_SHEET_APPROVAL_TYPE_LIST = 'RECEIVE_CHECK_SHEET_APPROVAL_TYPE_LIST'
export const FAILED_CHECK_SHEET_APPROVAL_TYPE_LIST = 'FAILED_CHECK_SHEET_APPROVAL_TYPE_LIST'

export const FETCH_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST = 'FETCH_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST'
export const RECEIVE_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST = 'RECEIVE_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST'
export const FAILED_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST = 'FAILED_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST'

export const FETCH_CHECK_SHEET_BINDING_DO_SETTING_LIST = 'FETCH_CHECK_SHEET_BINDING_DO_SETTING_LIST'
export const RECEIVE_CHECK_SHEET_BINDING_DO_SETTING_LIST = 'RECEIVE_CHECK_SHEET_BINDING_DO_SETTING_LIST'
export const FAILED_CHECK_SHEET_BINDING_DO_SETTING_LIST = 'FAILED_CHECK_SHEET_BINDING_DO_SETTING_LIST'

export const FETCH_CHECK_SHEET_DO_DETAIL_LIST = 'FETCH_CHECK_SHEET_DO_DETAIL_LIST'
export const RECEIVE_CHECK_SHEET_DO_DETAIL_LIST = 'RECEIVE_CHECK_SHEET_DO_DETAIL_LIST'
export const FAILED_CHECK_SHEET_DO_DETAIL_LIST = 'FAILED_CHECK_SHEET_DO_DETAIL_LIST'

export const FETCH_CHECK_SHEET_DO_APPROVAL_LIST = 'FETCH_CHECK_SHEET_DO_APPROVAL_LIST'
export const RECEIVE_CHECK_SHEET_DO_APPROVAL_LIST = 'RECEIVE_CHECK_SHEET_DO_APPROVAL_LIST'
export const FAILED_CHECK_SHEET_DO_APPROVAL_LIST = 'FAILED_CHECK_SHEET_DO_APPROVAL_LIST'

export const FETCH_CHECK_SHEET_DO_APPROVAL_INPUT_LIST = 'FETCH_CHECK_SHEET_DO_APPROVAL_INPUT_LIST'
export const RECEIVE_CHECK_SHEET_DO_APPROVAL_INPUT_LIST = 'RECEIVE_CHECK_SHEET_DO_APPROVAL_INPUT_LIST'
export const FAILED_CHECK_SHEET_DO_APPROVAL_INPUT_LIST = 'FAILED_CHECK_SHEET_DO_APPROVAL_INPUT_LIST'

export function fetchCheckSheet(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_CHECK_SHEET_LIST});
        axios({
            method: 'post',
            url: API_LINKS.CHECK_SHEET_LIST_URL,
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_CHECK_SHEET_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_CHECK_SHEET_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_CHECK_SHEET_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_CHECK_SHEET_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchCheckSheetDetail(id_check_sheet){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_CHECK_SHEET_DETAIL_LIST});
        axios({
            method: 'post',
            url: API_LINKS.CHECK_SHEET_DETAIL_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_check_sheet: id_check_sheet
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_CHECK_SHEET_DETAIL_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_DETAIL_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_DETAIL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_DETAIL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_CHECK_SHEET_DETAIL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_CHECK_SHEET_DETAIL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_CHECK_SHEET_DETAIL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchApprovalType(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_CHECK_SHEET_APPROVAL_TYPE_LIST});
        axios({
            method: 'post',
            url: API_LINKS.CHECK_SHEET_APPROVAL_TYPE_LIST_URL,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_CHECK_SHEET_APPROVAL_TYPE_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_APPROVAL_TYPE_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_APPROVAL_TYPE_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_APPROVAL_TYPE_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_CHECK_SHEET_APPROVAL_TYPE_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_CHECK_SHEET_APPROVAL_TYPE_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_CHECK_SHEET_APPROVAL_TYPE_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchApprovalTypeSetting(id_check_sheet_approval_type){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST});
        axios({
            method: 'post',
            url: API_LINKS.CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_check_sheet_approval_type: id_check_sheet_approval_type
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchCheckSheetBindingDO(id_do){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_CHECK_SHEET_BINDING_DO_SETTING_LIST});
        axios({
            method: 'post',
            url: API_LINKS.CHECK_SHEET_BINDING_DO_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_do: id_do
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_CHECK_SHEET_BINDING_DO_SETTING_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_BINDING_DO_SETTING_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_BINDING_DO_SETTING_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_BINDING_DO_SETTING_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_CHECK_SHEET_BINDING_DO_SETTING_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_CHECK_SHEET_BINDING_DO_SETTING_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_CHECK_SHEET_BINDING_DO_SETTING_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchCheckSheetDODetail(id_do){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_CHECK_SHEET_DO_DETAIL_LIST});
        axios({
            method: 'post',
            url: API_LINKS.CHECK_SHEET_BINDING_DO_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_do: id_do
            }
        })
        .then((response) => {
            if(response.data.statusCode === 200){
                let responseDataResult = response.data.result
                let id_check_sheet_do = responseDataResult? responseDataResult[0].id : null
                axios({
                    method: 'post',
                    url: API_LINKS.CHECK_SHEET_DO_DETAIL_LIST,
                    headers: {
                        Authorization: auth
                    },
                    data:{
                        id_check_sheet_do: id_check_sheet_do
                    }
                })
                .then((response) => {
                    dispatch( {
                        type: RECEIVE_CHECK_SHEET_DO_DETAIL_LIST,
                        payload: response.data
                    })
                })
            }
            dispatch( {
                type: RECEIVE_CHECK_SHEET_DO_DETAIL_LIST,
                payload: []
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_DO_DETAIL_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_DO_DETAIL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_DO_DETAIL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_CHECK_SHEET_DO_DETAIL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_CHECK_SHEET_DO_DETAIL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_CHECK_SHEET_DO_DETAIL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchCheckSheetDoApproval(id_do){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_CHECK_SHEET_DO_APPROVAL_LIST});
        axios({
            method: 'post',
            url: API_LINKS.CHECK_SHEET_BINDING_DO_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_do: id_do
            }
        })
        .then((response) => {
            if(response.data.statusCode === 200){
                let responseDataResult = response.data.result
                let id_check_sheet_do = responseDataResult? responseDataResult[0].id : null
                let id_check_sheet_approval_type = responseDataResult? responseDataResult[0].id_check_sheet_approval_type : null
                let fetchCheckSheetApprovalList = axios({
                    method: 'post',
                    url: API_LINKS.CHECK_SHEET_DO_APPROVAL_LIST,
                    headers: {
                        Authorization: auth
                    },
                    data:{
                        id_check_sheet_do: id_check_sheet_do
                    }
                })
                let fetchCheckSheetApprovalSettingList = axios({
                    method: 'post',
                    url: API_LINKS.CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST_URL,
                    headers: {
                        Authorization: auth
                    },
                    data:{
                        id_check_sheet_approval_type: id_check_sheet_approval_type
                    }
                })
                Promise.all([fetchCheckSheetApprovalList, fetchCheckSheetApprovalSettingList])
                .then((responses) => {
                    let payload = {}
                    if(!responses)
                        return payload
                    if(!responses.length > 0)
                        return payload
                    payload["users_approval_list"] = responses[0].data
                    payload["users_approval_setting"] = responses[1].data
                    return payload
                })
                .then((payload) => {
                    dispatch( {
                        type: RECEIVE_CHECK_SHEET_DO_APPROVAL_LIST,
                        payload: payload
                    })
                })
            }
            dispatch( {
                type: RECEIVE_CHECK_SHEET_DO_DETAIL_LIST,
                payload: []
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_DO_APPROVAL_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_DO_APPROVAL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_CHECK_SHEET_DO_APPROVAL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_CHECK_SHEET_DO_APPROVAL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_CHECK_SHEET_DO_APPROVAL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_CHECK_SHEET_DO_APPROVAL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}
