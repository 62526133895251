import { FETCH_STCK_COMPANY_BANK_ACC_LIST, RECEIVE_STCK_COMPANY_BANK_ACC_LIST, FAILED_STCK_COMPANY_BANK_ACC_LIST } from '../../actions/stocker/stck.companybankacc.act'

const defaultStckCompanyBankAccState = {
    stckCompanyBankAccData: [],
    stckTotalCompanyBankAcc: 0,
    stckCompanyBankAccSelect: [],
    success: true,
    inProgress: false,
}

export function StckCompanyBankAcc(state = defaultStckCompanyBankAccState, action){
    switch (action.type) {
        case FETCH_STCK_COMPANY_BANK_ACC_LIST:
            return ({stckCompanyBankAccData: [], success: true, stckTotalCompanyBankAcc: 0, stckCompanyBankAccSelect: [], inProgress: true})
        case RECEIVE_STCK_COMPANY_BANK_ACC_LIST:
            var _totalCompanyBankAcc = action.payload.result.length;
            var _stckCompanyBankAccSelect = []
            if(action.payload.result !== []) {
                var _stckCBankAcc = action.payload.result

                _stckCBankAcc.forEach((p, index) => {
                    var _loc = {
                        'label': _stckCBankAcc[index].bank_name + ' - ' + _stckCBankAcc[index].account_number,
                        'value': _stckCBankAcc[index].id
                    }
                    _stckCompanyBankAccSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckCompanyBankAccData: action.payload, success: true, stckTotalCompanyBankAcc: _totalCompanyBankAcc, stckCompanyBankAccSelect: _stckCompanyBankAccSelect, inProgress: false})
        case FAILED_STCK_COMPANY_BANK_ACC_LIST:
            return ({stckCompanyBankAccData: [], success: false, stckTotalCompanyBankAcc: 0, stckCompanyBankAccSelect: [], inProgress: false})
        default:
            return state
    }
}