import { FETCH_USER_TABLE, RECEIVE_USER_TABLE, FAILED_USER_TABLE } from '../../actions/aUser-UserListTable'

const defaultUserTableState = {
    userTableData: []    
}

export function fetchUserTable(state = defaultUserTableState, action){
    switch (action.type) {
        case FETCH_USER_TABLE:
            return ({userTableData: [], inProgress: true})
        case RECEIVE_USER_TABLE:
            let _stckUserTableDataSelect = []
            if(action.payload.result !== []) {
                let _userData = action.payload.result
                _userData.forEach((u, index) => {
                    let _userToPush = {
                        'label': _userData[index].first_name + " - " +_userData[index].email,
                        'value': _userData[index].id,
                        'id_level': _userData[index].id_level
                    }
                    _stckUserTableDataSelect.push(_userToPush)
                })
            }
            return Object.assign({}, state, {userTableData: action.payload, stckUserTableDataSelect : _stckUserTableDataSelect, inProgress: false, success: true})
        case FAILED_USER_TABLE:
            return ({userTableData: [], inProgress: false})
        default:
            return state
    }
}