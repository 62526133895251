import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD = 'FETCH_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD'
export const RECEIVE_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD = 'RECEIVE_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD'
export const FAILED_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD = 'FAILED_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD'

export function fetchFleetMaintenanceNotificationDashboard(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD});
        axios({
            method: 'post',
            url: API_LINKS.TRANSPORTER_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD_URL,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}