import { FETCH_STCK_PO_PAYMENT_LIST, RECEIVE_STCK_PO_PAYMENT_LIST, FAILED_STCK_PO_PAYMENT_LIST } from '../../actions/stocker/stck.popayment.act'

const defaultStckPoPaymentState = {
    stckPoPaymentData: [],
    stckTotalPoPayment: 0,
    stckPoPaymentSelect: [],
    success: true,
    inProgress: false,
}

export function StckPoPayment(state = defaultStckPoPaymentState, action){
    switch (action.type) {
        case FETCH_STCK_PO_PAYMENT_LIST:
            return ({stckPoPaymentData: [], success: true, stckTotalPoPayment: 0, stckPoPaymentSelect: [], inProgress: true})
        case RECEIVE_STCK_PO_PAYMENT_LIST:
            var _totalPoPayment = action.payload.result.length;
            var _stckPoPaymentSelect = []
            // if(action.payload.result !== []) {
            //     var _poApprovalData = action.payload.result

            //     _poApprovalData.forEach((p, index) => {
            //         var _loc = {
            //             'label': _poApprovalData[index].po_type_name + ' (' + _poApprovalData[index].po_type_desc + ')',
            //             'value': _poApprovalData[index].id,"id": 1,
            //             "id_company": 1,
            //             "id_purchase_order": 1,
            //             "po_number": "PO0001",
            //             "po_creation_date": "2021-10-15 21:00:00",
            //             "po_due_date": "2021-12-15 21:00:00",
            //             "id_po_rule": 1,
            //             "limit_po_value": 10,
            //             "step_count": 10,
            //             "id_user_approval": 122,
            //             "id_division": 1,
            //             "division_name": "",
            //             "remark": ""
            //         }
            //         _stckPoPaymentSelect.push(_loc)
            //     })
            // }
            return Object.assign({}, state, {stckPoPaymentData: action.payload, success: true, stckTotalPoPayment: _totalPoPayment, stckPoPaymentSelect: _stckPoPaymentSelect, inProgress: false})
        case FAILED_STCK_PO_PAYMENT_LIST:
            return ({stckPoPaymentData: [], success: false, stckTotalPoPayment: 0, stckPoPaymentSelect: [], inProgress: false})
        default:
            return state
    }
}