import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_LIVE_MONITORING_FOR_EST_COST = 'FETCH_LIVE_MONITORING_FOR_EST_COST'
export const RECEIVE_LIVE_MONITORING_FOR_EST_COST = 'RECEIVE_LIVE_MONITORING_FOR_EST_COST'
export const FAILED_LIVE_MONITORING_FOR_EST_COST = 'FAILED_LIVE_MONITORING_FOR_EST_COST'

export function fetchLiveMonitoringFleetForEstCost(_id_fleet){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return async (dispatch, getState) => {
        dispatch({type: FETCH_LIVE_MONITORING_FOR_EST_COST});
        axios({
            method: 'post',
            url: API_LINKS.LIVE_MONITORING_FOR_EST_COST_URL,
            headers: {
                Authorization: auth
            },
            data: {id_fleet : _id_fleet}
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_LIVE_MONITORING_FOR_EST_COST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_LIVE_MONITORING_FOR_EST_COST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_LIVE_MONITORING_FOR_EST_COST
                    })
                    return toast.error("Failed in fetching Work Orders List. Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_LIVE_MONITORING_FOR_EST_COST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_LIVE_MONITORING_FOR_EST_COST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_LIVE_MONITORING_FOR_EST_COST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_LIVE_MONITORING_FOR_EST_COST
                })
                return toast.error('Something went wrong... Please try again later...')
            }

        })
    }
}