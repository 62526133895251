import { FETCH_STCK_OPERATING_AREAS_LIST, RECEIVE_STCK_OPERATING_AREAS_LIST, FAILED_STCK_OPERATING_AREAS_LIST } from '../../actions/stocker/stck.operatingareas.act'

const defaultStckOperatingAreaState = {
    stckOperatingAreaData: [],
    stckTotalOperatingArea: 0,
    stckOperatingAreaSelect: [],
    success: true,
    inProgress: false,
}

export function StckOperatingAreas(state = defaultStckOperatingAreaState, action){
    switch (action.type) {
        case FETCH_STCK_OPERATING_AREAS_LIST:
            return ({stckOperatingAreaData: [], success: true, stckTotalOperatingArea: 0, stckOperatingAreaSelect: [], inProgress: true})
        case RECEIVE_STCK_OPERATING_AREAS_LIST:
            var _totalOperatingArea = action.payload.result.length;
            var _stckOperatingAreaSelect = []
            if(action.payload.result !== []) {
                var _operatingAreaData = action.payload.result

                _operatingAreaData.forEach((p, index) => {
                    var _loc = {
                        'label': _operatingAreaData[index].area_name,
                        'value': _operatingAreaData[index].id
                    }
                    _stckOperatingAreaSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckOperatingAreaData: action.payload, success: true, stckTotalOperatingArea: _totalOperatingArea, stckOperatingAreaSelect: _stckOperatingAreaSelect, inProgress: false})
        case FAILED_STCK_OPERATING_AREAS_LIST:
            return ({stckOperatingAreaData: [], success: false, stckTotalOperatingArea: 0, stckOperatingAreaSelect: [], inProgress: false})
        default:
            return state
    }
}