export default function handleErrorResponseCode(error) {
        try{
        const STATUS_CODES_ERROR = {
            400 : error.response.data.message ? error.response.data.message : "Invalid request!",
            401 : "Your account is unauthorized to perform the action!",
            403 : "Unauthorized Access! Please try to logout..",
            404 : "URL Not Found!",
            500 : "Server cannot be contacted! Please ask your system administrator!"
        }
        if (error.response) {
            console.log(error.message)
            return STATUS_CODES_ERROR[error.response.status];
        } else if (error.request) {
            /*
            * The request was made but no response was received, `error.request`
            * is an instance of XMLHttpRequest in the browser and an instance
            * of http.ClientRequest in Node.js
            */
            return "Request error! Please try again later...";
        } else {
            // Something happened in setting up the request and triggered an Error
            return  "Something went wrong... Please try again later..."
        }
    } catch(e){
        return "Request error! Please try again later...";
    }

}