import { FETCH_STCK_SALES_ORDER_LIST, RECEIVE_STCK_SALES_ORDER_LIST, FAILED_STCK_SALES_ORDER_LIST } from '../../actions/stocker/stck.salesorder.act'

const defaultStckSalesOrderState = {
    stckSalesOrderData: [],
    stckTotalSalesOrder: 0,
    stckSalesOrderSelect: [],
    stckSalesOrderIntercompanySelect: [],
    success: true,
    inProgress: false,
}

export function StckSalesOrder(state = defaultStckSalesOrderState, action){
    switch (action.type) {
        case FETCH_STCK_SALES_ORDER_LIST:
            return ({stckSalesOrderData: [], success: true, stckTotalSalesOrder: 0, stckSalesOrderSelect: [], stckSalesOrderIntercompanySelect: [], inProgress: true})
        case RECEIVE_STCK_SALES_ORDER_LIST:
            var _totalSalesOrder = action.payload.result.length;
            var _stckSalesOrderSelect = []
            var _stckSalesOrderIntercompanySelect = []
            if(action.payload.result !== []) {
                var _soData = action.payload.result
                _soData.forEach((p, index) => {
                    var _loc = {
                        'label': (_soData[index].human_so_number? _soData[index].human_so_number : _soData[index].so_number) + 
                                 (_soData[index].customer_name?  " - " + _soData[index].customer_name: null ),
                        'value': _soData[index].id,
                        "id": _soData[index].id,
                        "approval_status": _soData[index].approval_status,
                        "id_company": _soData[index].id_company,
                        "id_administration_office": _soData[index].id_administration_office,
                        "admin_office_name": _soData[index].admin_office_name,
                        "admin_office_address": _soData[index].admin_office_address,
                        "admin_office_main_phone_number": _soData[index].admin_office_main_phone_number,
                        "id_customer": _soData[index].id_customer,
                        "id_customer_pic": _soData[index].id_customer_pic,
                        "customer_name": _soData[index].customer_name,
                        "id_sales_order_type": _soData[index].id_sales_order_type,
                        "sales_order_type_name": _soData[index].sales_order_type_name,
                        "sales_order_type_limit_value": _soData[index].sales_order_type_limit_value,
                        "so_number": _soData[index].so_number,
                        "human_so_number": _soData[index].human_so_number,
                        "so_creation_date": _soData[index].so_creation_date,
                        "so_execution_date": _soData[index].so_execution_date,
                        "so_due_date": _soData[index].so_due_date,
                        "so_expected_total_value": _soData[index].so_expected_total_value,
                        "so_realization_total_value": _soData[index].so_realization_total_value,
                        "so_predicted_outbound_date": _soData[index].so_predicted_outbound_date,
                        "id_division_handler": _soData[index].id_division_handler,
                        "division_handler_name": _soData[index].division_handler_name,
                        "so_receiver_name": _soData[index].so_receiver_name,
                        "so_receiver_contact": _soData[index].so_receiver_contact,
                        "so_expected_total_item": _soData[index].so_expected_total_item,
                        "so_realization_total_item": _soData[index].so_realization_total_item,
                        "so_expected_tax_value": _soData[index].so_expected_tax_value,
                        "so_realization_tax_value": _soData[index].so_realization_tax_value,
                        "payment_term_day": _soData[index].payment_term_day,
                        "id_payment_term_condition": _soData[index].id_payment_term_condition,
                        "payment_term_condition_name": _soData[index].payment_term_condition_name,
                        "id_bank_account": _soData[index].id_bank_account,
                        "company_bank_account_number": _soData[index].company_bank_account_number,
                        "company_bank_branch_name": _soData[index].company_bank_branch_name,
                        "company_bank_account_holding_name": _soData[index].company_bank_account_holding_name,
                        "qr_code": _soData[index].qr_code,
                        "is_destination": _soData[index].is_destination? _soData[index].is_destination : null,
                    }
                    if(_soData[index].intercompany_id && (_soData[index].approval_status !== 5))
                    {
                        _stckSalesOrderIntercompanySelect.push(_loc)
                    }    
                    if(!_soData[index].intercompany_id){
                        _stckSalesOrderSelect.push(_loc)
                    }
                })
            }
            return Object.assign({}, state, {stckSalesOrderData: action.payload, success: true, stckTotalSalesOrder: _totalSalesOrder, 
                stckSalesOrderSelect: _stckSalesOrderSelect, stckSalesOrderIntercompanySelect: _stckSalesOrderIntercompanySelect, inProgress: false})
        case FAILED_STCK_SALES_ORDER_LIST:
            return ({stckSalesOrderData: [], success: false, stckTotalSalesOrder: 0, stckSalesOrderSelect: [], 
                    stckSalesOrderIntercompanySelect: [], inProgress: false})
        default:
            return state
    }
}
