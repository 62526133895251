import { FETCH_SECURITY_TABLE, RECEIVE_SECURITY_TABLE, FAILED_SECURITY_TABLE } from '../../actions/transporter/aTransporter-SecurityTable'

const defaultSecurityTableState = {
    securityTableData: [],
    success: false,
}

export function fetchSecurityTable(state = defaultSecurityTableState, action){
    switch (action.type) {
        case FETCH_SECURITY_TABLE:
            return ({securityTableData: [], success: false, inProgress: true})
        case RECEIVE_SECURITY_TABLE:
            let _stckSecurityDataSelect = []
            if(action.payload.result !== []) {
                let _userData = action.payload.result
                _userData.forEach((u, index) => {
                    let _userToPush = {
                        'label': _userData[index].name,
                        'value': _userData[index].id
                    }
                    _stckSecurityDataSelect.push(_userToPush)
                })
            }
            return Object.assign({}, state, {securityTableData: action.payload, stckSecurityDataSelect : _stckSecurityDataSelect, success: true, inProgress: false})
        case FAILED_SECURITY_TABLE:
            return ({securityTableData: [], success: false, inProgress: false})
        default:
            return state
    }
}