import axios from "axios";
import * as API_LINKS from '../../config/links'
import {toast} from 'react-toastify'
import handleErrorResponseCode from '../../../helpers/error-response-code-handler'

export const FETCH_PUBLIC_FACILITY_TYPE_LIST = 'FETCH_PUBLIC_FACILITY_TYPE_LIST'
export const RECEIVE_PUBLIC_FACILITY_TYPE_LIST = 'RECEIVE_PUBLIC_FACILITY_TYPE_LIST'
export const FAILED_PUBLIC_FACILITY_TYPE_LIST = 'FAILED_PUBLIC_FACILITY_TYPE_LIST'

export function fetchPublicFacilityType(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_PUBLIC_FACILITY_TYPE_LIST});
        axios({
            method: 'post',
            url: API_LINKS.PUBLIC_FACILITY_TYPE_LIST_URL,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_PUBLIC_FACILITY_TYPE_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            let errorResponseCode = error.response
            if (errorResponseCode) {
                dispatch({
                    type: FAILED_PUBLIC_FACILITY_TYPE_LIST
                })
                toast.error(
                    handleErrorResponseCode(errorResponseCode)
                )                 
            }
        })
    }
}