import { FETCH_STCK_BATCH_LIST, RECEIVE_STCK_BATCH_LIST, FAILED_STCK_BATCH_LIST } from '../../actions/stocker/stck.batch.act'

const defaultStckBatchsState = {
    stckBatchsData: [],
    stckTotalBatchs: 0,
    stckBatchsSelect: [],
    success: true,
    inProgress: false,
}

export function StckBatchs(state = defaultStckBatchsState, action){
    switch (action.type) {
        case FETCH_STCK_BATCH_LIST:
            return ({stckBatchsData: [], success: true, stckTotalBatchs: 0, stckBatchsSelect: [], inProgress: true})
        case RECEIVE_STCK_BATCH_LIST:
            var _totalUnits = action.payload.result.length;
            var _stckBatchsSelect = []
            if(action.payload.result !== []) {
                var _batchsData = action.payload.result
                _batchsData.forEach((p, index) => {
                    var _loc = {
                        'label': _batchsData[index].batch_number + " - " + _batchsData[index].name ,
                        'value': _batchsData[index].id
                    }
                    _stckBatchsSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckBatchsData: action.payload, success: true, stckTotalBatchs: _totalUnits, stckBatchsSelect: _stckBatchsSelect, inProgress: false})
        case FAILED_STCK_BATCH_LIST:
            return ({stckBatchsData: [], success: false, stckTotalBatchs: 0, stckBatchsSelect: [], inProgress: false})
        default:
            return state
    }
}