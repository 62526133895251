import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'
import moment from 'moment'

export const FETCH_REQUEST_LIST = 'FETCH_REQUEST_LIST'
export const RECEIVE_REQUEST_LIST = 'RECEIVE_REQUEST_LIST'
export const FAILED_REQUEST_LIST = 'FAILED_REQUEST_LIST'

export const FETCH_REQUEST_RENTEE_CONTRACT_LIST = 'FETCH_REQUEST_RENTEE_CONTRACT_LIST'
export const RECEIVE_REQUEST_RENTEE_CONTRACT_LIST = 'RECEIVE_REQUEST_RENTEE_CONTRACT_LIST'
export const FAILED_REQUEST_RENTEE_CONTRACT_LIST = 'FAILED_REQUEST_RENTEE_CONTRACT_LIST'

export const FETCH_REQUEST_CONTRACT_LIST = 'FETCH_REQUEST_CONTRACT_LIST'
export const RECEIVE_REQUEST_CONTRACT_LIST = 'RECEIVE_REQUEST_CONTRACT_LIST'
export const FAILED_REQUEST_CONTRACT_LIST = 'FAILED_REQUEST_CONTRACT_LIST'

export const FETCH_CONTRACT_LIST = 'FETCH_CONTRACT_LIST'
export const RECEIVE_CONTRACT_LIST = 'RECEIVE_CONTRACT_LIST'
export const FAILED_CONTRACT_LIST = 'FAILED_CONTRACT_LIST'

export const FETCH_CONTRACT_ALL_LIST = 'FETCH_CONTRACT_ALL_LIST'
export const RECEIVE_CONTRACT_ALL_LIST = 'RECEIVE_CONTRACT_ALL_LIST'
export const FAILED_CONTRACT_ALL_LIST = 'FAILED_CONTRACT_ALL_LIST'

export const FETCH_CONTRACT_OWNER_LIST = 'FETCH_CONTRACT_OWNER_LIST'
export const RECEIVE_CONTRACT_OWNER_LIST = 'RECEIVE_CONTRACT_OWNER_LIST'
export const FAILED_CONTRACT_OWNER_LIST = 'FAILED_CONTRACT_OWNER_LIST'

export const FETCH_FLEET_REQUEST_LIST = 'FETCH_FLEET_REQUEST_LIST'
export const RECEIVE_FLEET_REQUEST_LIST = 'RECEIVE_FLEET_REQUEST_LIST'
export const FAILED_FLEET_REQUEST_LIST = 'FAILED_FLEET_REQUEST_LIST'

export const FETCH_FLEET_REQUEST_DETAIL_LIST = 'FETCH_FLEET_REQUEST_DETAIL_LIST'
export const RECEIVE_FLEET_REQUEST_DETAIL_LIST = 'RECEIVE_FLEET_REQUEST_DETAIL_LIST' 
export const FAILED_FLEET_REQUEST_DETAIL_LIST = 'FAILED_FLEET_REQUEST_DETAIL_LIST'


export function fetchRenteeRequestList(id_group){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_REQUEST_LIST});
        axios({
            method: 'post',
            url: API_LINKS.RENTEE_REQUEST_LIST,
            headers: {
                Authorization: auth
            },
            data:{
                id_group: id_group
            }
        })
        .then((response) => {
            response.data.result.map(r => {
                r.start_date = moment(r.start_date).format("YYYY-MM-DD")
                r.end_date = moment(r.end_date).format("YYYY-MM-DD")
                return r;
            })
            response.data.result = response.data.result.filter(r => 
                r.id_contract === null
            )
            dispatch( {
                type: RECEIVE_REQUEST_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_REQUEST_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_REQUEST_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_REQUEST_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_REQUEST_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_REQUEST_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_REQUEST_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchRenteeRequestListContract(id_group){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_REQUEST_RENTEE_CONTRACT_LIST});
        axios({
            method: 'post',
            url: API_LINKS.RENTEE_REQUEST_LIST,
            headers: {
                Authorization: auth
            },
            data:{
                id_group: id_group
            }
        })
        .then((response) => {
            response.data.result.map(r => {
                r.start_date = moment(r.start_date).format("YYYY-MM-DD")
                r.end_date = moment(r.end_date).format("YYYY-MM-DD")
                if(r.approve_by_fleet_owner !== null && r.approve_by_rente !== null){
                    r.status = "approved"
                    return r
                }
                r.status="need approval"
                return r;
            })
            response.data.result = response.data.result.filter(r => 
                r.id_contract !== null
            )
            dispatch( {
                type: RECEIVE_REQUEST_RENTEE_CONTRACT_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_REQUEST_RENTEE_CONTRACT_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_REQUEST_RENTEE_CONTRACT_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_REQUEST_RENTEE_CONTRACT_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_REQUEST_RENTEE_CONTRACT_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_REQUEST_RENTEE_CONTRACT_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_REQUEST_RENTEE_CONTRACT_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}


export function fetchRenteeRequestListOwner(id_group){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_REQUEST_LIST});
        axios({
            method: 'post',
            url: API_LINKS.RENTEE_REQUEST_OWNER_LIST,
            headers: {
                Authorization: auth
            },
            data:{
                id_group: id_group
            }
        })
        .then((response) => {
            response.data.result.map(r => {
                r.start_date = moment(r.start_date).format("YYYY-MM-DD")
                r.end_date = moment(r.end_date).format("YYYY-MM-DD")
                return r;
            })
            response.data.result = response.data.result.filter(r => 
                r.id_contract === null
            )
            dispatch( {
                type: RECEIVE_REQUEST_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_REQUEST_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_REQUEST_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_REQUEST_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_REQUEST_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_REQUEST_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_REQUEST_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchRenteeRequestListOwnerContract(id_group){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_REQUEST_CONTRACT_LIST});
        axios({
            method: 'post',
            url: API_LINKS.RENTEE_REQUEST_OWNER_LIST,
            headers: {
                Authorization: auth
            },
            data:{
                id_group: id_group
            }
        })
        .then((response) => {
            response.data.result.map(r => {
                r.start_date = moment(r.start_date).format("YYYY-MM-DD")
                r.end_date = moment(r.end_date).format("YYYY-MM-DD")
                if(r.approve_by_fleet_owner !== null && r.approve_by_rente !== null){
                    r.status = "approved"
                    return r
                }
                r.status="need rentee approval"
                return r
            })
            response.data.result = response.data.result.filter(r => 
                r.id_contract !== null
            )
            dispatch( {
                type: RECEIVE_REQUEST_CONTRACT_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_REQUEST_CONTRACT_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_REQUEST_CONTRACT_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_REQUEST_CONTRACT_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_REQUEST_CONTRACT_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_REQUEST_CONTRACT_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_REQUEST_CONTRACT_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchRenteeContractList(id_contract){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_CONTRACT_LIST});
        axios({
            method: 'post',
            url: API_LINKS.RENTEE_CONTRACT_LIST,
            headers: {
                Authorization: auth
            },
            data:{
                id_contract: id_contract
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_CONTRACT_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_CONTRACT_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_CONTRACT_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_CONTRACT_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_CONTRACT_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_CONTRACT_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_CONTRACT_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchRenteeContractListAll(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_CONTRACT_ALL_LIST});
        let id_requests = []
        axios({
            method: 'post',
            url: API_LINKS.RENTEE_REQUEST_LIST,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            id_requests = response.data.result
            response.data.result = response.data.result.filter(r => 
                (r.id_contract !== null) && (moment(moment().format("YYYY-MM-DD")) >= moment(moment(r.start_date).format("YYYY-MM-DD"))) && 
                (moment(moment().format("YYYY-MM-DD")) <= moment(moment(r.end_date).format("YYYY-MM-DD")))
            )
            let fetchAllContract = []
            response.data.result.forEach(req => {
                fetchAllContract.push(        
                axios({
                    method: 'post',
                    url: API_LINKS.RENTEE_CONTRACT_LIST,
                    headers: {
                        Authorization: auth
                    },
                    data:{
                        id_contract: req.id_contract
                    }
                }))
            })
            Promise.all([...fetchAllContract])
            .then(responses => {
                let payload = []
                for(let response of responses){
                    let responseResult = response.data.result
                    if(!responseResult)
                        continue
                    if(!responseResult.length > 0)
                        continue
                    let getRequestId = id_requests.filter(req => req.id_contract === responseResult[0].id)[0].id
                    responseResult[0]["id_request"] = getRequestId
                    payload = [...payload, ...responseResult]
                }
                return payload
            })
            .then(payload => {
                dispatch( {
                    type: RECEIVE_CONTRACT_ALL_LIST,
                    payload: payload
                })
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_CONTRACT_ALL_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_CONTRACT_ALL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_CONTRACT_ALL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_CONTRACT_ALL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_CONTRACT_ALL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_CONTRACT_ALL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchRenteeContractListOwnerAll(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_CONTRACT_OWNER_LIST});
        let id_requests = []
        axios({
            method: 'post',
            url: API_LINKS.RENTEE_REQUEST_OWNER_LIST,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            id_requests = response.data.result
            response.data.result = response.data.result.filter(r => 
                r.id_contract !== null && (moment(moment().format("YYYY-MM-DD")) >= moment(moment(r.start_date).format("YYYY-MM-DD"))) && 
                (moment(moment().format("YYYY-MM-DD")) <= moment(moment(r.end_date).format("YYYY-MM-DD")))
            )
            let fetchAllContract = []
            response.data.result.forEach(req => {
                fetchAllContract.push(        
                axios({
                    method: 'post',
                    url: API_LINKS.RENTEE_CONTRACT_LIST,
                    headers: {
                        Authorization: auth
                    },
                    data:{
                        id_contract: req.id_contract
                    }
                }))
            })
            Promise.all([...fetchAllContract])
            .then(responses => {
                let payload = []
                for(let response of responses){
                    let responseResult = response.data.result
                    if(!responseResult)
                        continue
                    if(!responseResult.length > 0)
                        continue
                    let getRequestId = id_requests.filter(req => req.id_contract === responseResult[0].id)[0].id
                    responseResult[0]["id_request"] = getRequestId
                    payload = [...payload, ...responseResult]
                }
                return payload
            })
            .then(payload => {
                dispatch( {
                    type: RECEIVE_CONTRACT_OWNER_LIST,
                    payload: payload
                })
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_CONTRACT_OWNER_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_CONTRACT_OWNER_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_CONTRACT_OWNER_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_CONTRACT_OWNER_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_CONTRACT_OWNER_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_CONTRACT_OWNER_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}


export function fetchRenteeFleetRequestList(id_group){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_FLEET_REQUEST_LIST});
        axios({
            method: 'post',
            url: API_LINKS.RENTEE_FLEET_REQUEST_LIST,
            headers: {
                Authorization: auth
            },
            data:{
                id_group:id_group
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_FLEET_REQUEST_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_FLEET_REQUEST_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_FLEET_REQUEST_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_FLEET_REQUEST_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_FLEET_REQUEST_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_FLEET_REQUEST_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_FLEET_REQUEST_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchRenteeFleetRequestListDetail(id_group, id_request){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_FLEET_REQUEST_DETAIL_LIST});
        axios({
            method: 'post',
            url: API_LINKS.RENTEE_REQUEST_FLEET_OWNER_LIST,
            headers: {
                Authorization: auth
            },
            data:{
                id_group:id_group,
                id_request:id_request
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_FLEET_REQUEST_DETAIL_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_FLEET_REQUEST_DETAIL_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_FLEET_REQUEST_DETAIL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_FLEET_REQUEST_DETAIL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_FLEET_REQUEST_DETAIL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_FLEET_REQUEST_DETAIL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_FLEET_REQUEST_DETAIL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}