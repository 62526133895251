import { FETCH_WAREHOUSE_TRANSFER_APPROVAL_TYPE_LIST, 
    RECEIVE_WAREHOUSE_TRANSFER_APPROVAL_TYPE_LIST, 
    FAILED_WAREHOUSE_TRANSFER_APPROVAL_TYPE_LIST }
from '../../actions/stocker/stck.warehousetransferapprovaltype.act'

const defaultStckWarehouseTransferApprovalTypeState = {
    stckWarehouseTransferApprovalTypeData: [],
    stckTotalWarehouseTransferApprovalType: 0,
    stckWarehouseTransferApprovalTypeSelect: [],
    success: true,
    inProgress: false,
}

export function StckWarehouseTransferApprovalType(state = defaultStckWarehouseTransferApprovalTypeState, action){
switch (action.type) {
case FETCH_WAREHOUSE_TRANSFER_APPROVAL_TYPE_LIST:
    return ({stckWarehouseTransferApprovalTypeData: [], success: true, stckTotalWarehouseTransferApprovalType: 0, stckWarehouseTransferApprovalTypeSelect: [], inProgress: true})
case RECEIVE_WAREHOUSE_TRANSFER_APPROVAL_TYPE_LIST:
    var _totalUnits = action.payload.result.length;
    var _stckWarehouseTransferApprovalTypeSelect = []
    if(action.payload.result !== []) {
        var _payloadResult = action.payload.result
        _payloadResult.forEach((p, index) => {
            var _loc = {
                'label': _payloadResult[index].name ,
                'value': _payloadResult[index].id
            }
            _stckWarehouseTransferApprovalTypeSelect.push(_loc)
        })
    }
    return Object.assign({}, state, {stckWarehouseTransferApprovalTypeData: action.payload, success: true, stckTotalWarehouseTransferApprovalType: _totalUnits, stckWarehouseTransferApprovalTypeSelect: _stckWarehouseTransferApprovalTypeSelect, inProgress: false})
case FAILED_WAREHOUSE_TRANSFER_APPROVAL_TYPE_LIST:
    return ({stckWarehouseTransferApprovalTypeData: [], success: false, stckTotalWarehouseTransferApprovalType: 0, stckWarehouseTransferApprovalTypeSelect: [], inProgress: false})
default:
    return state
}
}