import axios from "axios";
import {
  WALLET_ERROR_GET_SALDO,
  WALLET_OPEN_PAYMENT_MODAL,
  WALLET_PENDING_GET_SALDO,
  WALLET_SUCCESS_GET_SALDO,
} from "../../types/wallet/tWallet-Saldo";
import * as API_LINKS from "../../config/links";

const addZero = (value) => {
  return value < 10 ? `0${value}` : `${value}`;
};

export const fetchSaldo = () => async (dispatch, getState) => {
  try {
    const localState = getState();

    const dt = new Date();
    const str = `${dt.getFullYear()}-${addZero(dt.getMonth() + 1)}-${addZero(
      dt.getDate()
    )} ${addZero(dt.getHours())}:${addZero(dt.getMinutes())}`;
    dispatch({
      type: WALLET_PENDING_GET_SALDO,
      payload: str,
    });

    const response = await axios({
      url: API_LINKS.USER_SALDO,
      method: "post",
      headers: {
        Authorization: localState.authStore.authData.result.token,
      },
    });

    dispatch({
      type: WALLET_SUCCESS_GET_SALDO,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: WALLET_ERROR_GET_SALDO,
      payload: err,
    });
  }
};

export const onPayment = (params) => (dispatch, getState) => {
  const localState = getState();
  const openModal = !localState.walletSaldo.isModalPaymentOpen;
  let xenditUrl = null;
  let paymentTotalAmount = null;
  let invoiceId = null;
  let companyDestinationId = null;
  let typeTransaction = null;
  let agreementText = "";
  let additionalFunction = () => null;

  if (openModal) {
    xenditUrl = params && params.xenditUrl ? params.xenditUrl : null;
    paymentTotalAmount =
      params && params.paymentTotalAmount ? params.paymentTotalAmount : null;
    invoiceId = params && params.invoiceId ? params.invoiceId : null;
    companyDestinationId =
      params && params.companyDestinationId
        ? params.companyDestinationId
        : null;
    typeTransaction =
      params && params.typeTransaction ? params.typeTransaction : null;
    agreementText = params && params.agreementText ? params.agreementText : "";
    additionalFunction =
      params && params.additionalFunction
        ? params.additionalFunction
        : () => null;
  }

  dispatch({
    type: WALLET_OPEN_PAYMENT_MODAL,
    payload: {
      openModal,
      xenditUrl,
      paymentTotalAmount,
      invoiceId,
      companyDestinationId,
      typeTransaction,
      agreementText,
      additionalFunction,
    },
  });
};
