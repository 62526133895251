import { FETCH_FLEET_REPORT, RECEIVE_FLEET_REPORT, FAILED_FLEET_REPORT, CLEAR_FLEET_REPORT,
    PROCESS_TRIP_MODE, RECEIVE_TRIP_MODE_PROCESS, FAILED_PROCESS_TRIP_MODE, CLEAR_FLEET_REPORT_TRIP_MODE } from '../../actions/transporter/aTransporter-FleetReport.jsx'
import moment from 'moment';

const defaultFleetReportState = {
    fleetReportTableData: [],
    advanceFilterTrip: [],
    advanceFilterStopOnly:[],
    advanceFilterStopOnlylength: null,
    fleetReportSavedPlaces: [],
    fleetReportPublicPlaces: [],
    tripNumbers:{},
    inProgress: false,
    success: true
}

export function fetchFleetReport(state = defaultFleetReportState, action){
    switch (action.type) {
        case FETCH_FLEET_REPORT:
            return ({fleetReportTableData: [], fleetReportMaps: [], advanceFilterTrip: [], tripNumbers:{}, fleetReportSavedPlaces: [],
                fleetReportPublicPlaces:[], advanceFilterStopOnlylength: null, advanceFilterStopOnly:[], inProgress: true, success: false})
        case RECEIVE_FLEET_REPORT:
            var list = []
            var list2 = []
            let nearStopSavedPlaces = []
            let nearStopPublicPlaces = []
            if(action.payload.length !== 0) {
                var liveMonitoringData = action.payload.result
                liveMonitoringData.forEach((p, index) => {
                    if(liveMonitoringData[index].lat !== null || liveMonitoringData[index].lng !== null) {
                        var data = {
                            'id': liveMonitoringData[index].no,
                            'obd_id': liveMonitoringData[index].id,
                            'lat': liveMonitoringData[index].lat,
                            'lng': liveMonitoringData[index].lon,
                            'wrn': liveMonitoringData[index].wrn,
                            'spd': liveMonitoringData[index].spd,
                            'time': moment(liveMonitoringData[index].time).format("DD-MMM-YYYY HH:mm:ss")
                        }
                        list.push(data)

                        var _dataMap = {
                            "acc": liveMonitoringData[index].acc,
                            "created_on": liveMonitoringData[index].created_on,
                            "dev": liveMonitoringData[index].dev,
                            "door_left": liveMonitoringData[index].door_left,
                            "door_rear": liveMonitoringData[index].door_rear,
                            "door_right": liveMonitoringData[index].door_right,
                            "fuel": liveMonitoringData[index].fuel,
                            "id": liveMonitoringData[index].id,
                            "lat": liveMonitoringData[index].lat,
                            "lon": liveMonitoringData[index].lon,
                            "sp": liveMonitoringData[index].sp,
                            "spd": liveMonitoringData[index].spd,
                            "temp_c": liveMonitoringData[index].temp_c,
                            "time": liveMonitoringData[index].time,
                            "vsys": liveMonitoringData[index].vsys,
                            "wrn": liveMonitoringData[index].wrn,
                            "_id": liveMonitoringData[index]._id,
                            'wrn_normalized': (liveMonitoringData[index].wrn * 100), //normalized
                            'door_rear_normalized': (liveMonitoringData[index].door_rear * 98), //normalized
                        }

                        list2.push(_dataMap)
                    }
                })
                if(action.payload.stop_only){
                     action.payload.stop_only.map(ns => {
                        if(ns.saved_place_near.length > 0)
                            nearStopSavedPlaces.push(...ns.saved_place_near)
                        return ns
                    })
                    action.payload.stop_only.map(ns => {
                        if(ns.public_place_near.length > 0)
                            nearStopPublicPlaces.push(...ns.public_place_near)
                        return ns;
                    })
                }
            }
            return Object.assign({}, state, {fleetReportTableData: list2, fleetReportMaps: list, advanceFilterTrip:action.payload.trip, 
                fleetReportSavedPlaces:nearStopSavedPlaces, fleetReportPublicPlaces:nearStopPublicPlaces,              
                tripNumbers:action.payload.trip_numbers, advanceFilterStopOnlylength:action.payload.stop_only.length, advanceFilterStopOnly:action.payload.stop_only, inProgress: false, success: true})
        case FAILED_FLEET_REPORT:
            return ({fleetReportTableData: [], fleetReportMaps: [], advanceFilterTrip: [], tripNumbers:{},  fleetReportSavedPlaces: [],
                fleetReportPublicPlaces:[], advanceFilterStopOnlylength: null, advanceFilterStopOnly:[], inProgress: false, success: false})
        case CLEAR_FLEET_REPORT:
            return ({fleetReportTableData: [], fleetReportMaps: [], advanceFilterTrip: [], tripNumbers:{}, fleetReportSavedPlaces: [],
                fleetReportPublicPlaces:[], advanceFilterStopOnlylength: null, advanceFilterStopOnly:[], inProgress: false, success: true})
        default:
            return state
    }
}


const defaultFleetReportTripState = {
    fleetReportTripByEngine: [],
    fleetReportTripByTime: [],
    tripNumbersByEngine: [],
    tripNumbersByTime: [],
    loading: "initial",
}

export function changeFleetReportTrip(state = defaultFleetReportTripState, action){
    switch (action.type) {
        case PROCESS_TRIP_MODE:
            return ({fleetReportTripByEngine: [], fleetReportTripByTime: [], loading: "loading",
            tripNumbersByEngine: [], tripNumbersByTime: []})
        case RECEIVE_TRIP_MODE_PROCESS:
            let _fleetReportTripByEngine = state.fleetReportTripByEngine
            let _fleetReportTripByTime = state.fleetReportTripByTime
            let _tripNumbersByEngine = state.tripNumbersByEngine
            let _tripNumbersByTime = state.tripNumbersByTime
            if(action.mode === 2){
                _fleetReportTripByEngine = action.payload
                _tripNumbersByEngine = action.tripNumbersByEngine
            }
            if(action.mode === 3){
                _fleetReportTripByTime = action.payload
                _tripNumbersByTime = action.tripNumbersByTime
            }
            return ({fleetReportTripByEngine: _fleetReportTripByEngine, fleetReportTripByTime: _fleetReportTripByTime, 
                        loading: "success", tripNumbersByEngine: _tripNumbersByEngine, tripNumbersByTime: _tripNumbersByTime})
        case FAILED_PROCESS_TRIP_MODE:
            return ({fleetReportTripByEngine: [], fleetReportTripByTime: [], loading: "failed",
                        tripNumbersByEngine: [], tripNumbersByTime: []})
        case CLEAR_FLEET_REPORT_TRIP_MODE:
            return ({fleetReportTripByEngine: [], fleetReportTripByTime: [], loading: "success",
                         tripNumbersByEngine: [], tripNumbersByTime: []})
        default:
            return state
    }
}