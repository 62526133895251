import { FETCH_PTH_CUSTOMER_APPROVAL_LIST, RECEIVE_PTH_CUSTOMER_APPROVAL_LIST, FAILED_PTH_CUSTOMER_APPROVAL_LIST } from '../../actions/pointhub/pth.customerapproval.act'

const defaultPthCustomerApprovalState = {
    pthCustomerApprovalData: [],
    pthTotalCustomerApproval: 0,
    pthCustomerApprovalSelect: [],
    success: true,
    inProgress: false,
}

export function PthCustomerApproval(state = defaultPthCustomerApprovalState, action){
    switch (action.type) {
        case FETCH_PTH_CUSTOMER_APPROVAL_LIST:
            return ({pthCustomerApprovalData: [], success: true, pthTotalCustomerApproval: 0, pthCustomerApprovalSelect: [], inProgress: true})
        case RECEIVE_PTH_CUSTOMER_APPROVAL_LIST:
            var _totalCustomerApproval = action.payload.result.length;
            var _pthCustomerApprovalSelect = []
            if(action.payload.result !== []) {
                var _pthCustApprovalData = action.payload.result

                _pthCustApprovalData.forEach((p, index) => {
                    var _loc = {
                        'label': _pthCustApprovalData[index].name,
                        'value': _pthCustApprovalData[index].id
                    }
                    _pthCustomerApprovalSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {pthCustomerApprovalData: action.payload, success: true, pthTotalCustomerApproval: _totalCustomerApproval, pthCustomerApprovalSelect: _pthCustomerApprovalSelect, inProgress: false})
        case FAILED_PTH_CUSTOMER_APPROVAL_LIST:
            return ({pthCustomerApprovalData: [], success: false, pthTotalCustomerApproval: 0, pthCustomerApprovalSelect: [], inProgress: false})
        default:
            return state
    }
}