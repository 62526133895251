import { FETCH_COVID_TEST_LIST, RECEIVE_COVID_TEST_LIST, FAILED_COVID_TEST_LIST } from '../../actions/transporter/covidtest.act'

const defaultCovidTestListState = {
    covidTestListData: [],
    inProgress: false,
    success: true, 
    totalTest: 0, 
}

export function CovidTestList(state = defaultCovidTestListState, action){
    switch (action.type) {
        case FETCH_COVID_TEST_LIST:
            return ({covidTestListData: [], success: false, inProgress: true, totalTest: 0})
        case RECEIVE_COVID_TEST_LIST:
            var _total = action.payload.result.length
            return Object.assign({}, state, {covidTestListData: action.payload.result, success: true, inProgress: false, totalTest: _total})
        case FAILED_COVID_TEST_LIST:
            return ({covidTestListData: [], success: false, inProgress: false, totalTest: 0})
        default:
            return state
    }
}