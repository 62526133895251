import { FETCH_FUEL_MANAGEMENT_LIST, RECEIVE_FUEL_MANAGEMENT_LIST, FAILED_FUEL_MANAGEMENT_LIST } from '../../actions/transporter/aTransporter-FuelManagement'

const defaultFuelManagementListState = {
    fuelManagementListData: []  ,
    totalFuelTopup: 0,
    loading: false,
    success: true,
}

export function fetchFuelManagementList(state = defaultFuelManagementListState, action){
    switch (action.type) {
        case FETCH_FUEL_MANAGEMENT_LIST:
            return ({fuelManagementListData: [], totalFleetMaintenance: 0, success: true, inProgress: true})
        case RECEIVE_FUEL_MANAGEMENT_LIST:
            var totalFuelTopup = action.payload.result.length;
            return Object.assign({}, state, {fuelManagementListData: action.payload.result, totalFuelTopup: totalFuelTopup, success: true, inProgress: false})
        case FAILED_FUEL_MANAGEMENT_LIST:
            return ({fuelManagementListData: [], totalFleetMaintenance: 0, success: false, inProgress: false})
        default:
            return state
    }
}