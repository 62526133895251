import { FETCH_STCK_SO_TYPE_LIST, RECEIVE_STCK_SO_TYPE_LIST, FAILED_STCK_SO_TYPE_LIST } from '../../actions/stocker/stck.sotype.act'

const defaultStckSoTypeState = {
    stckSoTypeData: [],
    stckTotalSoType: 0,
    stckSoTypeSelect: [],
    success: true,
    inProgress: false,
}

export function StckSoType(state = defaultStckSoTypeState, action){
    switch (action.type) {
        case FETCH_STCK_SO_TYPE_LIST:
            return ({stckSoTypeData: [], success: true, stckTotalSoType: 0, stckSoTypeSelect: [], inProgress: true})
        case RECEIVE_STCK_SO_TYPE_LIST:
            var _totalSoType = action.payload.result.length;
            var _stckSoTypeSelect = []
            if(action.payload.result !== []) {
                var _soTypeData = action.payload.result

                _soTypeData.forEach((p, index) => {
                    var _loc = {
                        'label': _soTypeData[index].so_type_name + ' (' + _soTypeData[index].so_type_desc + ')',
                        'value': _soTypeData[index].id,
                        "id_company": _soTypeData[index].id_company,
                        "so_type_name": _soTypeData[index].so_type_name,
                        "so_type_desc": _soTypeData[index].so_type_desc,
                        "so_limit_value": _soTypeData[index].so_limit_value,
                        "id_status": _soTypeData[index].id_status,
                        "created_on": _soTypeData[index].created_on,
                        "modified_on": _soTypeData[index].modified_on,
                    }
                    _stckSoTypeSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckSoTypeData: action.payload, success: true, stckTotalSoType: _totalSoType, stckSoTypeSelect: _stckSoTypeSelect, inProgress: false})
        case FAILED_STCK_SO_TYPE_LIST:
            return ({stckSoTypeData: [], success: false, stckTotalSoType: 0, stckSoTypeSelect: [], inProgress: false})
        default:
            return state
    }
}