import { FETCH_FLEET_MAINTENANCE_TABLE, RECEIVE_FLEET_MAINTENANCE_TABLE, FAILED_FLEET_MAINTENANCE_TABLE } from '../../actions/transporter/aTransporter-FleetMaintenanceTable'

const defaultFleetMaintenanceTableState = {
    fleetMaintenanceTableData: []  ,
    totalFleetMaintenance: 0,
    success: false,
}

export function fetchFleetMaintenanceTable(state = defaultFleetMaintenanceTableState, action){
    switch (action.type) {
        case FETCH_FLEET_MAINTENANCE_TABLE:
            return ({fleetMaintenanceTableData: [], totalFleetMaintenance: 0, success: true, inProgress: true})
        case RECEIVE_FLEET_MAINTENANCE_TABLE:
            var totalFleetMaintenance = action.payload.result.length;
            return Object.assign({}, state, {fleetMaintenanceTableData: action.payload.result, totalFleetMaintenance: totalFleetMaintenance, success: true, inProgress: false})
        case FAILED_FLEET_MAINTENANCE_TABLE:
            return ({fleetMaintenanceTableData: [], totalFleetMaintenance: 0, success: false, inProgress: false})
        default:
            return state
    }
}