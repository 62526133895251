import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_DELIVERY_AUDIT = 'FETCH_DELIVERY_AUDIT'
export const RECEIVE_DELIVERY_AUDIT = 'RECEIVE_DELIVERY_AUDIT'
export const FAILED_DELIVERY_AUDIT = 'FAILED_DELIVERY_AUDIT'

export function fetchDeliveryAudit(_id){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(auth)

    return (dispatch, getState) => {
        dispatch({type: FETCH_DELIVERY_AUDIT});
        axios({
            method: 'post',
            url: API_LINKS.DELIVERY_AUDIT_URL,
            headers: {
                Authorization: auth
            },
            data: {
                id: _id
                // date: 
                // For Parameters
            }
        })
        .then((response) => {
            if(response.data.status === "success") {
              return response.data
            }
        })
        .then(async response => {
            const {checkin} = response.result || null
            if(!Array.isArray(checkin))
                throw new Error("")
            let responseWOPODVerif = await axios({
                method: 'post',
                url: API_LINKS.WORK_ORDER_POD_VERIF_LOG,
                headers: {
                    Authorization: auth
                },
            })
            let responseResult = responseWOPODVerif.data.result.result
            if(Array.isArray(responseResult)){
                for(let data of checkin){
                    data.work_order_from.pod_verif_log = responseResult.filter(r => 
                            r.id_work_order === data.work_order_from.id
                    )
                }
            }  
            dispatch( {
                type: RECEIVE_DELIVERY_AUDIT,
                payload: response
            })   
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_DELIVERY_AUDIT
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_DELIVERY_AUDIT
                    })
                    return toast.error("Failed in fetching Delivery AUDIT List. Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_DELIVERY_AUDIT
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_DELIVERY_AUDIT
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_DELIVERY_AUDIT
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_DELIVERY_AUDIT
                })
                return toast.error('Something went wrong... Please try again later...')
            }

        })
    }
}

export function fetchDeliveryAuditFamily(_id){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(auth)

    return (dispatch, getState) => {
        dispatch({type: FETCH_DELIVERY_AUDIT});
        axios({
            method: 'post',
            url: API_LINKS.DELIVERY_AUDIT_URL_FAMILY,
            headers: {
                Authorization: auth
            },
            data: {
                id: _id
                // date: 
                // For Parameters
            }
        })
        .then((response) => {
            if(response.data.status === "success") {
              return response.data
            }
        })
        .then(async response => {
            const {checkin} = response.result || null
            if(!Array.isArray(checkin))
                throw new Error("")
            let responseWOPODVerif = await axios({
                method: 'post',
                url: API_LINKS.WORK_ORDER_POD_VERIF_LOG,
                headers: {
                    Authorization: auth
                },
            })
            let responseResult = responseWOPODVerif.data.result.result
            if(Array.isArray(responseResult)){
                for(let data of checkin){
                    data.work_order_from.pod_verif_log = responseResult.filter(r => 
                            r.id_work_order === data.work_order_from.id
                    )
                }
            }  
            dispatch( {
                type: RECEIVE_DELIVERY_AUDIT,
                payload: response
            })   
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_DELIVERY_AUDIT
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_DELIVERY_AUDIT
                    })
                    return toast.error("Failed in fetching Delivery AUDIT List. Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_DELIVERY_AUDIT
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_DELIVERY_AUDIT
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_DELIVERY_AUDIT
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_DELIVERY_AUDIT
                })
                return toast.error('Something went wrong... Please try again later...')
            }

        })
    }
}