import { FETCH_INBOUND_ITEM_DETAIL_LIST, RECEIVE_INBOUND_ITEM_DETAIL_LIST, FAILED_INBOUND_ITEM_DETAIL_LIST } from '../../actions/stocker/stck.inbounditemdetail.act'

const defaultStckInboundItemDetailState = {
    stckInboundItemDetailData: [],
    stckTotalInboundItemDetail: 0,
    stckInboundItemDetailSelect: [],
    success: true,
    inProgress: false,
}

export function StckInboundItemDetail(state = defaultStckInboundItemDetailState, action){
    switch (action.type) {
        case FETCH_INBOUND_ITEM_DETAIL_LIST:
            return ({stckInboundItemDetailData: [], success: true, stckTotalInboundItemDetail: 0, stckInboundItemDetailSelect: [], inProgress: true})
        case RECEIVE_INBOUND_ITEM_DETAIL_LIST:
            var _totalInboundItemDetail = action.payload.result.length;
            var _stckInboundItemDetailSelect = []
            if(action.payload.result !== []) {
                var _inboundItemDetailData = action.payload.result

                _inboundItemDetailData.forEach((p, index) => {
                    var _loc = {
                        'label': _inboundItemDetailData[index].inbound_number,
                        'value': _inboundItemDetailData[index].id,
                        "id": _inboundItemDetailData[index].id,
                        "id_master_inbound": _inboundItemDetailData[index].id_master_inbound,
                        "inbound_number": _inboundItemDetailData[index].inbound_number,
                        "document_number": _inboundItemDetailData[index].document_number,
                        "id_inbound_detail": _inboundItemDetailData[index].id_inbound_detail,
                        "awb_number": _inboundItemDetailData[index].awb_number,
                        "id_master_item": _inboundItemDetailData[index].id_master_item,
                        "item_name": _inboundItemDetailData[index].item_name,
                        "sku_item_name": _inboundItemDetailData[index].sku_item_name,
                        "expected_qty": _inboundItemDetailData[index].expected_qty,
                        "realization_qty": _inboundItemDetailData[index].realization_qty,
                        "rejected_qty": _inboundItemDetailData[index].rejected_qty,
                        "batch_number": _inboundItemDetailData[index].batch_number,
                        "production_date": _inboundItemDetailData[index].production_date,
                        "expired_date": _inboundItemDetailData[index].expired_date,
                        "id_receiver": _inboundItemDetailData[index].id_receiver,
                    }
                    _stckInboundItemDetailSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckInboundItemDetailData: action.payload, success: true, stckTotalInboundItemDetail: _totalInboundItemDetail, stckInboundItemDetailSelect: _stckInboundItemDetailSelect, inProgress: false})
        case FAILED_INBOUND_ITEM_DETAIL_LIST:
            return ({stckInboundItemDetailData: [], success: false, stckTotalInboundItemDetail: 0, stckInboundItemDetailSelect: [], inProgress: false})
        default:
            return state
    }
}