import { FETCH_LL_LOCATION_LIST, RECEIVE_LL_LOCATION_LIST, FAILED_LL_LOCATION_LIST } from '../../actions/landlord/aLandLord-LocationList'

const defaultLLLocationState = {
    llLocationData: [],
    llTotalLocation: 0,
    llLocationSelect: [],
    success: true,
    inProgress: false,
}

export function fetchLLLocationList(state = defaultLLLocationState, action){
    switch (action.type) {
        case FETCH_LL_LOCATION_LIST:
            return ({llLocationData: [], success: true, llTotalLocation: 0, llLocationSelect: [], inProgress: true})
        case RECEIVE_LL_LOCATION_LIST:
            var _totalLocation = action.payload.result.length;
            var _selectLocation = []
            if(action.payload.result !== []) {
                var _locationData = action.payload.result

                _locationData.forEach((p, index) => {
                    var _loc = {
                        'label': _locationData[index].location_name,
                        'value': _locationData[index].id
                    }
                    _selectLocation.push(_loc)
                })
            }
            return Object.assign({}, state, {llLocationData: action.payload, success: true, llTotalLocation: _totalLocation, llLocationSelect: _selectLocation, inProgress: false})
        case FAILED_LL_LOCATION_LIST:
            return ({llLocationData: [], success: false, llTotalLocation: 0, llLocationSelect: [], inProgress: false})
        default:
            return state
    }
}