import { ADD_SHAPE, REMOVE_SHAPE, 
        SELECT_SHAPE, UPDATE_SHAPE,
       FETCH_WAREHOUSE_MAPPING_LIST, RECEIVE_WAREHOUSE_MAPPING_LIST, 
       FAILED_WAREHOUSE_MAPPING_LIST ,CHANGE_MAPPING_MODE,
    } from '../../actions/stocker/stck.rackvisualbuilder.act'

const defaultStckRackVisualBuilderState = {
    shapes: [],
    deletionTime: null,
    selectedShape: null,
    availableRackId: {},
    mappingMode: 1,
    loading: null,
    toInputShapes: [],
    toEditShapes: [],
    toDeleteShapes: []
}

const updateShape = (shape, index, shapeUpdate) => {
    if(shapeUpdate.type === "SIZE"){
        shape[index].width = shapeUpdate.value.width
        shape[index].height = shapeUpdate.value.height
        shape[index].skewX = shapeUpdate.value.skewX
        shape[index].skewY = shapeUpdate.value.skewY
        shape[index].rotation = shapeUpdate.value.rotation
        shape[index].scaleX = shapeUpdate.value.scaleX
        shape[index].scaleY = shapeUpdate.value.scaleY
        shape[index].offsetX = shapeUpdate.value.offsetX
        shape[index].offsetY = shapeUpdate.value.offsetY
    }
    if(shapeUpdate.type === "POSITION"){
        shape[index].x = shapeUpdate.value.x
        shape[index].y = shapeUpdate.value.y
    }
    if(shape[index].dbId){
        shape[index].save_status = "EDITED"
    }
    return shape
}

const flagDeleteShape = (shape, index) => {
    shape[index].isDeleted = true
    if(shape[index].dbId){
        shape[index].save_status = "DELETED"
    }
    return shape
}

const getTypeStr = (type) => {
    if(type === 1)
        return "FLOOR_PLAN"
    if(type === 2)
        return "RACK"
    if(type === 3)
        return "TEXT"
    return type
}

export function StckRackVisualBuilder(state = defaultStckRackVisualBuilderState, action){
    const copyShapes = [...state.shapes]
    const copyAvailableRackId = {...state.availableRackId}
    switch (action.type) {
        case ADD_SHAPE:
            const shape = action.payload
            if(shape.type === 'RACK')
                copyAvailableRackId[shape.objId] = true
            if(shape.type === 'FLOOR_PLAN')
                copyShapes[0] = shape
            else
                copyShapes.push(shape)
            return Object.assign({}, state, {shapes: copyShapes, availableRackId: copyAvailableRackId})
        case REMOVE_SHAPE:
            const shapeNameToDelete = action.payload
            const indexToDelete = copyShapes.findIndex(obj => obj.name === shapeNameToDelete)
            return Object.assign({}, state, {shapes: flagDeleteShape(copyShapes, indexToDelete), deletionTime: new Date()})
        case SELECT_SHAPE:
            return Object.assign({}, state, {selectedShape: action.payload})
        case UPDATE_SHAPE:
            const shapeUpdate = action.payload
            const indexToUpdate = copyShapes.findIndex(obj => obj.objId === shapeUpdate.id)
            console.log(copyShapes)
            console.log("UPDATE_SHAPE", indexToUpdate)
            return Object.assign({}, state, {shapes: updateShape(copyShapes, indexToUpdate, shapeUpdate)})
        case CHANGE_MAPPING_MODE:
            return Object.assign({}, state, {shapes: copyShapes, mappingMode: action.payload})
        case FETCH_WAREHOUSE_MAPPING_LIST:
            return Object.assign({}, state, {loading: "loading"})
        case RECEIVE_WAREHOUSE_MAPPING_LIST:
            action.payload.map((obj) => {
                obj.save_status = "EDITED"
                obj.type = getTypeStr(obj.type)
                if(obj.type === 'RACK')
                    copyAvailableRackId[obj.objId] = true
                return obj;
             })
            return Object.assign({}, state, {shapes: action.payload? action.payload : [],  availableRackId: copyAvailableRackId, loading: "success"})
        case FAILED_WAREHOUSE_MAPPING_LIST:
            return Object.assign({}, state, {loading: "failed"})
        default:
            return state
    }
}