import { FETCH_STCK_VENDOR_LIST, RECEIVE_STCK_VENDOR_LIST, FAILED_STCK_VENDOR_LIST } from '../../actions/stocker/stck.vendor.act'

const defaultStckVendorState = {
    stckVendorData: [],
    stckTotalVendor: 0,
    stckVendorSelect: [],
    success: true,
    inProgress: false,
}

export function StckVendor(state = defaultStckVendorState, action){
    switch (action.type) {
        case FETCH_STCK_VENDOR_LIST:
            return ({stckVendorData: [], success: true, stckTotalVendor: 0, stckVendorSelect: [], inProgress: true})
        case RECEIVE_STCK_VENDOR_LIST:
            var _totalVendor = action.payload.result.length;
            var _stckVendorSelect = []
            if(action.payload.result !== []) {
                var _vendorData = action.payload.result

                _vendorData.forEach((p, index) => {
                    var _loc = {
                        'label': _vendorData[index].name,
                        'value': _vendorData[index].id
                    }
                    _stckVendorSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckVendorData: action.payload, success: true, stckTotalVendor: _totalVendor, stckVendorSelect: _stckVendorSelect, inProgress: false})
        case FAILED_STCK_VENDOR_LIST:
            return ({stckVendorData: [], success: false, stckTotalVendor: 0, stckVendorSelect: [], inProgress: false})
        default:
            return state
    }
}