import { FETCH_STCK_DIVISION_LIST, RECEIVE_STCK_DIVISION_LIST, FAILED_STCK_DIVISION_LIST } from '../../actions/stocker/stck.division.act'

const defaultStckDivisionState = {
    stckDivisionData: [],
    stckTotalDivision: 0,
    stckDivisionSelect: [],
    success: true,
    inProgress: false,
}

export function StckDivision(state = defaultStckDivisionState, action){
    switch (action.type) {
        case FETCH_STCK_DIVISION_LIST:
            return ({stckDivisionData: [], success: true, stckTotalDivision: 0, stckDivisionSelect: [], inProgress: true})
        case RECEIVE_STCK_DIVISION_LIST:
            var _totalDivision = action.payload.result.length;
            var _stckDivisionSelect = []
            if(action.payload.result !== []) {
                var _divisionData = action.payload.result

                _divisionData.forEach((p, index) => {
                    var _loc = {
                        'label': _divisionData[index].division_name,
                        'value': _divisionData[index].id
                    }
                    _stckDivisionSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckDivisionData: action.payload, success: true, stckTotalDivision: _totalDivision, stckDivisionSelect: _stckDivisionSelect, inProgress: false})
        case FAILED_STCK_DIVISION_LIST:
            return ({stckDivisionData: [], success: false, stckTotalDivision: 0, stckDivisionSelect: [], inProgress: false})
        default:
            return state
    }
}