import { FETCH_MECHANIC_SPECIALIZATION_TABLE, RECEIVE_MECHANIC_SPECIALIZATION_TABLE, FAILED_MECHANIC_SPECIALIZATION_TABLE } from '../../actions/transporter/mechanicSpecialization.act'

const defaultMechanicSpecializationListState = {
    mechanicsSpecializationListData: [],
    inProgress: false,
    success: true
}

export function MechanicSpecializationList(state = defaultMechanicSpecializationListState, action){
    switch (action.type) {
        case FETCH_MECHANIC_SPECIALIZATION_TABLE:
            return ({mechanicsSpecializationListData: [], success: false, inProgress: true})
        case RECEIVE_MECHANIC_SPECIALIZATION_TABLE:
            // var _total = action.payload.result.length
            return Object.assign({}, state, {mechanicsSpecializationListData: action.payload.result, success: true, inProgress: false})
        case FAILED_MECHANIC_SPECIALIZATION_TABLE:
            return ({mechanicsSpecializationListData: [], success: false, inProgress: false})
        default:
            return state
    }
}