import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_WORK_ORDERS_TABLE = 'FETCH_WORK_ORDERS_TABLE'
export const RECEIVE_WORK_ORDERS_TABLE = 'RECEIVE_WORK_ORDERS_TABLE'
export const FAILED_WORK_ORDERS_TABLE = 'FAILED_WORK_ORDERS_TABLE'

export function fetchWorkOrdersTable(_id){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(auth)

    return (dispatch, getState) => {
        dispatch({type: FETCH_WORK_ORDERS_TABLE});
        axios({
            method: 'post',
            url: API_LINKS.WORK_ORDERS_LIST_URL,
            headers: {
                Authorization: auth
            },
            data: {
                id_delivery_order: _id,
                // date: 
                // For Parameters
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_WORK_ORDERS_TABLE,
                payload: response.data.result
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_TABLE
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_TABLE
                    })
                    return toast.error("Failed in fetching Work Orders List. Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_TABLE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_WORK_ORDERS_TABLE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_WORK_ORDERS_TABLE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_WORK_ORDERS_TABLE
                })
                return toast.error('Something went wrong... Please try again later...')
            }

        })
    }
}

export const FETCH_WORK_ORDERS_UPLOADED_IMAGE = 'FETCH_WORK_ORDERS_UPLOADED_IMAGE'
export const RECEIVE_WORK_ORDERS_UPLOADED_IMAGE = 'RECEIVE_WORK_ORDERS_UPLOADED_IMAGE'
export const FAILED_WORK_ORDERS_UPLOADED_IMAGE = 'FAILED_WORK_ORDERS_UPLOADED_IMAGE'

export function fetchWorkOrdersUploadedImage(_id_delivery_order, _id_work_order){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(auth)

    return async (dispatch, getState) => {
        dispatch({type: FETCH_WORK_ORDERS_UPLOADED_IMAGE});
        axios({
            method: 'post',
            url: API_LINKS.WORK_ORDER_UPLOADED_IMAGE_LIST_URL,
            headers: {
                Authorization: auth
            },
            data: {
                id_delivery_order: _id_delivery_order,
                id_work_order: _id_work_order
                // date: 
                // For Parameters
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_WORK_ORDERS_UPLOADED_IMAGE,
                payload: response.data.result,
                id_work_order: _id_work_order
            })
        })
        .catch(function(error){
            console.log(error)
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_UPLOADED_IMAGE
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_UPLOADED_IMAGE
                    })
                    return toast.error("Failed in fetching Work Orders List. Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_UPLOADED_IMAGE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_WORK_ORDERS_UPLOADED_IMAGE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_WORK_ORDERS_UPLOADED_IMAGE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_WORK_ORDERS_UPLOADED_IMAGE
                })
                return toast.error('Something went wrong... Please try again later...')
            }

        })
    }
}

export const FETCH_WORK_ORDERS_IMAGE_COMPARE = 'FETCH_WORK_ORDERS_IMAGE_COMPARE'
export const RECEIVE_WORK_ORDERS_IMAGE_COMPARE = 'RECEIVE_WORK_ORDERS_IMAGE_COMPARE'
export const FAILED_WORK_ORDERS_IMAGE_COMPARE = 'FAILED_WORK_ORDERS_IMAGE_COMPARE'

export function fetchWorkOrdersUploadedImageCompare(_id_delivery_order, _id_work_order){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    // console.log(auth)

    return async (dispatch, getState) => {
        dispatch({type: FETCH_WORK_ORDERS_IMAGE_COMPARE});
        axios({
            method: 'post',
            url: API_LINKS.WORK_ORDER_UPLOADED_IMAGE_COMPARE_LIST_URL,
            headers: {
                Authorization: auth
            },
            data: {
                id_delivery_order: _id_delivery_order,
                id_work_order: _id_work_order
                // date: 
                // For Parameters
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_WORK_ORDERS_IMAGE_COMPARE,
                payload: response.data.result,
                id_work_order: _id_work_order
            })
        })
        .catch(function(error){
            console.log(error)
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_IMAGE_COMPARE
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_IMAGE_COMPARE
                    })
                    return toast.error("Failed in fetching Work Orders List. Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_WORK_ORDERS_IMAGE_COMPARE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_WORK_ORDERS_IMAGE_COMPARE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_WORK_ORDERS_IMAGE_COMPARE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_WORK_ORDERS_IMAGE_COMPARE
                })
                return toast.error('Something went wrong... Please try again later...')
            }

        })
    }
}





