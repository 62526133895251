//For Base URL and User Authentication
const BASE_URL = "https://api.automa.id/api/pointhub";
const BASE_STCK = "https://api.automa.id/api/stocker";

const PTH_CUSTOMER_LIST_URL = BASE_URL + '/customer/list'
const PTH_CUSTOMER_ADD_URL = BASE_URL + '/customer/input'
const PTH_CUSTOMER_EDIT_URL = BASE_URL + '/customer/edit'
const PTH_CUSTOMER_DELETE_URL = BASE_URL + '/customer/delete'
const PTH_CUSTOMER_BLOCK_URL = BASE_URL + '/customer/block'

const CUSTOMER_APPROVAL_LIST_URL = BASE_URL + '/customer/approval/list'
const CUSTOMER_APPROVAL_ADD_URL = BASE_URL + '/customer/approval/input'
const CUSTOMER_APPROVAL_EDIT_URL = BASE_URL + '/customer/approval/edit'
const CUSTOMER_APPROVAL_BLOCK_URL = BASE_URL + '/customer/approval/block'

const CUSTOMER_BANK_ACC_LIST_URL = BASE_URL + '/customer/bank/list'
const CUSTOMER_BANK_ACC_ADD_URL = BASE_URL + '/customer/bank/input'
const CUSTOMER_BANK_ACC_DELETE_URL = BASE_URL + '/customer/bank/delete'
const CUSTOMER_BANK_ACC_EDIT_URL = BASE_URL + '/customer/bank/edit'

const PTH_CUSTOMER_PIC_LIST_URL = BASE_URL + '/customer/pic/list'
const PTH_CUSTOMER_PIC_ADD_URL = BASE_URL + '/customer/pic/input'
const PTH_CUSTOMER_PIC_DELETE_URL = BASE_URL + '/customer/pic/delete'
const PTH_CUSTOMER_PIC_EDIT_URL = BASE_URL + '/customer/pic/edit'

const PIC_POSITIONS_LIST_URL = BASE_STCK + '/pic-position/list'
const PIC_POSITIONS_ADD_URL = BASE_STCK + '/pic-position/input'
const PIC_POSITIONS_EDIT_URL = BASE_STCK + '/pic-position/edit'
const PIC_POSITIONS_DELETE_URL = BASE_STCK + '/pic-position/delete'

const PTH_BANKS_LIST_URL = BASE_STCK + '/bank/list'

export {
    PTH_CUSTOMER_LIST_URL,
    PTH_CUSTOMER_ADD_URL,
    PTH_CUSTOMER_EDIT_URL,
    PTH_CUSTOMER_DELETE_URL,
    PTH_CUSTOMER_BLOCK_URL,

    CUSTOMER_BANK_ACC_LIST_URL,
    CUSTOMER_BANK_ACC_ADD_URL,
    CUSTOMER_BANK_ACC_DELETE_URL,
    CUSTOMER_BANK_ACC_EDIT_URL,

    CUSTOMER_APPROVAL_LIST_URL,
    CUSTOMER_APPROVAL_ADD_URL,
    CUSTOMER_APPROVAL_EDIT_URL,
    CUSTOMER_APPROVAL_BLOCK_URL,

    PIC_POSITIONS_LIST_URL,
    PIC_POSITIONS_ADD_URL,
    PIC_POSITIONS_EDIT_URL,
    PIC_POSITIONS_DELETE_URL,

    PTH_CUSTOMER_PIC_LIST_URL,
    PTH_CUSTOMER_PIC_ADD_URL,
    PTH_CUSTOMER_PIC_EDIT_URL,
    PTH_CUSTOMER_PIC_DELETE_URL,

    PTH_BANKS_LIST_URL
}