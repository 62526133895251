import { FETCH_WORK_ORDER_ADDER_MULTI, RECEIVE_WORK_ORDER_ADDER_MULTI, FAILED_WORK_ORDER_ADDER_MULTI, RESET_WORK_ORDER_ADDER_MULTI } from '../../actions/transporter/aTransporter-WOAdderMulti'

const defaultWorkOrderAdderMultiState = {
    workOrderAdderMultiData: []    
}

export function fetchWorkOrderAdderMulti(state = defaultWorkOrderAdderMultiState, action){
    switch (action.type) {
        case FETCH_WORK_ORDER_ADDER_MULTI:
            return ({workOrderAdderMultiData: [], inProgress: true})
        case RECEIVE_WORK_ORDER_ADDER_MULTI:
            defaultWorkOrderAdderMultiState.workOrderAdderMultiData.push(action.payload)
            return Object.assign({}, state, {workOrderAdderMultiData: defaultWorkOrderAdderMultiState.workOrderAdderMultiData, inProgress: false})
        case FAILED_WORK_ORDER_ADDER_MULTI:
            return ({workOrderAdderMultiData: [], inProgress: false})
        case RESET_WORK_ORDER_ADDER_MULTI:
            return ({workOrderAdderMultiData: [], inProgress: false})
        default:
            return state
    }
}