import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT = 'FETCH_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT'
export const RECEIVE_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT = 'RECEIVE_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT'
export const FAILED_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT = 'FAILED_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT'

export function fetchTriPLWoBidApprovedCheckPayment(_id, _id_bid){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return async (dispatch, getState) => {
        dispatch({type: FETCH_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT});
        axios({
            method: 'post',
            url: API_LINKS.TRIPL_WO_BID_APPROVE_CHECK_PAYMENT_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id : _id,
                id_bid: _id_bid
            }
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT,
                payload: response.data,
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT
                    })
                    // return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_TRIPL_WO_BID_APPROVE_CHECK_PAYMENT
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export const FETCH_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE = 'FETCH_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE'
export const RECEIVE_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE = 'RECEIVE_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE'
export const FAILED_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE= 'FAILED_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE'

export function fetchTriPLWoBidApprovedGenerateInvoice(_id, _id_bid, _pm){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return async (dispatch, getState) => {
        dispatch({type: FETCH_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE});
        axios({
            method: 'post',
            url: API_LINKS.TRIPL_WO_BID_APPROVE_GENERATE_INVOICE_URL_V2,
            headers: {
                Authorization: auth
            },
            data:{
                id : _id,
                id_bid: _id_bid,
                payment_methods: _pm
            }
        })
        .then((response) => {
            console.log("pake iniiii apinyaaaaaaa")
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE,
                payload: response.data,
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_TRIPL_WO_BID_APPROVE_GENERATE_INVOICE
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}