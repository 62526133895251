import {
  GET_INTERCOMPANY_ORDER_CART_ERROR,
  GET_INTERCOMPANY_ORDER_CART_LOADING,
  GET_INTERCOMPANY_ORDER_CART_SUCCESS,
  SET_INTERCOMPANY_ORDER_CART,
  SET_INTERCOMPANY_ORDER_SEARCH_KEYWORD,
  SET_INTERCOMPANY_ORDER_SELECTED_ITEM,
  SET_INTERCOMPANY_ORDER_SHIPMENT,
} from "../../types/pointhub/intercompany-order.types";

const initialState = {
  cart: [],
  selectedItem: [],
  shipment: [],
  loadingCart: false,
  search: "",
};

const intercompany = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTERCOMPANY_ORDER_CART:
      return {
        ...state,
        cart: [...action.payload],
      };
    case SET_INTERCOMPANY_ORDER_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: [...action.payload],
      };
    case SET_INTERCOMPANY_ORDER_SHIPMENT:
      return {
        ...state,
        shipment: [...action.payload],
      };
    case GET_INTERCOMPANY_ORDER_CART_LOADING:
      return {
        ...state,
        loadingCart: true,
      };
    case GET_INTERCOMPANY_ORDER_CART_SUCCESS:
      return {
        ...state,
        loadingCart: false,
        cart: action.payload,
      };
    case GET_INTERCOMPANY_ORDER_CART_ERROR:
      return {
        ...state,
        loadingCart: false,
      };
    case SET_INTERCOMPANY_ORDER_SEARCH_KEYWORD:
      return {
        ...state,
        search: action.payload.keyword,
      };
    default:
      return state;
  }
};

export default intercompany;
