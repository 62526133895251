import { FETCH_STCK_RETURN_ORDER_LIST, RECEIVE_STCK_RETURN_ORDER_LIST, FAILED_STCK_RETURN_ORDER_LIST } from '../../actions/stocker/stck.returnorder.act'

const defaultStckReturnOrderState = {
    stckReturnOrderData: [],
    stckTotalRetunOrder: 0,
    stckReturnOrderSelect: [],
    success: true,
    inProgress: false,
}

export function StckReturnOrder(state = defaultStckReturnOrderState, action){
    switch (action.type) {
        case FETCH_STCK_RETURN_ORDER_LIST:
            return ({stckReturnOrderData: [], success: true, stckTotalRetunOrder: 0, stckReturnOrderSelect: [], inProgress: true})
        case RECEIVE_STCK_RETURN_ORDER_LIST:
            var _totalReturnOrder = action.payload.result.length;
            var _stckReturnOrderSelect = []
            if(action.payload.result !== []) {
                var _roData = action.payload.result

                _roData.forEach((p, index) => {
                    var _loc = {
                        'label': _roData[index].return_order_number,
                        'value': _roData[index].id,
                        "id": _roData[index].id,
                        "id_company": _roData[index].id_company,
                        "id_vendor": _roData[index].id_vendor,
                        "vendor_name": _roData[index].vendor_name,
                        "vendor_address": _roData[index].vendor_address,
                        "vendor_main_phone_number": _roData[index].vendor_main_phone_number,
                        "id_administration_office": _roData[index].id_administration_office,
                        "admin_office_name": _roData[index].admin_office_name,
                        "admin_office_address": _roData[index].admin_office_address,
                        "admin_office_main_phone_number": _roData[index].admin_office_main_phone_number,
                        "id_warehouse": _roData[index].id_warehouse,
                        "warehouse_name": _roData[index].warehouse_name,
                        "warehouse_address": _roData[index].warehouse_address,
                        "id_warehouse_area": _roData[index].id_warehouse_area,
                        "warehouse_area_name": _roData[index].warehouse_area_name,
                        "warehouse_area_size": _roData[index].warehouse_area_size,
                        "id_master_inbound": _roData[index].id_master_inbound,
                        "inbound_number": _roData[index].inbound_number,
                        "document_number": _roData[index].document_number,
                        "return_issue_date": _roData[index].return_issue_date,
                        "return_order_number": _roData[index].return_order_number,
                        "expected_pickup_time": _roData[index].expected_pickup_time,
                        "expected_loading_time": _roData[index].expected_loading_time,
                        "expected_departure_time": _roData[index].expected_departure_time,
                        "expected_total_item": _roData[index].expected_total_item,
                        "realization_pickup_time": _roData[index].realization_pickup_time,
                        "realization_loading_time": _roData[index].realization_loading_time,
                        "realization_departure_time": _roData[index].realization_departure_time,
                        "realization_exchanged_total_item": _roData[index].realization_exchanged_total_item,
                        "remark": _roData[index].remark,
                        "created_by": _roData[index].created_by,
                    }
                    _stckReturnOrderSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckReturnOrderData: action.payload, success: true, stckTotalRetunOrder: _totalReturnOrder, stckReturnOrderSelect: _stckReturnOrderSelect, inProgress: false})
        case FAILED_STCK_RETURN_ORDER_LIST:
            return ({stckReturnOrderData: [], success: false, stckTotalRetunOrder: 0, stckReturnOrderSelect: [], inProgress: false})
        default:
            return state
    }
}