import { FETCH_STCK_WAREHOUSE_AREA_LIST, RECEIVE_STCK_WAREHOUSE_AREA_LIST, FAILED_STCK_WAREHOUSE_AREA_LIST } from '../../actions/stocker/stck.warehousearea.act'

const defaultStckWarehouseAreaState = {
    stckWarehouseAreaData: [],
    stckTotalWarehouseArea: 0,
    stckWarehouseAreaSelect: [],
    success: true,
    inProgress: false,
}

export function StckWarehouseArea(state = defaultStckWarehouseAreaState, action){
    switch (action.type) {
        case FETCH_STCK_WAREHOUSE_AREA_LIST:
            return ({stckWarehouseAreaData: [], success: true, stckTotalWarehouseArea: 0, stckWarehouseAreaSelect: [], inProgress: true})
        case RECEIVE_STCK_WAREHOUSE_AREA_LIST:
            var _totalWarehouseArea = action.payload.result.length;
            var _stckWarehouseAreaSelect = []
            if(action.payload.result !== []) {
                var _warehouseAreaData = action.payload.result

                _warehouseAreaData.forEach((p, index) => {
                    var _loc = {
                        'label': _warehouseAreaData[index].area_name + ' - ' + _warehouseAreaData[index].area_size + ' m2',
                        'value': _warehouseAreaData[index].id,
                        'area_desc': _warehouseAreaData[index].area_desc,
                        'area_size': _warehouseAreaData[index].area_size,
                        'loading_area_desc': _warehouseAreaData[index].loading_area_desc,
                        'loading_area_bay': _warehouseAreaData[index].loading_area_bay,
                        'queueing_capacity': _warehouseAreaData[index].queueing_capacity,
                        'height': _warehouseAreaData[index].height,
                        'volume': _warehouseAreaData[index].volume,
                        "is_intercompany": _warehouseAreaData[index].is_intercompany
                    }
                    _stckWarehouseAreaSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckWarehouseAreaData: action.payload, success: true, stckTotalWarehouseArea: _totalWarehouseArea, stckWarehouseAreaSelect: _stckWarehouseAreaSelect, inProgress: false})
        case FAILED_STCK_WAREHOUSE_AREA_LIST:
            return ({stckWarehouseAreaData: [], success: false, stckTotalWarehouseArea: 0, stckWarehouseAreaSelect: [], inProgress: false})
        default:
            return state
    }
}
