import { FETCH_LANDLORD_SENSOR_REPORT, RECEIVE_LANDLORD_SENSOR_REPORT, FAILED_LANDLORD_SENSOR_REPORT } from '../../actions/landlord/aLandLord-SensorReadings'

const defaultLandlordSensorReadingState = {
    landlordSensorReadingsData: [],
    success: true,
}

export function landlord_fetchLandlordSensorReadings(state = defaultLandlordSensorReadingState, action){
    switch (action.type) {
        case FETCH_LANDLORD_SENSOR_REPORT:
            return ({landlordSensorReadingsData: [], success: false, inProgress: true})
        case RECEIVE_LANDLORD_SENSOR_REPORT:
            return Object.assign({}, state, {landlordSensorReadingsData: action.payload, success: true, inProgress: false})
        case FAILED_LANDLORD_SENSOR_REPORT:
            return ({landlordSensorReadingsData: [], success: false, inProgress: false})
        default:
            return state
    }
}