import { 
    FETCH_CURRATIVE_ORDER_LIST,  
    RECEIVE_CURRATIVE_ORDER_LIST,
    FAILED_CURRATIVE_ORDER_LIST ,

    FETCH_CURRATIVE_ORDER_IMAGE_LIST,
    RECEIVE_CURRATIVE_ORDER_IMAGE_LIST,
    FAILED_CURRATIVE_ORDER_IMAGE_LIST
} 
from '../../actions/transporter/currative-orders.act'

const defaultCurratoveOrderState = {
    totalCurrativeOrder:0,
    currativeOrders: [],
    success: true,  
    inProgress: false
}

export function CurrativeOrders(state = defaultCurratoveOrderState, action){
switch (action.type) {
    case FETCH_CURRATIVE_ORDER_LIST:
        return ({currativeOrders: [], success: true, inProgress: true, totalCurrativeOrder:0})
    case RECEIVE_CURRATIVE_ORDER_LIST:
        return Object.assign({}, state, {currativeOrders: action.payload.result, 
                                            totalCurrativeOrder: action.payload.result.data.length,
                                            success: true, inProgress: false})
    case FAILED_CURRATIVE_ORDER_LIST:
        return ({currativeOrders: [], success: false, inProgress: false, totalCurrativeOrder:0,})
    default:
        return state
}
}

const defaultCurrativeOrderProofsState = {
    totalcurrativeOrderProofs:0,
    currativeOrderProofs: [],
    success: true,  
    inProgress: false
}

export function CurrativeOrderProof(state = defaultCurrativeOrderProofsState, action){
switch (action.type) {
    case FETCH_CURRATIVE_ORDER_IMAGE_LIST:
        return ({currativeOrderProofs: [], success: true, inProgress: true, totalcurrativeOrderProofs:0})
    case RECEIVE_CURRATIVE_ORDER_IMAGE_LIST:
        return Object.assign({}, state, {currativeOrderProofs: action.payload.result, 
                                            totalcurrativeOrderProofs: action.payload.result.length,
                                            success: true, inProgress: false})
    case FAILED_CURRATIVE_ORDER_IMAGE_LIST:
        return ({currativeOrderProofs: [], success: false, inProgress: false, totalcurrativeOrderProofs:0,})
    default:
        return state
}
}