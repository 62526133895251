import { FETCH_INBOUND_DETAIL_LIST, RECEIVE_INBOUND_DETAIL_LIST, FAILED_INBOUND_DETAIL_LIST } from '../../actions/stocker/stck.inbounddetail.act'

const defaultStckInboundDetailState = {
    stckInboundDetailData: [],
    stckTotalInboundDetail: 0,
    stckInboundDetailSelect: [],
    success: true,
    inProgress: false,
}

export function StckInboundDetail(state = defaultStckInboundDetailState, action){
    switch (action.type) {
        case FETCH_INBOUND_DETAIL_LIST:
            return ({stckInboundDetailData: [], success: true, stckTotalInboundDetail: 0, stckInboundDetailSelect: [], inProgress: true})
        case RECEIVE_INBOUND_DETAIL_LIST:
            var _totalInboundDetail = action.payload.result.length;
            var _stckInboundDetailSelect = []
            if(action.payload.result !== []) {
                var _inboundDetailData = action.payload.result

                _inboundDetailData.forEach((p, index) => {
                    var _loc = {
                        'label': _inboundDetailData[index].inbound_number,
                        'value': _inboundDetailData[index].id,
                        "id": _inboundDetailData[index].id,
                        "inbound_number": _inboundDetailData[index].inbound_number,
                        "document_number": _inboundDetailData[index].document_number,
                        "id_tracker_type": _inboundDetailData[index].id_tracker_type,
                        "tracker_internal_code": _inboundDetailData[index].tracker_internal_code,
                        "tracker_type": _inboundDetailData[index].tracker_type,
                        "awb_number": _inboundDetailData[index].awb_number,
                        
                    }
                    _stckInboundDetailSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckInboundDetailData: action.payload, success: true, stckTotalInboundDetail: _totalInboundDetail, stckInboundDetailSelect: _stckInboundDetailSelect, inProgress: false})
        case FAILED_INBOUND_DETAIL_LIST:
            return ({stckInboundDetailData: [], success: false, stckTotalInboundDetail: 0, stckInboundDetailSelect: [], inProgress: false})
        default:
            return state
    }
}