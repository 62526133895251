import { FETCH_WORK_ORDER_SHARE, RECEIVE_WORK_ORDER_SHARE, FAILED_WORK_ORDER_SHARE } from '../../actions/transporter/aTransporter-WorkOrderShare'

const defaultWorkOrderShareState = {
    workOrderShareData: []    
}

export function fetchWorkOrderShare(state = defaultWorkOrderShareState, action){
    switch (action.type) {
        case FETCH_WORK_ORDER_SHARE:
            return ({workOrderShareData: [], inProgress: true})
        case RECEIVE_WORK_ORDER_SHARE:
            return Object.assign({}, state, {workOrderShareData: action.payload, inProgress: false})
        case FAILED_WORK_ORDER_SHARE:
            return ({workOrderShareData: [], inProgress: false})
        default:
            return state
    }
}