import { FETCH_STCK_IDENTITY_TYPES_LIST, RECEIVE_STCK_IDENTITY_TYPES_LIST, FAILED_STCK_IDENTITY_TYPES_LIST } from '../../actions/stocker/stck.identitytypes.act'

const defaultStckIdentityTypesState = {
    stckIdentityTypesData: [],
    stckTotalIdentityTypes: 0,
    stckIdentityTypesSelect: [],
    success: true,
    inProgress: false,
}

export function StckIdentityTypes(state = defaultStckIdentityTypesState, action){
    switch (action.type) {
        case FETCH_STCK_IDENTITY_TYPES_LIST:
            return ({stckIdentityTypesData: [], success: true, stckTotalIdentityTypes: 0, stckIdentityTypesSelect: [], inProgress: true})
        case RECEIVE_STCK_IDENTITY_TYPES_LIST:
            var _totalIdentityTypes = action.payload.result.length;
            var _stckIdentityTypesSelect = []
            if(action.payload.result !== []) {
                var _identityTypeData = action.payload.result

                _identityTypeData.forEach((p, index) => {
                    var _loc = {
                        'label': _identityTypeData[index].identity_type + ' - ' + _identityTypeData[index].identity_desc,
                        'value': _identityTypeData[index].id
                    }
                    _stckIdentityTypesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckIdentityTypesData: action.payload, success: true, stckTotalIdentityTypes: _totalIdentityTypes, stckIdentityTypesSelect: _stckIdentityTypesSelect, inProgress: false})
        case FAILED_STCK_IDENTITY_TYPES_LIST:
            return ({stckIdentityTypesData: [], success: false, stckTotalIdentityTypes: 0, stckIdentityTypesSelect: [], inProgress: false})
        default:
            return state
    }
}