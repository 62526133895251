import {FETCH_PACKAGE_MAINTENANCE_LIST, RECEIVE_PACKAGE_MAINTENANCE_LIST, FAILED_PACKAGE_MAINTENANCE_LIST,
    FETCH_PACKAGE_MAINTENANCE_TASK_LIST, RECEIVE_PACKAGE_MAINTENANCE_TASK_LIST, FAILED_PACKAGE_MAINTENANCE_TASK_LIST
} from '../../actions/transporter/package-mainteance.act'
        
function getSelectFromPayload(payloadData, settings){
    let result = []
    if(!payloadData)
        return result
    if(!Array.isArray(payloadData))
        return result
    let labelField = settings.labelField
    let valueField = settings.valueField
    payloadData.forEach(data => {
        result.push({
            label: data[labelField],
            value: data[valueField]
        })
    })
    return result
}


const defaultPackageMaintenanceState = {
    totalPackageMaintenances:0,
    packageMaintenances: [],
    packageMaintenancesSelect: [],
    success: true,  
    inProgress: false
}

export function PackageMaintenance(state = defaultPackageMaintenanceState, action){
    switch (action.type) {
        case FETCH_PACKAGE_MAINTENANCE_LIST:
            return ({packageMaintenances: [], success: true, packageMaintenancesSelect: [],
                    inProgress: true, totalPackageMaintenances:0})
        case RECEIVE_PACKAGE_MAINTENANCE_LIST:
            const {payload} = action
            let packageMaintenancesSelect = getSelectFromPayload(
                payload.result, {labelField:"package_code", valueField: "id"}
            )
            return Object.assign({}, state, {packageMaintenances: action.payload.result,
                                                packageMaintenancesSelect: packageMaintenancesSelect,
                                                totalPackageMaintenances: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_PACKAGE_MAINTENANCE_LIST:
            return ({packageMaintenances: [], success: false, packageMaintenancesSelect: [],
                    inProgress: false, totalPackageMaintenances:0,})
        default:
            return state
    }
}

const defaultPackageMaintenanceTaskState = {
    totalPackageMaintenanceTask:0,
    packageMaintenanceTask: [],
    packageMaintenanceTaskSelect: [],
    success: true,  
    inProgress: false
}

export function PackageMaintenanceTask(state = defaultPackageMaintenanceTaskState, action){
    switch (action.type) {
        case FETCH_PACKAGE_MAINTENANCE_TASK_LIST:
            return ({packageMaintenanceTask: [], success: true, packageMaintenanceTaskSelect: [],
                    inProgress: true, totalPackageMaintenanceTask:0})
        case RECEIVE_PACKAGE_MAINTENANCE_TASK_LIST:
            const {payload} = action
            let packageMaintenanceTaskSelect = getSelectFromPayload(
                payload.result, {labelField:"name", valueField: "id"}
            )
            payload.result.map(pmt => {
                pmt.subc = pmt.sub_category[0]? pmt.sub_category[0].categorical_sub_maintenance_name : null
                pmt.catg =  pmt.sub_category[0]? pmt.sub_category[0].categorical_maintenance_name : null
                return pmt
            })
            return Object.assign({}, state, {packageMaintenanceTask: payload.result,
                                                packageMaintenanceTaskSelect: packageMaintenanceTaskSelect,
                                                totalPackageMaintenanceTask: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_PACKAGE_MAINTENANCE_TASK_LIST:
            return ({packageMaintenanceTask: [], success: false, packageMaintenanceTaskSelect: [],
                    inProgress: false, totalPackageMaintenanceTask:0,})
        default:
            return state
    }
}