import { FETCH_STCK_OPNAME_LIST, RECEIVE_STCK_OPNAME_LIST, FAILED_STCK_OPNAME_LIST,
FETCH_STCK_OPNAME_DETAIL_LIST, RECEIVE_STCK_OPNAME_DETAIL_LIST, FAILED_STCK_OPNAME_DETAIL_LIST,
FETCH_STCK_MY_OPNAME_LIST, RECEIVE_STCK_MY_OPNAME_LIST, FAILED_STCK_MY_OPNAME_LIST } from '../../actions/stocker/stck.opname.act'

const defaultStckUOpnameState = {
    stckOpnamesData: [],
    stckTotalOpname: 0,
    stckOpnameSelect: [],
    loading: "loading",
}

export function StckOpnames(state = defaultStckUOpnameState, action){
    switch (action.type) {
        case FETCH_STCK_OPNAME_LIST:
            return ({stckOpnamesData: [], stckTotalOpname: 0, stckOpnameSelect: [], loading: "loading"})
        case RECEIVE_STCK_OPNAME_LIST:
            var _totalOpname = action.payload.result.length;
            return Object.assign({}, state, {stckOpnamesData: action.payload.result, stckTotalOpname: _totalOpname, loading: "success"})
        case FAILED_STCK_OPNAME_LIST:
            return ({stckOpnamesData: [], stckTotalOpname: 0, stckOpnameSelect: [], loading: "failed"})
        default:
            return state
    }
}

const defaultStckUOpnameTaskDetailState = {
    stckOpnameTaskDetailsData: [],
    stckTotalOpnameTaskDetail: 0,
    stckOpnameTaskDetailSelect: [],
    loading: "loading",
}

export function StckOpnameTaskDetails(state = defaultStckUOpnameTaskDetailState, action){
    switch (action.type) {
        case FETCH_STCK_OPNAME_DETAIL_LIST:
            return ({stckOpnameTaskDetailsData: [], stckTotalOpnameTaskDetail: 0, stckOpnameTaskDetailSelect: [], loading: "loading"})
        case RECEIVE_STCK_OPNAME_DETAIL_LIST:
            var _totalOpnameTaskDetail = action.payload.result.length;
            return Object.assign({}, state, {stckOpnameTaskDetailsData: action.payload.resultTransform, stckTotalOpnameTaskDetail: _totalOpnameTaskDetail, loading: "success"})
        case FAILED_STCK_OPNAME_DETAIL_LIST:
            return ({stckOpnameTaskDetailsData: [], stckTotalOpnameTaskDetail: 0, stckOpnameTaskDetailSelect: [], loading: "failed"})
        default:
            return state
    }
}

const defaultStckMyOpnameState = {
    stckOpnamesData: [],
    stckTotalOpname: 0,
    stckOpnameSelect: [],
    loading: "loading",
}

export function StckMyOpnames(state = defaultStckMyOpnameState, action){
    switch (action.type) {
        case FETCH_STCK_MY_OPNAME_LIST:
            return ({stckOpnamesData: [], stckTotalOpname: 0, stckOpnameSelect: [], loading: "loading"})
        case RECEIVE_STCK_MY_OPNAME_LIST:
            var _totalOpname = action.payload.result.length;
            return Object.assign({}, state, {stckOpnamesData: action.payload.result, stckTotalOpname: _totalOpname, loading: "success"})
        case FAILED_STCK_MY_OPNAME_LIST:
            return ({stckOpnamesData: [], stckTotalOpname: 0, stckOpnameSelect: [], loading: "failed"})
        default:
            return state
    }
}