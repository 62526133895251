import { FETCH_STCK_VENDOR_BANK_LIST, RECEIVE_STCK_VENDOR_BANK_LIST, FAILED_STCK_VENDOR_BANK_LIST } from '../../actions/stocker/stck.vendorbank.act'

const defaultStckVendorBankState = {
    stckVendorBankData: [],
    stckTotalVendorBank: 0,
    stckVendorBankSelect: [],
    success: true,
    inProgress: false,
}

export function StckVendorBank(state = defaultStckVendorBankState, action){
    switch (action.type) {
        case FETCH_STCK_VENDOR_BANK_LIST:
            return ({stckVendorBankData: [], success: true, stckTotalVendorBank: 0, stckVendorBankSelect: [], inProgress: true})
        case RECEIVE_STCK_VENDOR_BANK_LIST:
            var _totalVendorBank = action.payload.result.length;
            var _stckVendorBankSelect = []
            if(action.payload.result !== []) {
                var _vendorBankData = action.payload.result

                _vendorBankData.forEach((p, index) => {
                    var _loc = {
                        'label': _vendorBankData[index].bank_name + ' - ' + _vendorBankData[index].account_name + ' - ' + _vendorBankData[index].account_number,
                        'value': _vendorBankData[index].id,
                        'id_vendor': _vendorBankData[index].id_vendor,
                        'id_company': _vendorBankData[index].id_company,
                        'id_bank': _vendorBankData[index].id_bank,
                        'bank_name': _vendorBankData[index].bank_name,
                        'account_name': _vendorBankData[index].account_name,
                        'account_number': _vendorBankData[index].account_number,
                        'branch_name': _vendorBankData[index].branch_name
                    }
                    _stckVendorBankSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckVendorBankData: action.payload, success: true, stckTotalVendorBank: _totalVendorBank, stckVendorBankSelect: _stckVendorBankSelect, inProgress: false})
        case FAILED_STCK_VENDOR_BANK_LIST:
            return ({stckVendorBankData: [], success: false, stckTotalVendorBank: 0, stckVendorBankSelect: [], inProgress: false})
        default:
            return state
    }
}