import { FETCH_SETTING_LIST, RECEIVE_SETTING_LIST, FAILED_SETTING_LIST } from '../../actions/aUser-SettingList'

const defaultSettingState = {
    settingListData: [],
    kmlSet: 0,
    success: false,  
}

export function fetchSettingList(state = defaultSettingState, action){
    switch (action.type) {
        case FETCH_SETTING_LIST:
            return ({settingListData: [], success: false, kmlSet: 0,  inProgress: true})
        case RECEIVE_SETTING_LIST:
            var settingKML = action.payload.result.find(item => item.value === "5")
            settingKML = settingKML.status
            if(settingKML === 1) {
                settingKML = true
            } else {
                settingKML = false
            }
            return Object.assign({}, state, {settingListData: action.payload, kmlSet: settingKML, inProgress: false, success: true})
        case FAILED_SETTING_LIST:
            return ({settingListData: [], success: false, kmlSet: 0,  inProgress: false})
        default:
            return state
    }
}