import axios from "axios";
import {DISPOSAL_ITEM_LIST_URL} from '../../config/stck.links'
import {toast} from 'react-toastify'

export const FETCH_STCK_DISPOSAL_ITEM_LIST = 'FETCH_STCK_DISPOSAL_ITEM_LIST'
export const RECEIVE_STCK_DISPOSAL_ITEM_LIST = 'RECEIVE_STCK_DISPOSAL_ITEM_LIST'
export const FAILED_STCK_DISPOSAL_ITEM_LIST = 'FAILED_STCK_DISPOSAL_ITEM_LIST'

export function fetchStckDisposalItem(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_STCK_DISPOSAL_ITEM_LIST});
        axios({
            method: 'post',
            url: DISPOSAL_ITEM_LIST_URL,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_STCK_DISPOSAL_ITEM_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_STCK_DISPOSAL_ITEM_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_STCK_DISPOSAL_ITEM_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_STCK_DISPOSAL_ITEM_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_STCK_DISPOSAL_ITEM_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_STCK_DISPOSAL_ITEM_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_STCK_DISPOSAL_ITEM_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}