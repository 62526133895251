import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import {ToastContainer}  from 'react-toastify';

class ToastPortal extends Component {
    render(){
        const toastStyle = {
            zIndex: 99999,
        };
        return ReactDOM.createPortal(
            <ToastContainer position="bottom-right" autoClose={7500} style={toastStyle}/>
            , document.getElementById('toast-portal')
        )
    } 
}

export default ToastPortal