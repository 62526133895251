import { FETCH_FLEET_NOTIFICATION_DASHBOARD, RECEIVE_FLEET_NOTIFICATION_DASHBOARD, FAILED_FLEET_NOTIFICATION_DASHBOARD } from '../../actions/transporter/aTransporter-FleetNotificationDashboard'

const defaultFleetNotificationDashboardState = {
    pajakExpiredData: [],
    kirExpiredData: [],
    pajakAlmostExpiredData: [],
    kirAlmostExpiredData: [],
    totalNotification: 0,
    inProgress: false,
    success: true
}

export function fetchFleetNotificationDashboard(state = defaultFleetNotificationDashboardState, action){
    switch (action.type) {
        case FETCH_FLEET_NOTIFICATION_DASHBOARD:
            return ({
                pajakExpiredData: [],
                kirExpiredData: [],
                pajakAlmostExpiredData: [],
                kirAlmostExpiredData: [],
                totalNotification: 0,
                inProgress: true,
                success: true
            })
        case RECEIVE_FLEET_NOTIFICATION_DASHBOARD:
            return Object.assign({}, state, {
                pajakExpiredData: action.payload.result.pajak_expired_data,
                kirExpiredData: action.payload.result.kir_expired_data,
                pajakAlmostExpiredData: action.payload.result.pajak_almost_expired_data,
                kirAlmostExpiredData: action.payload.result.kir_almost_expired_data,
                totalNotification: (action.payload.result.pajak_expired_data.length + action.payload.result.kir_expired_data.length + action.payload.result.pajak_almost_expired_data.length + action.payload.result.kir_almost_expired_data.length),
                inProgress: false,
                success: true
            })
        case FAILED_FLEET_NOTIFICATION_DASHBOARD:
            return ({pajakExpiredData: [],
                kirExpiredData: [],
                pajakAlmostExpiredData: [],
                kirAlmostExpiredData: [],
                totalNotification: 0,
                inProgress: false,
                success: false
            })
        default:
            return state
    }
}