import { FETCH_STCK_ITEM_QUALITY_CATEGORIES_LIST, RECEIVE_STCK_ITEM_QUALITY_CATEGORIES_LIST, FAILED_STCK_ITEM_QUALITY_CATEGORIES_LIST } from '../../actions/stocker/stck.itemqualitycategories.act'

const defaultStckItemQualityCategoriesState = {
    stckItemQualityCategoriesData: [],
    stckTotalItemQualityCategories: 0,
    stckItemQualityCategoriesSelect: [],
    success: true,
    inProgress: false,
}

export function StckItemQualityCategories(state = defaultStckItemQualityCategoriesState, action){
    switch (action.type) {
        case FETCH_STCK_ITEM_QUALITY_CATEGORIES_LIST:
            return ({stckItemQualityCategoriesData: [], success: true, stckTotalItemQualityCategories: 0, stckItemQualityCategoriesSelect: [], inProgress: true})
        case RECEIVE_STCK_ITEM_QUALITY_CATEGORIES_LIST:
            var _totalItemQualityCategories = action.payload.result.length;
            var _stckItemQualityCategoriesSelect = []
            if(action.payload.result !== []) {
                var _itemQualityCategoriesData = action.payload.result

                _itemQualityCategoriesData.forEach((p, index) => {
                    var _loc = {
                        'label': _itemQualityCategoriesData[index].quality_name + ' - (' + _itemQualityCategoriesData[index].quality_value + '%)',
                        'value': _itemQualityCategoriesData[index].id
                    }
                    _stckItemQualityCategoriesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckItemQualityCategoriesData: action.payload, success: true, stckTotalItemQualityCategories: _totalItemQualityCategories, stckItemQualityCategoriesSelect: _stckItemQualityCategoriesSelect, inProgress: false})
        case FAILED_STCK_ITEM_QUALITY_CATEGORIES_LIST:
            return ({stckItemQualityCategoriesData: [], success: false, stckTotalItemQualityCategories: 0, stckItemQualityCategoriesSelect: [], inProgress: false})
        default:
            return state
    }
}