//For Base URL and User Authentication
const BASE_URL = "https://api.automa.id/api/landlord";

const LANDLORD_SENSOR_REPORT = BASE_URL + '/sensor/report';

//Location
const LL_LOCATION_BASE = BASE_URL + '/location'
const LOCATION_TYPE_LIST_URL = LL_LOCATION_BASE + '/type/list'
const LOCATION_TYPE_ADD_URL = LL_LOCATION_BASE + '/type/add'
const LOCATION_LIST_URL = LL_LOCATION_BASE + '/list'
const LOCATION_ADD_URL = LL_LOCATION_BASE + '/add'

//Sensor
const LL_SENSOR_BASE = BASE_URL + '/sensor'
const SENSOR_FABRICATOR_LIST_URL = LL_SENSOR_BASE + '/fab/list'
const SENSOR_FABRICATOR_ADD_URL = LL_SENSOR_BASE + '/fab/add'
const SENSOR_CLASS_LIST_URL = LL_SENSOR_BASE + '/class/list'
const SENSOR_CLASS_ADD_URL = LL_SENSOR_BASE + '/class/add'
const SENSOR_TYPE_LIST_URL = LL_SENSOR_BASE + '/type/list'
const SENSOR_TYPE_ADD_URL = LL_SENSOR_BASE + '/type/add'
const SENSOR_LIST_URL = LL_SENSOR_BASE + '/list'
const SENSOR_ADD_URL = LL_SENSOR_BASE + '/add'
const LL_SENSOR_INSTALL_BASE = LL_SENSOR_BASE + '/install'
const SENSOR_INSTALLATION_LIST_URL = LL_SENSOR_INSTALL_BASE + '/list'
const SENSOR_INSTALLATION_ADD_URL = LL_SENSOR_INSTALL_BASE + '/add'
const SENSOR_INSTALLATION_REMOVAL_URL = LL_SENSOR_INSTALL_BASE + '/remove'
const SENSOR_INSTALLATION_CALIBRATION_URL = LL_SENSOR_INSTALL_BASE + '/update'

//Utility
const LL_UTILITY_BASE = LL_LOCATION_BASE + '/utility'
const UTILITY_LIST_URL = LL_UTILITY_BASE + '/list'
const UTILITY_ADD_URL = LL_UTILITY_BASE + '/add'
const UTILITY_TYPE_LIST_URL = LL_UTILITY_BASE + '/type/list'
const UTILITY_TYPE_ADD_URL = LL_UTILITY_BASE + '/type/add'
const RESOURCE_USAGE_TYPE_LIST_URL = LL_UTILITY_BASE + '/resource/list'
const RESOURCE_USAGE_TYPE_ADD_URL = LL_UTILITY_BASE + '/resource/add'
const USAGE_SCHEDULE_LIST_URL = LL_UTILITY_BASE + '/usage/list'
const USAGE_SCHEDULE_ADD_URL = LL_UTILITY_BASE + '/usage/add'

export {
    LANDLORD_SENSOR_REPORT,

    //Location related
    LOCATION_TYPE_LIST_URL,
    LOCATION_TYPE_ADD_URL,
    LOCATION_LIST_URL,
    LOCATION_ADD_URL,

    //Sensor
    SENSOR_FABRICATOR_LIST_URL,
    SENSOR_FABRICATOR_ADD_URL,
    SENSOR_CLASS_LIST_URL,
    SENSOR_CLASS_ADD_URL,
    SENSOR_TYPE_LIST_URL,
    SENSOR_TYPE_ADD_URL,
    SENSOR_LIST_URL,
    SENSOR_ADD_URL,

    //Installatioon
    SENSOR_INSTALLATION_LIST_URL,
    SENSOR_INSTALLATION_ADD_URL,
    SENSOR_INSTALLATION_REMOVAL_URL,
    SENSOR_INSTALLATION_CALIBRATION_URL,

    UTILITY_LIST_URL,
    UTILITY_ADD_URL,
    UTILITY_TYPE_LIST_URL,
    UTILITY_TYPE_ADD_URL,
    RESOURCE_USAGE_TYPE_LIST_URL,
    RESOURCE_USAGE_TYPE_ADD_URL,
    USAGE_SCHEDULE_LIST_URL,
    USAGE_SCHEDULE_ADD_URL
}