import { FETCH_TRIPL_INCOMING_BID_LIST, RECEIVE_TRIPL_INCOMING_BID_LIST, FAILED_TRIPL_INCOMING_BID_LIST } from '../../actions/transporter/aTransporter-TriPLWOIncomingBidList'

const nullIdGroupAsAutomaWide = function(triPlRequest){
    triPlRequest.map(request =>{
        if(request.id_group === null)
            request.group_name = "Automa Wide"
        return request
    })
    return triPlRequest
}

const defaultTriPLIncomingBidListState = {
    triPLIncomingBidListData: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLIncomingBidList(state = defaultTriPLIncomingBidListState, action){
    switch (action.type) {
        case FETCH_TRIPL_INCOMING_BID_LIST:
            return ({triPLIncomingBidListData: [], success: true, inProgress: true})
        case RECEIVE_TRIPL_INCOMING_BID_LIST:
            return Object.assign({}, state, {triPLIncomingBidListData: nullIdGroupAsAutomaWide(action.payload.result), 
                                                success: true, inProgress: false})
        case FAILED_TRIPL_INCOMING_BID_LIST:
            return ({triPLIncomingBidListData: [], success: false, inProgress: false})
        default:
            return state
    }
}