import { FETCH_PTH_CUSTOMER_LIST, RECEIVE_PTH_CUSTOMER_LIST, FAILED_PTH_CUSTOMER_LIST,
        FETCH_PTH_CUSTOMER_SYNC_LIST, RECEIVE_PTH_CUSTOMER_SYNC_LIST, FAILED_PTH_CUSTOMER_SYNC_LIST} from '../../actions/pointhub/pth.customer.act'

const defaultPthCustomersState = {
    pthCustomersData: [],
    pthTotalCustomers: 0,
    pthCustomersSelect: [],
    success: true,
    inProgress: false,
}

export function PthCustomer(state = defaultPthCustomersState, action){
    switch (action.type) {
        case FETCH_PTH_CUSTOMER_LIST:
            return ({pthCustomersData: [], success: true, pthTotalCustomers: 0, pthCustomersSelect: [], inProgress: true})
        case RECEIVE_PTH_CUSTOMER_LIST:
            var _totalUnits = action.payload.result.length;
            var _pthCustomersSelect = []
            if(action.payload.result !== []) {
                var _pthCustData = action.payload.result

                _pthCustData.forEach((p, index) => {
                    var _loc = {
                        'label': _pthCustData[index].name,
                        'value': _pthCustData[index].id
                    }
                    _pthCustomersSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {pthCustomersData: action.payload, success: true, pthTotalCustomers: _totalUnits, pthCustomersSelect: _pthCustomersSelect, inProgress: false})
        case FAILED_PTH_CUSTOMER_LIST:
            return ({pthCustomersData: [], success: false, pthTotalCustomers: 0, pthCustomersSelect: [], inProgress: false})
        default:
            return state
    }
}

const defaultPthCustomerSyncState = {
    success: true,
    inProgress: false,
}

export function PthCustomerSync(state = defaultPthCustomerSyncState, action){
    switch (action.type) {
        case FETCH_PTH_CUSTOMER_SYNC_LIST:
            return ({ success: true, inProgress: true})
        case RECEIVE_PTH_CUSTOMER_SYNC_LIST:
            return Object.assign({}, state, {success: true, inProgress: false})
        case FAILED_PTH_CUSTOMER_SYNC_LIST:
            return ({success: false, inProgress: false})
        default:
            return state
    }
}