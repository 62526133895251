import { FETCH_MECHANIC_CERTIFICATION_TABLE, RECEIVE_MECHANIC_CERTIFICATION_TABLE, FAILED_MECHANIC_CERTIFICATION_TABLE } from '../../actions/transporter/mechanicsCertification.act'

const defaultMechanicCertificateListState = {
    mechanicsCertificationListData: [],
    inProgress: false,
    success: true
}

export function MechanicCertificationList(state = defaultMechanicCertificateListState, action){
    switch (action.type) {
        case FETCH_MECHANIC_CERTIFICATION_TABLE:
            return ({mechanicsCertificationListData: [], success: false, inProgress: true})
        case RECEIVE_MECHANIC_CERTIFICATION_TABLE:
            // var _total = action.payload.result.length
            return Object.assign({}, state, {mechanicsCertificationListData: action.payload.result, success: true, inProgress: false})
        case FAILED_MECHANIC_CERTIFICATION_TABLE:
            return ({mechanicsCertificationListData: [], success: false, inProgress: false})
        default:
            return state
    }
}