import { FETCH_MECHANIC_TABLE, RECEIVE_MECHANIC_TABLE, FAILED_MECHANIC_TABLE } from '../../actions/transporter/mechanics.act'

const defaultMechanicListState = {
    mechanicsListData: [],
    inProgress: false,
    success: true,
    mechanicSelectData: [],
}

export function MechanicList(state = defaultMechanicListState, action){
    switch (action.type) {
        case FETCH_MECHANIC_TABLE:
            return ({mechanicsListData: [], success: false, inProgress: true, mechanicSelectData: []})
        case RECEIVE_MECHANIC_TABLE:
            // var _total = action.payload.result.length
            var list = []
            if(action.payload.result.length !== 0) {
                var mechanicList = action.payload.result
                mechanicList.forEach((p, index) => {
                    var mechanic = {
                        'label': mechanicList[index].first_name + ' ' +mechanicList[index].last_name,
                        'value': mechanicList[index].id
                    }
                    list.push(mechanic)
                })
            }
            return Object.assign({}, state, {mechanicsListData: action.payload.result, success: true, inProgress: false, mechanicSelectData:list})
        case FAILED_MECHANIC_TABLE:
            return ({mechanicsListData: [], success: false, inProgress: false})
        default:
            return state
    }
}