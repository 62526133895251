import { FETCH_CAR_TYPE_TABLE, RECEIVE_CAR_TYPE_TABLE, FAILED_CAR_TYPE_TABLE } from '../../actions/transporter/aTransporter-CarTypeTable'

const defaultCarTypeTableState = {
    carTypeTableData: []    
}

export function fetchCarTypeTable(state = defaultCarTypeTableState, action){
    switch (action.type) {
        case FETCH_CAR_TYPE_TABLE:
            return ({carTypeTableData: [], inProgress: true})
        case RECEIVE_CAR_TYPE_TABLE:
            return Object.assign({}, state, {carTypeTableData: action.payload, inProgress: false})
        case FAILED_CAR_TYPE_TABLE:
            return ({carTypeTableData: [], inProgress: false})
        default:
            return state
    }
}