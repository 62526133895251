import { FETCH_CARBON_LOGGER_SCOPE3_LIST, RECEIVE_CARBON_LOGGER_SCOPE3_LIST, FAILED_CARBON_LOGGER_SCOPE3_LIST} from '../../actions/carbonx/carbonx.logger-scope3upstream.act'

const defaultStckLoggerScope3UpstreamState = {
    carbonxLoggerScope3UpstreamData: [],
    carbonxCarbonEmissionTotalScope3: 0,
    carbonxTotalLoggerScope3Upstream: 0,
    carbonxMaxScope3Upstream: 0,
    carbonxMinScope3Upstream: 0,
    carbonxAvgScope3Upstream: 0,
    carbonxScope3PrimaryDataPercentage: 0,
    carbonxScope3TotalWo : 0,
    success: true,
    inProgress: false,
}

function countPrimaryDataPercentage(pmt1, pmt2, dl){
    let pmt1Result = (100 * pmt1)/ dl
    let pmt2Rsult = (100 * pmt2)/dl
    let half1pctg = pmt1Result-50 >= 0? pmt1Result-50 : 0
    let half2pctg = pmt2Rsult-50 >= 0? pmt2Rsult-50 : 0
    return half1pctg + half2pctg
}   

export function CarbonXLoggerScope3Upstream(state = defaultStckLoggerScope3UpstreamState, action){
    switch (action.type) {
        case FETCH_CARBON_LOGGER_SCOPE3_LIST:
            return ({carbonxLoggerScope3UpstreamData: [], success: true, carbonxTotalLoggerScope3Upstream: 0, 
                        carbonxLoggerScope3UpstreamSelect: [], carbonxMaxScope3Upstream: 0,  carbonxMinScope3Upstream: 0,
                        // carbonxTotalLoggerScope3Upstream: 0,
                        carbonxScope3PrimaryDataPercentage:0, carbonxScope3TotalWo : 0,
                        inProgress: true})
        case RECEIVE_CARBON_LOGGER_SCOPE3_LIST:
            let payload = action.payload
            let _carbonxLoggerScope3UpstreamData = []
            let carbonxScope3PrimaryDataTotal = 0
            let carbonxScope3PrimaryData2Total = 0
            payload.result.data.forEach(scope3 => {
                if(!scope3.data)
                    return
                if(!Array.isArray(scope3.data))
                    return
                scope3.data.forEach(data => {
                    _carbonxLoggerScope3UpstreamData.push(data)
                    if(data.notes === 1)
                        carbonxScope3PrimaryDataTotal+=1
                    if(data.notes3 === 1)
                    carbonxScope3PrimaryData2Total += 1
                })
            })
            return Object.assign({}, state, {carbonxLoggerScope3UpstreamData: _carbonxLoggerScope3UpstreamData, success: true,
                                                carbonxTotalLoggerScope3Upstream: _carbonxLoggerScope3UpstreamData.length, 
                                                carbonxCarbonEmissionTotalScope3: (payload.result.total_carbon? payload.result.total_carbon : 0).toFixed(2), carbonxMaxScope3Upstream: Math.max(..._carbonxLoggerScope3UpstreamData.map(o => o.carbon_emission)), 
                                                carbonxMinScope3Upstream: Math.min(..._carbonxLoggerScope3UpstreamData.map(o => o.carbon_emission)), 
                                                carbonxAvgScope3Upstream: _carbonxLoggerScope3UpstreamData.map(a => a.carbon_emission).reduce((prev, curr) => prev + curr) / _carbonxLoggerScope3UpstreamData.length,
                                                carbonxScope3PrimaryDataPercentage: countPrimaryDataPercentage(carbonxScope3PrimaryDataTotal, carbonxScope3PrimaryData2Total, _carbonxLoggerScope3UpstreamData.length,),
                                                carbonxScope3TotalWo : payload.result.total_wo,
                                                inProgress: false})
        case FAILED_CARBON_LOGGER_SCOPE3_LIST:
            return ({carbonxLoggerScope3UpstreamData: [], success: false, carbonxTotalLoggerScope3Upstream: 0, 
                            carbonxLoggerScope3UpstreamSelect: [], carbonxMaxScope3Upstream: 0,  carbonxMinScope3Upstream: 0, 
                            // carbonxTotalLoggerScope3Upstream: 0,
                            carbonxScope3PrimaryDataPercentage:0, carbonxScope3TotalWo : 0,
                            inProgress: false})
        default:
            return state
    }
}