import { FETCH_STCK_PO_APPROVAL_LIST, RECEIVE_STCK_PO_APPROVAL_LIST, FAILED_STCK_PO_APPROVAL_LIST,
            FETCH_STCK_PO_APPROVAL_CHECK, RECEIVE_STCK_PO_APPROVAL_CHECK, FAILED_STCK_PO_APPROVAL_CHECK } from '../../actions/stocker/stck.poapproval.act'

const defaultStckPoApprovalState = {
    stckPoApprovalData: [],
    stckTotalPoApproval: 0,
    stckPoApprovalSelect: [],
    success: true,
    inProgress: false,
}

export function StckPoApproval(state = defaultStckPoApprovalState, action){
    switch (action.type) {
        case FETCH_STCK_PO_APPROVAL_LIST:
            return ({stckPoApprovalData: [], success: true, stckTotalPoApproval: 0, stckPoApprovalSelect: [], inProgress: true})
        case RECEIVE_STCK_PO_APPROVAL_LIST:
            var _totalPoApproval = action.payload.result.length;
            var _stckPoApprovalSelect = []
            return Object.assign({}, state, {stckPoApprovalData: action.payload, success: true, stckTotalPoApproval: _totalPoApproval, stckPoApprovalSelect: _stckPoApprovalSelect, inProgress: false})
        case FAILED_STCK_PO_APPROVAL_LIST:
            return ({stckPoApprovalData: [], success: false, stckTotalPoApproval: 0, stckPoApprovalSelect: [], inProgress: false})
        default:
            return state
    }
}

const defaultStckPoApprovalCheckState = {
    stckPoApprovalCheckData: [],
    success: true,
    inProgress: false,
}

export function StckPoApprovalCheck(state = defaultStckPoApprovalCheckState, action){
    switch (action.type) {
        case FETCH_STCK_PO_APPROVAL_CHECK:
            return ({stckPoApprovalCheckData: [], success: true, inProgress: true})
        case RECEIVE_STCK_PO_APPROVAL_CHECK:
            return Object.assign({}, state, {stckPoApprovalCheckData: action.payload, 
                                                        success: true, inProgress: false})
        case FAILED_STCK_PO_APPROVAL_CHECK:
            return ({stckPoApprovalCheckData: [], success: false, inProgress: false})
        default:
            return state
    }
}