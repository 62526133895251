import { FETCH_STCK_PURCHASE_ORDER_LIST, RECEIVE_STCK_PURCHASE_ORDER_LIST, FAILED_STCK_PURCHASE_ORDER_LIST } from '../../actions/stocker/stck.purchaseorder.act'

const defaultStckPurchaseOrderState = {
    stckPurchaseOrderData: [],
    stckTotalPurchaseOrder: 0,
    stckPurchaseOrderSelect: [],
    outboundGroupTotal: 0,
    outboundGroupData: [],
    success: true,
    inProgress: false,
}

export function StckPurchaseOrder(state = defaultStckPurchaseOrderState, action){
    switch (action.type) {
        case FETCH_STCK_PURCHASE_ORDER_LIST:
            return ({stckPurchaseOrderData: [], success: true, stckTotalPurchaseOrder: 0,
                 stckPurchaseOrderSelect: [], inProgress: true, outboundGroupTotal: 0, outboundGroupData: []})
        case RECEIVE_STCK_PURCHASE_ORDER_LIST:
            var _totalPurchaseOrder = action.payload.result.length;
            var _stckPurchaseOrderSelect = []
            if(action.payload.result.length !== 0) {
                var _poData = action.payload.result

                _poData.forEach((p, index) => {
                    var _loc = {
                        'label': _poData[index].human_po_number? _poData[index].human_po_number: _poData[index].po_number,
                        'value': _poData[index].id,
                        "id": _poData[index].id,
                        "id_company": _poData[index].id_company,
                        "id_administration_office": _poData[index].id_administration_office,
                        "adm_office_name": _poData[index].adm_office_name,
                        "adm_office_address": _poData[index].adm_office_address,
                        "id_vendor": _poData[index].id_vendor,
                        "vendor_name": _poData[index].vendor_name,
                        "vendor_address": _poData[index].vendor_address,
                        "vendor_main_phone_number": _poData[index].vendor_main_phone_number,
                        "id_vendor_pic": _poData[index].id_vendor_pic,
                        "vendor_pic_name": _poData[index].vendor_pic_name,
                        "vendor_pic_email": _poData[index].vendor_pic_email,
                        "vendor_pic_whatsapp_number": _poData[index].vendor_pic_whatsapp_number,
                        "id_po_type": _poData[index].id_po_type,
                        "po_type_name": _poData[index].po_type_name,
                        "po_limit_value": _poData[index].po_limit_value,
                        "id_division_requestor": _poData[index].id_division_requestor,
                        "po_requestor_name": _poData[index].po_requestor_name,
                        "po_requestor_phone_number": _poData[index].po_requestor_phone_number,
                        "po_number": _poData[index].po_number,
                        "human_po_number": _poData[index].human_po_number,
                        "po_creation_date": _poData[index].po_creation_date,
                        "po_execution_date": _poData[index].po_execution_date,
                        "po_due_date": _poData[index].po_due_date,
                        "po_expected_total_value": _poData[index].po_expected_total_value,
                        "po_realization_total_value": _poData[index].po_realization_total_value,
                        "po_expected_total_item": _poData[index].po_expected_total_item,
                        "po_realization_total_item": _poData[index].po_realization_total_item,
                        "po_expected_tax_value": _poData[index].po_expected_tax_value,
                        "po_realization_tax_value": _poData[index].po_realization_tax_value,
                        "id_payment_term_condition": _poData[index].id_payment_term_condition,
                        "payment_term_condition_name": _poData[index].payment_term_condition_name,
                        "payment_terms_day": _poData[index].payment_terms_day,
                        "qr_code": _poData[index].qr_code,
                    }
                    _stckPurchaseOrderSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckPurchaseOrderData: action.payload, success: true, stckTotalPurchaseOrder: _totalPurchaseOrder, 
                outboundGroupTotal: action.payload.outbound_group.length, outboundGroupData: action.payload.outbound_group,
                stckPurchaseOrderSelect: _stckPurchaseOrderSelect, inProgress: false})
        case FAILED_STCK_PURCHASE_ORDER_LIST:
            return ({stckPurchaseOrderData: [], success: false, stckTotalPurchaseOrder: 0, outboundGroupTotal: 0, 
                outboundGroupData: [], 
                stckPurchaseOrderSelect: [], inProgress: false})
        default:
            return state
    }
}