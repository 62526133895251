import { FETCH_PTH_CUSTOMER_PIC_LIST, RECEIVE_PTH_CUSTOMER_PIC_LIST, FAILED_PTH_CUSTOMER_PIC_LIST } from '../../actions/pointhub/pth.customerpic.act'

const defaultPthCustomersPicState = {
    pthCustomersPicData: [],
    pthTotalCustomersPic: 0,
    pthCustomersPicSelect: [],
    success: true,
    inProgress: false,
}

export function PthCustomersPic(state = defaultPthCustomersPicState, action){
    switch (action.type) {
        case FETCH_PTH_CUSTOMER_PIC_LIST:
            return ({pthCustomersPicData: [], success: true, pthTotalCustomers: 0, pthCustomersSelect: [], inProgress: true})
        case RECEIVE_PTH_CUSTOMER_PIC_LIST:
            var _totalUnits = action.payload.result.length;
            var _pthCustomersPicSelect = []
            if(action.payload.result !== []) {
                var _pthCustPicData = action.payload.result

                _pthCustPicData.forEach((p, index) => {
                    var _loc = {
                        'label': _pthCustPicData[index].name,
                        'value': _pthCustPicData[index].id
                    }
                    _pthCustomersPicSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {pthCustomersPicData: action.payload, success: true, pthTotalCustomersPic: _totalUnits, pthCustomersPicSelect: _pthCustomersPicSelect, inProgress: false})
        case FAILED_PTH_CUSTOMER_PIC_LIST:
            return ({pthCustomersPicData: [], success: false, pthTotalCustomersPic: 0, pthCustomersPicSelect: [], inProgress: false})
        default:
            return state
    }
}