import { FETCH_OUTBOUND_ITEM_DETAIL_LIST, RECEIVE_OUTBOUND_ITEM_DETAIL_LIST, FAILED_OUTBOUND_ITEM_DETAIL_LIST } from '../../actions/stocker/stck.outbounditemdetail.act'

const defaultStckOutboundItemDetailState = {
    stckOutboundItemDetailData: [],
    stckTotalOutboundItemDetail: null,
    stckOutboundItemDetailSelect: [],
    success: true,
    inProgress: false,
}

export function StckOutboundItemDetail(state = defaultStckOutboundItemDetailState, action){
    switch (action.type) {
        case FETCH_OUTBOUND_ITEM_DETAIL_LIST:
            return ({stckOutboundItemDetailData: [], success: true, stckTotalOutboundItemDetail: null, stckOutboundItemDetailSelect: [], inProgress: true})
        case RECEIVE_OUTBOUND_ITEM_DETAIL_LIST:
            let uniqueResult = {result:[]}
            action.payload.result.filter(function(item){
                var i = uniqueResult.result.findIndex(x => (x.id === item.id));
                if(i <= -1){
                    uniqueResult.result.push(item);
                }
                return null;
              });
            var _totalOutboundItemDetail = uniqueResult.result.length;
            var _stckOutboundItemDetailSelect = []
            if(uniqueResult.result !== []) {
                var _outboundItemDetailData = uniqueResult.result

                _outboundItemDetailData.forEach((p, index) => {
                    var _loc = {
                        'label': _outboundItemDetailData[index].outbound_number,
                        'value': _outboundItemDetailData[index].id,
                        "id": _outboundItemDetailData[index].id,
                        "id_master_outbound": _outboundItemDetailData[index].id_master_outbound,
                        "outbound_number": _outboundItemDetailData[index].outbound_number,
                        "document_number": _outboundItemDetailData[index].document_number,
                        "id_outbound_detail": _outboundItemDetailData[index].id_outbound_detail,
                        "awb_number": _outboundItemDetailData[index].awb_number,
                        "id_master_item": _outboundItemDetailData[index].id_master_item,
                        "item_name": _outboundItemDetailData[index].item_name,
                        "sku_item_name": _outboundItemDetailData[index].sku_item_name,
                        "expected_qty": _outboundItemDetailData[index].expected_qty,
                        "realization_qty": _outboundItemDetailData[index].realization_qty,
                        "rejected_qty": _outboundItemDetailData[index].rejected_qty,
                        "batch_number": _outboundItemDetailData[index].batch_number,
                        "production_date": _outboundItemDetailData[index].production_date,
                        "expired_date": _outboundItemDetailData[index].expired_date,
                        "id_receiver": _outboundItemDetailData[index].id_receiver,
                    }
                    _stckOutboundItemDetailSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckOutboundItemDetailData: uniqueResult, success: true, stckTotalOutboundItemDetail: _totalOutboundItemDetail, stckOutboundItemDetailSelect: _stckOutboundItemDetailSelect, inProgress: false})
        case FAILED_OUTBOUND_ITEM_DETAIL_LIST:
            return ({stckOutboundItemDetailData: [], success: false, stckTotalOutboundItemDetail: null, stckOutboundItemDetailSelect: [], inProgress: false})
        default:
            return state
    }
}