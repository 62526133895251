import { FETCH_SCHEDULED_MAINTENANCE_TABLE, RECEIVE_SCHEDULED_MAINTENANCE_TABLE, FAILED_SCHEDULED_MAINTENANCE_TABLE} from '../../actions/transporter/scheduledmaintenance.act'

const defaultScheduledMaintenanceListState = {
    scheduledMaintenanceListData: [],
    scheduledMaintenanceTotalData: 0,
    inProgress: false,
    success: true
}

export function ScheduledMaintenanceList(state = defaultScheduledMaintenanceListState, action){
    switch (action.type) {
        case FETCH_SCHEDULED_MAINTENANCE_TABLE:
            return ({scheduledMaintenanceListData: [], scheduledMaintenanceTotalData:0,
                        success: false, inProgress: true})
        case RECEIVE_SCHEDULED_MAINTENANCE_TABLE:
            // var _total = action.payload.result.length
            return Object.assign({}, state, {scheduledMaintenanceListData: action.payload.result.reverse(),
                scheduledMaintenanceTotalData: action.payload.result.length,
                success: true, inProgress: false})
        case FAILED_SCHEDULED_MAINTENANCE_TABLE:
            return ({scheduledMaintenanceListData: [], scheduledMaintenanceTotalData:0,
                     success: false, inProgress: false})
        default:
            return state
    }
}


