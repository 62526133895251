import { FETCH_REPACKAGING_ITEM_LIST, 
         RECEIVE_REPACKAGING_ITEM_LIST, 
         FAILED_REPACKAGING_ITEM_LIST } from '../../actions/stocker/stck.repackagingitem.act'

const defaultStckRepackagingItemState = {
    stckRepackagingItemData: [],
    stckTotalRepackagingItem: 0,
    success: true,
    inProgress: false,
}

export function StckRepackagingItem(state = defaultStckRepackagingItemState, action){
    switch (action.type) {
        case FETCH_REPACKAGING_ITEM_LIST:
            return ({stckRepackagingItemData: [], success: true, stckTotalRepackagingItem: 0, inProgress: true})
        case RECEIVE_REPACKAGING_ITEM_LIST:
            var _totalUnits = action.payload.result.length;
            return Object.assign({}, state, {stckRepackagingItemData: action.payload, success: true, stckTotalRepackagingItem: _totalUnits, inProgress: false})
        case FAILED_REPACKAGING_ITEM_LIST:
            return ({stckRepackagingItemData: [], success: false, stckTotalRepackagingItem: 0, inProgress: false})
        default:
            return state
    }
}