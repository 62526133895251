import { FETCH_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD, RECEIVE_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD, FAILED_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD } from '../../actions/transporter/aTransporter-FleetMaintenanceDashboardNotification'

const defaultFleetMaintenanceNotificationDashboardState = {
    fleetMaintenanceExpiredData: [],
    fleetMaintenanceAlmostExpiredData: [],
    totalNotification: 0,
    inProgress: false,
    success: true
}

export function fetchFleetMaintenanceNotificationDashboard(state = defaultFleetMaintenanceNotificationDashboardState, action){
    switch (action.type) {
        case FETCH_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD:
            return ({
                fleetMaintenanceExpiredData: [],
                fleetMaintenanceAlmostExpiredData: [],
                totalNotification: 0,
                inProgress: true,
                success: true
            })
        case RECEIVE_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD:
            return Object.assign({}, state, {
                fleetMaintenanceExpiredData: action.payload.result.expired_data,
                fleetMaintenanceAlmostExpiredData: action.payload.result.almost_expired_data,
                totalNotification: ( action.payload.result.count_expired + action.payload.result.count_almost_expired ),
                inProgress: false,
                success: true
            })
        case FAILED_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD:
            return ({
                fleetMaintenanceExpiredData: [],
                fleetMaintenanceAlmostExpiredData: [],
                totalNotification: 0,
                inProgress: false,
                success: false
            })
        default:
            return state
    }
}