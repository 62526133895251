import { FETCH_TRIPL_WINNER_TRACKER_LIST,  
        RECEIVE_TRIPL_WINNER_TRACKER_LIST,
        FAILED_TRIPL_WINNER_TRACKER_LIST ,
        FETCH_TRIPL_HISTORY_PATH_LIST,
        RECEIVE_TRIPL_HISTORY_PATH_LIST,
        FAILED_TRIPL_HISTORY_PATH_LIST} 
        from '../../actions/transporter/aTransporter-TriPLWinnerTracker'

const nullIdGroupAsAutomaWide = function(triPlRequest){
    triPlRequest.map(request =>{
        if(request.id_group === null)
            request.group_name = "Automa Wide"
        return request
    })
    return triPlRequest
}

const defaultTriPLWinnerTrackerListState = {
    triPLWinnerTrackerListData: [],
    triPLDeployedWoSelect: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLWinnerTracker(state = defaultTriPLWinnerTrackerListState, action){
    switch (action.type) {
        case FETCH_TRIPL_WINNER_TRACKER_LIST:
            return ({triPLWinnerTrackerListData: [], triPLDeployedWoSelect: [], success: true, inProgress: true})
        case RECEIVE_TRIPL_WINNER_TRACKER_LIST:
            let triPLDeployedWo = []
            const {result} = action.payload
            if(result.length !== 0) {
                result.forEach((data, index) => {
                    triPLDeployedWo.push({
                        'label': `${data.wo_number} - DO : ${data.do_number}
                                                    - ${data.company}
                                                    - from ${data.wo_from_name} to ${data.wo_destination_name}
                                                    - Departure : ${data.dept_time}
                                                    - Arrival: ${data.arrival_time}`,
                        'value': data.id
                    })
                })
            }
            return Object.assign({}, state, {triPLWinnerTrackerListData: nullIdGroupAsAutomaWide(action.payload.result), 
                                              triPLDeployedWoSelect:triPLDeployedWo,  success: true, inProgress: false})
        case FAILED_TRIPL_WINNER_TRACKER_LIST:
            return ({triPLWinnerTrackerListData: [], triPLDeployedWoSelect: [], success: false, inProgress: false})
        default:
            return state
    }
}

const defaultTriPLHistoryPathListState = {
    triPLHistoryPathListData: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLHistoryPath(state = defaultTriPLHistoryPathListState, action){
    switch (action.type) {
        case FETCH_TRIPL_HISTORY_PATH_LIST:
            return ({triPLHistoryPathListData: [], success: true, inProgress: true})
        case RECEIVE_TRIPL_HISTORY_PATH_LIST:
            return Object.assign({}, state, {triPLHistoryPathListData: action.payload, 
                                                success: true, inProgress: false})
        case FAILED_TRIPL_HISTORY_PATH_LIST:
            return ({triPLHistoryPathListData: [], success: false, inProgress: false})
        default:
            return state
    }
}