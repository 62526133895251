import { 
    FETCH_STCK_MASTER_ITEM_DETAIL_ADJUSTMENT_LIST,
    RECEIVE_STCK_MASTER_ITEM_DETAIL_ADJUSTMENT_LIST,
    FAILED_STCK_MASTER_ITEM_DETAIL_ADJUSTMENT_LIST,
    ADD_STCK_MASTER_ITEM_DETAIL_ADJUSTMENT
} 
from '../../actions/stocker/stck.masteritemdetailadjustment.act'

const defaultPublicFacilityState = {
    totalAdjustmentRecord:0,
    adjustmentRecord: [],
    success: true,  
    inProgress: false
}


export function StckMasterItemDetailAdjustment(state = defaultPublicFacilityState, action){
    switch (action.type) {
        case FETCH_STCK_MASTER_ITEM_DETAIL_ADJUSTMENT_LIST:
            return ({adjustmentRecord: [], success: true, inProgress: true, totalAdjustmentRecord:0})
        case RECEIVE_STCK_MASTER_ITEM_DETAIL_ADJUSTMENT_LIST:
            return Object.assign({}, state, {adjustmentRecord: action.payload.result, 
                                                totalAdjustmentRecord: action.payload.result.length,
                                                success: true, inProgress: false})
        case ADD_STCK_MASTER_ITEM_DETAIL_ADJUSTMENT:
            state.adjustmentRecord.push(action.payload)
        return Object.assign({}, state, {adjustmentRecord: state.adjustmentRecord, 
                totalAdjustmentRecord: state.adjustmentRecord.length,
                success: true, inProgress: false})
        case FAILED_STCK_MASTER_ITEM_DETAIL_ADJUSTMENT_LIST:
            return ({adjustmentRecord: [], success: false, inProgress: false, totalAdjustmentRecord:0,})
        default:
            return state
    }
}