import { FETCH_STCK_TRANSFER_METHOD_LIST, RECEIVE_STCK_TRANSFER_METHOD_LIST, FAILED_STCK_TRANSFER_METHOD_LIST } from '../../actions/stocker/stck.transfermethod.act'

const defaultStckTransferMethodState = {
    stckTransferMethodData: [],
    stckTotalTransferMethod: 0,
    stckTransferMethodSelect: [],
    success: true,
    inProgress: false,
}

export function StckTransferMethod(state = defaultStckTransferMethodState, action){
    switch (action.type) {
        case FETCH_STCK_TRANSFER_METHOD_LIST:
            return ({stckTransferMethodData: [], success: true, stckTotalTransferMethod: 0, stckTransferMethodSelect: [], inProgress: true})
        case RECEIVE_STCK_TRANSFER_METHOD_LIST:
            var _totalTransferMethod = action.payload.result.length;
            var _stckTransferMethodSelect = []
            if(action.payload.result !== []) {
                var _tsfMethodData = action.payload.result

                _tsfMethodData.forEach((p, index) => {
                    var _loc = {
                        'label': _tsfMethodData[index].transfer_method_name + ' - ' + _tsfMethodData[index].transfer_method_desc,
                        'value': _tsfMethodData[index].id
                    }
                    _stckTransferMethodSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckTransferMethodData: action.payload, success: true, stckTotalTransferMethod: _totalTransferMethod, stckTransferMethodSelect: _stckTransferMethodSelect, inProgress: false})
        case FAILED_STCK_TRANSFER_METHOD_LIST:
            return ({stckTransferMethodData: [], success: false, stckTotalTransferMethod: 0, stckTransferMethodSelect: [], inProgress: false})
        default:
            return state
    }
}