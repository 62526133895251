import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import { clearAuth } from '../../../redux/actions/aUser-Auth.jsx';
import {connect} from "react-redux";

class Logout extends Component {
    // constructor(props) {
    //     super(props);
    // }
    UNSAFE_componentWillMount() {
        this.props.clearAuth();
    }
    render() {
        localStorage.clear();
        return (<Redirect to='/login' />)
    }
}

function mapStateToProps(state){
    return {
        authData: state.authStore.authData
    }
}

export default connect(mapStateToProps, {clearAuth}) (Logout);