import { FETCH_DELIVERY_CHECK_IN_STATUS, RECEIVE_DELIVERY_CHECK_IN_STATUS, FAILED_DELIVERY_CHECK_IN_STATUS } from '../../actions/transporter/aTransporter-DeliveryCheckInStatus'

const defaultDeliveryCheckInLogState = {
    deliveryCheckInStatusData: []    
}

export function fetchDeliveryCheckInStatus(state = defaultDeliveryCheckInLogState, action){
    switch (action.type) {
        case FETCH_DELIVERY_CHECK_IN_STATUS:
            return ({deliveryCheckInStatusData: [], inProgress: true})
        case RECEIVE_DELIVERY_CHECK_IN_STATUS:
            return Object.assign({}, state, {deliveryCheckInStatusData: action.payload, inProgress: false})
        case FAILED_DELIVERY_CHECK_IN_STATUS:
            return ({deliveryCheckInStatusData: [], inProgress: false})
        default:
            return state
    }
}