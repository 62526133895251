import {FETCH_SCHEDULED_MAINTENANCE_INVOICE_TABLE, RECEIVE_SCHEDULED_MAINTENANCE_INVOICE_TABLE, FAILED_SCHEDULED_MAINTENANCE_INVOICE_TABLE
} from '../../actions/transporter/maintenance-invoice.act'
        

const defaultMaintenanceInvoiceState = {
    totalMaintenanceInvoices:0,
    maintenanceInvoices: [],
    success: true,  
    inProgress: false
}

export function MaintenanceInvoice(state = defaultMaintenanceInvoiceState, action){
    switch (action.type) {
        case FETCH_SCHEDULED_MAINTENANCE_INVOICE_TABLE:
            return ({maintenanceInvoices: [], success: true,
                    inProgress: true})
        case RECEIVE_SCHEDULED_MAINTENANCE_INVOICE_TABLE:
            // const {payload} = action
            return Object.assign({}, state, {maintenanceInvoices: action.payload,
                                                success: true, inProgress: false})
        case FAILED_SCHEDULED_MAINTENANCE_INVOICE_TABLE:
            return ({maintenanceInvoices: [], success: false, 
                    inProgress: false})
        default:
            return state
    }
}
