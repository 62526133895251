import axios from "axios";
import {WAREHOUSE_MAPPING_LIST} from '../../config/stck.links'
import {toast} from 'react-toastify'

export const ADD_SHAPE = 'ADD_SHAPE'
export function addShape(shape){
    return {
        type: ADD_SHAPE,
        payload: shape
    }
}

export const REMOVE_SHAPE = 'REMOVE_SHAPE'
export function removeShape(shapeName){
    return {
        type: REMOVE_SHAPE,
        payload: shapeName
    }
}

export const SELECT_SHAPE = 'SELECT_SHAPE'
export function selectShape(shape){
    return {
        type: SELECT_SHAPE,
        payload: shape
    }
}

export const UPDATE_SHAPE = 'UPDATE_SHAPE'
export function updateShape(shapeUpdate){
    return {
        type: UPDATE_SHAPE,
        payload: shapeUpdate
    }
}

export const CHANGE_MAPPING_MODE = 'CHANGE_MAPPING_MODE'
export function changeMappingMode(mode){
    return {
        type: CHANGE_MAPPING_MODE,
        payload: mode
    }
}

export const FETCH_WAREHOUSE_MAPPING_LIST = 'FETCH_WAREHOUSE_MAPPING_LIST'
export const RECEIVE_WAREHOUSE_MAPPING_LIST = 'RECEIVE_WAREHOUSE_MAPPING_LIST'
export const FAILED_WAREHOUSE_MAPPING_LIST = 'FAILED_WAREHOUSE_MAPPING_LIST'

export function fetchStckWarehouseMapping(id_warehouse_area){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_WAREHOUSE_MAPPING_LIST});
        axios({
            method: 'post',
            url: WAREHOUSE_MAPPING_LIST,
            headers: {
                Authorization: auth
            },
            data:{
                id_warehouse_area
            }
        })
        .then((response) => {
            if(response.data.result.length > 0){
                const floorPlan = [...response.data.result].filter((item) => item.type === 1)
                floorPlan[0].dbId = floorPlan[0].id
                floorPlan[0].save_status = "EDITED"
                floorPlan[0].type = "FLOOR_PLAN"
                // eslint-disable-next-line no-self-assign
                floorPlan[0].width = floorPlan[0].width
                // eslint-disable-next-line no-self-assign
                floorPlan[0].height = floorPlan[0].height
                floorPlan[0].isDraggable = true
                floorPlan[0].isChangedImage = true
                floorPlan[0].scaleX=floorPlan[0].scale_x
                floorPlan[0].scaleY=floorPlan[0].scale_y
                floorPlan[0].skewX=floorPlan[0].skew_x
                floorPlan[0].skewY=floorPlan[0].skew_y
                floorPlan[0].offsetX=floorPlan[0].offset_x
                floorPlan[0].offsetY=floorPlan[0].offset_y
                floorPlan[0].imageObj = {}
                floorPlan[0].imageObj.isFromFetch=true
                floorPlan[0].imageObj.path = floorPlan[0].image_link
                floorPlan[0].objId = Math.random()

                const rack = [...response.data.result].filter((item) => item.type === 2)
                rack.map((item) => {
                    item.dbId = item.id
                    item.save_status = "EDITED"
                    item.type = "RACK"
                    item.width = item.scale_x? item.width * item.scale_x : item.width
                    item.height = item.scale_y? item.height * item.scale_y : item.height
                    item.rack_name=  item.rack.rack_name
                    item.rack_data_id = item.rack.id
                    item.rack_max_x = item.rack.rack_max_x
                    item.rack_max_y = item.rack.rack_max_y
                    item.rack_max_z = item.rack.rack_max_z
                    item.rack_type = item.rack.rack_type
                    item.xy = {x: item.rack_max_x, y: item.rack_max_y}
                    item.isDraggable = true
                    item.scaleX=item.scale_x
                    item.scaleY=item.scale_y
                    item.skewX=item.skew_x
                    item.skewY=item.skew_y
                    item.offsetX=item.offset_x
                    item.offsetY=item.offset_y
                    item.objId = Math.random()

                    return item;
                })

                const txt = [...response.data.result].filter((item) => item.type === 3)
                txt.map((item) => {
                    item.dbId = item.id
                    item.save_status = "EDITED"
                    item.type = "TEXT"
                    // item.width = item.scale_x? item.width * item.scale_x : item.width
                    // item.height = item.scale_y? item.height * item.scale_y : item.height
                    item.isDraggable = true
                    item.fontStyle = item.font_style
                    item.fontSize = item.font_size
                    item.scaleX=item.scale_x
                    item.scaleY=item.scale_y
                    item.skewX=item.skew_x
                    item.skewY=item.skew_y
                    item.offsetX=item.offset_x
                    item.offsetY=item.offset_y
                    item.objId = Math.random()

                    return item;
                })

                response.data.result = [...floorPlan, ...rack, ...txt]
            }
            dispatch( {
                type: RECEIVE_WAREHOUSE_MAPPING_LIST,
                payload: response.data.result
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_WAREHOUSE_MAPPING_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_WAREHOUSE_MAPPING_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_WAREHOUSE_MAPPING_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_WAREHOUSE_MAPPING_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_WAREHOUSE_MAPPING_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_WAREHOUSE_MAPPING_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}
