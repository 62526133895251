import { FETCH_STCK_DISPOSAL_ITEM_DETAIL_LIST, RECEIVE_STCK_DISPOSAL_ITEM_DETAIL_LIST, FAILED_STCK_DISPOSAL_ITEM_DETAIL_LIST } from '../../actions/stocker/stck.disposalitemdetail.act'

const defaultStckDisposalItemDetailState = {
    stckDisposalItemDetailData: [],
    stckTotalDisposalItemDetail: 0,
    stckDisposaltemSelect: [],
    success: true,
    inProgress: false,
}

export function StckDisposalItemDetail(state = defaultStckDisposalItemDetailState, action){
    switch (action.type) {
        case FETCH_STCK_DISPOSAL_ITEM_DETAIL_LIST:
            return ({stckDisposalItemDetailData: [], success: true, stckTotalDisposalItemDetail: 0, stckDisposaltemSelect: [], inProgress: true})
        case RECEIVE_STCK_DISPOSAL_ITEM_DETAIL_LIST:
            var _totalDisposalItemDetail = action.payload.result.length;
            var _stckDisposaltemSelect = []
            if(action.payload.result !== []) {
                var _dItemData = action.payload.result

                _dItemData.forEach((p, index) => {
                    var _loc = {
                        'label': _dItemData[index].item_name + " - " + _dItemData[index].sku_item_name ,
                        'value': _dItemData[index].id,
                    }
                    _stckDisposaltemSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckDisposalItemDetailData: action.payload, success: true, stckTotalDisposalItemDetail: _totalDisposalItemDetail, stckDisposaltemSelect: _stckDisposaltemSelect, inProgress: false})
        case FAILED_STCK_DISPOSAL_ITEM_DETAIL_LIST:
            return ({stckDisposalItemDetailData: [], success: false, stckTotalDisposalItemDetail: 0, stckDisposaltemSelect: [], inProgress: false})
        default:
            return state
    }
}