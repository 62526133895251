import { FETCH_SM_MECHANIC_TABLE, RECEIVE_SM_MECHANIC_TABLE, FAILED_SM_MECHANIC_TABLE } from '../../actions/transporter/smmechanic.act'

const defaultSmSmMechanicListState = {
    smMechanicListData: [],
    inProgress: false,
    success: true,
    smMechanicSelectData: [],
}

export function SmMechanicList(state = defaultSmSmMechanicListState, action){
    switch (action.type) {
        case FETCH_SM_MECHANIC_TABLE:
            return ({smMechanicListData: [], success: false, inProgress: true, smMechanicSelectData: []})
        case RECEIVE_SM_MECHANIC_TABLE:
            // var _total = action.payload.result.length
            var list = []
            if(action.payload.result.length !== 0) {
                var smMechanicList = action.payload.result
                smMechanicList.forEach((p, index) => {
                    var smMechanic = {
                        // 'label': 'Maintenance:' + smMechanicList[index].scheduled_maintenance_title + '| Mechanic: ' +smMechanicList[index].mechanic_first_name,
                        'label': smMechanicList[index].mechanic_first_name + ' ' + smMechanicList[index].mechanic_last_name,
                        'value': smMechanicList[index].id
                    }
                    list.push(smMechanic)
                })
            }
            return Object.assign({}, state, {smMechanicListData: action.payload.result, success: true, inProgress: false, smMechanicSelectData:list})
        case FAILED_SM_MECHANIC_TABLE:
            return ({smMechanicListData: [], success: false, inProgress: false})
        default:
            return state
    }
} 