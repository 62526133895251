import axios from "axios";
import * as API_LINKS from "../../config/links.jsx";
import { toast } from "react-toastify";

export const FETCH_SAVED_PLACES_TABLE = "FETCH_SAVED_PLACES_TABLE";
export const RECEIVE_SAVED_PLACES_TABLE = "RECEIVE_SAVED_PLACES_TABLE";
export const FAILED_SAVED_PLACES_TABLE = "FAILED_SAVED_PLACES_TABLE";

export function fetchSavedPlacesTable(public_place = 2) {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;

  return (dispatch, getState) => {
    dispatch({ type: FETCH_SAVED_PLACES_TABLE });
    axios({
      method: "post",
      url: API_LINKS.SAVED_PLACES_LIST_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        public_place,
      },
    })
      .then((response) => {
        // console.log(response.data);
        dispatch({
          type: RECEIVE_SAVED_PLACES_TABLE,
          payload: response.data,
        });
        // console.log(response.data)
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_SAVED_PLACES_TABLE,
            });
            return toast.error("Invalid credentials! Please try again!");
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_SAVED_PLACES_TABLE,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_SAVED_PLACES_TABLE,
            });
            return toast.error(error.response.data.message);
          } else {
            dispatch({
              type: FAILED_SAVED_PLACES_TABLE,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_SAVED_PLACES_TABLE,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          dispatch({
            type: FAILED_SAVED_PLACES_TABLE,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}

export function fetchSavedPlacesById(id) {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;

  return (dispatch, getState) => {
    dispatch({ type: FETCH_SAVED_PLACES_TABLE });
    axios({
      method: "post",
      url: API_LINKS.SAVED_PLACES_LIST_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        id,
        public_place:2,
      },
    })
      .then((response) => {
        // console.log(response.data);
        dispatch({
          type: RECEIVE_SAVED_PLACES_TABLE,
          payload: response.data,
        });
        // console.log(response.data)
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_SAVED_PLACES_TABLE,
            });
            return toast.error("Invalid credentials! Please try again!");
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_SAVED_PLACES_TABLE,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_SAVED_PLACES_TABLE,
            });
            return toast.error(error.response.data.message);
          } else {
            dispatch({
              type: FAILED_SAVED_PLACES_TABLE,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_SAVED_PLACES_TABLE,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          dispatch({
            type: FAILED_SAVED_PLACES_TABLE,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}

export function fetchSavedPlacesTableByCustomer(customerName) {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;

  return (dispatch, getState) => {
    dispatch({ type: FETCH_SAVED_PLACES_TABLE });
    axios({
      method: "post",
      url: API_LINKS.SAVED_PLACES_LIST_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        public_place:2,
      },
    })
      .then((response) => {
        // console.log(response.data)
        response.data.firstCustomer = customerName;
        dispatch({
          type: RECEIVE_SAVED_PLACES_TABLE,
          payload: response.data,
        });
        // console.log(response.data)
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_SAVED_PLACES_TABLE,
            });
            return toast.error("Invalid credentials! Please try again!");
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_SAVED_PLACES_TABLE,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_SAVED_PLACES_TABLE,
            });
            return toast.error(error.response.data.message);
          } else {
            dispatch({
              type: FAILED_SAVED_PLACES_TABLE,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_SAVED_PLACES_TABLE,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          dispatch({
            type: FAILED_SAVED_PLACES_TABLE,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}

export const FETCH_SAVED_PLACES_MESH_LIST = "FETCH_SAVED_PLACES_MESH_LIST";
export const RECEIVE_SAVED_PLACES_MESH_LIST = "RECEIVE_SAVED_PLACES_MESH_LIST";
export const FAILED_SAVED_PLACES_MESH_LIST = "FAILED_SAVED_PLACES_MESH_LIST";

export function fetchSavedPlacesWihMesh(savedPlaceData, option = null) {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;
  return (dispatch, getState) => {
    dispatch({ type: FETCH_SAVED_PLACES_MESH_LIST });
    axios({
      method: "post",
      url: API_LINKS.SAVED_PLACES_MESH_LIST,
      headers: {
        Authorization: auth,
      },
      data: option,
    })
      .then((responses) => {
        let payload = [];
        console.log(responses);
        const result = responses.data.result;
        for (let i = 0; i < savedPlaceData.length; i++) {
          const mainSavedPlace = [...result].filter(
            (m) => m.id === savedPlaceData[i].id
          );
          if (mainSavedPlace.length === 0) continue;
          payload.push([
            savedPlaceData[i].customer_name,
            savedPlaceData[i].name,
            savedPlaceData[i].public_place_address,
            savedPlaceData[i].customer_handphone,
            savedPlaceData[i].customer_email,
            savedPlaceData[i].lat,
            savedPlaceData[i].lng,
            mainSavedPlace[0].wo_called,
            mainSavedPlace[0].visit,
            mainSavedPlace[0].ut,
            mainSavedPlace[0].load_time,
          ]);
        }
        return payload;
      })
      .then((payload) => {
        dispatch({
          type: RECEIVE_SAVED_PLACES_MESH_LIST,
          payload,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_SAVED_PLACES_MESH_LIST,
            });
            return toast.error("Invalid credentials! Please try again!");
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_SAVED_PLACES_MESH_LIST,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_SAVED_PLACES_MESH_LIST,
            });
            return toast.error(error.response.data.message);
          } else {
            dispatch({
              type: FAILED_SAVED_PLACES_MESH_LIST,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_SAVED_PLACES_MESH_LIST,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          dispatch({
            type: FAILED_SAVED_PLACES_MESH_LIST,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}

export const FETCH_SAVED_PLACES_NEAR = "FETCH_SAVED_PLACES_NEAR";
export const RECEIVE_SAVED_PLACES_NEAR = "RECEIVE_SAVED_PLACES_NEAR";
export const FAILED_SAVED_PLACES_NEAR = "FAILED_SAVED_PLACES_NEAR";

export function fetchSavedPlacesNear(data, id) {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;

  return (dispatch, getState) => {
    dispatch({ type: FETCH_SAVED_PLACES_NEAR });
    axios({
      method: "post",
      url: API_LINKS.SAVED_PLACES_NEAR_LIST,
      headers: {
        Authorization: auth,
      },
      data,
    })
      .then((response) => {
        response.data.result = response.data.result.filter((r) => r.id !== id);
        dispatch({
          type: RECEIVE_SAVED_PLACES_NEAR,
          payload: response.data,
        });
        // console.log(response.data)
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_SAVED_PLACES_NEAR,
            });
            return toast.error("Invalid credentials! Please try again!");
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_SAVED_PLACES_NEAR,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_SAVED_PLACES_NEAR,
            });
            return toast.error(error.response.data.message);
          } else {
            dispatch({
              type: FAILED_SAVED_PLACES_NEAR,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_SAVED_PLACES_NEAR,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          dispatch({
            type: FAILED_SAVED_PLACES_NEAR,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}
