import { FETCH_STCK_ITEM_HANDLING_CATEGORIES_LIST, RECEIVE_STCK_ITEM_HANDLING_CATEGORIES_LIST, FAILED_STCK_ITEM_HANDLING_CATEGORIES_LIST } from '../../actions/stocker/stck.itemhandlingcategories.act'

const defaultStckItemHandlingCategoriesState = {
    stckItemHandlingCategoriesData: [],
    stckTotalItemHandlingCategories: 0,
    stckItemHandlingCategoriesSelect: [],
    success: true,
    inProgress: false,
}

export function StckItemHandlingCategories(state = defaultStckItemHandlingCategoriesState, action){
    switch (action.type) {
        case FETCH_STCK_ITEM_HANDLING_CATEGORIES_LIST:
            return ({stckItemHandlingCategoriesData: [], success: true, stckTotalItemHandlingCategories: 0, stckItemHandlingCategoriesSelect: [], inProgress: true})
        case RECEIVE_STCK_ITEM_HANDLING_CATEGORIES_LIST:
            var _totalItemHandlingCategories = action.payload.result.length;
            var _stckItemHandlingCategoriesSelect = []
            if(action.payload.result !== []) {
                var _itemHandlingData = action.payload.result

                _itemHandlingData.forEach((p, index) => {
                    var _loc = {
                        'label': _itemHandlingData[index].handling_category_name + ' - ' + _itemHandlingData[index].class_category,
                        'value': _itemHandlingData[index].id
                    }
                    _stckItemHandlingCategoriesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckItemHandlingCategoriesData: action.payload, success: true, stckTotalItemHandlingCategories: _totalItemHandlingCategories, stckItemHandlingCategoriesSelect: _stckItemHandlingCategoriesSelect, inProgress: false})
        case FAILED_STCK_ITEM_HANDLING_CATEGORIES_LIST:
            return ({stckItemHandlingCategoriesData: [], success: false, stckTotalItemHandlingCategories: 0, stckItemHandlingCategoriesSelect: [], inProgress: false})
        default:
            return state
    }
}