import { FETCH_MASTER_OUTBOUND_LIST, RECEIVE_MASTER_OUTBOUND_LIST, FAILED_MASTER_OUTBOUND_LIST,
    FETCH_MASTER_OUTBOUND_IN_DO_LIST, RECEIVE_MASTER_OUTBOUND_IN_DO_LIST, FAILED_MASTER_OUTBOUND_IN_DO_LIST } from '../../actions/stocker/stck.masteroutbound.act'

const defaultStckMasterOutboundState = {
    stckMasterOutboundData: [],
    stckTotalMasterInbound: 0,
    stckMasterOutboundSelect: [],
    stckMasterOutboundSelectWCustomer: [],
    stckMasterOutboundSelectWCustomerforTriPL: [],
    stckMasterOutboundSelectDesc: [],
    stckMasterOutboundSelectIntercompany: [],
    success: true,
    inProgress: false,
}

export function StckMasterOutbound(state = defaultStckMasterOutboundState, action){
    switch (action.type) {
        case FETCH_MASTER_OUTBOUND_LIST:
            return ({stckMasterOutboundData: [], success: true, stckTotalMasterInbound: 0, stckMasterOutboundSelect: [], 
                                stckMasterOutboundSelectWCustomerforTriPL: [], stckMasterOutboundSelectIntercompany: [],
                                stckMasterOutboundSelectWCustomer: [], stckMasterOutboundSelectDesc: [], inProgress: true})
        case RECEIVE_MASTER_OUTBOUND_LIST:
            var _totalMasterOutbound = action.payload.result.length;
            var _stckMasterOutboundSelect = []
            var _stckMasterOutboundSelectDesc = []
            var _stckMasterOutboundSelectWCustomer = []
            var _stckMasterOutboundSelectIntercompany = []
            if(Array.isArray(action.payload.result) && action.payload.result.length !== 0) {
                var _mOutboundData = action.payload.result

                _mOutboundData.forEach((p, index) => {
                    var _loc = {
                        'label': _mOutboundData[index].outbound_number,
                        'value': _mOutboundData[index].id,
                        "id": _mOutboundData[index].id,
                        "id_company": _mOutboundData[index].id_company,
                        "id_administration_office": _mOutboundData[index].id_administration_office,
                        "admin_office_name": _mOutboundData[index].admin_office_name,
                        "admin_office_address": _mOutboundData[index].admin_office_address,
                        "admin_office_main_phone_number": _mOutboundData[index].admin_office_main_phone_number,
                        "id_sales_order": _mOutboundData[index].id_sales_order,
                        "so_number": _mOutboundData[index].so_number,
                        "so_creation_date": _mOutboundData[index].so_creation_date,
                        "so_due_date": _mOutboundData[index].so_due_date,
                        "id_warehouse": _mOutboundData[index].id_warehouse,
                        "warehouse_name": _mOutboundData[index].warehouse_name,
                        "warehouse_address": _mOutboundData[index].warehouse_address,
                        "id_warehouse_area": _mOutboundData[index].id_warehouse_area,
                        "warehouse_area_name": _mOutboundData[index].warehouse_area_name,
                        "warehouse_area_size": _mOutboundData[index].warehouse_area_size,
                        "id_customer": _mOutboundData[index].id_customer,
                        "id_outbound_type": _mOutboundData[index].id_outbound_type,
                        "outbound_type_name": _mOutboundData[index].outbound_type_name,
                        "id_unit": _mOutboundData[index].id_unit,
                        "unit_name": _mOutboundData[index].unit_name,
                        "outbound_number": _mOutboundData[index].outbound_number,
                        "document_number": _mOutboundData[index].document_number,
                        "expected_departure_time": _mOutboundData[index].expected_departure_time,
                        "expected_loading_time": _mOutboundData[index].expected_loading_time,
                        "expected_total_item": _mOutboundData[index].expected_total_item,
                        "expected_value": _mOutboundData[index].expected_value,
                        "realization_departure_time": _mOutboundData[index].realization_departure_time,
                        "realization_loading_time": _mOutboundData[index].realization_loading_time,
                        "realization_total_item": _mOutboundData[index].realization_total_item,
                        "realization_value": _mOutboundData[index].realization_value,
                        "outbound_issue_date": _mOutboundData[index].outbound_issue_date,
                        "expected_item_count": _mOutboundData[index].expected_item_count,
                        "realization_item_count": _mOutboundData[index].realization_item_count,
                        "created_by": _mOutboundData[index].created_by,
                        "invoice_url" : _mOutboundData[index].invoice_url,
                        "payment_status": _mOutboundData[index].payment_status,
                        "payment_term": _mOutboundData[index].id_payment_term_condition,
                        "payment_term_condition_name": _mOutboundData[index].payment_term_condition,
                        "approval_status": _mOutboundData[index].approval_status,
                        "id_company_receiver": _mOutboundData[index].id_company_receiver
                    }
                    var _loc2 = {
                        'label': _mOutboundData[index].outbound_number,
                        'value': _mOutboundData[index].id,
                        "id": _mOutboundData[index].id,
                        "id_company": _mOutboundData[index].id_company,
                        "id_administration_office": _mOutboundData[index].id_administration_office,
                        "admin_office_name": _mOutboundData[index].admin_office_name,
                        "admin_office_address": _mOutboundData[index].admin_office_address,
                        "admin_office_main_phone_number": _mOutboundData[index].admin_office_main_phone_number,
                        "id_sales_order": _mOutboundData[index].id_sales_order,
                        "so_number": _mOutboundData[index].so_number,
                        "so_creation_date": _mOutboundData[index].so_creation_date,
                        "so_due_date": _mOutboundData[index].so_due_date,
                        "id_warehouse": _mOutboundData[index].id_warehouse,
                        "warehouse_name": _mOutboundData[index].warehouse_name,
                        "warehouse_address": _mOutboundData[index].warehouse_address,
                        "id_warehouse_area": _mOutboundData[index].id_warehouse_area,
                        "warehouse_area_name": _mOutboundData[index].warehouse_area_name,
                        "warehouse_area_size": _mOutboundData[index].warehouse_area_size,
                        "id_customer": _mOutboundData[index].id_customer,
                        "id_outbound_type": _mOutboundData[index].id_outbound_type,
                        "outbound_type_name": _mOutboundData[index].outbound_type_name,
                        "id_unit": _mOutboundData[index].id_unit,
                        "unit_name": _mOutboundData[index].unit_name,
                        "outbound_number": _mOutboundData[index].outbound_number,
                        "document_number": _mOutboundData[index].document_number,
                        "expected_departure_time": _mOutboundData[index].expected_departure_time,
                        "expected_loading_time": _mOutboundData[index].expected_loading_time,
                        "expected_total_item": _mOutboundData[index].expected_total_item,
                        "expected_value": _mOutboundData[index].expected_value,
                        "realization_departure_time": _mOutboundData[index].realization_departure_time,
                        "realization_loading_time": _mOutboundData[index].realization_loading_time,
                        "realization_total_item": _mOutboundData[index].realization_total_item,
                        "realization_value": _mOutboundData[index].realization_value,
                        "outbound_issue_date": _mOutboundData[index].outbound_issue_date,
                        "expected_item_count": _mOutboundData[index].expected_item_count,
                        "realization_item_count": _mOutboundData[index].realization_item_count,
                        "created_by": _mOutboundData[index].created_by,
                        "customer_name": _mOutboundData[index].customer_name,
                        "invoice_url" : _mOutboundData[index].invoice_url,
                        "payment_status": _mOutboundData[index].payment_status,
                        "payment_term": _mOutboundData[index].id_payment_term_condition,
                        "payment_term_condition_name": _mOutboundData[index].payment_term_condition,
                        "approval_status": _mOutboundData[index].approval_status,
                        "id_company_receiver": _mOutboundData[index].id_company_receiver,
                    }

                    if(!_mOutboundData[index].is_intercompany){
                        _stckMasterOutboundSelect.push(_loc)
                        _stckMasterOutboundSelectDesc.push(_loc)
                        _stckMasterOutboundSelectWCustomer.push(_loc2)
                    }

                    if(_mOutboundData[index].is_intercompany === 1){
                        if((_loc.payment_term === 7) && (_loc.approval_status === 5)){
                            _stckMasterOutboundSelectIntercompany.push(_loc)
                        }
                        if(_loc.payment_term !== 7){
                            _stckMasterOutboundSelectIntercompany.push(_loc)
                        }
                    }
                })
            }
            return Object.assign({}, state, {stckMasterOutboundData: action.payload, success: true, stckTotalMasterInbound: _totalMasterOutbound, stckMasterOutboundSelect: _stckMasterOutboundSelect, 
                            stckMasterOutboundSelectWCustomerforTriPL: [..._stckMasterOutboundSelectWCustomer].reverse(),
                            stckMasterOutboundSelectWCustomer: [..._stckMasterOutboundSelectWCustomer].reverse(),
                            stckMasterOutboundSelectIntercompany: [..._stckMasterOutboundSelectIntercompany].reverse()
                            ,stckMasterOutboundSelectDesc: _stckMasterOutboundSelectDesc.reverse(), inProgress: false})
        case FAILED_MASTER_OUTBOUND_LIST:
            return ({stckMasterOutboundData: [], success: false, stckTotalMasterInbound: 0, stckMasterOutboundSelect: [], 
                            stckMasterOutboundSelectWCustomerforTriPL: [], stckMasterOutboundSelectIntercompany: [],
                            stckMasterOutboundSelectWCustomer: [], stckMasterOutboundSelectDesc: [], inProgress: false})
        default:
            return state
    }
}

const defaultStckMasterOutboundDoWoState = {
    stckMasterOutboundDoWoData: {},
    stckTotalMasterOutboundDoWo: 0,
    success: true,
    inProgress: false,
}

export function StckMasterOutboundDoWo(state = defaultStckMasterOutboundDoWoState, action){
    switch (action.type) {
        case FETCH_MASTER_OUTBOUND_IN_DO_LIST:
            return ({stckMasterOutboundDoWoData: {}, success: true, stckTotalMasterOutboundDoWo: 0,  inProgress: true})
        case RECEIVE_MASTER_OUTBOUND_IN_DO_LIST:
            return Object.assign({}, state, {stckMasterOutboundDoWoData: action.payload, success: true, 
                    stckTotalMasterOutboundDoWo: 0, inProgress: false})
        case FAILED_MASTER_OUTBOUND_IN_DO_LIST:
            return ({stckMasterOutboundDoWoData: {}, success: false, stckTotalMasterOutboundDoWo: 0, inProgress: false})
        default:
            return state
    }
}


