import { FETCH_DRIVERAPPUSER_TABLE, RECEIVE_DRIVERAPPUSER_TABLE, FAILED_DRIVERAPPUSER_TABLE } from '../../actions/transporter/driverappuser.act'

const defaultDriverAppUserListState = {
    driverAppUserListData: [],
    inProgress: false,
    success: true
}

export function DriverAppUsersList(state = defaultDriverAppUserListState, action){
    switch (action.type) {
        case FETCH_DRIVERAPPUSER_TABLE:
            return ({driverAppUserListData: [], success: false, inProgress: true})
        case RECEIVE_DRIVERAPPUSER_TABLE:
            // var _total = action.payload.result.length
            return Object.assign({}, state, {driverAppUserListData: action.payload.result, success: true, inProgress: false})
        case FAILED_DRIVERAPPUSER_TABLE:
            return ({driverAppUserListData: [], success: false, inProgress: false})
        default:
            return state
    }
}