import { FETCH_LL_UTILITY_TYPE_LIST, RECEIVE_LL_UTILITY_TYPE_LIST, FAILED_LL_UTILITY_TYPE_LIST } from '../../actions/landlord/aLandLord-UtilityTypeList'

const defaultLLUtilityTypeState = {
    llUtilityTypeData: [],
    llTotalUtilityType: 0,
    llUtilityTypeSelect: [],
    success: true,
    inProgress: false,
}

export function fetchLLUtilityTypeList(state = defaultLLUtilityTypeState, action){
    switch (action.type) {
        case FETCH_LL_UTILITY_TYPE_LIST:
            return ({llUtilityTypeData: [], success: true, llTotalUtilityType: 0, llUtilityTypeSelect: [], inProgress: true})
        case RECEIVE_LL_UTILITY_TYPE_LIST:
            var _totalUtilityType = action.payload.result.length;
            var _selectUtilityType = []
            if(action.payload.result !== []) {
                var _utilityTypeData = action.payload.result

                _utilityTypeData.forEach((p, index) => {
                    var _util = {
                        'label': _utilityTypeData[index].utility_type_name + ' - ' + _utilityTypeData[index].utility_type_desc,
                        'value': _utilityTypeData[index].id
                    }
                    _selectUtilityType.push(_util)
                })
            }
            return Object.assign({}, state, {llUtilityTypeData: action.payload, success: true, llTotalUtilityType: _totalUtilityType, llUtilityTypeSelect: _selectUtilityType, inProgress: false})
        case FAILED_LL_UTILITY_TYPE_LIST:
            return ({llUtilityTypeData: [], success: false, llTotalUtilityType: 0, llUtilityTypeSelect: [], inProgress: false})
        default:
            return state
    }
}