import { FETCH_COVID_VACCINE_LIST, RECEIVE_COVID_VACCINE_LIST, FAILED_COVID_VACCINE_LIST } from '../../actions/transporter/covidvaccine.act'

const defaultCovidVaccineListState = {
    covidVaccineListData: [],
    inProgress: false,
    success: true, 
    totalVaccine: 0, 
}

export function CovidVaccineList(state = defaultCovidVaccineListState, action){
    switch (action.type) {
        case FETCH_COVID_VACCINE_LIST:
            return ({covidVaccineListData: [], success: false, inProgress: true, totalVaccine: 0})
        case RECEIVE_COVID_VACCINE_LIST:
            var _total = action.payload.result.length
            return Object.assign({}, state, {covidVaccineListData: action.payload.result, success: true, inProgress: false, totalVaccine: _total})
        case FAILED_COVID_VACCINE_LIST:
            return ({covidVaccineListData: [], success: false, inProgress: false, totalVaccine: 0})
        default:
            return state
    }
}