import { FETCH_WORK_ORDERS_TABLE, RECEIVE_WORK_ORDERS_TABLE, FAILED_WORK_ORDERS_TABLE,
    FETCH_WORK_ORDERS_UPLOADED_IMAGE, RECEIVE_WORK_ORDERS_UPLOADED_IMAGE, FAILED_WORK_ORDERS_UPLOADED_IMAGE,
    FETCH_WORK_ORDERS_IMAGE_COMPARE, RECEIVE_WORK_ORDERS_IMAGE_COMPARE, FAILED_WORK_ORDERS_IMAGE_COMPARE} from '../../actions/transporter/aTransporter-WorkOrdersTable'

const defaultWorkOrdersTableState = {
    workOrdersTableData: [],
    success: false,
    saved: false, 
    boolDeparture: false, 
    boolHome: false,
    boolDraft: false,
    totalWO: 0,
    lastSeq: 0,
    woMarker: [],
    overviewPoly: null,
}

export function fetchWorkOrdersTable(state = defaultWorkOrdersTableState, action){
    switch (action.type) {
        case FETCH_WORK_ORDERS_TABLE:
            return ({workOrdersTableData: [], success: false, inProgress: true, boolDeparture: false, boolHome: false, boolDraft: false, totalWO: 0, saved: false, lastSeq: 0, woMarker: [], overviewPoly: null})
        case RECEIVE_WORK_ORDERS_TABLE:
            // var _saved
            // var _allWO = action.payload.result;
            // var arrayRes = []

            // var _totalWO = action.payload.result.length
            // console.log(_totalWO)
            // Departure WO Checker
            // const _departureWO = action.payload.result.find(o => o.wo_seq === 0);
            // const _boolDeparture = _departureWO !== undefined ? true : false

            // Home WO Checker
            // const _homeWO = action.payload.result.find(o => o.wo_seq === 9989);
            // const _boolHome = _homeWO !== undefined ? true : false

            var _boolHome = false
            var _boolDeparture = false

            //Draft Checker
            

            var _listMapMarker = []
            // Create Library for WO Maps Show
            if(Array.isArray(action.payload.result) && action.payload.result.length !== 0) {
                // console.log(action.payload.result)
                var woData = action.payload.result;
                console.log("===>",woData)
                woData.forEach((p, index) => {
                    if(woData[index].lat !== null || woData[index].lng !== null) {
                        // var _locName = woData[index].loc_name ? woData[index].loc_name : ''
                        var mapPoint = {
                            id: woData[index].id,
                            delivery_id: woData[index].delivery_id,
                            id_company: woData[index].id_company,
                            company: woData[index].company,
                            company_phone: woData[index].company_phone,
                            id_saved_place: woData[index].id_saved_place,
                            main_customer: woData[index].main_customer? woData[index].main_customer : woData[index].customer_name,
                            main_customer_phone: woData[index].main_customer_phone? woData[index].main_customer_phone : woData[index].customer_handphone,
                            wo_number: woData[index].wo_number,
                            wo_seq: woData[index].wo_seq,
                            wo_desc: woData[index].wo_desc,
                            wo_from_name: woData[index].wo_from_name,
                            wo_destination_name: woData[index].wo_destination_name,
                            wo_address: woData[index].wo_address,
                            pic_name: woData[index].pic_name,
                            pic_phone: woData[index].pic_phone,
                            loc_name: woData[index].loc_name,
                            lat: woData[index].lat,
                            lng: woData[index].lng,
                            dept_lat: woData[index].dept_lat,
                            dept_lng: woData[index].dept_lng,
                            distance: woData[index].distance,
                            real_distance: woData[index].real_distance,
                            est_departure_time: woData[index].est_departure_time,
                            est_arrival_time: woData[index].est_arrival_time,
                            dept_time: woData[index].dept_time,
                            arrival_time: woData[index].arrival_time,
                            cargo_weight: woData[index].cargo_weight,
                            price_delivery_tkm: woData[index].price_delivery_tkm,
                            toll_fee: woData[index].toll_fee,
                            weight_fee: woData[index].weight_fee,
                            other_fee: woData[index].other_fee,
                            id_security: woData[index].id_security,
                            security_email: woData[index].security_email,
                            status_delivery: woData[index].status_delivery,
                            approval_time: woData[index].approval_time,
                            status_delivery_detail: woData[index].status_delivery_detail,
                            status_customer_approval: woData[index].status_customer_approval,
                            status_customer_approval_detail: woData[index].status_customer_approval_detail,
                            customer_approval_time: woData[index].customer_approval_time,
                            customer_approval_name: woData[index].customer_approval_name,
                            customer_approval_remarks: woData[index].customer_approval_remarks,
                            customer_approval_ip: woData[index].customer_approval_ip,
                            customer_approval_browser: woData[index].customer_approval_browser,
                            id_request: woData[index].id_request,
                            id_request_wo_3pl: woData[index].id_request_wo_3pl,
                            target_type: woData[index].target_type,
                            est_stop_time: woData[index].est_stop_time,
                            break_time: woData[index].break_time,
                            company_requestor: woData[index].company_requestor,
                            company_requestor_email: woData[index].company_requestor_email,
                            non_company_requestor: woData[index].non_company_requestor,
                            non_company_requestor_name: woData[index].non_company_requestor_name,
                            non_company_requestor_email: woData[index].non_company_requestor_email,
                            invoice_id: woData[index].invoice_id,
                            invoice_value_base: woData[index].invoice_value_base,
                            notes: woData[index].notes,
                            notes_for_driver: woData[index].notes_for_driver,
                            est_cost: woData[index].est_cost,
                            est_carbon: woData[index].est_carbon,
                            id_stocker_outbound_saved: woData[index].id_outbound_stocker,
                            id_inbound_stocker: woData[index].id_inbound_stocker,
                            id_bid: woData[index].id_bid,
                            multimodal_track_number: woData[index].multimodal_track_number,
                            link_3pl_stocker: woData[index].link_3pl_stocker,
                            multimodal_type: woData[index].multimodal_type,
                            created_on: woData[index].created_on
                        }

                        _listMapMarker.push(mapPoint)
                        if(mapPoint.wo_seq === 0) {
                            _boolDeparture = true
                        } else if (mapPoint.wo_seq === 9989) {
                            _boolHome = true
                        }
                    }
                })
            }

            _listMapMarker.sort((a, b) => parseInt(a.wo_seq) - parseInt(b.wo_seq));

            // var arrayRes = _listMapMarker
            
            // const indexDept = arrayRes.findIndex(x => x.wo_seq === 0);
            // if (indexDept !== undefined) arrayRes.splice(indexDept, 1);

            // // Remove home from sequence
            // const indexHome = arrayRes.findIndex(x => x.wo_seq === 9989);
            // if (indexHome !== undefined) arrayRes.splice(indexHome, 1);

            var _seq = 0
            var _totalWO = _listMapMarker.length
            var i = 0
            if(_totalWO > 0) {
                for (i = 0; i < _totalWO; i++) {
                    if(_listMapMarker[i].wo_seq === 0 || _listMapMarker[i].wo_seq === 9989) {
                        // console.log(_listMapMarker[i].wo_seq)
                    } else {
                        var _temp = _listMapMarker[i].wo_seq
                        
                        if(_temp > _seq) {
                            _seq = _temp
                        }
                    }
                }
            }

            // console.log('sequence: ' + _seq)
            
            _seq = _seq + 1

            const _draft = action.payload.status_draft;

            const _poly = action.payload.overview_polyline;

            return Object.assign({}, state, { workOrdersTableData: _listMapMarker, success: true, inProgress: false, boolDeparture: _boolDeparture, boolHome: _boolHome, boolDraft: _draft, totalWO: _totalWO, saved: true, lastSeq: _seq, woMarker: _listMapMarker, overviewPoly: _poly })
        case FAILED_WORK_ORDERS_TABLE:
            return ({workOrdersTableData: [], success: false, inProgress: false, boolDeparture: false, boolHome: false, boolDraft: false, totalWO: 0, saved: false, lastSeq: 0, woMarker: [], overviewPoly: null})
        default:
            return state
    }
}

const defaultWoUploadedImagetState = {
    uploadedWoImageData: [],
    uploadedWoImageDatav2: {},
    uploaderEmailSelect:[],
    totalWoImage: 0,
    success: true,  
    inProgress: false
}

export function fetchWoUploadedImage(state = defaultWoUploadedImagetState, action){
    switch (action.type) {
        case FETCH_WORK_ORDERS_UPLOADED_IMAGE:
            return ({uploadedWoImageData: [], uploadedWoImageDatav2: [], uploaderEmailSelect:[],
                        totalWoImage: 0, success: true, inProgress: true})
        case RECEIVE_WORK_ORDERS_UPLOADED_IMAGE:
            let arrImages = reverseImages(action.payload.result)
            let arrImagesV2 = groupImageByUploader(action.payload.result)
            return Object.assign({}, state, {uploadedWoImageData: arrImages, totalWoImage: arrImages.length, uploaderEmailSelect:arrImagesV2.emailSelect,
                                                uploadedWoImageDatav2: arrImagesV2.imageGroupByEmails, success: true, inProgress: false})
        case FAILED_WORK_ORDERS_UPLOADED_IMAGE:
            return ({uploadedWoImageData: [], uploadedWoImageDatav2: [], uploaderEmailSelect:[],
                         totalWoImage: 0, success: false, inProgress: false})
        default:
            return state
    }
}

const reverseImages = function(imageArr){
    return imageArr.reverse()
}

const groupImageByUploader = function(imageArr){
    let imageGroupByEmails = {}
    let emailSelect=[{label:"All", value:"All"}]
    for(let i=0; i<imageArr.length; i++){
        let email = imageArr[i].email_user
        if(!email)
            email="unknown"
        if(!imageGroupByEmails[email]){
            emailSelect.push({
                label:"uploaded by " + email,
                value:email
            })
            imageGroupByEmails[email] = [imageArr[i]]
        }else{
            imageGroupByEmails[email].push(imageArr[i])
        }
    }
    return {imageGroupByEmails, emailSelect}
}

const defaultWoUploadedImageCompareState = {
    uploadedWoImageCompare: [],
    totalImage: 0,
    success: true,  
    inProgress: false
}

export function fetchWoUploadedImageCompare(state = defaultWoUploadedImageCompareState, action){
    switch (action.type) {
        case FETCH_WORK_ORDERS_IMAGE_COMPARE:
            return ({uploadedWoImageCompare: [] ,  totalImage: 0, success: true, inProgress: true})
        case RECEIVE_WORK_ORDERS_IMAGE_COMPARE:
            let arrImages = reverseImages(action.payload.result)
            // let arrImagesV2 = groupImageByUploader(action.payload.result)
            return Object.assign({}, state, {uploadedWoImageCompare: arrImages, totalImage: arrImages.length,
                                                success: true, inProgress: false})
        case FAILED_WORK_ORDERS_IMAGE_COMPARE:
            return ({uploadedWoImageCompare: [], uploadedWoImageComparev2: [],
                         totalImage: 0, success: false, inProgress: false})
        default:
            return state
    }
}
