import { FETCH_STCK_PO_TYPE_LIST, RECEIVE_STCK_PO_TYPE_LIST, FAILED_STCK_PO_TYPE_LIST } from '../../actions/stocker/stck.potype.act'

const defaultStckPoTypeState = {
    stckPoTypeData: [],
    stckTotalPoType: 0,
    stckPoTypeSelect: [],
    success: true,
    inProgress: false,
}

export function StckPoType(state = defaultStckPoTypeState, action){
    switch (action.type) {
        case FETCH_STCK_PO_TYPE_LIST:
            return ({stckPoTypeData: [], success: true, stckTotalPoType: 0, stckPoTypeSelect: [], inProgress: true})
        case RECEIVE_STCK_PO_TYPE_LIST:
            var _totalPoType = action.payload.result.length;
            var _stckPoTypeSelect = []
            if(action.payload.result !== []) {
                var _poTypeData = action.payload.result

                _poTypeData.forEach((p, index) => {
                    var _loc = {
                        'label': _poTypeData[index].po_type_name + ' (' + _poTypeData[index].po_type_desc + ')',
                        'value': _poTypeData[index].id
                    }
                    _stckPoTypeSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckPoTypeData: action.payload, success: true, stckTotalPoType: _totalPoType, stckPoTypeSelect: _stckPoTypeSelect, inProgress: false})
        case FAILED_STCK_PO_TYPE_LIST:
            return ({stckPoTypeData: [], success: false, stckTotalPoType: 0, stckPoTypeSelect: [], inProgress: false})
        default:
            return state
    }
}