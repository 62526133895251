import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_LIVE_MONITORING_GEOCODING_LIST = 'FETCH_LIVE_MONITORING_GEOCODING_LIST'
export const RECEIVE_LIVE_MONITORING_GEOCODING_LIST = 'RECEIVE_LIVE_MONITORING_GEOCODING_LIST'
export const FAILED_LIVE_MONITORING_GEOCODING_LIST = 'FAILED_LIVE_MONITORING_GEOCODING_LIST'
export const CLEAR_LIVE_MONITORING_GEOCODING_LIST = 'CLEAR_LIVE_MONITORING_GEOCODING_LIST'

export function setLiveMonitoringAddress(data, clearUp=false, componentName=""){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        if(clearUp){
            dispatch({
                type: CLEAR_LIVE_MONITORING_GEOCODING_LIST,
                payload:data
            });
        }
        if(!clearUp){
            dispatch({
                type: FETCH_LIVE_MONITORING_GEOCODING_LIST,
                payload:data
            });
            axios({
                method: 'post',
                url: componentName==="FleetObdHistory"?API_LINKS.GOOGLE_GEOCODING_v2:API_LINKS.GOOGLE_GEOCODING,
                headers: {
                    Authorization: auth
                },
                data:{
                    lat:data.lat,
                    lng:data.lng? data.lng : data.lon
                }
            })
            .then((response) => {
                let address = ""
                    if(componentName==="FleetObdHistory"){
                        address = response.data.result.display_name;
                    }
                    else{
                        response.data.result[0].address_components.forEach((addrs, index) => {
                            if(index >= response.data.result[0].address_components.length -2){
                                return
                            }
                            address += addrs.short_name + " "
                            if(((index+1) % 2) === 0){
                                address += "\n"
                            }
                        })
                    }
                dispatch( {
                    type: RECEIVE_LIVE_MONITORING_GEOCODING_LIST,
                    payload: {givenData : data, result: address}
                })
            })
            .catch(function(error){
                console.log(error)
                if (error.response) {
                    if(error.response.status === 401) {
                        dispatch({
                            type: FAILED_LIVE_MONITORING_GEOCODING_LIST,
                            payload: {givenData : data}
                        })
                        return toast.error("Invalid credentials! Please try again!");
                    } else if (error.response.status === 404 || error.response.status === 500) {
                        dispatch({
                            type: FAILED_LIVE_MONITORING_GEOCODING_LIST,
                            payload: {givenData : data}
                        })
                        return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    } else if (error.response.status === 400) {
                        dispatch({
                            type: FAILED_LIVE_MONITORING_GEOCODING_LIST,
                            payload: {givenData : data}
                        })
                        return toast.error(error.response.data.message);
                    } else {
                        dispatch({
                            type: FAILED_LIVE_MONITORING_GEOCODING_LIST,
                            payload: {givenData : data}
                        })
                        return toast.error('Something went wrong... Please try again later...');
                    }
                } else if (error.request) {
                    dispatch({
                        type: FAILED_LIVE_MONITORING_GEOCODING_LIST,
                        payload: {givenData : data}
                    })
                    return toast.error('Request error! Please try again later...')
                } else {
                    dispatch({
                        type: FAILED_LIVE_MONITORING_GEOCODING_LIST,
                        payload: {givenData : data}
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            })
        }
    }
}


export const FETCH_RENTEE_LIVE_MONITORING_GEOCODING_LIST = 'FETCH_RENTEE_LIVE_MONITORING_GEOCODING_LIST'
export const RECEIVE_RENTEE_LIVE_MONITORING_GEOCODING_LIST = 'RECEIVE_RENTEE_LIVE_MONITORING_GEOCODING_LIST'
export const FAILED_RENTEE_LIVE_MONITORING_GEOCODING_LIST = 'FAILED_RENTEE_LIVE_MONITORING_GEOCODING_LIST'
export const CLEAR_RENTEE_LIVE_MONITORING_GEOCODING_LIST = 'CLEAR_RENTEE_LIVE_MONITORING_GEOCODING_LIST'

export function setRenteeLiveMonitoringAddress(data, clearUp=false){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        if(clearUp){
            dispatch({
                type: CLEAR_RENTEE_LIVE_MONITORING_GEOCODING_LIST,
                payload:data
            });
        }
        if(!clearUp){
            dispatch({
                type: FETCH_RENTEE_LIVE_MONITORING_GEOCODING_LIST,
                payload:data
            });
            axios({
                method: 'post',
                url: API_LINKS.GOOGLE_GEOCODING,
                headers: {
                    Authorization: auth
                },
                data:{
                    lat:data.lat,
                    lng:data.lng
                }
            })
            .then((response) => {
                let address = ""
                response.data.result[0].address_components.forEach((addrs, index) => {
                    if(index >= response.data.result[0].address_components.length -2){
                        return
                    }
                    address += addrs.short_name + " "
                    if(((index+1) % 2) === 0){
                        address += "\n"
                    }
                })
                dispatch( {
                    type: RECEIVE_RENTEE_LIVE_MONITORING_GEOCODING_LIST,
                    payload: {givenData : data, result: address}
                })
            })
            .catch(function(error){
                if (error.response) {
                    if(error.response.status === 401) {
                        dispatch({
                            type: FAILED_RENTEE_LIVE_MONITORING_GEOCODING_LIST,
                            payload: {givenData : data}
                        })
                        return toast.error("Invalid credentials! Please try again!");
                    } else if (error.response.status === 404 || error.response.status === 500) {
                        dispatch({
                            type: FAILED_RENTEE_LIVE_MONITORING_GEOCODING_LIST,
                            payload: {givenData : data}
                        })
                        return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    } else if (error.response.status === 400) {
                        dispatch({
                            type: FAILED_RENTEE_LIVE_MONITORING_GEOCODING_LIST,
                            payload: {givenData : data}
                        })
                        return toast.error(error.response.data.message);
                    } else {
                        dispatch({
                            type: FAILED_RENTEE_LIVE_MONITORING_GEOCODING_LIST,
                            payload: {givenData : data}
                        })
                        return toast.error('Something went wrong... Please try again later...');
                    }
                } else if (error.request) {
                    dispatch({
                        type: FAILED_RENTEE_LIVE_MONITORING_GEOCODING_LIST,
                        payload: {givenData : data}
                    })
                    return toast.error('Request error! Please try again later...')
                } else {
                    dispatch({
                        type: FAILED_RENTEE_LIVE_MONITORING_GEOCODING_LIST,
                        payload: {givenData : data}
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            })
        }
    }
}