import { FETCH_STCK_PROVINCES_LIST, RECEIVE_STCK_PROVINCES_LIST, FAILED_STCK_PROVINCES_LIST } from '../../actions/stocker/stck.provinces.act'

const defaultStckProvincesState = {
    stckProvincesData: [],
    stckTotalProvinces: 0,
    stckProvincesSelect: [],
    success: true,
    inProgress: false,
}

export function StckProvinces(state = defaultStckProvincesState, action){
    switch (action.type) {
        case FETCH_STCK_PROVINCES_LIST:
            return ({stckProvincesData: [], success: true, stckTotalProvinces: 0, stckProvincesSelect: [], inProgress: true})
        case RECEIVE_STCK_PROVINCES_LIST:
            var _totalProvinces = action.payload.result.length;
            var _stckProvincesSelect = []
            if(action.payload.result !== []) {
                var _provincesData = action.payload.result

                _provincesData.forEach((p, index) => {
                    if(_provincesData[index].name === "-")
                        return
                    var _loc = {
                        'label': _provincesData[index].name,
                        'value': _provincesData[index].id,
                        'id_country': _provincesData[index].id_country,
                    }
                    _stckProvincesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckProvincesData: action.payload, success: true, stckTotalProvinces: _totalProvinces, stckProvincesSelect: _stckProvincesSelect, inProgress: false})
        case FAILED_STCK_PROVINCES_LIST:
            return ({stckProvincesData: [], success: false, stckTotalProvinces: 0, stckProvincesSelect: [], inProgress: false})
        default:
            return state
    }
}