import {SET_HOVER_CCHV} from '../../actions/transporter/cold-chain-chart-history.act'

const defaultCchvState = {
    Cchv: {},
}

export function Cchv(state = defaultCchvState, action){
    switch (action.type) {
        case SET_HOVER_CCHV:
            return Object.assign({}, state, {Cchv: action.payload})
        default:
            return state
    }
}