import { FETCH_OBD_TYPE_TABLE, RECEIVE_OBD_TYPE_TABLE, FAILED_OBD_TYPE_TABLE } from '../../actions/transporter/aTransporter-OBDTypeTable'

const defaultOBDTypeTableState = {
    obdTypeTableData: []    
}

export function fetchOBDTypeTable(state = defaultOBDTypeTableState, action){
    switch (action.type) {
        case FETCH_OBD_TYPE_TABLE:
            return ({obdTypeTableData: [], inProgress: true})
        case RECEIVE_OBD_TYPE_TABLE:
            return Object.assign({}, state, {obdTypeTableData: action.payload, inProgress: false})
        case FAILED_OBD_TYPE_TABLE:
            return ({obdTypeTableData: [], inProgress: false})
        default:
            return state
    }
}