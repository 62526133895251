import { FETCH_LL_LOCATION_TYPE_LIST, RECEIVE_LL_LOCATION_TYPE_LIST, FAILED_LL_LOCATION_TYPE_LIST } from '../../actions/landlord/aLandlord-LocationTypeList.jsx'

const defaultLLLocationTypeState = {
    llLocationTypeData: [],
    llTotalLocationType: 0,
    llLocationTypeSelect: [],
    success: true,
    inProgress: false,
}

export function fetchLLLocationTypeList(state = defaultLLLocationTypeState, action){
    switch (action.type) {
        case FETCH_LL_LOCATION_TYPE_LIST:
            return ({llLocationTypeData: [], success: true, llTotalLocationType: 0, llLocationTypeSelect: [], inProgress: true})
        case RECEIVE_LL_LOCATION_TYPE_LIST:
            var _totalLocationType = action.payload.result.length;
            var _selectLocationType = []
            if(action.payload.result !== []) {
                var _locationTypeData = action.payload.result

                _locationTypeData.forEach((p, index) => {
                    var _locType = {
                        'label': _locationTypeData[index].loc_type,
                        'value': _locationTypeData[index].id
                    }
                    _selectLocationType.push(_locType)
                })
            }
            return Object.assign({}, state, {llLocationTypeData: action.payload, success: true, llTotalLocationType: _totalLocationType, llLocationTypeSelect: _selectLocationType, inProgress: false})
        case FAILED_LL_LOCATION_TYPE_LIST:
            return ({llLocationTypeData: [], success: false, llTotalLocationType: 0, llLocationTypeSelect: [], inProgress: false})
        default:
            return state
    }
}