import { FETCH_STCK_MUTATION_LIST, RECEIVE_STCK_MUTATION_LIST, FAILED_STCK_MUTATION_LIST } 
from '../../actions/stocker/stck.mutations.act'
import moment from 'moment'

const defaultStckMutationsState = {
    stckMutationsData: [],
    stckTotalMutations: 0,
    stckDataDate: null,
    stckTotalQty: 0,
    success: true,
    inProgress: false,
}

export function StckMutations(state = defaultStckMutationsState, action){
    switch (action.type) {
        case FETCH_STCK_MUTATION_LIST:
            return ({stckMutationsData: [], stckTotalQty: 0, stckDataDate: null,
                success: true, stckTotalMutations: 0, inProgress: true})
        case RECEIVE_STCK_MUTATION_LIST:
            let _totalMutationData = action.payload.mutation.length;
            let _mutationData = action.payload.mutation
            let _totalStock = action.payload.total_qty
            let firstDate = action.payload.first_date
            let lastDate = action.payload.last_date
            let _datetimeRange = `${moment(firstDate).format("YYYY-MM-DD")} - ${moment(lastDate).format("YYYY-MM-DD")}`
            return Object.assign({}, state, {stckMutationsData: _mutationData, success: true, stckTotalQty: _totalStock, 
                                        stckDataDate: _datetimeRange, stckTotalMutations: _totalMutationData, inProgress: false})
        case FAILED_STCK_MUTATION_LIST:
            return ({stckMutationsData: [], success: false, stckTotalQty: 0, stckDataDate: null,
                stckTotalMutations: 0, inProgress: false})
        default:
            return state
    }
}