// import { result } from "lodash";
import {
  FETCH_SO_ITEM_DETAIL_LIST,
  RECEIVE_SO_ITEM_DETAIL_LIST,
  FAILED_SO_ITEM_DETAIL_LIST,
  RECEIVE_SO_ITEM_DETAIL_COUNTER,
} from "../../actions/stocker/stck.soitemdetail.act";

const defaultStckSoItemDetailState = {
  stckSoItemDetailData: [],
  stckSoItemDetailCounterSelect: [],
  stckTotalSoItemDetail: 0,
  stckSoItemDetailSelect: [],
  stckSoItemDetailIsAllPricePerItemFilled: false,
  success: true,
  inProgress: false,
};

export function StckSoItemDetail(state = defaultStckSoItemDetailState, action) {
  switch (action.type) {
    case FETCH_SO_ITEM_DETAIL_LIST:
      return {
        stckSoItemDetailData: [],
        success: true,
        stckTotalSoItemDetail: 0,
        stckSoItemDetailCounterSelect: [],
        stckSoItemDetailSelect: [],
        stckSoItemDetailIsAllPricePerItemFilled: false,
        inProgress: true,
      };
    case RECEIVE_SO_ITEM_DETAIL_LIST:
      var _totalSoItemDetail = action.payload.result.length;
      var _stckSoItemDetailSelect = [];
      let _stckSoItemDetailIsAllPricePerItemFilled = false
      if (action.payload.result.length !== 0) {
        var _soItemDetailData = action.payload.result;
        let pricePerItemCounter = 0
        _soItemDetailData.forEach((p, index) => {
          if(_soItemDetailData[index].price_per_item){
            pricePerItemCounter += 1
          }
          var _loc = {
            label: _soItemDetailData[index].so_number,
            value: _soItemDetailData[index].id,
          };
          _stckSoItemDetailSelect.push(_loc);
        });
        if(pricePerItemCounter === _soItemDetailData.length){
          _stckSoItemDetailIsAllPricePerItemFilled = true
        }
      }
      return Object.assign({}, state, {
        stckSoItemDetailData: action.payload,
        success: true,
        stckTotalSoItemDetail: _totalSoItemDetail,
        stckSoItemDetailCounterSelect: [],
        stckSoItemDetailSelect: _stckSoItemDetailSelect,
        // stckSoItemDetailSelect: [],
        stckSoItemDetailIsAllPricePerItemFilled: _stckSoItemDetailIsAllPricePerItemFilled,
        inProgress: false,
      });
    case RECEIVE_SO_ITEM_DETAIL_COUNTER:
      let payload = action.payload;
      let result = [];
      let resultSelect = [];
      if (payload.length !== 0) {
        result = filterItemByWarehouse(payload[0], payload[2]);
      }
      if (result.length !== 0) {
        result = decreaseRealizationQty(result, payload[3]);
      }
      for (let r of result) {
        if (r.realization_qty < 1) continue;
        resultSelect.push({
          label:
            r.item_name +
            " - " +
            r.batch_number +
            " - " +
            r.warehouse_name +
            " > " +
            r.warehouse_area_name +
            " (" +
            r.realization_qty +
            ") ",
          batch_only: r.batch_number,
          id_master_item: r.id_master_item,
          id_master_item_detail: r.id_master_item_detail,
          realization_qty: r.realization_qty,
          expected_qty: r.expected_qty,
          rejected_qty: r.rejected_qty,
          qty: r.qty,
          value: r.id,
          id_master_inbound: r.id_master_inbound,
          id_administration_office: r.id_administration_office,
          production_date: r.item_detail_production_date,
          expired_date: r.item_detail_expiry_date,
          item_name: r.item_name,
          batch_number: r.batch_number,
          price_per_item: r.price_per_item,
        });
      }
      return Object.assign({}, state, {
        stckSoItemDetailData: action.payload,
        success: true,
        stckTotalSoItemDetail: _totalSoItemDetail,
        stckSoItemDetailCounterSelect: resultSelect,
        // stckSoItemDetailSelect: [],
        stckSoItemDetailSelect: [],
        stckSoItemDetailIsAllPricePerItemFilled: _stckSoItemDetailIsAllPricePerItemFilled,
        inProgress: false,
      });
    case FAILED_SO_ITEM_DETAIL_LIST:
      return {
        stckSoItemDetailData: [],
        success: false,
        stckTotalSoItemDetail: 0,
        stckSoItemDetailCounterSelect: [],
        // stckSoItemDetailSelect: [],
        stckSoItemDetailSelect: result,
        stckSoItemDetailIsAllPricePerItemFilled: false,
        inProgress: false,
      };
    default:
      return state;
  }
}

function filterItemByWarehouse(soItemDetail, masterItemDetail) {
  let result = [];
  if (soItemDetail.length === 0) return result;
  if (masterItemDetail.length === 0) return result;
  for (let item of soItemDetail) {
    let temp = masterItemDetail.filter(
      (i) => i.id === item.id_master_item_detail
    );
    if (temp.length === 0) continue;
    item["id_master_inbound"] = temp[0]["id_master_inbound"];
    item["id_administration_office"] = temp[0]["id_administration_office"];
    item["id_warehouse"] = temp[0]["id_warehouse"];
    item["id_warehouse_area"] = temp[0]["id_warehouse_area"];
    item["warehouse_name"] = temp[0]["warehouse_name"];
    item["warehouse_area_name"] = temp[0]["warehouse_area_name"];
    item["production_date"] = temp[0]["production_date"];
    item["expired_date"] = temp[0]["expiry_date"];
    item["qty"] = temp[0]["qty"];
    result.push(item);
  }
  return result;
}

function decreaseRealizationQty(filteredSoItemDetail, currentSoOutbound) {
  let result = [];
  if (filteredSoItemDetail.length === 0) return filteredSoItemDetail;
  if (currentSoOutbound.length === 0) return filteredSoItemDetail;
  for (let item of filteredSoItemDetail) {
    let temp = currentSoOutbound.filter(
      (i) =>
        i.batch_number === item.batch_number &&
        i.id_master_item === item.id_master_item
    );
    for (let index in temp) {
      item["realization_qty"] = temp[index]
        ? Number(item["realization_qty"]) -
          Number(temp[index]["realization_qty"])
        : item["realization_qty"];
    }
    result.push(item);
  }
  return result;
}
