import {
    FETCH_DEVICE_LIST,
    RECEIVE_DEVICE_LIST,
    FAILED_DEVICE_LIST,
} from "../../types/enermo/devices.types";

const defaultdevicesState = {
    devicesData: [],
    totalDevices: 0,
    devicesSelect: [],
    success: true,
    inProgress: false,
}

export function devicesReducer(state = defaultdevicesState, action){

    const { type, payload } = action

    switch (type) {
        case FETCH_DEVICE_LIST:
            return ({devicesData: [], success: true, deviceTotal: 0, devicesSelect: [], inProgress: true})

        case RECEIVE_DEVICE_LIST:
            var _totalDevice = payload.result.length;
            var _selectDevices = []
            if(payload.result !== []) {
                var _devicesData = payload.result

                _devicesData.forEach((p, index) => {
                    var _util = {
                        'label': _devicesData[index].device_name,
                        'value': _devicesData[index].id
                    }
                    _selectDevices.push(_util)
                })
            }
            return Object.assign({}, state, {devicesData: payload, success: true, totalDevices: _totalDevice, devicesSelect: _selectDevices, inProgress: false})

        case FAILED_DEVICE_LIST:
            return ({devicesData: [], success: false, totalDevices: 0, devicesSelect: [], inProgress: false})

        default:
            return state
    }
}