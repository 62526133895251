import { FETCH_STCK_RACK_LIST, RECEIVE_STCK_RACK_LIST, FAILED_STCK_RACK_LIST } 
from '../../actions/stocker/stck.rack.act'

const defaultStckRacksState = {
    stckRacksData: [],
    stckTotalRacks: 0,
    stckRacksSelect: [],
    success: false,
    inProgress: false,
}

export function StckRacks(state = defaultStckRacksState, action){
    switch (action.type) {
        case FETCH_STCK_RACK_LIST:
            return ({stckRacksData: [], success: false, stckTotalRacks: 0, stckRacksSelect: [], inProgress: true})
        case RECEIVE_STCK_RACK_LIST:
            var _totalRacks = action.payload.result.length;
            var _stckRacksSelect = []
            if(action.payload.result !== []) {
                var _RacksData = action.payload.result

                _RacksData.forEach((p, index) => {
                    var _loc = {
                        'label': "Rack " + _RacksData[index].id,
                        'value': _RacksData[index].id,
                    }
                    _stckRacksSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckRacksData: action.payload.result, success: true, stckTotalRacks: _totalRacks, stckRacksSelect: _stckRacksSelect, inProgress: false})
        case FAILED_STCK_RACK_LIST:
            return ({stckRacksData: [], success: false, stckTotalRacks: 0, stckRacksSelect: [], inProgress: false})
        default:
            return state
    }
}