import{
    FETCH_REQUEST_LIST, RECEIVE_REQUEST_LIST, FAILED_REQUEST_LIST,
    FETCH_REQUEST_RENTEE_CONTRACT_LIST, RECEIVE_REQUEST_RENTEE_CONTRACT_LIST, FAILED_REQUEST_RENTEE_CONTRACT_LIST,
    FETCH_REQUEST_CONTRACT_LIST, RECEIVE_REQUEST_CONTRACT_LIST, FAILED_REQUEST_CONTRACT_LIST,
    FETCH_CONTRACT_LIST, RECEIVE_CONTRACT_LIST, FAILED_CONTRACT_LIST,
    FETCH_FLEET_REQUEST_LIST, RECEIVE_FLEET_REQUEST_LIST, FAILED_FLEET_REQUEST_LIST,
    FETCH_CONTRACT_ALL_LIST, RECEIVE_CONTRACT_ALL_LIST, FAILED_CONTRACT_ALL_LIST,
    FETCH_CONTRACT_OWNER_LIST, RECEIVE_CONTRACT_OWNER_LIST, FAILED_CONTRACT_OWNER_LIST,
    FETCH_FLEET_REQUEST_DETAIL_LIST, RECEIVE_FLEET_REQUEST_DETAIL_LIST, FAILED_FLEET_REQUEST_DETAIL_LIST

}from '../../actions/transporter/rentee-request.act'

function getSelectFromPayload(payloadData, settings){
    let result = []
    if(!payloadData)
        return result
    if(!Array.isArray(payloadData))
        return result
    payloadData.forEach(data => {
        let obj = {}
        Object.keys(settings).forEach(function(key) {
            obj[key] = data[settings[key]]
        });
        result.push(obj)
    })
    return result
}

const defaultRenteeRequestState = {
    totalRenteeRequests:0,
    renteeRequests: [],
    success: true,  
    inProgress: false
}

export function RenteeRequest(state = defaultRenteeRequestState, action){
    switch (action.type) {
        case FETCH_REQUEST_LIST:
            return ({
                // totalRenteeRequests: [],
                success: true, RenteeRequestsSelect: [],
                    inProgress: true, totalRenteeRequests:0})
        case RECEIVE_REQUEST_LIST:
            const {payload} = action
            return Object.assign({}, state, {renteeRequests: payload.result,
                                                totalRenteeRequests: payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_REQUEST_LIST:
            return ({
                // totalRenteeRequests: [],
                success: false, RenteeRequestsSelect: [],
                    inProgress: false, totalRenteeRequests:0,})
        default:
            return state
    }
}

const defaultRenteeRequestsRenteeContractState = {
    totalRenteeRequestsRenteeContract:0,
    renteeRequestsRenteeContract: [],
    success: true,  
    inProgress: false
}

export function RenteeRequestsRenteeContract(state = defaultRenteeRequestsRenteeContractState, action){
    switch (action.type) {
        case FETCH_REQUEST_RENTEE_CONTRACT_LIST:
            return ({
                // totalRenteeRequestsRenteeContract: [],
                success: true, RenteeRequestsRenteeContractSelect: [],
                    inProgress: true, totalRenteeRequestsRenteeContract:0})
        case RECEIVE_REQUEST_RENTEE_CONTRACT_LIST:
            const {payload} = action
            return Object.assign({}, state, {renteeRequestsRenteeContract: payload.result,
                                                totalRenteeRequestsRenteeContract: payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_REQUEST_RENTEE_CONTRACT_LIST:
            return ({
                // totalRenteeRequestsRenteeContract: [],
                success: false, RenteeRequestsRenteeContractSelect: [],
                    inProgress: false, totalRenteeRequestsRenteeContract:0,})
        default:
            return state
    }
}

const defaultRenteeRequestContractState = {
    totalRenteeRequestsContract:0,
    renteeRequests: [],
    success: true,  
    inProgress: false
}

export function RenteeRequestContract(state = defaultRenteeRequestContractState, action){
    switch (action.type) {
        case FETCH_REQUEST_CONTRACT_LIST:
            return ({
                // totalRenteeRequestsContract: [],
                success: true, RenteeRequestsContractSelect: [],
                    inProgress: true, totalRenteeRequestsContract:0})
        case RECEIVE_REQUEST_CONTRACT_LIST:
            const {payload} = action
            return Object.assign({}, state, {renteeRequests: payload.result,
                                                totalRenteeRequestsContract: payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_REQUEST_CONTRACT_LIST:
            return ({
                // totalRenteeRequestsContract: [],
                success: false, RenteeRequestsContractSelect: [],
                    inProgress: false, totalRenteeRequestsContract:0,})
        default:
            return state
    }
}

const defaultRenteeContractState = {
    totalRenteeContract:0,
    renteeContract: [],
    success: true,  
    inProgress: false
}

export function RenteeContract(state = defaultRenteeContractState, action){
    switch (action.type) {
        case FETCH_CONTRACT_LIST:
            return ({
                // totalRenteeContract: [],
                success: true, RenteeContractSelect: [],
                    inProgress: true, totalRenteeContract:0})
        case RECEIVE_CONTRACT_LIST:
            const {payload} = action
            return Object.assign({}, state, {renteeContract: payload.result,
                                                totalRenteeContract: payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_CONTRACT_LIST:
            return ({
                // totalRenteeContract: [],
                success: false, RenteeContractSelect: [],
                    inProgress: false, totalRenteeContract:0,})
        default:
            return state
    }
}

const defaultRenteeContractAllState = {
    totalRenteeContractAll:0,
    renteeContractAll: [],
    renteeContractAllSelect: [],
    success: true,  
    inProgress: false
}

export function RenteeContractAll(state = defaultRenteeContractAllState, action){
    switch (action.type) {
        case FETCH_CONTRACT_ALL_LIST:
            return ({
                // totalRenteeContractAll: [],
                success: true, renteeContractAllSelect: [],
                    inProgress: true, totalRenteeContractAll:0})
        case RECEIVE_CONTRACT_ALL_LIST:
            const {payload} = action
            return Object.assign({}, state, {renteeContractAll: payload,
                                                renteeContractAllSelect: getSelectFromPayload(payload, {
                                                    label:"contract_number",
                                                    value:"id",
                                                    id_request: "id_request"
                                                }),
                                                totalRenteeContractAll: payload.length,
                                                success: true, inProgress: false})
        case FAILED_CONTRACT_ALL_LIST:
            return ({
                // totalRenteeContractAll: [],
                 success: false, renteeContractAllSelect: [],
                    inProgress: false, totalRenteeContractAll:0,})
        default:
            return state
    }
}

const defaultRenteeContractOwnerAllState = {
    totalRenteeContractOwnerAll:0,
    renteeContractAll: [],
    renteeContractOwnerAllSelect: [],
    success: true,  
    inProgress: false
}

export function RenteeContractOwnerAll(state = defaultRenteeContractOwnerAllState, action){
    switch (action.type) {
        case FETCH_CONTRACT_OWNER_LIST:
            return ({
                // totalRenteeContractOwnerAll: [],
                success: true, renteeContractOwnerAllSelect: [],
                    inProgress: true, totalRenteeContractOwnerAll:0})
        case RECEIVE_CONTRACT_OWNER_LIST:
            const {payload} = action
            return Object.assign({}, state, {renteeContractAll: payload,
                                                renteeContractOwnerAllSelect: getSelectFromPayload(payload, {
                                                    label:"contract_number",
                                                    value:"id",
                                                    id_request: "id_request"
                                                }),
                                                totalRenteeContractOwnerAll: payload.length,
                                                success: true, inProgress: false})
        case FAILED_CONTRACT_OWNER_LIST:
            return ({
                // totalRenteeContractOwnerAll: [],
                success: false, renteeContractOwnerAllSelect: [],
                    inProgress: false, totalRenteeContractOwnerAll:0,})
        default:
            return state
    }
}

const defaultRenteeRequestFleetState = {
    totalRenteeRequestFleets:0,
    renteeRequestFleets: [],
    renteeRequestFleetsSelect: [],
    success: true,  
    inProgress: false
}

export function RenteeRequestFleet(state = defaultRenteeRequestFleetState, action){
    switch (action.type) {
        case FETCH_FLEET_REQUEST_LIST:
            return ({renteeRequestFleets: [], success: true, renteeRequestFleetsSelect: [],
                    inProgress: true, totalRenteeRequestFleets:0})
        case RECEIVE_FLEET_REQUEST_LIST:
            const {payload} = action
            let _renteeRequestFleetsSelect = []
            payload.result.forEach((p, index) => {
                let completeFleet = {
                    'label': payload.result[index].reg_number_with_door + '(' + payload.result[index].car_brands + ' ' + payload.result[index].car_type + ')',
                    'value': payload.result[index].id,
                }
                _renteeRequestFleetsSelect.push(completeFleet)
            })
            return Object.assign({}, state, {renteeRequestFleets: action.payload.result,
                                                renteeRequestFleetsSelect: _renteeRequestFleetsSelect,
                                                totalRenteeRequestFleets: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_FLEET_REQUEST_LIST:
            return ({RenteeRequestFleets: [], success: false, renteeRequestFleets: [],
                renteeRequestFleetsSelect: [], inProgress: false, totalRenteeRequestFleets:0,})
        default:
            return state
    }
}

const defaultRenteeRequestFleetDetailState = {
    totalRenteeRequestFleetDetails:0,
    renteeRequestFleetDetails: [],
    success: true,  
    inProgress: false
}

export function RenteeRequestFleetDetail(state = defaultRenteeRequestFleetDetailState, action){
    switch (action.type) {
        case FETCH_FLEET_REQUEST_DETAIL_LIST:
            return ({renteeRequestFleetDetails: [], success: true,
                    inProgress: true, totalRenteeRequestFleetDetails:0})
        case RECEIVE_FLEET_REQUEST_DETAIL_LIST:
            // const {payload} = action
            return Object.assign({}, state, {renteeRequestFleetDetails: action.payload.result,
                                                totalRenteeRequestFleetDetails: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_FLEET_REQUEST_DETAIL_LIST:
            return ({RenteeRequestFleetDetails: [], success: false, renteeRequestFleetDetails: [],
                    inProgress: false, totalRenteeRequestFleetDetails:0,})
        default:
            return state
    }
}

