import { FETCH_STCK_SO_RULE_LIST, RECEIVE_STCK_SO_RULE_LIST, FAILED_STCK_SO_RULE_LIST } from '../../actions/stocker/stck.sorule.act'

const defaultStckSoRuleState = {
    stckSoRuleData: [],
    stckTotalSoRule: 0,
    stckSoRuleSelect: [],
    success: true,
    inProgress: false,
}

export function StckSoRule(state = defaultStckSoRuleState, action){
    switch (action.type) {
        case FETCH_STCK_SO_RULE_LIST:
            return ({stckSoRuleData: [], success: true, stckTotalSoRule: 0, stckSoRuleSelect: [], inProgress: true})
        case RECEIVE_STCK_SO_RULE_LIST:
            var _totalPoRule = action.payload.result.length;
            var _stckSoRuleSelect = []
            if(action.payload.result !== []) {
                var _soRuleData = action.payload.result

                _soRuleData.forEach((p, index) => {
                    var _loc = {
                        'label': `Limit ${_soRuleData[index].limit_so_value} - Step ${_soRuleData[index].step_count}`,                        'value': _soRuleData[index].id,
                        "id_company": _soRuleData[index].id_company,
                        "id_sales_order_type": _soRuleData[index].id_sales_order_type,
                        "limit_so_value": _soRuleData[index].limit_so_value,
                        "step_count": _soRuleData[index].step_count,
                        "id_user": _soRuleData[index].id_user,
                        "so_type_name": _soRuleData[index].so_type_name,
                        "so_type_limit_value": _soRuleData[index].so_type_limit_value,
                    }
                    _stckSoRuleSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckSoRuleData: action.payload, success: true, stckTotalSoRule: _totalPoRule, stckSoRuleSelect: _stckSoRuleSelect, inProgress: false})
        case FAILED_STCK_SO_RULE_LIST:
            return ({stckSoRuleData: [], success: false, stckTotalSoRule: 0, stckSoRuleSelect: [], inProgress: false})
        default:
            return state
    }
}