import { FETCH_DRIVERS_NOTIFICATION_DASHBOARD, RECEIVE_DRIVERS_NOTIFICATION_DASHBOARD, FAILED_DRIVERS_NOTIFICATION_DASHBOARD } from '../../actions/transporter/aTransporter-DriversNotificationDashboard'

const defaultDriversNotificationDashboardState = {
    simExpiredData: [],
    simAlmostExpiredData: [],
    totalNotification: 0,
    inProgress: false,
    success: true
}

export function fetchDriversNotificationDashboard(state = defaultDriversNotificationDashboardState, action){
    switch (action.type) {
        case FETCH_DRIVERS_NOTIFICATION_DASHBOARD:
            return ({
                simExpiredData: [],
                simAlmostExpiredData: [],
                totalNotification: 0,
                inProgress: true,
                success: true
            })
        case RECEIVE_DRIVERS_NOTIFICATION_DASHBOARD:
            return Object.assign({}, state, {
                simExpiredData: action.payload.result.expired_data,
                simAlmostExpiredData: action.payload.result.almost_expired_data,
                totalNotification: ( action.payload.result.count_expired + action.payload.result.count_almost_expired ),
                inProgress: false,
                success: true
            })
        case FAILED_DRIVERS_NOTIFICATION_DASHBOARD:
            return ({
                simExpiredData: [],
                simAlmostExpiredData: [],
                totalNotification: 0,
                inProgress: false,
                success: false})
        default:
            return state
    }
}