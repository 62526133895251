import { FETCH_TRIPL_WORK_ORDER_REQUEST_RESULT, RECEIVE_TRIPL_WORK_ORDER_REQUEST_RESULT, FAILED_TRIPL_WORK_ORDER_REQUEST_RESULT } from '../../actions/transporter/aTransporter-TriPLWORequestListResult'

const nullIdGroupAsAutomaWide = function(triPlRequest){
    triPlRequest.map(request =>{
        if(request.id_group === null)
            request.group_name = "Automa Wide"
        return request
    })
    return triPlRequest
}

const defaultTriPLWorkOrderRequestState = {
    triPLWorkOrderRequestData: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLWorkOrderRequestResult(state = defaultTriPLWorkOrderRequestState, action){
    switch (action.type) {
        case FETCH_TRIPL_WORK_ORDER_REQUEST_RESULT:
            return ({triPLWorkOrderRequestData: [], success: true, inProgress: true})
        case RECEIVE_TRIPL_WORK_ORDER_REQUEST_RESULT:
            return Object.assign({}, state, {triPLWorkOrderRequestData: nullIdGroupAsAutomaWide(action.payload.result), 
                                            success: true, inProgress: false})
        case FAILED_TRIPL_WORK_ORDER_REQUEST_RESULT:
            return ({triPLWorkOrderRequestData: [], success: false, inProgress: false})
        default:
            return state
    }
}