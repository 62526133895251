import { FETCH_STCK_ITEM_TAX_CATEGORIES_LIST, RECEIVE_STCK_ITEM_TAX_CATEGORIES_LIST, FAILED_STCK_ITEM_TAX_CATEGORIES_LIST } from '../../actions/stocker/stck.itemtaxcategories.act'

const defaultStckItemTaxCategoriesState = {
    stckItemTaxCategoriesData: [],
    stckTotalItemTaxCategories: 0,
    stckItemTaxCategoriesSelect: [],
    success: true,
    inProgress: false,
}

export function StckItemTaxCategories(state = defaultStckItemTaxCategoriesState, action){
    switch (action.type) {
        case FETCH_STCK_ITEM_TAX_CATEGORIES_LIST:
            return ({stckItemTaxCategoriesData: [], success: true, stckTotalItemTaxCategories: 0, stckItemTaxCategoriesSelect: [], inProgress: true})
        case RECEIVE_STCK_ITEM_TAX_CATEGORIES_LIST:
            var _totalItemTaxCategories = action.payload.result.length;
            var _stckItemTaxCategoriesSelect = []
            if(action.payload.result !== []) {
                var _itemTaxData = action.payload.result

                _itemTaxData.forEach((p, index) => {
                    var _loc = {
                        'label': _itemTaxData[index].tax_name + ' (' + _itemTaxData[index].tax_value + ')',
                        'value': _itemTaxData[index].id
                    }
                    _stckItemTaxCategoriesSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckItemTaxCategoriesData: action.payload, success: true, stckTotalItemTaxCategories: _totalItemTaxCategories, stckItemTaxCategoriesSelect: _stckItemTaxCategoriesSelect, inProgress: false})
        case FAILED_STCK_ITEM_TAX_CATEGORIES_LIST:
            return ({stckItemTaxCategoriesData: [], success: false, stckTotalItemTaxCategories: 0, stckItemTaxCategoriesSelect: [], inProgress: false})
        default:
            return state
    }
}