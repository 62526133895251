import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_TRIPL_APPROVED_REQUEST_LIST = 'FETCH_TRIPL_APPROVED_REQUEST_LIST'
export const RECEIVE_TRIPL_APPROVED_REQUEST_LIST = 'RECEIVE_TRIPL_APPROVED_REQUEST_LIST'
export const FAILED_TRIPL_APPROVED_REQUEST_LIST = 'FAILED_TRIPL_APPROVED_REQUEST_LIST'

export function fetchTriPLApprovedRequestList(){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_TRIPL_APPROVED_REQUEST_LIST});
        axios({
            method: 'post',
            url: API_LINKS.TRIPL_WO_REQ_APPROVED_REQUEST_LIST_URL,
            headers: {
                Authorization: auth
            },
            data: {
                // date: 
                // For Parameters
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_TRIPL_APPROVED_REQUEST_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_TRIPL_APPROVED_REQUEST_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_TRIPL_APPROVED_REQUEST_LIST
                    })
                    return toast.error("Failed in fetching Work Orders List. Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_TRIPL_APPROVED_REQUEST_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_TRIPL_APPROVED_REQUEST_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_TRIPL_APPROVED_REQUEST_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_TRIPL_APPROVED_REQUEST_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }

        })
    }
}