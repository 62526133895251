import axios from "axios";
import {OPNAME_TASK_LIST_URL, OPNAME_GENERATE_TASK_DETAIL_LIST} from '../../config/stck.links'
import {toast} from 'react-toastify'

export const FETCH_STCK_OPNAME_LIST = 'FETCH_STCK_OPNAME_LIST'
export const RECEIVE_STCK_OPNAME_LIST = 'RECEIVE_STCK_OPNAME_LIST'
export const FAILED_STCK_OPNAME_LIST = 'FAILED_STCK_OPNAME_LIST'

export function fetchStckOpnameList(id_user){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_STCK_OPNAME_LIST});
        axios({
            method: 'post',
            url: OPNAME_TASK_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                created_by: id_user
            }
        })
        .then((response) => {
            response.data.result = response.data.result.filter(d => d.created_by === id_user);
            dispatch( {
                type: RECEIVE_STCK_OPNAME_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_STCK_OPNAME_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_STCK_OPNAME_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_STCK_OPNAME_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_STCK_OPNAME_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_STCK_OPNAME_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_STCK_OPNAME_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export const FETCH_STCK_MY_OPNAME_LIST = 'FETCH_STCK_MY_OPNAME_LIST'
export const RECEIVE_STCK_MY_OPNAME_LIST = 'RECEIVE_STCK_MY_OPNAME_LIST'
export const FAILED_STCK_MY_OPNAME_LIST = 'FAILED_STCK_MY_OPNAME_LIST'

export function fetchStckMyOpnameList(id_user){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_STCK_MY_OPNAME_LIST});
        axios({
            method: 'post',
            url: OPNAME_TASK_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                task_by: id_user
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_STCK_MY_OPNAME_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_STCK_MY_OPNAME_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_STCK_MY_OPNAME_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_STCK_MY_OPNAME_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_STCK_MY_OPNAME_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_STCK_MY_OPNAME_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_STCK_MY_OPNAME_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export const FETCH_STCK_OPNAME_DETAIL_LIST = 'FETCH_STCK_OPNAME_DETAIL_LIST'
export const RECEIVE_STCK_OPNAME_DETAIL_LIST = 'RECEIVE_STCK_OPNAME_DETAIL_LIST'
export const FAILED_STCK_OPNAME_DETAIL_LIST = 'FAILED_STCK_OPNAME_DETAIL_LIST'

export function fetchStckOpnameTaskDetailList(id_opname){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_STCK_OPNAME_DETAIL_LIST});
        axios({
            method: 'post',
            url: OPNAME_GENERATE_TASK_DETAIL_LIST,
            headers: {
                Authorization: auth
            },
            data:{
                id_opname_task_order: id_opname
            }
        })
        .then((response) => {
           response.data.resultTransform = {}
           response.data.result.forEach(d => {
                d.qty_diff = null
                if( d.qty_result_count)
                    d.qty_diff = Math.abs(d.qty_target - d.qty_result_count)
                const warehouse_name = d.warehouse_name ? d.warehouse_name : " "
                if(!response.data.resultTransform[warehouse_name]){
                    response.data.resultTransform[warehouse_name] = {}
                }
                const warehouse_area_name = d.area_name ? d.area_name : " "
                if(!response.data.resultTransform[warehouse_name][warehouse_area_name]){
                    response.data.resultTransform[warehouse_name][warehouse_area_name] = {}
                }
                const rack_name = d.rack_name ? d.rack_name : " "
                if(!response.data.resultTransform[warehouse_name][warehouse_area_name][rack_name]){
                    response.data.resultTransform[warehouse_name][warehouse_area_name][rack_name] = {}
                }
                if(!Array.isArray(response.data.resultTransform[warehouse_name][warehouse_area_name][rack_name])){
                    response.data.resultTransform[warehouse_name][warehouse_area_name][rack_name] = []
                }
                response.data.resultTransform[warehouse_name][warehouse_area_name][rack_name].push(d)
            });
            dispatch( {
                type: RECEIVE_STCK_OPNAME_DETAIL_LIST,
                payload: response.data
            })
        })
        // .catch(function(error){
        //     if (error.response) {
        //         if(error.response.status === 401) {
        //             dispatch({
        //                 type: FAILED_STCK_OPNAME_DETAIL_LIST
        //             })
        //             return toast.error("Invalid username or password! Please try again!");
        //         } else if (error.response.status === 404 || error.response.status === 500) {
        //             dispatch({
        //                 type: FAILED_STCK_OPNAME_DETAIL_LIST
        //             })
        //             return toast.error("Server cannot be contacted! Please ask your system administrator!");
        //         } else if (error.response.status === 400) {
        //             dispatch({
        //                 type: FAILED_STCK_OPNAME_DETAIL_LIST
        //             })
        //             return toast.error(error.response.data.message);
        //         } else {
        //             dispatch({
        //                 type: FAILED_STCK_OPNAME_DETAIL_LIST
        //             })
        //             return toast.error('Something went wrong... Please try again later...');
        //         }
        //     } else if (error.request) {
        //         dispatch({
        //             type: FAILED_STCK_OPNAME_DETAIL_LIST
        //         })
        //         return toast.error('Request error! Please try again later...')
        //     } else {
        //         dispatch({
        //             type: FAILED_STCK_OPNAME_DETAIL_LIST
        //         })
        //         return toast.error('Something went wrong... Please try again later...')
        //     }
        // })
    }
}





