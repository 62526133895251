import { 
        FETCH_PUBLIC_FACILITY_LIST,  
        RECEIVE_PUBLIC_FACILITY_LIST,
        FAILED_PUBLIC_FACILITY_LIST ,

        FETCH_PUBLIC_FACILITY_GLOBAL_LIST,
        RECEIVE_PUBLIC_FACILITY_GLOBAL_LIST,
        FAILED_PUBLIC_FACILITY_GLOBAL_LIST
    } 
    from '../../actions/transporter/publicfacility.act'

const defaultPublicFacitlityState = {
    totalPublicFacitlity:0,
    publicFacilityData: [],
    success: true,  
    inProgress: false
}

export function publicFacility(state = defaultPublicFacitlityState, action){
    switch (action.type) {
        case FETCH_PUBLIC_FACILITY_LIST:
            return ({publicFacilityData: [], success: true, inProgress: true, totalPublicFacitlity:0})
        case RECEIVE_PUBLIC_FACILITY_LIST:
            return Object.assign({}, state, {publicFacilityData: action.payload.result, 
                                                totalPublicFacitlity: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_PUBLIC_FACILITY_LIST:
            return ({publicFacilityData: [], success: false, inProgress: false, totalPublicFacitlity:0,})
        default:
            return state
    }
}

const defaultPublicFacilityGlobalState = {
    totalPublicFacilityGlobal:0,
    publicFacilityGlobalData: [],
    success: true,  
    inProgress: false
}

export function publicFacilityGlobal(state = defaultPublicFacilityGlobalState, action){
    switch (action.type) {
        case FETCH_PUBLIC_FACILITY_GLOBAL_LIST:
            return ({publicFacilityGlobalData: [], success: true, inProgress: true, totalPublicFacilityGlobal:0})
        case RECEIVE_PUBLIC_FACILITY_GLOBAL_LIST:
            return Object.assign({}, state, {publicFacilityGlobalData: action.payload.result, 
                                                totalPublicFacilityGlobal: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_PUBLIC_FACILITY_GLOBAL_LIST:
            return ({publicFacilityGlobalData: [], success: false, inProgress: false, totalPublicFacilityGlobal:0,})
        default:
            return state
    }
}