import axios from "axios";
import {PURCHASE_ORDER_PRINT_JOB_LIST_URL} from '../../config/stck.links'
import {toast} from 'react-toastify'

export const FETCH_STCK_PO_PRINT_JOB_LIST = 'FETCH_STCK_PO_PRINT_JOB_LIST'
export const RECEIVE_STCK_PO_PRINT_JOB_LIST = 'RECEIVE_STCK_PO_PRINT_JOB_LIST'
export const FAILED_STCK_PO_PRINT_JOB_LIST = 'FAILED_STCK_PO_PRINT_JOB_LIST'

export function fetchStckPoPrintJob(obj){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_STCK_PO_PRINT_JOB_LIST});
        axios({
            method: 'post',
            url: PURCHASE_ORDER_PRINT_JOB_LIST_URL,
            headers: {
                Authorization: auth
            },
            data: {
                id: obj? obj.id_print_job: null,
                id_purchase_order: obj? obj.id_purchase_order: null,
                print_job_date: obj? obj.print_job_date: null,
                id_user: obj? obj.id_user: null,
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_STCK_PO_PRINT_JOB_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_STCK_PO_PRINT_JOB_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_STCK_PO_PRINT_JOB_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_STCK_PO_PRINT_JOB_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_STCK_PO_PRINT_JOB_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_STCK_PO_PRINT_JOB_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_STCK_PO_PRINT_JOB_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}