import axios from "axios";
import {
  SALES_ORDER_LIST_URL,
  INTERCOMPANY_LIST_URL,
} from "../../config/stck.links";
import { toast } from "react-toastify";

export const FETCH_STCK_SALES_ORDER_LIST = "FETCH_STCK_SALES_ORDER_LIST";
export const RECEIVE_STCK_SALES_ORDER_LIST = "RECEIVE_STCK_SALES_ORDER_LIST";
export const FAILED_STCK_SALES_ORDER_LIST = "FAILED_STCK_SALES_ORDER_LIST";

export function fetchStckSalesOrder(
  _id=null,
  _idAdmOffice=null,
  _idCustomer=null,
  _idSoType=null,
  _idDivisionHandler=null,
  _idPaymentTermCondition=null
) {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;

  return (dispatch, getState) => {
    dispatch({ type: FETCH_STCK_SALES_ORDER_LIST });
    axios({
      method: "post",
      url: SALES_ORDER_LIST_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        id: _id,
        id_administratioon_office: _idAdmOffice,
        id_customer: _idCustomer,
        id_sales_order_type: _idSoType,
        id_division_handler: _idDivisionHandler,
        id_payment_term_condition: _idPaymentTermCondition,
      },
    })
      .then(async (responseSO) => {
        const intercompanyList = await axios({
          method: "post",
          url: INTERCOMPANY_LIST_URL,
          headers: {
            Authorization: auth,
          },
        });
        let fetchIntercompanyDestination = [];
        await responseSO.data.result.map((r) => {
          r.so_number = r.human_so_number? r.human_so_number : r.so_number
          if (!r.is_intercompany) r.is_intercompany = 0;
          if (r.is_intercompany === 1) {
            r.is_destination = 0;
            const filteredIntercompany = intercompanyList.data.result.filter(
              (i) => i.id_sales_order === r.id
            );
            if (filteredIntercompany.length > 0) {
              r.id_company_destination_name =
                filteredIntercompany[0].id_company_destination_name;
              r.area_name = filteredIntercompany[0].area_name;
              r.customer_name =
                filteredIntercompany[0].id_company_destination_name;
              r.warehouse_name = filteredIntercompany[0].warehouse_name;
              r.intercompany_id = filteredIntercompany[0].id;
              r.inter_po_number = filteredIntercompany[0].po_number;
              r.is_destination =
                filteredIntercompany[0].creator_company ===
                filteredIntercompany[0].id_company_origin
                  ? 0
                  : 1;
              r.approval_status = filteredIntercompany[0].approval_status;
            }
            if (filteredIntercompany.length === 0) {
              r.is_destination = 1;
              fetchIntercompanyDestination.push(
                axios({
                  method: "post",
                  url: INTERCOMPANY_LIST_URL,
                  headers: {
                    Authorization: auth,
                  },
                  data: {
                    id_sales_order: r.id,
                    is_destination: 1,
                  },
                })
              );
            }
          }
        return r;
        });
        Promise.all(fetchIntercompanyDestination)
          .then((responses) => {
            for (let response of responses) {
              let responseResult = response.data.result;
              if (!responseResult) continue;
              if (!responseResult.length > 0) continue;
              const fillDestData = responseSO.data.result.filter(
                (r) => r.id === responseResult[0].id_sales_order
              )[0];
              fillDestData.area_name = responseResult[0].area_name;
              fillDestData.warehouse_name = responseResult[0].warehouse_name;
              fillDestData.customer_name =
                responseResult[0].id_company_origin_name;
              fillDestData.intercompany_id = responseResult[0].id;
              fillDestData.approval_status = responseResult[0].approval_status;
              fillDestData.inter_po_number = responseResult[0].po_number;
              fillDestData.is_destination =
                responseResult[0].creator_company ===
                responseResult[0].id_company_origin
                  ? 0
                  : 1;
            }
          })
          .then(() => {
            dispatch({
              type: RECEIVE_STCK_SALES_ORDER_LIST,
              payload: responseSO.data,
            });
          });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_STCK_SALES_ORDER_LIST,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_STCK_SALES_ORDER_LIST,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_STCK_SALES_ORDER_LIST,
            });
            return toast.error(error.response.data.message);
          } else {
            dispatch({
              type: FAILED_STCK_SALES_ORDER_LIST,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_STCK_SALES_ORDER_LIST,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          dispatch({
            type: FAILED_STCK_SALES_ORDER_LIST,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}

export function fetchStckSalesOrderByIdDestination(id_comp_dest) {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;

  return (dispatch, getState) => {
    dispatch({ type: FETCH_STCK_SALES_ORDER_LIST });
    axios({
      method: "post",
      url: SALES_ORDER_LIST_URL,
      headers: {
        Authorization: auth,
      },
    })
      .then(async (responseSO) => {
        const intercompanyList = await axios({
          method: "post",
          url: INTERCOMPANY_LIST_URL,
          headers: {
            Authorization: auth,
          },
        });
        let fetchIntercompanyDestination = [];
        await responseSO.data.result.forEach((r) => {
          r.so_number = r.human_so_number? r.human_so_number : r.so_number
          if (!r.is_intercompany) r.is_intercompany = 0;
          if (r.is_intercompany === 1) {
            r.is_destination = 0;
            const filteredIntercompany = intercompanyList.data.result.filter(
              (i) => (i.id_company_destination === id_comp_dest) && (i.id_sales_order === r.id)
            );
            if (filteredIntercompany.length > 0) {
              r.id_company_destination_name =
                filteredIntercompany[0].id_company_destination_name;
              r.area_name = filteredIntercompany[0].area_name;
              r.customer_name =
                filteredIntercompany[0].id_company_destination_name;
              r.warehouse_name = filteredIntercompany[0].warehouse_name;
              r.intercompany_id = filteredIntercompany[0].id;
              r.inter_po_number = filteredIntercompany[0].po_number;
              r.approval_status = filteredIntercompany[0].approval_status
              r.is_destination =
              filteredIntercompany[0].creator_company ===
              filteredIntercompany[0].id_company_origin
                ? 0
                : 1;
            }
            if (filteredIntercompany.length === 0) {
              r.is_destination = 1;
              fetchIntercompanyDestination.push(
                axios({
                  method: "post",
                  url: INTERCOMPANY_LIST_URL,
                  headers: {
                    Authorization: auth,
                  },
                  data: {
                    id_sales_order: r.id,
                    is_destination: 1,
                  },
                })
              );
            }
          }
        });
        Promise.all(fetchIntercompanyDestination)
          .then((responses) => {
            for (let response of responses) {
              let responseResult = response.data.result;
              if (!responseResult) continue;
              if (!responseResult.length > 0) continue;
              const fillDestData = responseSO.data.result.filter(
                (r) => r.id === responseResult[0].id_sales_order
              )[0];
              fillDestData.area_name = responseResult[0].area_name;
              fillDestData.warehouse_name = responseResult[0].warehouse_name;
              fillDestData.customer_name =
                responseResult[0].id_company_origin_name;
              fillDestData.intercompany_id = responseResult[0].id;
              fillDestData.approval_status = responseResult[0].approval_status;
              fillDestData.inter_po_number = responseResult[0].po_number;
            }
          })
          .then(() => {
            dispatch({
              type: RECEIVE_STCK_SALES_ORDER_LIST,
              payload: responseSO.data,
            });
          });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_STCK_SALES_ORDER_LIST,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_STCK_SALES_ORDER_LIST,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_STCK_SALES_ORDER_LIST,
            });
            return toast.error(error.response.data.message);
          } else {
            dispatch({
              type: FAILED_STCK_SALES_ORDER_LIST,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_STCK_SALES_ORDER_LIST,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          dispatch({
            type: FAILED_STCK_SALES_ORDER_LIST,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}
