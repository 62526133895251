import axios from "axios";
import {
            OUTBOUND_ITEM_DETAIL_LIST_URL,
            } from '../../config/stck.links'
import {toast} from 'react-toastify'
export const FETCH_ITEMS_ITEM_LIST = 'FETCH_ITEMS_ITEM_LIST'
export const RECEIVE_ITEMS_ITEM_LIST = 'RECEIVE_ITEMS_ITEM_LIST'
export const FAILED_ITEMS_ITEM_LIST = 'FAILED_ITEMS_ITEM_LIST'

export function fetchItemBatch(outboundId){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        try{
            dispatch({type: FETCH_ITEMS_ITEM_LIST});
            let fetchOtubound =
                    axios({
                            method: 'post',
                            url: OUTBOUND_ITEM_DETAIL_LIST_URL,
                            headers: {
                                Authorization: auth
                            },
                            data:{
                                id_master_outbound: outboundId
                            }
                    })            
                Promise.all([fetchOtubound])
                .then(responses => {
                    let payload = []
                    let responseResult = responses[0].data.result
                    if(!responseResult)
                        return payload
                    if(!responseResult.length > 0)
                        return payload
                    return responseResult
                })
                .then(payload => {
                    dispatch( {
                        type: RECEIVE_ITEMS_ITEM_LIST,
                        payload: payload
                    })
                })
        }catch(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_ITEMS_ITEM_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_ITEMS_ITEM_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_ITEMS_ITEM_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_ITEMS_ITEM_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_ITEMS_ITEM_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_ITEMS_ITEM_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        }
    }
}