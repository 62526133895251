import axios from "axios";
import {
  MASTER_ITEM_LIST_URL,
  MASTER_ITEM_LIST_URL_V2,
  MASTER_ITEM_STOCK_COUNT_URL,
} from "../../config/stck.links";
import { toast } from "react-toastify";

export const FETCH_MASTER_ITEM_LIST = "FETCH_MASTER_ITEM_LIST";
export const RECEIVE_MASTER_ITEM_LIST = "RECEIVE_MASTER_ITEM_LIST";
export const FAILED_MASTER_ITEM_LIST = "FAILED_MASTER_ITEM_LIST";

export function fetchMasterItem(
  _id,
  _idBrand,
  _idVendor,
  _idItemCategory,
  _idItemQualityCategory,
  _idItemHandling,
  _idItemTax,
  _skuCode
) {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;

  return (dispatch, getState) => {
    dispatch({ type: FETCH_MASTER_ITEM_LIST });
    axios({
      method: "post",
      url: MASTER_ITEM_LIST_URL_V2,
      headers: {
        Authorization: auth,
      },
      data: {
        id: _id,
        id_brand: _idBrand,
        id_vendor: _idVendor,
        id_item_category: _idItemCategory,
        id_item_category_quality: _idItemQualityCategory,
        id_item_handling_category: _idItemHandling,
        id_item_tax_category: _idItemTax,
        sku_code: _skuCode,
      },
    })
      .then((response) => {
        response.data.intercompany = null;
        dispatch({
          type: RECEIVE_MASTER_ITEM_LIST,
          payload: response.data,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_MASTER_ITEM_LIST,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_MASTER_ITEM_LIST,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_MASTER_ITEM_LIST,
            });
            return toast.error(error.response.data.message);
          } else {
            dispatch({
              type: FAILED_MASTER_ITEM_LIST,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_MASTER_ITEM_LIST,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          dispatch({
            type: FAILED_MASTER_ITEM_LIST,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}

export const FETCH_MASTER_ITEM_LIST_INTERCOMPANY =
  "FETCH_MASTER_ITEM_LIST_INTERCOMPANY";
export const RECEIVE_MASTER_ITEM_LIST_INTERCOMPANY =
  "RECEIVE_MASTER_ITEM_LIST_INTERCOMPANY";
export const FAILED_MASTER_ITEM_LIST_INTERCOMPANY =
  "FAILED_MASTER_ITEM_LIST_INTERCOMPANY";

export function fetchMasterItemIntercompany(_id_company, _item_name) {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;

  return (dispatch, getState) => {
    dispatch({ type: FETCH_MASTER_ITEM_LIST_INTERCOMPANY });
    axios({
      method: "post",
      url: MASTER_ITEM_LIST_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        id_company: _id_company,
        item_name: _item_name,
      },
    })
      .then((response) => {
        dispatch({
          type: RECEIVE_MASTER_ITEM_LIST_INTERCOMPANY,
          payload: response.data,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_MASTER_ITEM_LIST_INTERCOMPANY,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_MASTER_ITEM_LIST_INTERCOMPANY,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_MASTER_ITEM_LIST_INTERCOMPANY,
            });
            return toast.error(error.response.data.message);
          } else {
            dispatch({
              type: FAILED_MASTER_ITEM_LIST_INTERCOMPANY,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_MASTER_ITEM_LIST_INTERCOMPANY,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          dispatch({
            type: FAILED_MASTER_ITEM_LIST_INTERCOMPANY,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}

export const FETCH_MASTER_ITEM_STOCK_COUNT = "FETCH_MASTER_ITEM_STOCK_COUNT";
export const RECEIVE_MASTER_ITEM_STOCK_COUNT =
  "RECEIVE_MASTER_ITEM_STOCK_COUNT";
export const FAILED_MASTER_ITEM_STOCK_COUNT = "FAILED_MASTER_ITEM_STOCK_COUNT";

export function fetchMasterItemStockCount() {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;

  return (dispatch, getState) => {
    dispatch({ type: FETCH_MASTER_ITEM_STOCK_COUNT });
    axios({
      method: "post",
      url: MASTER_ITEM_STOCK_COUNT_URL,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        dispatch({
          type: RECEIVE_MASTER_ITEM_STOCK_COUNT,
          payload: response.data,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_MASTER_ITEM_STOCK_COUNT,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_MASTER_ITEM_STOCK_COUNT,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_MASTER_ITEM_STOCK_COUNT,
            });
            return toast.error(error.response.data.message);
          } else {
            dispatch({
              type: FAILED_MASTER_ITEM_STOCK_COUNT,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_MASTER_ITEM_STOCK_COUNT,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          dispatch({
            type: FAILED_MASTER_ITEM_STOCK_COUNT,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}
