import { FETCH_WAREHOUSE_TRANSFER_ITEM_LIST, RECEIVE_WAREHOUSE_TRANSFER_ITEM_LIST, FAILED_WAREHOUSE_TRANSFER_ITEM_LIST }
             from '../../actions/stocker/stck.warehousetransferitem.act'

const defaultStckWarehouseTransferItemState = {
    stckWarehouseTransferItemData: [],
    stckTotalWarehouseTransferItem: 0,
    stckWarehouseTransferItemSelect: [],
    success: true,
    inProgress: false,
}

export function StckWarehouseTransferItem(state = defaultStckWarehouseTransferItemState, action){
    switch (action.type) {
        case FETCH_WAREHOUSE_TRANSFER_ITEM_LIST:
            return ({stckWarehouseTransferItemData: [], success: true, stckTotalWarehouseTransferItem: 0, stckWarehouseTransferItemSelect: [], inProgress: true})
        case RECEIVE_WAREHOUSE_TRANSFER_ITEM_LIST:
            var _totalUnits = action.payload.result.length;
            var _stckWarehouseTransferItemSelect = []
            if(action.payload.result !== []) {
                var _repackagingsData = action.payload.result
                _repackagingsData.forEach((p, index) => {
                    var _loc = {
                        'label': _repackagingsData[index].code + " - " + _repackagingsData[index].name ,
                        'value': _repackagingsData[index].id
                    }
                    _stckWarehouseTransferItemSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckWarehouseTransferItemData: action.payload, success: true, stckTotalWarehouseTransferItem: _totalUnits, stckWarehouseTransferItemSelect: _stckWarehouseTransferItemSelect, inProgress: false})
        case FAILED_WAREHOUSE_TRANSFER_ITEM_LIST:
            return ({stckWarehouseTransferItemData: [], success: false, stckTotalWarehouseTransferItem: 0, stckWarehouseTransferItemSelect: [], inProgress: false})
        default:
            return state
    }
}