import { FETCH_STCK_OPERATING_AREAS_GEOFENCES_LIST, RECEIVE_STCK_OPERATING_AREAS_GEOFENCES_LIST, FAILED_STCK_OPERATING_AREAS_GEOFENCES_LIST } from '../../actions/stocker/stck.operatingareageofence.act'

const defaultStckOperatingAreaGeofenceState = {
    stckOperatingAreaGeofenceData: [],
    stckTotalOperatingAreaGeofence: 0,
    stckOperatingAreaGeofenceSelect: [],
    success: true,
    inProgress: false,
}

export function StckOperatingAreaGeofences(state = defaultStckOperatingAreaGeofenceState, action){
    switch (action.type) {
        case FETCH_STCK_OPERATING_AREAS_GEOFENCES_LIST:
            return ({stckOperatingAreaGeofenceData: [], success: true, stckTotalOperatingAreaGeofence: 0, stckOperatingAreaGeofenceSelect: [], inProgress: true})
        case RECEIVE_STCK_OPERATING_AREAS_GEOFENCES_LIST:
            var _totalOperatingAreaGeofence = action.payload.result.length;
            var _stckOperatingAreaGeofenceSelect = []
            if(action.payload.result !== []) {
                var _operatingAreaGeofenceData = action.payload.result

                _operatingAreaGeofenceData.forEach((p, index) => {
                    var _loc = {
                        'label': _operatingAreaGeofenceData[index].location_name,
                        'value': _operatingAreaGeofenceData[index].id
                    }
                    _stckOperatingAreaGeofenceSelect.push(_loc)
                })
            }
            return Object.assign({}, state, {stckOperatingAreaGeofenceData: action.payload, success: true, stckTotalOperatingAreaGeofence: _totalOperatingAreaGeofence, stckOperatingAreaGeofenceSelect: _stckOperatingAreaGeofenceSelect, inProgress: false})
        case FAILED_STCK_OPERATING_AREAS_GEOFENCES_LIST:
            return ({stckOperatingAreaGeofenceData: [], success: false, stckTotalOperatingAreaGeofence: 0, stckOperatingAreaGeofenceSelect: [], inProgress: false})
        default:
            return state
    }
}