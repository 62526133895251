import { FETCH_TRIPL_WO_BID_HISTORY_LIST,  
        RECEIVE_TRIPL_WO_BID_HISTORY_LIST,
        FAILED_TRIPL_WO_BID_HISTORY_LIST } 
        from '../../actions/transporter/aTransporter-TriPLWOBidHistoryList'

const nullIdGroupAsAutomaWide = function(triPlRequest){
    triPlRequest.map(request =>{
        if(request.id_group === null)
            request.group_name = "Automa Wide"
        return request
    })
    return triPlRequest
}

const defaultTriPLWoBidHistoryListState = {
    triPLWoBidHistoryListData: [],
    success: true,  
    inProgress: false
}

export function fetchTriPLWoBidHistoryList(state = defaultTriPLWoBidHistoryListState, action){
    switch (action.type) {
        case FETCH_TRIPL_WO_BID_HISTORY_LIST:
            return ({triPLWoBidHistoryListData: [], success: true, inProgress: true})
        case RECEIVE_TRIPL_WO_BID_HISTORY_LIST:
            return Object.assign({}, state, {triPLWoBidHistoryListData: nullIdGroupAsAutomaWide(action.payload.result), 
                                                success: true, inProgress: false})
        case FAILED_TRIPL_WO_BID_HISTORY_LIST:
            return ({triPLWoBidHistoryListData: [], success: false, inProgress: false})
        default:
            return state
    }
}