import { FETCH_LL_SENSOR_LIST, RECEIVE_LL_SENSOR_LIST, FAILED_LL_SENSOR_LIST } from '../../actions/landlord/aLandLord-SensorList'

const defaultLLSensorState = {
    llSensorData: [],
    llTotalSensor: 0,
    llSensorSelect: [],
    success: true,
    inProgress: false,
}

export function fetchLLSensorList(state = defaultLLSensorState, action){
    switch (action.type) {
        case FETCH_LL_SENSOR_LIST:
            return ({llSensorData: [], success: true, llTotalSensor: 0, llSensorSelect: [], inProgress: true})
        case RECEIVE_LL_SENSOR_LIST:
            var _totalSensor = action.payload.result.length;
            var _selectSensor = []
            if(action.payload.result !== []) {
                var _locationData = action.payload.result

                _locationData.forEach((p, index) => {
                    var _loc = {
                        'label': _locationData[index].sensor_fabricator_name + ' - ' + _locationData[index].sensor_fabricator_desc,
                        'value': _locationData[index].id
                    }
                    _selectSensor.push(_loc)
                })
            }
            return Object.assign({}, state, {llSensorData: action.payload, success: true, llTotalSensor: _totalSensor, llSensorSelect: _selectSensor, inProgress: false})
        case FAILED_LL_SENSOR_LIST:
            return ({llSensorData: [], success: false, llTotalSensor: 0, llSensorSelect: [], inProgress: false})
        default:
            return state
    }
}