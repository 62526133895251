import { FETCH_DELIVERY_AUDIT, RECEIVE_DELIVERY_AUDIT, FAILED_DELIVERY_AUDIT } from '../../actions/transporter/aTransporter-DeliveryAudit'
import moment from 'moment';

const defaultDeliveryAuditState = {
    deliveryAuditData: [],
    doDetailsData: {},
    woDetailsData: [],
    fleetPath: [],
    fleetStatus: {},
    totalWO: 0,
    closed_status: null,
    checkIn: '0/0',
    inProgress: false,
    success: false,
    delay_time: 0,
    waypoints: [],
    distance_est: 0,
    distance_all: 0,
}

export function fetchDeliveryAudit(state = defaultDeliveryAuditState, action){
    switch (action.type) {
        case FETCH_DELIVERY_AUDIT:
            return ({deliveryAuditData: [], success: false, totalWO: 0, doDetailsData: {}, woDetailsData: [], closed_status: null, fleetPath: [], fleetStatus: {}, checkIn: '0/0', delay_time: 0, waypoints: [], distance_est: 0, distance_all: 0, inProgress: true})
        case RECEIVE_DELIVERY_AUDIT:
            var _doDetails = action.payload.result.do_details;
            var _woDetails = action.payload.result.checkin;
            var _delayTime = action.payload.result.delay_time;
            var _checkInResult = action.payload.result.checkinresult;
            var _fleetPath = []
            var _waypoints = action.payload.result.location_list;
            var distance_est = action.payload.result.distance_est;
            var distance_all = action.payload.result.distance_all;
            var _fleetStatus = action.payload.result.fleet_status;
            var _closeStatus = action.payload.result.do_details.closed_status;
            // console.log(_waypoints)

            var paths = action.payload.result.path_fleet;

            paths.forEach((p, index) => {
                if(paths[index].lat !== null || paths[index].lng !== null) {
                    var data = {
                        'id': paths[index].no,
                        'obd_id': paths[index].id,
                        'fuel': paths[index].fuel,
                        'fuel_normalized': (paths[index].fuel * 100),
                        'lng': paths[index].lon,
                        'lat': paths[index].lat,
                        'wrn': paths[index].wrn,
                        'wrn_normalized': (paths[index].wrn * 100), //normalized
                        'spd': paths[index].spd,
                        'vsys': paths[index].vsys,
                        'temp': paths[index].temp,
                        'temp_c': paths[index].temp_c,
                        'door_left': paths[index].door_left,
                        'door_right': paths[index].door_right,
                        'door_rear': paths[index].door_rear,
                        'door_rear_normalized': (paths[index].door_rear * 98), //normalized
                        'level_agg' : paths[index].level_agg,
                        'fill_alert': paths[index].fill_alert,
                        '__v': (paths[index].__v * 100), //normalized
                        'time': moment(paths[index].time).format("DD-MMM-YYYY HH:mm:ss")
                    }
                    _fleetPath.push(data)
                }
            })

            var _totalWo = _woDetails.length;
            return Object.assign({}, state, {deliveryAuditData: action.payload, success: true, closed_status: _closeStatus, doDetailsData: _doDetails, woDetailsData: _woDetails, fleetStatus: _fleetStatus, fleetPath: _fleetPath, checkIn: _checkInResult, distance_est: distance_est, distance_all: distance_all, totalWO: _totalWo, delay_time: _delayTime, waypoints: _waypoints, inProgress: false})
        case FAILED_DELIVERY_AUDIT:
            return ({deliveryAuditData: [], success: false, totalWO: 0, doDetailsData: {}, woDetailsData: [], closed_status: null, fleetStatus: {}, fleetPath: [], checkIn: '0/0', delay_time: 0, distance_est: 0, distance_all: 0, waypoints: [], inProgress: false})
        default:
            return state
    }
}